import { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { DocumentEditorModal } from '@/modules/Document'
import { documentHooks } from '@/modules/Document/hooks'
import { documentMutations } from '@/modules/Document/mutations'
import { T } from '@/modules/Language'
import { ageCategoryHooks } from '@/modules/Registry/AgeCategory'
import { salesHooks } from '@/modules/Sales/hooks'

import { ContentSection } from '../../../components'
import {
  Header,
  LessonsAndReward,
  Totals,
  VisitorsTable,
  VSTDocumentSection,
  WarningRow,
} from './components'

export const SalesVST = () => {
  const {
    data: { id: ownerId, vst },
  } = salesHooks.useSalesDetailsContext()

  const { documentsByTemplateType, loading: loadingDocument } =
    documentHooks.useOwnerDocuments({
      ownerId,
    })

  const { ageCategories } = ageCategoryHooks.useAgeCategories()
  const [downloadDocument] = documentMutations.useDownloadDocumentMutation()

  const [isDocumentOpen, setDocumentOpen] = useState<boolean>(false)
  const [isExpanded, setExpanded] = useState<boolean>(false)

  const vstDocument = (documentsByTemplateType['VST'] || [])[0] || null
  const downloadedAt = vstDocument?.document.downloadedAt

  const participants = vst?.participantVstSummary ?? []
  const showSignature = vst?.vstDocumentConfig.showSignature ?? false

  const lodging = participants.filter((summary) => !summary.dayVisitor)
  const dayVisitors = participants.filter((summary) => summary.dayVisitor)

  const showWarning =
    vst &&
    (vst.totalIncompleteParticipants > 0 || vst.totalErrorParticipants > 0)

  return (
    <>
      <ContentSection
        title={<T>SalesDetails:section.vstSummary</T>}
        headerExtra={
          <ExtraHeader>
            {downloadedAt && (
              <Date>
                <T>SalesDetails:VST.printedAt</T>
                {' – '}
                {moment(downloadedAt).format('dd. DD.MM.YY, HH:mm')}
              </Date>
            )}

            <InnocuousButton
              compact
              disabled={loadingDocument || !vstDocument}
              onClick={() => setDocumentOpen(true)}
            >
              <PrimaryColor>
                <T>SalesDetails:VST.openDocument</T>
              </PrimaryColor>
            </InnocuousButton>
          </ExtraHeader>
        }
      >
        <Wrapper>
          <Header isExpanded={isExpanded} setExpanded={setExpanded} />

          {showWarning && <WarningRow />}

          {isExpanded && (
            <ContentWrapper noPadding>
              <VisitorsTable
                ageCategories={ageCategories}
                lodging={lodging}
                dayVisitors={dayVisitors}
              />

              <TotalsWrapper alignItems="center">
                <LessonsAndReward />

                <Spacer />

                <Totals />
              </TotalsWrapper>
            </ContentWrapper>
          )}
        </Wrapper>
      </ContentSection>

      {isDocumentOpen && vstDocument && (
        <DocumentEditorModal
          document={vstDocument.document}
          onClose={() => setDocumentOpen(false)}
          onPrintDocument={({ id }) =>
            downloadDocument({ variables: { input: { id } } })
          }
          renderCustomSettings={() => (
            <VSTDocumentSection
              downloadedAt={vstDocument.document.downloadedAt}
            />
          )}
          showAttributesSettings={showSignature}
          showTemplateSettings
          showSettingsSection
        />
      )}
    </>
  )
}

/////////

const ContentWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
    margin-top: ${theme.spacing.gu(2)}rem;
    padding-top: ${theme.spacing.gu(2)}rem;
  `}
`

const ExtraHeader = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const Date = styled.span`
  white-space: pre;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const TotalsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
    margin-top: ${theme.spacing.gu(2)}rem;
    padding-top: ${theme.spacing.gu(2)}rem;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 1px;
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(3)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem;
  `}
`
