import { getCurrentHostnameBaseUrl } from '@/utils/urls'

// @ts-ignore
const runtimeConfig = window.__willbaRuntimeConfig || {}

/**
 * Generic configuration
 */
export const PUBLIC_URL =
  runtimeConfig.PUBLIC_URL ||
  process.env.REACT_APP_PUBLIC_URL ||
  `${getCurrentHostnameBaseUrl()}:3000`

/**
 * API endpoints
 */
export const API_URL: string =
  runtimeConfig.API_URL ||
  process.env.REACT_APP_API_URL ||
  `${getCurrentHostnameBaseUrl()}:8080/rest`

export const ELASTIC_URL: string =
  runtimeConfig.ELASTIC_URL ||
  process.env.REACT_APP_ELASTIC_URL ||
  `${getCurrentHostnameBaseUrl()}:8080/rest/search`

export const GRAPHQL_URL: string =
  runtimeConfig.GRAPHQL_URL ||
  process.env.REACT_APP_GRAPHQL_URL ||
  `${getCurrentHostnameBaseUrl()}:8080/graphql`

export const WS_URL: string =
  runtimeConfig.WS_URL ||
  process.env.REACT_APP_WS_URL ||
  `ws://${window.location.hostname}:8080/subscriptions`

export const STRAPI_URL: string =
  runtimeConfig.STRAPI_URL ||
  process.env.REACT_APP_STRAPI_URL ||
  `${getCurrentHostnameBaseUrl()}:8080/rest/cms`

/**
 * Keycloak configuration
 */

export const KEYCLOAK_URL =
  runtimeConfig.KEYCLOAK_URL ||
  process.env.KEYCLOAK_URL ||
  'https://auth.willba.app'
// `${getCurrentHostnameBaseUrl()}:9080` For local Keycloak environment

export const KEYCLOAK_REALM =
  runtimeConfig.KEYCLOAK_REALM ||
  process.env.KEYCLOAK_REALM ||
  `willba-development`

export const KEYCLOAK_CLIENT_ID =
  runtimeConfig.KEYCLOAK_CLIENT_ID ||
  process.env.KEYCLOAK_CLIENT_ID ||
  `willba-client`

/**
 * Clarity configuration
 */

export const CLARITY_PROJECT_ID =
  runtimeConfig.CLARITY_PROJECT_ID || process.env.CLARITY_PROJECT_ID || ''
