import { gql, useQuery } from '@apollo/client'

import { DocumentEditorModal } from '@/modules/Document'
import { documentFragments } from '@/modules/Document/fragments'

import {
  InvoiceDocumentQuery,
  InvoiceDocumentQueryVariables,
} from '~generated-types'

const QUERY = gql`
  ${documentFragments.document}

  query InvoiceDocument($input: InvoicesByIdsInput!) {
    invoicesByIds(input: $input) {
      invoices {
        id
        document {
          ...Document
        }
      }
    }
  }
`

type Props = {
  invoiceId: string
  onClose: () => void
}

export const ReceiptModal = ({ invoiceId, onClose }: Props) => {
  const { data } = useQuery<
    InvoiceDocumentQuery,
    InvoiceDocumentQueryVariables
  >(QUERY, {
    variables: {
      input: {
        ids: [invoiceId],
      },
    },
  })

  const document = data?.invoicesByIds.invoices[0].document

  return document ? (
    <DocumentEditorModal document={document} onClose={onClose} />
  ) : null
}

export default ReceiptModal
