import { useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import moment, { Moment } from 'moment'
import styled from 'styled-components/macro'

import { ErrorContainer, ErrorTitle } from '@/components/Errors'
import { FormSectionTitle } from '@/components/FormWrappers'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { FormikRenderProps } from '@/utils/forms'

import {
  CustomerOrganizationDataInput,
  EInvoicingAddressInput,
  PersonInput,
} from '~generated-types'

import {
  AutoSaveIndicator,
  BasicDetailsOrganization,
  basicDetailsOrganizationEditSchema,
  BasicDetailsPerson,
  basicDetailsPersonEditSchema,
} from '../../../../forms'
import { customerHooks } from '../../../../hooks'
import { Customer } from '../../../../types'
import { LabelSelector } from './LabelSelector'
import { PublicName } from './PublicName'

type Props = {
  data: Customer
}

type SubmitValues = {
  eInvoicingAddress: EInvoicingAddressInput
  organization: CustomerOrganizationDataInput
  person: PersonInput
}

export const BasicDetails = ({ data }: Props) => {
  const {
    updateOrganizationCustomer,
    updateOrganizationEInvoicingAddress,
    updatePersonCustomer,
  } = customerHooks.useCustomer({ customerNumber: data.customerNumber })

  const [lastSaved, setLastSaved] = useState<Moment | null>(null)

  const handleUpdateLabels = async (labelIds: string[]) => {
    const doUpdate = () =>
      data.__typename === 'CustomerOrganization'
        ? updateOrganizationCustomer({
            input: {
              customerId: data.id,
              labelIds,
              organization: {
                businessId: data.organization.businessId,
                name: data.organization.name || '',
              },
            },
          })
        : updatePersonCustomer({
            input: {
              customerId: data.id,
              labelIds,
              person: {
                email: data.person.email,
                firstName: data.person.firstName,
                lastName: data.person.lastName,
                phone: data.person.phone,
              },
            },
          })

    return doUpdate()
      .then(() => setLastSaved(moment()))
      .catch(() => setLastSaved(null))
  }

  const handleSubmit = async (
    values: SubmitValues,
    { setStatus, setSubmitting }: FormikHelpers<SubmitValues>
  ) => {
    const doUpdate = async () => {
      if (data.__typename === 'CustomerOrganization') {
        await updateOrganizationCustomer({
          input: {
            customerId: data.id,
            organization: {
              businessId: values?.organization?.businessId,
              name: values?.organization?.name ?? '',
            },
          },
        })
        await updateOrganizationEInvoicingAddress({
          input: {
            customerId: data.id,
            eInvoicingAddress: {
              address: values?.eInvoicingAddress?.address || '',
              operator: values?.eInvoicingAddress?.operator || '',
            },
          },
        })
      } else {
        await updatePersonCustomer({
          input: {
            customerId: data.id,
            person: {
              email: values?.person?.email,
              firstName: values?.person?.firstName || '',
              lastName: values?.person?.lastName || '',
              phone: values?.person?.phone,
            },
          },
        })
      }
    }

    return doUpdate()
      .then(() => {
        setStatus(null)
        setLastSaved(moment())
      })
      .catch(() => {
        setStatus('error')
        setLastSaved(null)
      })
      .finally(() => setSubmitting(false))
  }

  const handleUpdatePublicName = (publicName: string) => {
    const doUpdate = () =>
      data.__typename === 'CustomerOrganization'
        ? updateOrganizationCustomer({
            input: {
              customerId: data.id,
              labelIds: data.labels.map(({ id }) => id),
              organization: {
                businessId: data.organization.businessId,
                name: data.organization.name || '',
              },
              publicName,
            },
          })
        : updatePersonCustomer({
            input: {
              customerId: data.id,
              labelIds: data.labels.map(({ id }) => id),
              person: {
                email: data.person.email,
                firstName: data.person.firstName,
                lastName: data.person.lastName,
                phone: data.person.phone,
              },
              publicName,
            },
          })

    return doUpdate()
      .then(() => setLastSaved(moment()))
      .catch(() => setLastSaved(null))
  }

  return (
    <Formik
      initialValues={getInitialData(data)}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={
        data.__typename === 'CustomerPerson'
          ? basicDetailsPersonEditSchema
          : basicDetailsOrganizationEditSchema
      }
    >
      {(formikRenderProps: FormikRenderProps) => {
        const showSubmitError =
          !formikRenderProps.isSubmitting &&
          formikRenderProps?.status === 'error'

        return (
          <>
            <Form>
              {showSubmitError && (
                <ErrorContainer gutter="gutter">
                  <ErrorTitle>
                    <T>Customers:EditCustomer.submitError</T>
                  </ErrorTitle>
                </ErrorContainer>
              )}
              <FlexColumn>
                <Title>
                  <T>Customers:basicDetails</T>
                  <AutoSaveIndicator
                    formikRenderProps={formikRenderProps}
                    lastSaved={lastSaved}
                  />
                </Title>
                <FlexRow style={{ width: '80%' }}>
                  <FlexColumn flex={1}>
                    {data.__typename === 'CustomerPerson' ? (
                      <BasicDetailsPerson
                        hideTitle
                        formikRenderProps={formikRenderProps}
                        lastSaved={lastSaved}
                      />
                    ) : (
                      <BasicDetailsOrganization
                        hideTitle
                        formikRenderProps={formikRenderProps}
                        lastSaved={lastSaved}
                      />
                    )}
                  </FlexColumn>
                  <FlexColumn flex={1}>
                    <PublicName
                      onChange={handleUpdatePublicName}
                      value={data.publicName || ''}
                    />
                    <LabelSelector
                      onChange={handleUpdateLabels}
                      value={data.labels.map(({ id }) => id)}
                    />
                  </FlexColumn>
                </FlexRow>
              </FlexColumn>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

////////////

const initialValues = {
  eInvoicingAddress: { address: '', operator: '' },
  organization: { businessId: null, name: '' },
  person: { email: null, firstName: '', lastName: '', phone: null },
}

const getInitialData = (customer: Customer): SubmitValues =>
  customer.__typename === 'CustomerPerson'
    ? {
        ...initialValues,
        person: {
          email: customer.person.email,
          firstName: customer.person.firstName,
          lastName: customer.person.lastName,
          phone: customer.person.phone,
        },
      }
    : {
        ...initialValues,
        eInvoicingAddress: customer.eInvoicingAddress
          ? {
              address: customer.eInvoicingAddress.address,
              operator: customer.eInvoicingAddress.operator,
            }
          : { address: '', operator: '' },
        organization: {
          businessId: customer.organization.businessId,
          name: customer.organization.name ?? '',
        },
      }

const Title = styled(FormSectionTitle)`
  display: flex;
  align-items: baseline;
`
