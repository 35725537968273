import styled, { css } from 'styled-components/macro'

export const HeaderButton = styled.div<{ disabled?: boolean; width?: string }>`
  display: grid;
  align-content: center;
  cursor: pointer;
  transition: 0.2s;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${({ width }) => width ?? 'auto'};

  & > div svg {
    display: none;
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      `
        background-color: ${theme.palette.smoke.lighter};

        span,
        svg {
          color: ${theme.palette.primary.main};
        }
      `}

    & > div svg {
      display: block;
    }
  }
`
