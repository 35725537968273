import { gql } from '@apollo/client'

export default gql`
  fragment UserAttribute on DocumentUserAttribute {
    active
    editorValue
    id
    key
    name
    templateValue
  }
`
