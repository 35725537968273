import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

import { FlexRow } from '@/components/Layout'
import { DocumentEditorModal } from '@/modules/Document'
import { Document as DocumentType } from '@/modules/Document/types'
import {
  Customer,
  Document,
  SectionSeparator,
} from '@/modules/Order/components'
import {
  Confirmation,
  ConfirmationByIdPayload,
  OrderType as Order,
} from '@/modules/Order/types'
import { updateStateDocument } from '@/modules/Order/utils'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  DocumentFileStatus as DFS,
  OrderConfirmationAction as CA,
} from '~generated-types'

import { ActionsSection, DetailsSection } from './Sections'

type Props = {
  confirmation: Confirmation
  isVisible: boolean
  order: Order
  refetch: () => Promise<ApolloQueryResult<ConfirmationByIdPayload>>
}

export const Content = ({ confirmation, isVisible, order, refetch }: Props) => {
  const { customer: orderCustomer, id: orderId } = order
  const {
    auditLog: { createdAt },
    customer,
    document: confirmationDocument,
    id,
    lifecycle: { validatedActions },
    type,
  } = confirmation

  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false)

  const readOnly = !validatedActions.find((o) => o.action === CA.Accept)?.valid

  const onUpdateDocument = (document: DocumentType) =>
    updateStateDocument({
      document,
      orderId,
      ownerId: id,
      ownerType: type,
      setOrdersById,
    })

  return (
    <>
      <ActionsSection
        confirmation={confirmation}
        openDocumentModal={() => setDocumentModalOpen(true)}
        orderId={orderId}
      />

      <SectionSeparator />

      <FlexRow>
        <DetailsSection createdAt={createdAt} />

        <SectionSeparator />

        <Customer
          customer={customer}
          orderCustomer={orderCustomer}
          orderId={orderId}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Document
          document={confirmationDocument}
          isVisible={isVisible}
          onUpdateDocument={onUpdateDocument}
          orderId={orderId}
          ownerId={id}
          ownerType={type}
          readOnly={readOnly}
          refetch={refetch}
        />
      </FlexRow>

      {isDocumentModalOpen && confirmationDocument && (
        <DocumentEditorModal
          document={confirmationDocument}
          onClose={() => setDocumentModalOpen(false)}
          onUpdateDocument={onUpdateDocument}
          showAttributesSettings
          showLanguageSettings
          showTemplateSettings
          showSettingsSection={confirmationDocument.fileStatus !== DFS.Final}
        />
      )}
    </>
  )
}
