import { ApolloQueryResult } from '@apollo/client'
import { useSubscription } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { Document as DocumentType } from '@/modules/Document/types'
import { orderSubscriptions } from '@/modules/Order/subscriptions'
import {
  ConfirmationByIdPayload,
  DocumentSubscription,
  DocumentSubscriptionVariables,
  InvoiceByIdPayload,
  OfferByIdPayload,
} from '@/modules/Order/types'

import { OrderPhaseType } from '~generated-types'

import { Attachments, FreeTextInput } from './components'

type RefetchPayload =
  | ConfirmationByIdPayload
  | InvoiceByIdPayload
  | OfferByIdPayload

type Props = {
  document: DocumentType
  freeText?: string | null
  isVisible: boolean
  onUpdateDocument: (document: DocumentType) => void
  orderId: string
  ownerId: string
  ownerType: OrderPhaseType
  readOnly: boolean
  refetch: () => Promise<ApolloQueryResult<RefetchPayload>>
}

export const Document = ({
  document: doc,
  freeText,
  isVisible,
  onUpdateDocument,
  orderId,
  ownerId,
  ownerType,
  readOnly: externalReadOnly,
  refetch,
}: Props) => {
  const { attachments, id, status } = doc

  const readOnly = status === 'CLOSED' || externalReadOnly

  useSubscription<DocumentSubscription, DocumentSubscriptionVariables>(
    orderSubscriptions.DOCUMENT,
    {
      onData({ data: { data } }) {
        if (data) {
          const { data: document } = data.document.event

          if (document) {
            onUpdateDocument(document)
          }
        }
      },
      skip: !isVisible,
      variables: { input: { documentId: id } },
    }
  )

  return (
    <FlexColumn noPadding>
      {ownerType !== OrderPhaseType.OrderConfirmation &&
        ownerType !== OrderPhaseType.Offer &&
        freeText !== undefined && (
          <>
            <FreeTextInput
              freeText={freeText}
              ownerId={ownerId}
              orderId={orderId}
              readOnly={readOnly}
            />

            <HorizontalSeparator />
          </>
        )}

      <Attachments
        attachments={attachments}
        documentId={id}
        onUpdateAttachments={(attachments) =>
          onUpdateDocument({ ...doc, attachments })
        }
        readOnly={readOnly}
        refetch={refetch}
      />
    </FlexColumn>
  )
}

//////

const HorizontalSeparator = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
    margin: ${`${theme.spacing.gutter} 0`};
  `}
`
