import { FC, ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { CheckboxInput } from '@/components/FormControls'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { TreeRow, TreeRowLabel } from '@/components/TreeSelector'
import { T } from '@/modules/Language'
import {
  SalesFacetsPayload,
  SalesFacetsVariables,
  salesQueries,
} from '@/modules/Sales/queries'

type Props = {
  setValue: (value: string[]) => void
  value: string[]
}

export const SaleTypeFilter: FC<Props> = ({
  setValue,
  value,
}): ReactElement => {
  const { data, loading } = useQuery<SalesFacetsPayload, SalesFacetsVariables>(
    salesQueries.SALES_FACETS,
    {
      fetchPolicy: 'cache-first',
      variables: { forCreate: false },
    }
  )

  const facets = data?.registry.salesFacets.map(({ name }) => name).sort() || []

  const toggleValue = (type: string) => {
    if (value.includes(type)) {
      setValue(value.filter((x) => x !== type))
    } else {
      setValue([...value, type])
    }
  }

  return (
    <Section
      label={<T>FrontDesk:DailyCustomerList.saleType.title</T>}
      render={() =>
        loading ? (
          <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        ) : (
          <SelectorWrapper>
            {facets.map((option) => (
              <CheckboxWrapper key={`sale-type-option-${option}`}>
                <CheckboxInput
                  checked={value.includes(option)}
                  noMargin
                  onChange={() => toggleValue(option)}
                >
                  <TreeRowLabel>{option}</TreeRowLabel>
                </CheckboxInput>
              </CheckboxWrapper>
            ))}
          </SelectorWrapper>
        )
      }
    />
  )
}

////////////

const CheckboxWrapper = styled(TreeRow)`
  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const SelectorWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Section = styled(FilterSection)`
  && {
    ${({ theme }) => css`
      padding-bottom: 0;
      margin-bottom: ${theme.spacing.gu(3)}rem;
    `}
  }
`
