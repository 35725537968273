import { gql } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

export default gql`
  ${documentFragments.document}

  subscription Document($input: DocumentFilterInput!) {
    document(input: $input) {
      documentId
      event {
        ... on DocumentEvent {
          data {
            ...Document
          }
          id
          type
        }
      }
    }
  }
`
