import { useDownloadDocumentMutation } from './downloadDocument'
import { useSetDocumentTemplateMutation } from './setDocumentTemplate'
import { useUpdateDocumentMutation } from './updateDocument'
import { useUpdateDocumentUserAttributeMutation } from './updateDocumentUserAttribute'

export const documentMutations = Object.freeze({
  useDownloadDocumentMutation,
  useSetDocumentTemplateMutation,
  useUpdateDocumentMutation,
  useUpdateDocumentUserAttributeMutation,
})
