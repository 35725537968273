import { gql } from '@apollo/client'

export default gql`
  fragment Catalog on Catalog {
    active
    company {
      id
      name
      succeededBy {
        id
      }
    }
    id
    name
  }
`
