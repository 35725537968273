import { CellClassParams, ColDef, ColGroupDef } from 'ag-grid-community'
import { useTranslation } from 'react-i18next'

import { AgGrid } from '@/components/AgGrid'

import {
  CreateInputKey,
  Language,
  Participant,
  SchemaErrors,
} from '../ExcelImport.types'

type Props = {
  fileSchema: (CreateInputKey | null)[]
  languages: Language[]
  participants: Participant[]
  schemaErrors: SchemaErrors
}

export const ParticipantsTable = ({
  fileSchema,
  languages,
  participants,
  schemaErrors,
}: Props) => {
  const { t } = useTranslation()

  const noAddressSchema = fileSchema.filter(
    (i) =>
      i !== 'address1' &&
      i !== 'address2' &&
      i !== 'city' &&
      i !== 'postcode' &&
      i !== 'country' &&
      i !== null
  )

  const schemaHasAddress = fileSchema.some(
    (i) =>
      i === 'address1' ||
      i === 'address2' ||
      i === 'city' ||
      i === 'postcode' ||
      i === 'country'
  )

  const columnsNoAddress: (ColDef | ColGroupDef)[] = noAddressSchema
    .filter((field) => field)
    .map((field) => ({
      cellClass: (params: CellClassParams) =>
        !field || schemaErrors[params.rowIndex]?.includes(field)
          ? 'ag-cell-error'
          : '',
      field: field || undefined,
      headerName: t(`ParticipantsList:Import.previewHeader.${field}`),
    }))

  const columnsWithAddress: (ColDef | ColGroupDef)[] = [
    {
      field: 'address',
      headerName: t('ParticipantsList:PersonalFields.address'),
    },
  ]

  const columns = [
    ...columnsNoAddress,
    ...(schemaHasAddress ? columnsWithAddress : []),
  ]

  const rows = participants.map(
    ({ postalAddress, birthday, education, lifeStage, language, ...rest }) => ({
      ...rest,
      address: schemaHasAddress
        ? [
            postalAddress?.address1,
            postalAddress?.address2,
            postalAddress?.city,
            postalAddress?.country,
            postalAddress?.postcode,
          ]
            .filter(Boolean)
            .join(', ')
        : '',
      birthday: birthday
        ? `${birthday.date}.${birthday.month}.${birthday.year}`
        : '',
      education: education ? t(`enums:education.${education}`) : '',
      language: language
        ? languages.find(({ code }) => code === language)?.name
        : '',
      lifeStage: lifeStage ? t(`enums:lifeStage.${lifeStage}`) : '',
    })
  )

  return (
    <AgGrid
      columnDefs={columns}
      rowData={rows}
      rowClassRules={{
        'ag-row-error': (params) => !!schemaErrors[params.rowIndex],
      }}
    />
  )
}
