import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSubscription } from '@apollo/client'
import moment from 'moment'

import { FetchState, FetchStates } from '@/common/types'
import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T, useLanguageContext } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { ACTIVE_STATES } from '@/modules/Sales/types'
import { getStateLabels } from '@/modules/Sales/utils'

import {
  ParticipantsListDataQuery,
  ParticipantSortProperty,
  ParticipantVstSetAttributesInput,
  ParticipantVstSetDatesDefaultInput,
  ParticipantVstSetDatesInput,
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
  SalesParticipantAddRoomInput,
  SalesParticipantCreateInput,
  SalesParticipantFullFragment,
  SalesParticipantSetAccommodationInput,
  SalesParticipantSetRoomFriendsInput,
  SalesParticipantSetVisitStatusInput,
  SalesParticipantUpdateInput,
  SalesType,
  SortOrder,
} from '~generated-types'

import { useParticipants, useSaleParticipants } from './hooks'
import subscriptions from './ParticipantsList.subscriptions'
import {
  FetchedParticipant,
  FilterType,
  Participant,
  ParticipantsFilters,
  SalesParticipantCheckIn,
  SalesParticipantCheckOut,
  SetDatesInput,
} from './ParticipantsList.types'

type ParticipantConnection =
  ParticipantsListDataQuery['sales']['participantConnection']

type Pagination = Omit<ParticipantConnection, 'nodes' | '__typename'> & {
  currentPage: number
}

type SortBy = {
  field: ParticipantSortProperty
  order: SortOrder
}

type ContextType = {
  filters: ParticipantsFilters | null
  fetchState: FetchState
  outdatedParticipantsIds: string[]
  salesId: string
  saleType: SalesType | null
  sort: SortBy[]
  setSort: (sort: SortBy[]) => void
  pageSize: number
  pagination: Pagination
  participants: Participant[]
  refetchAccommodationTick: number
  refetchParticipants: () => Promise<any>
  showAllParticipants: boolean
  setShowAllParticipant: (showAllParticipants: boolean) => void
  handleAddParticipant: (
    input: Omit<SalesParticipantCreateInput, 'salesId'>
  ) => Promise<any>
  handleAddParticipants: (
    input: Omit<SalesParticipantCreateInput, 'salesId'>[]
  ) => Promise<any>
  handleRemoveParticipant: (participantId: string) => Promise<any>
  handleMoveParticipant: (
    participantId: string,
    enrollmentId: string,
    salesId: string
  ) => Promise<any>
  handleChangeParticipantData: (
    data: Omit<SalesParticipantUpdateInput, 'id'>,
    id: string
  ) => Promise<FetchedParticipant | undefined>
  handleAddRoom: (input: SalesParticipantAddRoomInput) => Promise<any>
  handleDeleteService: (id: string) => Promise<any>
  handleRemoveRoom: (roomId: string) => Promise<any>
  handleServiceSetDates: (
    input: SetDatesInput,
    participantId: string
  ) => Promise<any>
  handleSetServiceTarget: (
    input: SalesParticipantSetAccommodationInput
  ) => Promise<any>
  handleRemovePurchase: (
    purchaseId: string,
    participantId: string
  ) => Promise<any>
  handleRemovePurchases: (purchaseIds: string[]) => Promise<any>
  handleSetCheckIn: (
    roomReservationId: string,
    providedTime?: string | null | undefined
  ) => Promise<any>
  handleSetCheckOut: (
    roomReservationId: string,
    providedTime?: string | null | undefined
  ) => Promise<any>
  handleSetVisitStatus: (
    input: SalesParticipantSetVisitStatusInput
  ) => Promise<any>
  handleSetVSTAttributes: (
    input: ParticipantVstSetAttributesInput
  ) => Promise<any>
  handleSetVSTDates: (input: ParticipantVstSetDatesInput) => Promise<any>
  handleSetVSTDatesDefault: (
    input: ParticipantVstSetDatesDefaultInput
  ) => Promise<any>
  handleSwitchPage: (pageNumber: number) => void
  handleSetRoomFriends: (
    input: SalesParticipantSetRoomFriendsInput
  ) => Promise<any>
  handleAddCatalogProduct: (
    input: PurchaseProductAddFromCatalogProductInput
  ) => Promise<any>
  handleAddSalesProduct: (
    input: PurchaseProductAddFromSalesProductInput
  ) => Promise<any>
  handleAddSalesProducts: (
    input: PurchaseProductAddFromSalesProductInput[]
  ) => Promise<any>
  refreshingList: boolean
  setProcessing: (isProcessing: boolean) => void
  setPageSize: (pageSize: number) => void
  setFilters: (filters: ParticipantsFilters) => void
}

const ParticipantsListContext = createContext<ContextType>({
  fetchState: FetchStates.ERROR,
  filters: null,
  handleAddCatalogProduct: () => Promise.reject(),
  handleAddParticipant: () => Promise.reject(),
  handleAddParticipants: () => Promise.reject(),
  handleAddRoom: () => Promise.reject(),
  handleAddSalesProduct: () => Promise.reject(),
  handleAddSalesProducts: () => Promise.reject(),
  handleChangeParticipantData: () => Promise.reject(),
  handleDeleteService: () => Promise.reject(),
  handleMoveParticipant: () => Promise.reject(),
  handleRemoveParticipant: () => Promise.reject(),
  handleRemovePurchase: () => Promise.reject(),
  handleRemovePurchases: () => Promise.reject(),
  handleRemoveRoom: () => Promise.reject(),
  handleServiceSetDates: () => Promise.reject(),
  handleSetCheckIn: () => Promise.reject(),
  handleSetCheckOut: () => Promise.reject(),
  handleSetRoomFriends: () => Promise.reject(),
  handleSetServiceTarget: () => Promise.reject(),
  handleSetVisitStatus: () => Promise.reject(),
  handleSetVSTAttributes: () => Promise.reject(),
  handleSetVSTDates: () => Promise.reject(),
  handleSetVSTDatesDefault: () => Promise.reject(),
  handleSwitchPage: () => undefined,
  outdatedParticipantsIds: [],
  pageSize: 0,
  pagination: {
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  },
  participants: [],
  refetchAccommodationTick: 0,
  refetchParticipants: () => Promise.reject(),
  refreshingList: false,
  salesId: '',
  saleType: null,
  setFilters: () => undefined,
  setPageSize: () => undefined,
  setProcessing: () => undefined,
  setShowAllParticipant: () => undefined,
  setSort: () => undefined,
  showAllParticipants: false,
  sort: [],
})

type Props = {
  children: ReactNode
  salesId: string
}

export const ParticipantsListContextProvider = memo(
  ({ children, salesId }: Props) => {
    const contextValueRef = useRef<ContextType | null>(null)

    const {
      handleAddCatalogProduct: addCatalogProduct,
      handleAddParticipant: addParticipant,
      handleAddParticipants: addParticipants,
      handleAddRoom: addRoom,
      handleAddSalesProduct: addSalesProduct,
      handleAddSalesProducts: addSalesProducts,
      handleChangeParticipantData: changeParticipantData,
      handleSetRoomFriends: setRoomFriends,
      handleDeleteService: deleteService,
      handleMoveParticipant: moveParticipant,
      handleRemoveParticipant: removeParticipant,
      handleRemovePurchase: removePurchase,
      handleRemovePurchases: removePurchases,
      handleRemoveRoom: removeRoom,
      handleSetCheckIn: setCheckIn,
      handleSetCheckOut: setCheckOut,
      handleServiceSetDates: serviceSetDates,
      handleSetServiceTarget: setServiceTarget,
      handleSetVisitStatus: setVisitStatus,
      handleSetVSTAttributes: setVSTAttributes,
      handleSetVSTDates: setVSTDates,
      handleSetVSTDatesDefault: setVSTDatesDefault,
    } = useSaleParticipants()

    const {
      data: {
        facet: { participantsDefaults },
      },
    } = salesHooks.useSalesDetailsContext()

    const { salesStates, loading: salesStatesLoading } =
      salesHooks.useSalesStates()

    const { confirm } = useDialogService()
    const { language } = useLanguageContext()

    const getDefaultFilters = () => ({
      accommodationTargetIds: null,
      ageCategoryIds: null,
      enrollmentStates: salesStates
        ? salesStates
            .filter(({ systemState }) => ACTIVE_STATES.includes(systemState))
            .map(({ key, names }) => ({
              label: getStateLabels(names)[language],
              value: key,
            }))
        : null,
      genders: null,
      roomIds: null,
      search: null,
    })

    // ------------ State data --------------

    const storedCurrentPage: number | null = JSON.parse(
      sessionStorage.getItem(`participantsCurrentPage-${salesId}`) || 'null'
    )
    const storedPageSize: number | null = JSON.parse(
      sessionStorage.getItem(`participantsPageSize-${salesId}`) || 'null'
    )
    const storedSorting: SortBy[] = JSON.parse(
      sessionStorage.getItem(`participantsSort-${salesId}`) || 'null'
    )

    const storedPagination = {
      currentPage: storedCurrentPage ?? 0,
      hasNextPage: false,
      hasPreviousPage: false,
      totalElements: 0,
      totalPages: 0,
    }

    const [participants, setParticipants] = useState<Participant[]>([])
    const [pagination, setPagination] = useState<Pagination>(storedPagination)
    const [pageSize, setPageSizeState] = useState(
      storedPageSize ?? participantsDefaults?.pageSize ?? 10
    )
    const [filters, setFiltersBase] = useState<ParticipantsFilters | null>(null)
    const [sort, setSortState] = useState<SortBy[]>(
      storedSorting ||
        participantsDefaults?.sort.map((sort) => ({
          field: sort.field,
          order: sort.order,
        })) || [
          {
            field: ParticipantSortProperty.FirstRoomNumber,
            order: SortOrder.Asc,
          },
          {
            field: ParticipantSortProperty.LastName,
            order: SortOrder.Asc,
          },
        ] ||
        []
    )
    const [showAllParticipants, setShowAllParticipant] =
      useState<boolean>(false)
    const [fetchState, setState] = useState<FetchState>(FetchStates.LOADING)
    const [saleType, setSaleType] = useState<SalesType | null>(null)
    const [saleNumber, setSaleNumber] = useState<number | null>(0)
    const [outdatedParticipantsIds, setOutdatedParticipantsIds] = useState<
      string[]
    >([])
    const [isProcessing, setProcessing] = useState<boolean>(false)
    const [refetchAccommodationTick, setRefetchAccommodationTick] =
      useState<number>(0)

    // --------------------------------------

    const handleSwitchPage = (pageNumber: number) => {
      if (!pagination) return

      setPagination({
        ...pagination,
        currentPage: pageNumber,
      })

      sessionStorage.setItem(
        `participantsCurrentPage-${salesId}`,
        JSON.stringify(pageNumber)
      )
    }

    const transformParticipantFilters = (
      filters: ParticipantsFilters | null
    ) => {
      if (!filters) {
        return null
      }

      const { search, ...restFilters } = filters

      const transformedFilters = Object.fromEntries(
        Object.entries(restFilters).map(([k, v]) => [
          k,
          v ? v.map(({ value }: FilterType) => value) : null,
        ])
      )

      return { search, ...transformedFilters }
    }

    const participantConnectionInput = {
      filter: transformParticipantFilters(filters),
      pagination: {
        page: showAllParticipants ? 0 : pagination.currentPage,
        size: showAllParticipants ? 1000 : pageSize,
      },
      sort,
    }

    const {
      data: participantsData,
      loading: participantsLoading,
      error: participantsError,
      refetch: refetchParticipants,
    } = useParticipants({
      skip: !filters,
      variables: {
        participantConnectionInput,
        salesId,
      },
    })

    useEffect(() => {
      if (salesStates) {
        setFiltersBase(getDefaultFilters())
      }
    }, [JSON.stringify(salesStates)])

    useEffect(() => {
      if (participantsLoading || salesStatesLoading) {
        return setState(FetchStates.LOADING)
      }

      if (!participantsData || participantsError) {
        return setState(FetchStates.ERROR)
      }

      const sale = participantsData.sales

      fetchState !== FetchStates.IDLE && setState(FetchStates.IDLE)
      !saleType && setSaleType(sale.type)
      !saleNumber && setSaleNumber(sale.orderNumber || null)
      setParticipants(sale.participantConnection.nodes)
      setOutdatedParticipantsIds([])

      const { totalPages, totalElements, hasNextPage, hasPreviousPage } =
        sale.participantConnection

      setPagination({
        currentPage:
          pagination.currentPage > totalPages - 1 ? 0 : pagination.currentPage,
        hasNextPage,
        hasPreviousPage,
        totalElements,
        totalPages,
      })
    }, [participantsData])

    // -------------

    useSubscription(subscriptions.PARTICIPANTS, {
      onData({ data: { data } }) {
        if (isProcessing) {
          return
        }

        const event = data.participant.event
        const participantId = data.participant.participantId
        const participantSaleId = data.participant.salesId

        if (event.__typename === 'ParticipantEvent') {
          if (event.type === 'CREATE') {
            setOutdatedParticipantsIds(
              Array.from(new Set([...outdatedParticipantsIds, participantId]))
            )
          } else if (event.type === 'DELETE') {
            setOutdatedParticipantsIds(
              outdatedParticipantsIds.filter((id) => id !== participantId)
            )
            setParticipants(
              participants.filter(({ id }) => id !== participantId)
            )
          } else {
            const participantSaleNumber =
              salesId === participantSaleId ? saleNumber : null

            const updateState = () =>
              setParticipants(
                participants.map((participant) =>
                  participant.id === participantId
                    ? {
                        ...participant,
                        ...event.data,
                        sales: {
                          ...participant.sales,
                          id: data.participant.salesId,
                        },
                      }
                    : participant
                )
              )

            !participantSaleNumber
              ? setOutdatedParticipantsIds(
                  Array.from(
                    new Set([...outdatedParticipantsIds, participantId])
                  )
                )
              : updateState()
          }
        } else if (event.__typename === 'ServiceParticipantEvent') {
          if (event.type === 'CREATE') {
            setOutdatedParticipantsIds(
              Array.from(new Set(...outdatedParticipantsIds, participantId))
            )
          } else if (event.type === 'DELETE') {
            setParticipants((current) =>
              (current as SalesParticipantFullFragment[]).map((participant) =>
                participant.id === participantId
                  ? {
                      ...participant,
                      services: participant.services.filter(
                        (service) => service.id !== event.id
                      ),
                    }
                  : participant
              )
            )
          } else {
            setOutdatedParticipantsIds(
              Array.from(new Set(...outdatedParticipantsIds, participantId))
            )
          }
        }
      },
      variables: {
        filter: {
          salesId,
        },
      },
    })

    const resetPagination = () =>
      setPagination({ ...pagination, currentPage: 0 })

    const setFilters = (filters: ParticipantsFilters) => {
      setFiltersBase(filters)
      resetPagination()
    }

    const setPageSize = (size: number) => {
      setPageSizeState(size)

      sessionStorage.setItem(
        `participantsPageSize-${salesId}`,
        JSON.stringify(size)
      )
    }

    const setSort = (sort: SortBy[]) => {
      const additionalSort =
        sort[0].field === ParticipantSortProperty.Sales ||
        sort[0].field === ParticipantSortProperty.FirstRoomNumber
          ? [
              {
                field: ParticipantSortProperty.LastName,
                order: SortOrder.Asc,
              },
            ]
          : []
      const sortUpdated = [...sort, ...additionalSort]

      setSortState(sortUpdated)

      sessionStorage.setItem(
        `participantsSort-${salesId}`,
        JSON.stringify(sortUpdated)
      )

      resetPagination()
    }

    // ------------ Methods ------------
    const handleAddParticipant = (
      input: Omit<SalesParticipantCreateInput, 'salesId'>
    ) => {
      setProcessing(true)

      return addParticipant({ ...input, salesId }).then((res) => {
        setProcessing(false)
        if (!res) return

        setParticipants((current) => {
          const existingParticipant = current.find(
            (participant) => participant.id === res.id
          )
          return existingParticipant
            ? current
            : [
                ...current,
                {
                  ...res,
                  isNewParticipant: true,
                  services: [],
                },
              ]
        })

        setPagination({
          ...pagination,
          totalElements: pagination.totalElements + 1,
        })

        return res
      })
    }

    const handleAddParticipants = (
      input: Omit<SalesParticipantCreateInput, 'salesId'>[]
    ) => {
      setProcessing(true)

      return addParticipants(input.map((i) => ({ ...i, salesId }))).then(() =>
        setProcessing(false)
      )
    }

    const handleRemoveParticipant = (id: string) => {
      setProcessing(true)

      const onRemove = () =>
        removeParticipant(id).then((res) => {
          setProcessing(false)
          return res
        })

      return onRemove()
    }

    const handleMoveParticipant = (
      id: string,
      targetSalesId: string,
      salesId: string
    ) => {
      setProcessing(true)

      return moveParticipant(id, targetSalesId, salesId).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) =>
              participant.id === id
                ? {
                    ...res.participant,
                    services: participant.services,
                  }
                : participant
            )
          )

        return res
      })
    }

    const handleChangeParticipantData = (
      data: Omit<SalesParticipantUpdateInput, 'id'>,
      id: string
    ) => {
      const participant = (participants as SalesParticipantFullFragment[]).find(
        (participant) => participant.id === id
      )

      const input = participant && {
        accommodationRequest: participant.accommodationRequest,
        additionalInfo: participant.additionalInfo,
        age: participant.age,
        ageCategoryKey: participant.ageCategory?.key || null,
        allergyDescription: participant.allergyDescription,
        birthday: participant.birthday
          ? {
              date: participant.birthday.date,
              month: participant.birthday.month,
              year: participant.birthday.year,
            }
          : null,
        email: participant.email,
        firstName: participant.firstName,
        gender: participant.gender,
        group: participant.group,
        lastName: participant.lastName,
        nationality: participant.nationality,
        ...data,
        id,
      }

      input && setProcessing(true)

      return input
        ? changeParticipantData(input).then((res) => {
            setProcessing(false)

            res &&
              setParticipants((current) =>
                (current as SalesParticipantFullFragment[]).map(
                  (participant) =>
                    participant.id === id
                      ? {
                          ...res,
                          services: participant.services,
                        }
                      : participant
                )
              )

            return res
          })
        : Promise.reject()
    }

    const handleSetRoomFriends = (input: SalesParticipantSetRoomFriendsInput) =>
      setRoomFriends(input).then((res) => {
        setProcessing(false)

        return (
          res &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) =>
              participant.id === input.id
                ? {
                    ...res,
                    services: participant.services,
                  }
                : participant
            )
          )
        )
      })

    const handleAddRoom = (input: SalesParticipantAddRoomInput) => {
      setProcessing(true)

      return addRoom({
        ...input,
      }).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) =>
              participant.id === input.id
                ? {
                    ...participant,
                    services: [
                      ...participant.services.filter(
                        (service) => service.id !== res.service.id
                      ),
                      res.service,
                    ],
                  }
                : participant
            )
          )

        setRefetchAccommodationTick((t) => t + 1)

        return res
      })
    }

    const handleRemoveRoom = (roomId: string) => {
      setProcessing(true)

      const onRemove = () =>
        removeRoom(roomId).then((res) => {
          setProcessing(false)

          res &&
            setParticipants(
              // @ts-ignore
              (current) =>
                (current as SalesParticipantFullFragment[]).map(
                  (participant) => ({
                    ...participant,
                    services: participant.services
                      .map((service) =>
                        service.__typename === 'ServiceParticipantBed' &&
                        service.participantRoom?.id === roomId
                          ? res.service
                          : service
                      )
                      .filter(Boolean),
                  })
                )
            )

          setRefetchAccommodationTick((t) => t + 1)

          return res
        })

      return confirm({
        cancelLabel: <T>common:action.cancel</T>,
        confirmLabel: (
          <DangerColor>
            <T>common:action.remove</T>
          </DangerColor>
        ),
        description: <T>ParticipantsList:Actions.removeFromRoomConfirmation</T>,
      })
        .then(onRemove)
        .catch(() => setProcessing(false))
    }

    const handleSetCheckIn = (
      roomReservationId: string,
      providedTime: string | null | undefined
    ) => {
      setProcessing(true)

      return setCheckIn(roomReservationId, providedTime).then((res) => {
        setProcessing(false)

        const reservation =
          res &&
          res.find(
            (roomReservation: SalesParticipantCheckIn) =>
              roomReservation.id === roomReservationId
          )

        reservation &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) => ({
              ...participant,
              services: participant.services.map((service) =>
                service.__typename === 'ServiceParticipantBed' &&
                service.participantRoom?.id === roomReservationId
                  ? {
                      ...service,
                      participantRoom: {
                        ...service.participantRoom,
                        checkIn: reservation.checkIn
                          ? moment(reservation?.checkIn).toISOString()
                          : null,
                      },
                    }
                  : service
              ),
            }))
          )

        return res
      })
    }

    const handleSetCheckOut = (
      roomReservationId: string,
      providedTime: string | null | undefined
    ) => {
      setProcessing(true)

      return setCheckOut(roomReservationId, providedTime).then((res) => {
        setProcessing(false)

        const reservation =
          res &&
          res.find(
            (roomReservation: SalesParticipantCheckOut) =>
              roomReservation.id === roomReservationId
          )

        reservation &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) => ({
              ...participant,
              services: participant.services.map((service) =>
                service.__typename === 'ServiceParticipantBed' &&
                service.participantRoom?.id === roomReservationId
                  ? {
                      ...service,
                      participantRoom: {
                        ...service.participantRoom,
                        checkOut: reservation.checkOut
                          ? moment(reservation?.checkOut).toISOString()
                          : null,
                      },
                    }
                  : service
              ),
            }))
          )

        return res
      })
    }

    const handleServiceSetDates = (
      input: SetDatesInput,
      participantId: string
    ) => {
      setProcessing(true)

      return serviceSetDates(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) =>
              participant.id === participantId
                ? {
                    ...participant,
                    services: participant.services.map((service) =>
                      res.service.__typename === 'ServiceParticipantBed' &&
                      service.id === res.service.id
                        ? res.service
                        : service
                    ),
                  }
                : participant
            )
          )

        return res
      })
    }

    const handleDeleteService = (id: string) => {
      setProcessing(true)

      const onRemove = () =>
        deleteService(id).then((res) => {
          setProcessing(false)

          res &&
            setParticipants((current) =>
              (current as SalesParticipantFullFragment[]).map((participant) =>
                participant.id === res.participant?.id
                  ? {
                      ...participant,
                      services: [
                        ...participant.services.filter(
                          ({ id }) => id !== res.id
                        ),
                      ],
                    }
                  : participant
              )
            )

          setRefetchAccommodationTick((t) => t + 1)

          return res
        })

      return confirm({
        cancelLabel: <T>common:action.cancel</T>,
        confirmLabel: (
          <DangerColor>
            <T>common:action.remove</T>
          </DangerColor>
        ),
        description: <T>ParticipantsList:Actions.removeServiceConfirmation</T>,
      })
        .then(onRemove)
        .catch(() => setProcessing(false))
    }

    const handleSetVisitStatus = (
      input: SalesParticipantSetVisitStatusInput
    ) => {
      setProcessing(true)

      return setVisitStatus(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            current.map((participant) =>
              participant.id === input.id
                ? {
                    ...participant,
                    visitStatus: res.visitStatus,
                  }
                : participant
            )
          )

        return res
      })
    }

    const handleAddCatalogProduct = (
      input: PurchaseProductAddFromCatalogProductInput
    ) => {
      setProcessing(true)

      return addCatalogProduct(input)
        .then(() => refetchParticipants())
        .finally(() => setProcessing(false))
    }

    const handleAddSalesProduct = (
      input: PurchaseProductAddFromSalesProductInput
    ) => {
      setProcessing(true)

      return addSalesProduct(input)
        .then(() => refetchParticipants())
        .finally(() => setProcessing(false))
    }

    const handleAddSalesProducts = (
      input: PurchaseProductAddFromSalesProductInput[]
    ) => {
      setProcessing(true)

      return addSalesProducts(input).finally(() => setProcessing(false))
    }

    const handleRemovePurchase = (
      purchaseId: string,
      participantId: string
    ) => {
      setProcessing(true)

      const onRemove = () =>
        removePurchase(purchaseId).then((res) => {
          setProcessing(false)

          res &&
            setParticipants((current) =>
              (current as SalesParticipantFullFragment[]).map((participant) =>
                participant.id === participantId
                  ? {
                      ...participant,
                      services: participant.services.reduce(
                        (acc: SalesParticipantFullFragment['services'], s) => {
                          if (s.purchaseProduct?.id === purchaseId) {
                            return s.__typename === 'ServiceParticipantBed'
                              ? [...acc, { ...s, purchaseProduct: null }]
                              : acc
                          }
                          return [...acc, s]
                        },
                        []
                      ),
                    }
                  : participant
              )
            )

          return res
        })

      return confirm({
        cancelLabel: <T>common:action.cancel</T>,
        confirmLabel: (
          <DangerColor>
            <T>common:action.remove</T>
          </DangerColor>
        ),
        description: <T>ParticipantsList:Actions.removePurchaseConfirmation</T>,
      })
        .then(onRemove)
        .catch(() => setProcessing(false))
    }

    const handleSetServiceTarget = (
      input: SalesParticipantSetAccommodationInput
    ) => {
      setProcessing(true)

      return setServiceTarget(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            (current as SalesParticipantFullFragment[]).map((participant) => {
              const isServiceExist = !!participant.services.find(
                ({ id }) => id === res.service.id
              )

              return participant.id === input.id
                ? {
                    ...participant,
                    services: isServiceExist
                      ? participant.services.map((s) =>
                          s.id === res.service.id ? res.service : s
                        )
                      : [...participant.services, res.service],
                  }
                : participant
            })
          )

        setRefetchAccommodationTick((t) => t + 1)

        return res
      })
    }

    const handleSetVSTAttributes = (
      input: ParticipantVstSetAttributesInput
    ) => {
      setProcessing(true)

      return setVSTAttributes(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            current.map((participant) =>
              participant.id === input.participantId
                ? {
                    ...participant,
                    vst: res.vst,
                  }
                : participant
            )
          )

        return res
      })
    }

    const handleSetVSTDates = (input: ParticipantVstSetDatesInput) => {
      setProcessing(true)

      return setVSTDates(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            current.map((participant) => {
              const participantVstRes = res.find(
                ({ participantId }) => participantId === participant.id
              )
              return participantVstRes
                ? {
                    ...participant,
                    vst: participantVstRes.vst,
                  }
                : participant
            })
          )

        return res
      })
    }

    const handleSetVSTDatesDefault = (
      input: ParticipantVstSetDatesDefaultInput
    ) => {
      setProcessing(true)

      return setVSTDatesDefault(input).then((res) => {
        setProcessing(false)

        res &&
          setParticipants((current) =>
            current.map((participant) => {
              const participantVstRes = res.find(
                ({ participantId }) => participantId === participant.id
              )
              return participantVstRes
                ? {
                    ...participant,
                    vst: participantVstRes.vst,
                  }
                : participant
            })
          )

        return res
      })
    }

    contextValueRef.current = {
      fetchState,
      filters,
      handleAddCatalogProduct,
      handleAddParticipant,
      handleAddParticipants,
      handleAddRoom,
      handleAddSalesProduct,
      handleAddSalesProducts,
      handleChangeParticipantData,
      handleDeleteService,
      handleMoveParticipant,
      handleRemoveParticipant,
      handleRemovePurchase,
      handleRemovePurchases: removePurchases,
      handleRemoveRoom,
      handleServiceSetDates,
      handleSetCheckIn,
      handleSetCheckOut,
      handleSetRoomFriends,
      handleSetServiceTarget,
      handleSetVisitStatus,
      handleSetVSTAttributes,
      handleSetVSTDates,
      handleSetVSTDatesDefault,
      handleSwitchPage,
      outdatedParticipantsIds,
      pageSize,
      pagination,
      participants,
      refetchAccommodationTick,
      refetchParticipants,
      refreshingList: participantsLoading,
      salesId,
      saleType,
      setFilters,
      setPageSize,
      setProcessing,
      setShowAllParticipant,
      setSort,
      showAllParticipants,
      sort,
    }

    return (
      <ParticipantsListContext.Provider value={contextValueRef.current}>
        {children}
      </ParticipantsListContext.Provider>
    )
  }
)

export const useParticipantsListContext = () =>
  useContext(ParticipantsListContext)
