import styled, { css } from 'styled-components/macro'

import { InvoiceConfig as InvoiceConfigType } from '@/modules/Order/types'

import { InvoiceConfig, InvoiceFreeTextInput } from './components'

type Props = {
  config?: InvoiceConfigType
  freeText?: string | null
  orderId: string
  ownerId: string
  readOnly: boolean
  refreshPdf: () => Promise<void>
}

export const InvoiceDocumentSettings = ({
  config,
  freeText,
  ...props
}: Props) => (
  <>
    {config && (
      <>
        <InvoiceConfig config={config} {...props} />

        <HorizontalLine />
      </>
    )}

    {freeText !== undefined && (
      <InvoiceFreeTextInput freeText={freeText} {...props} />
    )}
  </>
)

///////

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`
