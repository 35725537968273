import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import {
  Reservation,
  resourceReservationHooks as hooks,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { Visibility } from '~generated-types'

import { EditButton, IconWrapper } from '../common'
import { getExtraStyles } from './common'

type Props = {
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
  visibility: Visibility
}

export const TimeVisibilityRow = ({
  readOnly,
  reservationId,
  updateReservations,
  visibility,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { setVisibility } = hooks.useResourceReservationMutations({
    updateReservations,
  })

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    return () => setProcessing(false)
  }, [])

  const handleChangeVisibility = (customerTimeVisibility: Visibility) => {
    setProcessing(true)

    setVisibility({ customerTimeVisibility, reservationId }).then(() => {
      setEditMode(false)
      setHover(false)
      setProcessing(false)
    })
  }

  return (
    <InlineModalIconSection>
      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={isProcessing}
            menuIsOpen={isEditMode}
            name="time-visibility-selector"
            onBlur={() => {
              setEditMode(false)
              setHover(false)
            }}
            onChange={(option?: Option | null) =>
              option && handleChangeVisibility(option.value as Visibility)
            }
            options={[Visibility.Shown, Visibility.Hidden].map((value) =>
              getOption(value)
            )}
            placeholder=""
            value={getOption(visibility)}
          />
        ) : (
          <EditButton
            disabled={readOnly}
            onClick={() => setEditMode(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <T>{`ResourceReservations:customerTimeVisibility.${visibility}`}</T>

            {!readOnly && (
              <IconWrapper>
                {isHover && (
                  <FontAwesomeIcon
                    color={palette.text.lighter}
                    icon="pen"
                    size="sm"
                  />
                )}
              </IconWrapper>
            )}
          </EditButton>
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////////

const getOption = (visibility: Visibility) => ({
  label: <T>{`ResourceReservations:customerTimeVisibility.${visibility}`}</T>,
  value: visibility,
})
