import { Glossary } from './glossary.types'

const mergeGlossary = (glossaries: Glossary[], key: keyof Glossary) =>
  glossaries.map((glossary) => glossary[key]).flat()

export const mergeGlossaries = (glossaries: Glossary[]): Glossary => ({
  address1: mergeGlossary(glossaries, 'address1'),
  address2: mergeGlossary(glossaries, 'address2'),
  age: mergeGlossary(glossaries, 'age'),
  allergyDescription: mergeGlossary(glossaries, 'allergyDescription'),
  birthday: mergeGlossary(glossaries, 'birthday'),
  city: mergeGlossary(glossaries, 'city'),
  country: mergeGlossary(glossaries, 'country'),
  drugAllergyDescription: mergeGlossary(glossaries, 'drugAllergyDescription'),
  education: mergeGlossary(glossaries, 'education'),
  email: mergeGlossary(glossaries, 'email'),
  firstName: mergeGlossary(glossaries, 'firstName'),
  gender: mergeGlossary(glossaries, 'gender'),
  group: mergeGlossary(glossaries, 'group'),
  info: mergeGlossary(glossaries, 'info'),
  language: mergeGlossary(glossaries, 'language'),
  lastName: mergeGlossary(glossaries, 'lastName'),
  lifeStage: mergeGlossary(glossaries, 'lifeStage'),
  nationality: mergeGlossary(glossaries, 'nationality'),
  postcode: mergeGlossary(glossaries, 'postcode'),
  roomNumber: mergeGlossary(glossaries, 'roomNumber'),
})
