import { useState } from 'react'

import { DocumentEditorModal } from '@/modules/Document'
import { documentHooks } from '@/modules/Document/hooks'
import { T } from '@/modules/Language'

import { DocumentType } from '~generated-types'

import { SubNavLinkButton } from './SubNavLink'

type Props = {
  ownerId: string
}

export const Program = ({ ownerId }: Props) => {
  const [isEditorOpen, setEditorOpen] = useState<boolean>(false)

  const { documentsByTemplateType, error, loading } =
    documentHooks.useOwnerDocuments({
      ownerId,
    })

  const programDocuments = documentsByTemplateType[DocumentType.Program]

  if (error || !programDocuments?.length) {
    return (
      <SubNavLinkButton
        disabled
        icon={['far', 'file-alt']}
        label={<T>SalesDetails:section.programPDF</T>}
        loading={loading}
        onClick={() => undefined}
      />
    )
  }

  return (
    <>
      <SubNavLinkButton
        disabled={loading}
        icon={['far', 'file-alt']}
        label={<T>SalesDetails:section.programPDF</T>}
        loading={loading}
        onClick={() => setEditorOpen(true)}
      />

      {isEditorOpen && (
        <DocumentEditorModal
          document={programDocuments[0].document}
          onClose={() => setEditorOpen(false)}
          showAttributesSettings
          showLanguageSettings
          showTemplateSettings
          showSettingsSection
        />
      )}
    </>
  )
}
