import { CSSProperties } from 'react'

import { Spacing } from '@/theme'

export const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor:
      styles.backgroundColor === 'transparent' ? 'not-allowed' : 'pointer',
    lineHeight: '24px',
    padding: '4px 8px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
})
