import { useEffect, useMemo, useState } from 'react'
import { get } from 'dot-prop'
import moment, { Moment } from 'moment'
import { parse, stringify } from 'query-string'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ContentWrap } from '@/components/Layout'

import { SalesForDateType } from '~generated-types'

import { CustomerList, Header, Sidebar } from './components'
import { useSalesByIdsStats, useSalesForDate } from './hooks'

export const DailyCustomerList = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const searchObj = parse(search || '')

  const localStoragePosition = useMemo(
    () => JSON.parse(localStorage.getItem('fdLastViewed') || 'null'),
    []
  )

  const searchDate = moment(get(searchObj, 'date') || '')
  const [date, setDate] = useState<Moment>(
    searchDate.isValid() ? searchDate : moment()
  )

  const searchTypes = get(searchObj, 'type') || []
  const types: SalesForDateType[] = Array.isArray(searchTypes)
    ? searchTypes
    : searchTypes
      ? [searchTypes]
      : []

  const searchSaleTypes = get(searchObj, 'saleType') || []
  const saleTypes: string[] = Array.isArray(searchSaleTypes)
    ? searchSaleTypes
    : searchSaleTypes
      ? [searchSaleTypes]
      : []

  const [dimensionHits, setDimensionHits] = useState<string[]>([])
  const [searchHits, setSearchHits] = useState<null | string[]>(null)

  const handleChangeDimensionHits = (salesIds: string[]) => {
    setDimensionHits(salesIds)
  }

  const handleChangeSearchHits = (salesIds: null | string[]) => {
    setSearchHits(salesIds)
  }

  const handleSetDate = (targetDate: Moment) => {
    // We need to use window.location.search, because we use ReactiveSearch
    // components in the filters and their URLParams-functionality gets borked
    // as it does not update the search in useLocation()
    const currentSearch = parse(window.location.search)
    const newSearch = `${stringify({
      ...currentSearch,
      date: targetDate.format('YYYY-MM-DD'),
    })}`
    const targetUrl = `${path}?${newSearch}`

    history.replace(targetUrl)
    setDate(targetDate)

    // Clear focused sales on date changes
    handleSetFocusedId(null)
  }

  const handleSetTypes = (targetTypes: SalesForDateType[]) => {
    // We need to use window.location.search, because we use ReactiveSearch
    // components in the filters and their URLParams-functionality gets borked
    // as it does not update the search in useLocation()
    const currentSearch = parse(window.location.search)
    const newSearch = `${stringify({
      ...currentSearch,
      type: targetTypes,
    })}`
    const targetUrl = `${path}?${newSearch}`

    history.replace(targetUrl)
  }

  const handleSetSaleTypes = (targetSaleTypes: string[]) => {
    // We need to use window.location.search, because we use ReactiveSearch
    // components in the filters and their URLParams-functionality gets borked
    // as it does not update the search in useLocation()
    const currentSearch = parse(window.location.search)
    const newSearch = `${stringify({
      ...currentSearch,
      saleType: targetSaleTypes,
    })}`
    const targetUrl = `${path}?${newSearch}`

    history.replace(targetUrl)
  }

  const handleSetFocusedId = (id: string | null) => {
    id === null && localStorage.removeItem('fdLastViewed')
    setFocusedId(id)
  }

  const [focusedId, setFocusedId] = useState<string | null>(null)

  const onSaleSectionsUpdate = (sections: (string | null)[]) => {
    localStorage.setItem(
      'fdLastViewed',
      JSON.stringify({
        date: date.format('YYYY-MM-DD'),
        salesId: focusedId,
        sections,
      })
    )
  }

  useEffect(() => {
    localStoragePosition?.date &&
      handleSetDate(moment(localStoragePosition?.date))
    setFocusedId(localStoragePosition?.salesId || null)
  }, [localStoragePosition])

  useEffect(() => {
    // Redirect to stored date or current date if target is invalid
    if (!searchDate.isValid()) {
      handleSetDate(moment(localStoragePosition?.date))
      setFocusedId(localStoragePosition?.salesId || null)
    }

    // Fixing the omission of `searchDate` might need bigger changes into the
    // logic of this view. If you include `setDate` into the dependencies array,
    // you'll need to wrap it in a `useCallback`--but in that case you can't pass
    // any parameters to it.
  }, [searchDate])

  const { loading, error, sales } = useSalesForDate({
    date,
    forceRefetch: true,
  })

  const salesIds = sales && !loading ? sales.map(({ id }) => id) : []

  const {
    loading: statsLoading,
    error: statsError,
    sales: salesStats,
  } = useSalesByIdsStats({
    date,
    ids: salesIds,
    skip: loading || !salesIds.length,
  })

  const filteredSales = sales
    .filter(
      (sales) => !dimensionHits.length || dimensionHits.includes(sales.id)
    )
    .filter((sales) => !searchHits || searchHits.includes(sales.id))
    .filter(
      (sales) => !saleTypes.length || saleTypes.includes(sales.facet.name)
    )
    .filter(
      (sales) =>
        !types.length ||
        statsLoading ||
        salesStats
          .find((s) => s.id === sales.id)
          ?.visitStats.types.find((t) => types.includes(t))
    )

  const focusedSales = sales.find((sales) => sales.id === focusedId)

  return (
    <ContentWrap>
      <ScrollWrapper>
        <StickyHeader>
          <Header
            date={date}
            selectNextDate={() => handleSetDate(date.clone().add(1, 'days'))}
            selectPrevDate={() =>
              handleSetDate(date.clone().subtract(1, 'days'))
            }
          />
        </StickyHeader>
        <CustomerList
          error={error || statsError}
          focusedSales={focusedSales}
          loading={loading}
          sales={filteredSales}
          salesStats={salesStats}
          localStoragePosition={localStoragePosition}
          setFocusedSalesId={handleSetFocusedId}
          onSaleSectionsUpdate={onSaleSectionsUpdate}
        />
      </ScrollWrapper>
      <Sidebar
        date={date}
        onChangeDimensionHits={handleChangeDimensionHits}
        onChangeSearchHits={handleChangeSearchHits}
        onSetDate={handleSetDate}
        onSetTypes={handleSetTypes}
        onSetSaleTypes={handleSetSaleTypes}
        saleTypes={saleTypes}
        types={types}
      />
    </ContentWrap>
  )
}

////////////

const ScrollWrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex: 1;
`

const StickyHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`
