import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { DataSearch, ReactiveBase } from '@/components/Reactivesearch'
import { FontColor } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticCatalogProduct } from '@/modules/Products/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { ElasticBaseUrl } from '@/utils/api'

import { useSalesProductListContext } from '../../SalesProductListState'
import { ListViewMode } from '../../types'

type SuggestionType = {
  _source: ElasticCatalogProduct
}

export const CatalogProductSearch = () => {
  const {
    data: { seller },
  } = salesHooks.useSalesDetailsContext()
  const { language } = useLanguageContext()
  const { createProduct, listViewMode } = useSalesProductListContext()

  const [productSearchValue, setProductSearchValue] = useState<string>('')

  return (
    <ReactiveBase
      app="registry.products"
      style={{ width: 'fit-content' }}
      url={ElasticBaseUrl}
    >
      <DataSearchWrapper>
        <DataSearch
          componentId="search"
          dataField="name"
          onChange={(value) => setProductSearchValue(value)}
          onValueSelected={(_, cause, source) => {
            if (source && cause === 'SUGGESTION_SELECT') {
              createProduct(source.id)
              setProductSearchValue('')
            }
          }}
          placeholder={translate(
            listViewMode === ListViewMode.Purchases
              ? 'Products:ProductManager.action.addPurchase'
              : 'Products:ProductManager.action.addProduct',
            language
          )}
          queryFormat="and"
          render={({
            loading,
            rawData,
            downshiftProps: { isOpen, selectItem },
          }) => {
            const data = (rawData?.hits.hits ?? []).filter(
              ({ _source }: SuggestionType) =>
                !seller ||
                _source.catalog.companyId === seller.id ||
                _source.catalog.succeededById === seller.id
            )

            return !!data.length
              ? isOpen && !loading && (
                  <SuggestionsWrapper>
                    {data.map(({ _source }: SuggestionType, index: number) => {
                      const { catalog, id, name } = _source

                      return (
                        <Suggestion
                          isFirst={index === 0}
                          isLast={index === data.length - 1}
                          key={id}
                          onClick={() => selectItem({ source: _source })}
                        >
                          <FlexColumn noPadding>
                            <span>{name}</span>
                            <FontColor lighter>{catalog.name}</FontColor>
                          </FlexColumn>
                        </Suggestion>
                      )
                    })}
                  </SuggestionsWrapper>
                )
              : isOpen && !loading && productSearchValue && (
                  <SuggestionsWrapper>
                    <Suggestion isFirst isLast readOnly>
                      <FontColor lighter>
                        <T>ElasticFilterSearchList:empty</T>
                      </FontColor>
                    </Suggestion>
                  </SuggestionsWrapper>
                )
          }}
          showIcon={false}
          size={100}
          value={productSearchValue}
        />
      </DataSearchWrapper>
    </ReactiveBase>
  )
}

///////

const DataSearchWrapper = styled.div`
  &&& input {
    margin-bottom: 0;
    border-radius: 6px;

    ${({ theme }) => css`
      border: 1px solid ${theme.palette.smoke.main};
      padding: 0 ${theme.spacing.gu(1.5)}rem;
      margin-right: ${theme.spacing.gu(2)}rem;
      width: ${theme.spacing.gu(40)}rem;
    `}
  }

  &&& input::placeholder {
    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}
  }
`

const Suggestion = styled.div<{
  isFirst?: boolean
  isLast?: boolean
  readOnly?: boolean
}>`
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.25)}rem ${theme.spacing.gu(1.5)}rem;
    border-bottom: 1px solid ${theme.palette.smoke.light};
  `}

  ${({ isLast }) => isLast && `border-bottom: none;`}

  &:hover {
    ${({ readOnly, theme }) =>
      !readOnly && `background: ${theme.palette.smoke.lighter};`}

    ${({ isFirst }) =>
      isFirst &&
      `
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  `}

    ${({ isLast }) =>
      isLast &&
      `
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  `}
  }
`

const SuggestionsWrapper = styled(FlexColumn)`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.24);
  z-index: 501;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    max-height: ${theme.spacing.gu(33)}rem;
    margin-top: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(40)}rem;
  `}
`
