import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { EventEnrollment } from '@/modules/Sales/types'

import { HeaderButton } from './HeaderButton'

type Props = {
  enrollment: EventEnrollment
  readOnly?: boolean
  rename: (enrollmentId: string, newName: string | null) => Promise<any>
}

export const NameSection = ({
  enrollment: { id, name, orderNumber },
  readOnly,
  rename,
}: Props) => {
  const [nameIsEditing, setNameEditing] = useState(false)
  const [nameValue, setNameValue] = useState(name || '')

  const handleChangeName = (name: string | null) =>
    rename(id, name).then(() => setNameEditing(false))

  return (
    <SaleNameWrapper>
      {nameIsEditing ? (
        <StyledInput
          autoFocus
          type="text"
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleChangeName(nameValue)
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNameValue(e.target.value)
          }
          onBlur={() => handleChangeName(nameValue)}
          value={nameValue}
        />
      ) : (
        <HeaderButton
          disabled={readOnly}
          onClick={() => !readOnly && setNameEditing(true)}
        >
          <SaleName>
            <Label>
              <span>{`#${orderNumber}${name ? ` – ${name}` : ''}`}</span>
            </Label>

            <IconWrapper>
              {!readOnly && <FontAwesomeIcon icon="pen" size="sm" />}
            </IconWrapper>
          </SaleName>
        </HeaderButton>
      )}
    </SaleNameWrapper>
  )
}

////////////

const Label = styled.div`
  flex: 1;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(25)}rem;
  `}
`

const SaleNameWrapper = styled.div`
  align-self: stretch;
  flex: 1;
`

const SaleName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ theme }) => css`
      margin-right: ${theme.spacing.gutterSmall};
    `}
  }
`

const StyledInput = styled(Input)`
  &&& {
    border: 0;
    border-radius: 0;
    height: 100%;
    flex: 1;
    width: 100%;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(2)}rem;
    `}
  }
`

const IconWrapper = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`
