import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { HeaderSectionSeparator } from '@/components/ExpansionPanel'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { EventEnrollment } from '@/modules/Sales/types'
import { useTheme } from '@/theme'

import { SaleState } from '../../../../components/Header/components/SaleState'
import {
  CustomerSection,
  HeaderButton,
  NameSection,
  ParticipantsSection,
  Section,
} from './components'

type StateCurrentFunc = (isExpanded: boolean) => void

type Props = {
  enrollment: EventEnrollment
  readOnly?: boolean
  isExpanded: boolean
  rename: (enrollmentId: string, newName: string | null) => Promise<any>
  setExpanded: (current: StateCurrentFunc | boolean) => void
  openSections: (sections: string[]) => void
}

export const EnrollmentHeader = ({
  enrollment,
  readOnly,
  isExpanded,
  rename,
  setExpanded,
  openSections,
}: Props) => {
  const {
    customer,
    estimatedDates,
    id,
    orders,
    participantConnection,
    paymentAgreement,
  } = enrollment

  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const totalPrice = useMemo(
    () =>
      orders
        .map((order) => order.paymentInfo.totalPrice.amountVatIncluded)
        .reduce((acc, val) => acc + val, 0),
    [orders]
  )

  const handleOpenSales = (id: string) => {
    const url = `/sales/details/${id}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <HeaderWrapper>
      <CustomerSection customer={customer} />

      <HeaderSectionSeparator />

      <NameSection
        enrollment={enrollment}
        readOnly={readOnly || enrollment.locked}
        rename={rename}
      />

      <SalesStateWrapper>
        <SaleState salesId={enrollment.id} lifecycle={enrollment.lifecycle} />
      </SalesStateWrapper>

      <ParticipantsSection
        onClick={() => openSections(['participants'])}
        participantConnection={participantConnection}
      />

      <HeaderSectionSeparator />

      <Section width={`${spacing.gu(18)}rem`}>
        {!!estimatedDates && (
          <>
            <Label>
              {translate('SalesDetails:EstimatedDates.title', language)}
            </Label>
            <Count>
              {formatDate(estimatedDates.start, estimatedDates.end)}
            </Count>
          </>
        )}
      </Section>

      <HeaderSectionSeparator />

      <PaymentAgreementWrapper>
        {paymentAgreement && (
          <Tooltip
            content={paymentAgreement.name}
            trigger={(triggerProps) => (
              <span {...triggerProps}>{paymentAgreement?.code}</span>
            )}
          />
        )}
      </PaymentAgreementWrapper>

      <HeaderSectionSeparator />

      <HeaderButton
        onClick={() => openSections(['orders'])}
        style={{ justifyContent: 'flex-end' }}
        width={`${spacing.gu(16)}rem`}
      >
        {totalPrice >= 0 ? (
          <>
            <Label style={{ textAlign: 'end' }}>
              {translate('SalesDetails:Enrollment.totalPriceTitle', language)}
            </Label>
            <Count>{`${Math.round(totalPrice * 100) / 100} €`}</Count>
          </>
        ) : (
          <T>SalesDetails:Enrollment.totalPriceError</T>
        )}
      </HeaderButton>

      <HeaderSectionSeparator />

      <HeaderButton onClick={() => handleOpenSales(id)}>
        <FontAwesomeIcon
          icon="up-right-from-square"
          color={palette.smoke.extraDark}
        />
      </HeaderButton>

      {!isExpanded && (
        <HeaderButton onClick={() => setExpanded((current) => !current)}>
          <FontAwesomeIcon icon={'expand'} color={palette.smoke.extraDark} />
        </HeaderButton>
      )}
    </HeaderWrapper>
  )
}

////////////

const formatDate = (start: string, end: string) => {
  const isSameDate = moment(start).isSame(moment(end), 'day')
  const isSameYear = moment(start).isSame(moment(end), 'year')

  return isSameDate
    ? moment(start).format('DD.MM.YY')
    : `${moment(start).format(isSameYear ? 'DD.MM' : 'DD.MM.YY')} – ${moment(
        end
      ).format('DD.MM.YY')}`
}

const HeaderWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 52px;
`

const Count = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const Label = styled.span`
  font-variant-caps: all-small-caps;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const PaymentAgreementWrapper = styled(Section)`
  align-items: center;
  font-weight: 500;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
    font-size: ${theme.typography.fontSizeBase};
  `};
`

const SalesStateWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > div {
    min-width: 195px;
    display: flex;
    justify-content: space-between;
  }
`
