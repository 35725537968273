import styled, { css } from 'styled-components/macro'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { EventEnrollment } from '@/modules/Sales/types'

import { HeaderButton } from './HeaderButton'

type Props = {
  onClick: () => void
  participantConnection: EventEnrollment['participantConnection']
}

export const ParticipantsSection = ({
  onClick,
  participantConnection,
}: Props) => {
  const { language } = useLanguageContext()

  const participantsNames = participantConnection?.nodes
    ?.map(
      (p) =>
        `${p.firstName || ''}${
          p.lastName ? `${p.firstName ? ' ' : ''}${p.lastName}` : ''
        }`
    )
    .filter(Boolean)

  return (
    <Wrapper>
      <HeaderButton onClick={onClick}>
        {participantConnection?.totalElements.toString() ? (
          <>
            <Label>
              {translate(
                'SalesDetails:Enrollment.participantsTitle',
                language,
                {
                  count: participantConnection?.totalElements,
                }
              )}
            </Label>

            <Row>
              <Count>{participantConnection?.totalElements}</Count>

              {participantsNames && (
                <span>
                  {participantsNames.length > 0 && ' – '}
                  {participantsNames.join(', ')}
                </span>
              )}
            </Row>
          </>
        ) : (
          <T>SalesDetails:Enrollment.participantsError</T>
        )}
      </HeaderButton>
    </Wrapper>
  )
}

////////////

const Count = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const Label = styled.span`
  font-variant-caps: all-small-caps;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Row = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(25)}rem;
  `}
`

const Wrapper = styled.span`
  align-self: stretch;
  flex: 1;
`
