import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import Keycloak from 'keycloak-js'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { RefreshModal } from '@/components/RefreshModal'
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '@/config/env'
import { defaultTheme } from '@/theme/defaultTheme'
import { apiCall } from '@/utils/api'

type ResourceAccess = {
  [key: string]: {
    roles: string[]
  }
}

type UserInfo = {
  sub: string
  email_verified: boolean
  realm_access: {
    roles: string[]
  }
  resource_access: ResourceAccess
  name: string
  preferred_username: string
  given_name: string
  locale: string
  family_name: string
  picture: string
  email: string
}

type Props = {
  children: ReactNode
}

type KeycloakContextType = {
  // Data
  keycloak: Keycloak | null
  userInfo: UserInfo | null

  // Methods
  hasRealmRole: (roleKey: string) => boolean
  hasResourceRole: (resourceKey: string, roleKey: string) => boolean
  openRefreshModal: () => void
}

const KeycloakContext = createContext<KeycloakContextType>({
  hasRealmRole: () => false,
  hasResourceRole: () => false,
  keycloak: null,
  openRefreshModal: () => undefined,
  userInfo: null,
})

export const KeycloakProvider = ({ children }: Props) => {
  const [isRefreshModalOpen, setRefreshModalOpen] = useState<boolean>(false)
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null)
  const [resourceAccess, setResourceAccess] = useState<ResourceAccess | null>(
    null
  )
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [realmRoles, setRealmRoles] = useState<string[]>([])

  useEffect(() => {
    const authClient = new Keycloak({
      clientId: KEYCLOAK_CLIENT_ID,
      realm: KEYCLOAK_REALM,
      url: KEYCLOAK_URL,
    })

    let isUpdateOngoing = false

    /**
     * Fetch new access token if current token is expired or will be expired within minValidity seconds
     * Set access token into SessionStorage
     */
    const updateToken = (minValidity = 10) => {
      if (isUpdateOngoing) {
        console.warn('Token update is already ongoing, returning...')
        return
      } else {
        isUpdateOngoing = true
        authClient
          .updateToken(minValidity)
          .then((refreshed) => {
            if (refreshed && authClient.token) {
              sessionStorage.setItem('token', authClient.token)
            }
          })
          .catch(() => {
            console.error('Failed to refresh the token')
            setRefreshModalOpen(true)
          })
          .finally(() => {
            isUpdateOngoing = false
          })
      }
    }

    /**
     * On frozen page state will be resumed, always refresh a token
     * In this scenario, the execution of the JS has been paused and the keycloak.js timeout is not reliable
     */
    document.addEventListener('resume', () => {
      updateToken(-1)
    })

    /**
     * On hidden page state will be visible, refresh a token if expired
     */
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        updateToken()
      }
    })

    /**
     * On passive page state will be active, refresh a token if expired
     */
    window.onfocus = () => {
      updateToken()
    }

    // Setup normal refresh flow: once the token will be expired, fetch a new one
    authClient.onTokenExpired = () => {
      updateToken()
    }

    authClient.init({ onLoad: 'login-required' }).then(() => {
      // Set token into session storage before rendering the other page content
      if (authClient.token) {
        sessionStorage.setItem('token', authClient.token)
      }
      setKeycloak(authClient)
    })

    // Cleanup effect
    return () => {
      if (keycloak) {
        keycloak.logout()
      }
    }
  }, [])

  // Fetch user info and roles
  useEffect(() => {
    if (keycloak?.token) {
      const url = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/userinfo`

      apiCall<UserInfo>(url, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(({ data, ok }) => {
          if (data && ok) {
            setUserInfo(data)
            setRealmRoles(data.realm_access.roles)
            setResourceAccess(data.resource_access)
          }
        })
        .catch(() => {
          console.error('Failed to receive user info')
          setRefreshModalOpen(true)
        })
    }
  }, [keycloak?.token])

  const hasRealmRole = (roleKey: string) => realmRoles.includes(roleKey)

  const hasResourceRole = (resourceKey: string, roleKey: string) => {
    if (resourceAccess && resourceAccess[resourceKey]) {
      return resourceAccess[resourceKey].roles.includes(roleKey)
    }

    return false
  }

  const openRefreshModal = () => setRefreshModalOpen(true)

  if (!keycloak || !userInfo)
    return (
      <LoaderWrapper>
        <ReactLoading
          color={defaultTheme.palette.smoke.lighter}
          height={72}
          type="spin"
          width={72}
        />
      </LoaderWrapper>
    )

  return (
    <KeycloakContext.Provider
      value={{
        hasRealmRole,
        hasResourceRole,
        keycloak,
        openRefreshModal,
        userInfo,
      }}
    >
      {isRefreshModalOpen && <RefreshModal />}
      {children}
    </KeycloakContext.Provider>
  )
}

export const useKeycloakContext = () => useContext(KeycloakContext)

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  position: fixed;
  width: 100%;
`
