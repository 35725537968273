import { APIBaseUrl, apiCall } from '@/utils/api'

import { Document } from '../types'

type DocumentServiceType = {
  buildPdf: (documentId: string) => Promise<any>
  fetchAll: (ownerId: string) => Promise<Document[]>
}

export const BASE_URL = `${APIBaseUrl}/documents`

const DocumentService: DocumentServiceType = Object.freeze({
  buildPdf: (documentId: string) =>
    fetch(`${BASE_URL}/${documentId}/file`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to download pdf [documentId=${documentId}]`)
        }

        return res.blob()
      })
      .then((res) => URL.createObjectURL(res)),
  fetchAll: (ownerId: string) =>
    apiCall<Document[]>(`${BASE_URL}?ownerId=${ownerId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data, ok }) => {
      if (!ok || !data) {
        throw new Error(
          `Failed to fetch documents by owner [ownerId=${ownerId}]`
        )
      }

      return data
    }),
})

export default DocumentService
