import { CreateInputKey } from '../ExcelImport.types'
import { en, fi, Glossary, mergeGlossaries } from '../glossary'

const glossary: Glossary = mergeGlossaries([en, fi])

export const getKeyByColumnName = (
  columnName: string
): CreateInputKey | null => {
  const colNameLowC = columnName.toLowerCase()

  const {
    address1,
    address2,
    age,
    allergyDescription,
    birthday,
    city,
    country,
    drugAllergyDescription,
    education,
    firstName,
    gender,
    group,
    info,
    language,
    lastName,
    lifeStage,
    nationality,
    postcode,
    roomNumber,
    email,
  } = glossary

  const colNameInGlossary = (glossaryItem: string[]): boolean =>
    !!glossaryItem.find((n) => colNameLowC === n)

  if (colNameInGlossary(firstName)) {
    return 'firstName'
  } else if (colNameInGlossary(lastName)) {
    return 'lastName'
  } else if (colNameInGlossary(birthday)) {
    return 'birthday'
  } else if (colNameInGlossary(age)) {
    return 'age'
  } else if (colNameInGlossary(gender)) {
    return 'gender'
  } else if (colNameInGlossary(nationality)) {
    return 'nationality'
  } else if (colNameInGlossary(address1)) {
    return 'address1'
  } else if (colNameInGlossary(address2)) {
    return 'address2'
  } else if (colNameInGlossary(email)) {
    return 'email'
  } else if (colNameInGlossary(postcode)) {
    return 'postcode'
  } else if (colNameInGlossary(city)) {
    return 'city'
  } else if (colNameInGlossary(country)) {
    return 'country'
  } else if (colNameInGlossary(allergyDescription)) {
    return 'allergyDescription'
  } else if (colNameInGlossary(drugAllergyDescription)) {
    return 'drugAllergyDescription'
  } else if (colNameInGlossary(info)) {
    return 'additionalInfo'
  } else if (colNameInGlossary(group)) {
    return 'group'
  } else if (colNameInGlossary(roomNumber)) {
    return 'roomNumber'
  } else if (colNameInGlossary(education)) {
    return 'education'
  } else if (colNameInGlossary(language)) {
    return 'language'
  } else if (colNameInGlossary(lifeStage)) {
    return 'lifeStage'
  }

  return null
}
