import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { DocumentUserAttribute } from '@/modules/Document/types'
import { T } from '@/modules/Language'

import { Section, SubTitle } from '../common'
import { AttributeRichEditor } from './AttributeRichEditor'

type Props = {
  attributes: DocumentUserAttribute[]
  onUpdate: (attribute: DocumentUserAttribute) => void
}

export const Attributes = ({ attributes, onUpdate }: Props) => (
  <Section>
    <SubTitle>
      <T>Document:Settings.attributes.title</T>
    </SubTitle>

    {[...attributes].map((attribute: DocumentUserAttribute) => (
      <div key={attribute.key}>
        <CheckboxWrapper alignItems="center">
          <CheckboxInput
            checked={attribute.active}
            noMargin
            onChange={() =>
              onUpdate({ ...attribute, active: !attribute.active })
            }
          >
            <CheckboxLabel>{attribute.name}</CheckboxLabel>
          </CheckboxInput>
        </CheckboxWrapper>

        {attribute.active && (
          <AttributeRichEditor
            editorValue={attribute.editorValue}
            updateAttribute={(editorValue, templateValue) =>
              onUpdate({ ...attribute, editorValue, templateValue })
            }
          />
        )}
      </div>
    ))}
  </Section>
)

///////

const CheckboxLabel = styled(FlexRow)`
  ${({ theme }) => `
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  label {
    ${({ theme }) => `
      color: ${theme.palette.text.light};
      height: ${theme.spacing.guPx(4) + 4}px;
    `}

    &:hover {
      ${({ theme }) => `
        color: ${theme.palette.primary.dark};
      `}
    }
  }
`
