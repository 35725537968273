import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { getIssueColor, useTheme } from '@/theme'

import { Feature } from '~generated-types'

import { useSalesDetailsContext } from '../../SalesDetailsState'
import { SalesRoute } from '../../types'
import { EventBriefInfo } from './EventBriefInfo'
import { OrderList } from './OrderList'
import { Program } from './Program'
import { SaleLogs } from './SaleLogs'
import { SubNavLinkButton, SubNavLinkSelectable } from './SubNavLink'

type Props = {
  baseRoute: string
  routes: Omit<SalesRoute, 'component'>[]
  showAccommodation: () => void
}

export const Sidebar = ({ baseRoute, routes, showAccommodation }: Props) => {
  const { search } = useLocation()
  const { palette } = useTheme()

  const {
    contentColors,
    data: { id, type, event, auditLog, facet },
    saleReadOnly,
    navItemSelect,
    navItemUnselect,
  } = useSalesDetailsContext()

  const renderNavLinks = () => (
    <FlexColumn noPadding>
      {routes.map(({ icon, issue, key }) => {
        const isActive = new URLSearchParams(search).toString().includes(key)

        return (
          <ItemWrapper
            key={key}
            alignItems="stretch"
            justifyContent="space-between"
            background={issue ? getIssueColor(issue, palette).background : null}
          >
            <SubNavLinkSelectable
              icon={icon}
              issue={issue}
              label={<T l10n={`SalesDetails:section.${key}`} />}
              onClick={(e) => {
                e.preventDefault()
                navItemSelect(key)
              }}
              color={contentColors[key]}
              to={`${baseRoute}`}
              isActive={() => isActive}
            />
            <RemoveButton
              onClick={() => (isActive ? navItemUnselect(key) : null)}
            >
              {isActive && (
                <FontAwesomeIcon
                  color={palette.primary.main}
                  fixedWidth
                  icon={['far', 'eye-slash']}
                  size="sm"
                />
              )}
            </RemoveButton>
          </ItemWrapper>
        )
      })}
    </FlexColumn>
  )

  return (
    <SidebarWrapper>
      <SidebarContent>
        <Gutter type={[0, 2]}>{renderNavLinks()}</Gutter>

        <Divider />

        <Gutter type={[0, 2]}>
          <FlexColumn noPadding>
            <Program ownerId={id} />

            {facet.features.find((f) => f.feature === Feature.Participants) && (
              <SubNavLinkButton
                icon="user-friends"
                label={<T>SalesDetails:section.accommodate</T>}
                onClick={showAccommodation}
              />
            )}
          </FlexColumn>
        </Gutter>

        {type === 'ENROLLMENT' && event && (
          <>
            <Divider />
            <EventBriefInfo event={event} />
          </>
        )}

        {(type === 'SALES' || type === 'ENROLLMENT') && (
          <>
            <Divider />
            <Gutter type={[0, 2]}>
              <OrderList readOnly={saleReadOnly} baseRoute={baseRoute} />
            </Gutter>
          </>
        )}

        <SaleLogs salesId={id} creationDate={auditLog.createdAt} />
      </SidebarContent>
    </SidebarWrapper>
  )
}

////////////

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${({ theme }) => css`
    flex: 0 0 ${theme.spacing.gu(32)}rem;
  `}
`

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-right: solid 1px ${theme.palette.smoke.main};
    padding: ${theme.spacing.gutter} 0 ${theme.spacing.gu(14)}rem;
  `}
`

const Divider = styled.div`
  flex: 0 0 1px;
  align-self: stretch;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    margin: ${theme.spacing.gutter} 0;
  `}
`

const RemoveButton = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    opacity: 0.7;
  }
`

const ItemWrapper = styled(FlexRow)<{ background: string | null }>`
  border-radius: 4px;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.25)}rem;
  `}

  ${({ background }) => background && `background: ${background};`}

  & > a {
    margin-bottom: 0;
  }

  &:hover ${RemoveButton} {
    display: flex;
  }
`
