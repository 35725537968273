import { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { documentMutations } from '@/modules/Document/mutations'
import {
  Document,
  DocumentUserAttribute as Attribute,
} from '@/modules/Document/types'
import { T } from '@/modules/Language'

import { Attributes, LanguageSelector, TemplateSelector } from './components'

type Props = {
  buildPdf: () => Promise<void>
  document: Document
  onUpdateDocument?: (document: Document) => void
  renderCustomSettings?: (refreshPdf: () => Promise<void>) => ReactNode
  showAttributesSettings?: boolean
  showLanguageSettings?: boolean
  showTemplateSettings?: boolean
}

export const DocumentSettings = ({
  buildPdf,
  document: doc,
  onUpdateDocument,
  renderCustomSettings,
  showAttributesSettings,
  showLanguageSettings,
  showTemplateSettings,
}: Props) => {
  const { id, userAttributes } = doc

  const [attributes, setAttributes] = useState<Attribute[]>([])

  useEffect(() => {
    setAttributes(userAttributes)
  }, [userAttributes])

  const [updateUserAttribute] =
    documentMutations.useUpdateDocumentUserAttributeMutation()

  const onUpdateAttributes = ({
    active,
    editorValue,
    key,
    templateValue,
  }: Attribute) =>
    updateUserAttribute({
      variables: {
        input: {
          active,
          documentId: id,
          editorValue,
          key,
          templateValue,
        },
      },
    })
      .then(({ data }) => {
        const userAttribute = data?.documentUserAttributeUpdate.userAttribute

        if (userAttribute) {
          setAttributes(
            attributes.map((a) => (a.key === key ? userAttribute : a))
          )
        }

        return userAttribute
      })
      .then((nextUserAttribute) => {
        const prevUserAttribute = userAttributes.find(
          ({ id }) => id === nextUserAttribute?.id
        )

        if (prevUserAttribute && nextUserAttribute) {
          const { active: prevActive } = prevUserAttribute
          const { active: nextActive, templateValue: nextTemplateValue } =
            nextUserAttribute

          if (!(prevActive !== nextActive && !nextTemplateValue)) {
            return buildPdf()
          }
        }
      })
      .catch(() => undefined)

  const onUpdate = (document: Document) => {
    buildPdf()
    onUpdateDocument && onUpdateDocument(document)
  }

  const showDefaultSettings =
    showAttributesSettings || showLanguageSettings || showTemplateSettings

  return (
    <Wrapper noPadding style={{ width: '35%' }}>
      <Title>
        <T>Document:Settings.title</T>
      </Title>

      <HorizontalLine />

      <FlexColumn flex={1} noPadding style={{ overflow: 'auto' }}>
        {showDefaultSettings && (
          <>
            {showTemplateSettings && (
              <>
                <TemplateSelector document={doc} onUpdateDocument={onUpdate} />
                <HorizontalLine />
              </>
            )}

            {showLanguageSettings && (
              <>
                <LanguageSelector document={doc} onUpdateDocument={onUpdate} />
                <HorizontalLine />
              </>
            )}

            {showAttributesSettings && !!attributes.length && (
              <>
                <Attributes
                  attributes={attributes}
                  onUpdate={onUpdateAttributes}
                />
                <HorizontalLine />
              </>
            )}
          </>
        )}

        {renderCustomSettings && renderCustomSettings(buildPdf)}
      </FlexColumn>
    </Wrapper>
  )
}

///////

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
    padding: ${theme.spacing.gu(4)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ noPadding?: boolean }>`
  width: 100%;

  ${({ noPadding, theme }) => css`
    line-height: ${theme.spacing.gu(2.5)}rem;
    padding: ${noPadding ? 0 : theme.spacing.gutterBig};
  `}
`
