import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'

type Props = {
  handleUpload: (files: FileList) => Promise<void>
}

export const UploadErrorHelper = ({ handleUpload }: Props) => (
  <>
    <ErrorMessage>
      <T>ParticipantsList:Import.importError</T>
    </ErrorMessage>

    <UploadWrapper>
      <FileInput
        id="file"
        type="file"
        accept=".xls,.xlsx"
        onChange={(e) => e.target.files && handleUpload(e.target.files)}
      />

      <UploadButton htmlFor="file">
        <UploadIcon icon="arrow-up-from-bracket" />

        <T>ParticipantsList:Import.reUploadFile</T>
      </UploadButton>
    </UploadWrapper>
  </>
)

/////////

const ErrorMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.dark};
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const FileInput = styled.input`
  display: none;
`

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & span {
    cursor: pointer;
  }

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
    color: ${theme.palette.primary.main};
  `}
`

const UploadButton = styled.label`
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 4px;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: min-width 300 ease;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const UploadIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
