import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { FilePreview } from '../../FilePreview'

type Props = {
  handleDownload: () => Promise<void | undefined>
  handlePrint: () => Promise<void | undefined>
  isCompact?: boolean
  state: FetchState
  url: string
}

export const DocumentPreview = ({
  handleDownload,
  handlePrint,
  isCompact,
  state,
  url,
}: Props) => (
  <SectionContainer style={{ width: `${isCompact ? '65%' : '100%'}` }}>
    <TitleWrapper alignItems="center">
      <Title>
        <T>Document:Preview.title</T>
      </Title>

      <Spacer />

      <InnocuousButton
        compact
        disabled={state !== FetchStates.IDLE}
        onClick={handlePrint}
      >
        <PrimaryColor>
          <Icon icon="print" />
          <T>common:action.print</T>
        </PrimaryColor>
      </InnocuousButton>

      <InnocuousButton
        compact
        disabled={state !== FetchStates.IDLE}
        onClick={handleDownload}
      >
        <PrimaryColor>
          <Icon icon="download" />
          <T>common:action.download</T>
        </PrimaryColor>
      </InnocuousButton>
    </TitleWrapper>

    <FilePreview fetchingState={state} url={url} />
  </SectionContainer>
)

///////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const SectionContainer = styled(FlexColumn)`
  width: 100%;

  ${({ theme }) => css`
    line-height: ${theme.spacing.gu(2.5)}rem;
    padding: ${theme.spacing.gu(3.5)}rem ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(4)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2.5)}rem;
  `}
`
