import { gql } from '@apollo/client'

export default gql`
  query SalesFacets($forCreate: Boolean) {
    registry {
      salesFacets(forCreate: $forCreate) {
        abbreviation
        id
        name
        salesType
      }
    }
  }
`
