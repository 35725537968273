import { documentService } from '../services'

export const generatePrintFn = (url: string) => async () => {
  const iframe = window.document.createElement('iframe')
  iframe.src = url
  iframe.style.display = 'none'

  const closePrint = () => {
    document.body.removeChild(iframe)
    window.removeEventListener('focus', closePrint)
  }

  iframe.onload = () => {
    if (iframe.contentWindow) {
      iframe.contentWindow.focus()
      iframe.contentWindow.print()

      window.addEventListener('focus', closePrint)
    }
  }

  document.body.appendChild(iframe)
}

export const generatePrintFnById = (id: string) => async () => {
  try {
    const url = await documentService.buildPdf(id)
    return generatePrintFn(url)()
  } catch (err) {
    console.error('Failed to print', err)
    throw new Error(`Failed to print target PDF [id=${id}]`)
  }
}
