import { ApolloQueryResult } from '@apollo/client'

import { InlineModalLine } from '@/components/InlineModal'
import {
  Reservation,
  ResourceReservation,
  ResourceReservationGroup,
} from '@/modules/Reservations/ResourceReservation'

import { UseResourceReservationQuery } from '~generated-types'

import {
  BasicDetails,
  CustomerVisibility,
  Date,
  Dimensions,
  Fields,
  Group,
  Owner,
  Purchase,
  Quantity,
  Resource,
  Tasks,
  Time,
} from './components'
import { Context, Section } from './types'

type Props = {
  context: Context
  contextId?: string | null
  group: ResourceReservationGroup
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  reservation: ResourceReservation
  setCurrentSection: (section: Section) => void
  updateReservations: (reservations: Reservation[]) => void
}

export const ReservationManager = ({
  context,
  contextId,
  group,
  readOnly,
  refetchReservation,
  reservation,
  setCurrentSection,
  updateReservations,
}: Props) => {
  const {
    color,
    customerTimeVisibility,
    customerVisibility,
    description,
    descriptionOptions,
    dimensions,
    end,
    id,
    internalNote,
    displayMessage,
    issues,
    purchaseProducts,
    resource,
    resourceQuantity,
    sales,
    start,
    tasks,
  } = reservation

  return (
    <>
      {/* Owner */}
      {(context === 'GLOBAL' ||
        (context === 'BOTTOM_SHEET' && contextId !== sales.id)) && (
        <Owner
          owner={sales}
          readOnly={
            readOnly ||
            !!reservation.tasks.length ||
            !!reservation.purchaseProducts.length ||
            (context === 'BOTTOM_SHEET' && contextId !== sales.id)
          }
          setCurrentSection={setCurrentSection}
        />
      )}

      {/* Resource and color*/}
      <Resource
        color={color}
        isEditable={context === 'SALES'}
        issues={issues}
        readOnly={readOnly}
        reservationId={reservation.id}
        resource={resource}
        updateReservations={updateReservations}
      />

      {/* Date */}
      <Date
        end={end}
        isEditable={context === 'SALES'}
        readOnly={readOnly}
        reservationId={id}
        resourceId={resource?.id || null}
        start={start}
        updateReservations={updateReservations}
      />

      {/* Time and visibility in the program*/}
      <Time
        allowSameTime={!resource}
        end={end}
        isPooled={!!resource?.isPooled}
        readOnly={readOnly}
        reservationId={id}
        start={start}
        updateReservations={updateReservations}
      />

      {/* Quantity */}
      {!!resource?.isPooled && (
        <Quantity
          readOnly={readOnly}
          reservationId={id}
          resourceId={resource.id || ''}
          resourceQuantity={resourceQuantity}
          updateReservations={updateReservations}
        />
      )}

      {/* Dimensions */}
      {!!dimensions && (
        <Dimensions
          readOnly={readOnly}
          reservationId={id}
          reservationDimensions={dimensions.selection}
          updateReservations={updateReservations}
        />
      )}

      {/* Group */}
      <Group
        group={group}
        readOnly={readOnly || context === 'GLOBAL'}
        reservationId={id}
        setCurrentSection={setCurrentSection}
        updateReservations={updateReservations}
      />

      <InlineModalLine />

      {/* Reservation and time visibility in the program */}
      <CustomerVisibility
        isProgramReservation={!resource}
        readOnly={readOnly || context === 'GLOBAL'}
        reservationId={id}
        reservationVisibility={customerVisibility}
        resourceType={resource?.type || null}
        timeVisibility={customerTimeVisibility}
        updateReservations={updateReservations}
      />

      <InlineModalLine />

      {/* Description and internal note */}
      <BasicDetails
        description={description}
        descriptionOptions={descriptionOptions}
        internalNote={internalNote}
        readOnly={readOnly}
        reservationId={id}
        updateReservations={updateReservations}
      />

      <InlineModalLine />

      {/* Purchase */}
      <Purchase
        context={context}
        purchase={purchaseProducts.length ? purchaseProducts[0] : null}
        readOnly={readOnly}
        refetchReservation={refetchReservation}
        sales={sales}
        setCurrentSection={setCurrentSection}
      />

      {/* Tasks */}
      <Tasks
        context={context}
        readOnly={readOnly}
        refetchReservation={refetchReservation}
        reservationId={id}
        sales={sales}
        setCurrentSection={setCurrentSection}
        tasks={tasks || []}
        updateReservations={updateReservations}
      />

      {/* Fields */}
      <Fields
        displayMessage={displayMessage}
        reservationId={id}
        readOnly={readOnly}
        updateReservations={updateReservations}
      />
    </>
  )
}
