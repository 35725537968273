import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  downloadedAt: string | null
}

export const VSTDocumentSection = ({ downloadedAt }: Props) => (
  <>
    <Section>
      <Title>
        <T>SalesDetails:VST.printedAt</T>
      </Title>

      {downloadedAt ? (
        <Date>{moment(downloadedAt).format('dd. DD.MM.YY, HH:mm')}</Date>
      ) : (
        <Placeholder>
          <T>SalesDetails:VST.printedAtPlaceholder</T>
        </Placeholder>
      )}
    </Section>

    <HorizontalLine />
  </>
)

///////

const Date = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
