import styled, { css } from 'styled-components/macro'

import { EditButton as EditButtonBase } from '@/components/ExtraButtons'
import { FlexColumn } from '@/components/Layout'

export const EditButton = styled(EditButtonBase)`
  flex: unset;
  font-weight: 500;
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }

  @media (max-width: 1520px) {
    .edit-button-icon {
      display: none;
    }
  }
`

export const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(4)}rem;
  `}
`

export const SubTitle = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`
