import { gql, useMutation } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

import type {
  UpdateDocumentUserAttributeMutation,
  UpdateDocumentUserAttributeMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  ${documentFragments.userAttribute}
  mutation UpdateDocumentUserAttribute(
    $input: DocumentUserAttributeUpdateInput!
  ) {
    documentUserAttributeUpdate(input: $input) {
      userAttribute {
        ...UserAttribute
      }
    }
  }
`

export const useUpdateDocumentUserAttributeMutation = () =>
  useMutation<
    UpdateDocumentUserAttributeMutation,
    UpdateDocumentUserAttributeMutationVariables
  >(MUTATION)
