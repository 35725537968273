import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Gutter } from '@/components/Layout/Gutter'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { documentService } from '@/modules/Document/services'
import { Document } from '@/modules/Document/types'
import { generateDownloadFn, generatePrintFn } from '@/modules/Document/utils'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { DocumentPreview, DocumentSettings } from './components'

type Props = {
  document: Document
  onClose: () => void
  onPrintDocument?: (document: Document) => void
  onUpdateDocument?: (document: Document) => void
  renderCustomSettings?: (refreshPdf: () => Promise<void>) => ReactNode
  showAttributesSettings?: boolean
  showLanguageSettings?: boolean
  showTemplateSettings?: boolean
  showSettingsSection?: boolean
}

export const DocumentEditorModal = ({
  document: doc,
  onClose,
  onPrintDocument,
  showSettingsSection,
  ...settingsProps
}: Props) => {
  const { spacing } = useTheme()
  const { pathname, search } = useLocation()

  const [PDFUrl, setPDFUrl] = useState<string>('')
  const [PDFState, setPDFState] = useState<FetchState>(FetchStates.IDLE)

  const buildPdf = async () => {
    setPDFState(FetchStates.LOADING)

    try {
      const url = await documentService.buildPdf(doc.id)
      setPDFUrl(url)
      setPDFState(FetchStates.IDLE)
    } catch (err) {
      setPDFState(FetchStates.ERROR)
      console.error('Failed to download', err)
    }
  }

  useEffect(() => {
    buildPdf()
  }, [doc.id])

  const handlePrint = () => {
    const onPrint = generatePrintFn(PDFUrl)

    return onPrint().then(() => onPrintDocument && onPrintDocument(doc))
  }

  const handleDownload = () => {
    const onDownload = generateDownloadFn(PDFUrl, doc.name)

    return onDownload().then(() => onPrintDocument && onPrintDocument(doc))
  }

  return createPortal(
    <>
      <SideDrawer width={`${spacing.gu(showSettingsSection ? 170 : 110)}rem`}>
        <Wrapper>
          {showSettingsSection && (
            <>
              <DocumentSettings
                buildPdf={buildPdf}
                document={doc}
                {...settingsProps}
              />

              <VerticalLine />
            </>
          )}

          <DocumentPreview
            handleDownload={handleDownload}
            handlePrint={handlePrint}
            isCompact={showSettingsSection}
            state={PDFState}
            url={PDFUrl}
          />
        </Wrapper>

        <SideDrawerBottomControls>
          <Gutter type={['gutter', 'gutterBig']}>
            <InnocuousButton noNudge onClick={onClose}>
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>

      <SideDrawerShadow to={`${pathname}${search}`} onClick={onClose} />
    </>,
    document.getElementById('modal-root') as HTMLDivElement
  )
}

///////

const VerticalLine = styled.div`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.palette.smoke.light};
  `}
`

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`
