import { useState } from 'react'
import { useQuery } from '@apollo/client'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  InlineModal,
  InlineModalContent,
  InlineModalSection,
  InlineModalTitle,
} from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import * as salesMutations from '@/modules/Sales/mutations'
import {
  SalesFacetsPayload,
  SalesFacetsVariables,
  salesQueries,
} from '@/modules/Sales/queries'
import { useTheme } from '@/theme'

import { SalesType } from '~generated-types'

import { FacetOption } from './FacetOption'

type Props = {
  closeSelector: () => void
}

export const NewSaleSelector = ({ closeSelector }: Props) => {
  const history = useHistory()
  const { palette, spacing } = useTheme()

  const [creatingAbbr, setCreatingAbbr] = useState<string | null>(null)

  const { data, loading, error } = useQuery<
    SalesFacetsPayload,
    SalesFacetsVariables
  >(salesQueries.SALES_FACETS, {
    variables: { forCreate: true },
  })

  const facets = data?.registry.salesFacets || []
  const eventFacets = facets.filter((f) => f.salesType === SalesType.Event)
  const salesFacets = facets.filter((f) => f.salesType === SalesType.Sales)

  const [createSale] = salesMutations.useCreateSaleMutation()
  const [createEvent] = salesMutations.useCreateEventMutation()

  const handleCreateSale = (facetId: string | undefined) =>
    createSale({ variables: { input: { facetId } } })
      .then(({ data }) => {
        setCreatingAbbr(null)
        data && history.push(`/sales/details/${data.salesCreate.id}`)
      })
      .catch(() => undefined)
      .finally(closeSelector)

  const handleCreateEvent = () =>
    createEvent({ variables: { input: {} } })
      .then(({ data }) => {
        setCreatingAbbr(null)
        data && history.push(`/sales/details/${data.salesCreateEvent.id}`)
      })
      .catch(() => undefined)
      .finally(closeSelector)

  return (
    <InlineModal
      style={{ minWidth: '300px', paddingBottom: `${spacing.gu(3)}rem` }}
    >
      <InlineModalContent>
        {loading ? (
          <InlineModalSection>
            <InfoWrapper>
              <ReactLoading
                color={palette.smoke.main}
                height={30}
                type="spin"
                width={30}
              />
            </InfoWrapper>
          </InlineModalSection>
        ) : error ? (
          <InlineModalSection>
            <InfoWrapper>
              <T>common:error.common</T>
            </InfoWrapper>
          </InlineModalSection>
        ) : (
          <>
            <InlineModalSection>
              <FlexColumn noPadding flex={1}>
                <InlineModalTitle>
                  <T>Sales:groups.sales</T>
                </InlineModalTitle>

                {salesFacets.map((facet) => (
                  <FacetOption
                    busy={creatingAbbr === facet.abbreviation}
                    colorSchema={{
                      background: palette.primary.extraLight,
                      text: palette.primary.dark,
                    }}
                    facet={facet}
                    key={facet.id}
                    onSelect={() => {
                      setCreatingAbbr(facet.abbreviation)
                      handleCreateSale(facet.id)
                    }}
                  />
                ))}
              </FlexColumn>
            </InlineModalSection>

            <InlineModalSection>
              <FlexColumn noPadding flex={1}>
                <InlineModalTitle>
                  <T>Sales:groups.saleDefinition</T>
                </InlineModalTitle>

                {eventFacets.map((facet) => (
                  <FacetOption
                    busy={creatingAbbr === facet.abbreviation}
                    colorSchema={{
                      background: palette.secondary.lighter,
                      text: palette.secondary.dark,
                    }}
                    facet={facet}
                    key={facet.id}
                    onSelect={() => {
                      setCreatingAbbr(facet.abbreviation)
                      handleCreateEvent()
                    }}
                  />
                ))}
              </FlexColumn>
            </InlineModalSection>
          </>
        )}
      </InlineModalContent>
    </InlineModal>
  )
}

const InfoWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`
