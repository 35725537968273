import { gql } from '@apollo/client'

import { attachmentFragments } from '@/modules/Attachments/fragments'
import userAttribute from '@/modules/Document/fragments/userAttribute'

export default gql`
  ${attachmentFragments.attachment}
  ${userAttribute}

  fragment Document on Document {
    attachments {
      ...Attachment
    }
    downloadedAt
    file {
      objectName
      fileName
      mimeType
      created
    }
    fileStatus
    id
    language
    name
    status
    template {
      documentName
      documentType
      filesAllowed
      id
      name
    }
    templateOptions {
      documentName
      documentType
      filesAllowed
      id
      name
    }
    userAttributes {
      ...UserAttribute
    }
  }
`
