import { ById } from '@/common/types'
import { Document } from '@/modules/Document/types'

import { OrderPhaseType } from '~generated-types'

import { Confirmation, Invoice, Offer, OrderType as Order } from '../types'

type StateType<T> = T | ((data: T) => T)

type Params = {
  document: Document
  orderId: string
  ownerId: string
  ownerType: OrderPhaseType
  setOrdersById: (ordersById: StateType<ById<Order>>) => void
}

export const updateStateDocument = ({
  document,
  orderId,
  ownerId,
  ownerType,
  setOrdersById,
}: Params) => {
  switch (ownerType) {
    case OrderPhaseType.Invoice:
    case OrderPhaseType.InvoiceRefund:
    case OrderPhaseType.InvoiceReservationFee:
    case OrderPhaseType.InvoiceReservationFeeRefund:
      return setOrdersById((ordersById) => {
        const invoices = ordersById[orderId].invoices as Invoice[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            invoices: invoices.map((i) =>
              i.id === ownerId ? { ...i, document } : i
            ),
          },
        }
      })
    case OrderPhaseType.OrderConfirmation:
      return setOrdersById((ordersById) => {
        const confirmations = ordersById[orderId]
          .confirmations as Confirmation[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            confirmations: confirmations.map((c) =>
              c.id === ownerId ? { ...c, document } : c
            ),
          },
        }
      })
    case OrderPhaseType.Offer:
      return setOrdersById((ordersById) => {
        const offers = ordersById[orderId].offers as Offer[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            offers: offers.map((o) =>
              o.id === ownerId ? { ...o, document } : o
            ),
          },
        }
      })
    default:
      return
  }
}
