import { gql } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

import offerInfo from './offerInfo'

export default gql`
  ${documentFragments.document}
  ${offerInfo}

  fragment Offer on Offer {
    ...OfferInfo

    document {
      ...Document
    }
  }
`
