import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from 'polished'
import ReactLoading from 'react-loading'
import Media from 'react-media'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useLastUsedSalesContext } from '@/modules/Sales/contexts'
import {
  LastUsedSalesPayload,
  LastUsedSalesVariables,
  salesQueries,
} from '@/modules/Sales/queries'
import { useTheme } from '@/theme'

import { LastUsedSalesModal } from './LastUsedSalesModal'

export const LastUsedSales = () => {
  const { lastUsedSalesIds: ids } = useLastUsedSalesContext()
  const { palette } = useTheme()

  const [isOpen, setOpen] = useState<boolean>(false)
  const [isShowAll, setShowAll] = useState<boolean>(false)

  const { data, loading } = useQuery<
    LastUsedSalesPayload,
    LastUsedSalesVariables
  >(salesQueries.LAST_USED_SALES, {
    fetchPolicy: 'no-cache',
    skip: !isOpen,
    variables: { ids },
  })

  const onClose = () => {
    setOpen(false)
    setShowAll(false)
  }

  return (
    <>
      <ModalContainer
        isOpen={isOpen && !loading}
        modal={
          data && (
            <LastUsedSalesModal
              isShowAll={isShowAll}
              onClose={onClose}
              onShowAll={() => setShowAll(true)}
              sales={[...data.salesAll].sort(
                (a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)
              )}
            />
          )
        }
        onClose={onClose}
        placement="bottom-end"
        referenceElement={({ ref }) => (
          <NavigationLinkWrapper ref={ref}>
            <StyledButton
              activeClassName="is-active"
              isActive={() => isOpen}
              to={'/'}
              onClick={(e) => {
                e.preventDefault()
                setOpen(true)
              }}
            >
              {loading ? (
                <ReactLoading
                  color={palette.primary.dark}
                  height={12}
                  type="spin"
                  width={12}
                />
              ) : (
                <Icon icon="clock-rotate-left" />
              )}

              <Media query={{ minWidth: 1150 }}>
                {(matches) =>
                  matches && (
                    <Label>
                      <T>Sales:lastUsedSales.title</T>
                    </Label>
                  )
                }
              </Media>
            </StyledButton>
          </NavigationLinkWrapper>
        )}
      />
    </>
  )
}

////////

const Icon = styled(FontAwesomeIcon)`
  height: 12px;
  width: 12px;
`

const Label = styled.span`
  font-weight: 600;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const NavigationLinkWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const StyledButton = styled(NavLink)`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  position: relative;
  border: 0;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &.is-active {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${lighten(0.05, theme.palette.primary.extraLight)};
    `}
  }

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
      background: ${theme.palette.primary.extraLight};
    `}
  }
`
