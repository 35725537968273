import { gql, useMutation } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

import type {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  ${documentFragments.document}

  mutation UpdateDocument($input: DocumentUpdateInput!) {
    documentUpdate(input: $input) {
      ...Document
    }
  }
`

export const useUpdateDocumentMutation = () =>
  useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(MUTATION)
