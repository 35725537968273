export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Language: string
  LocalDate: string
  LocalDateTime: string
  LocalTime: string
  Timestamp: string
  URL: string
}

export type AccommodationAvailabilityInput = {
  /** Inclusive, max 6 weeks from startDate */
  endDate: Scalars['LocalDate']
  /** Inclusive */
  startDate: Scalars['LocalDate']
}

export enum AccommodationConsumptionDurationType {
  Static = 'STATIC',
  Variable = 'VARIABLE',
}

export enum AccommodationConsumptionType {
  Bed = 'BED',
  Room = 'ROOM',
}

/**  Inputs: accommodation */
export type AccommodationGroupCreate = {
  name?: InputMaybe<Scalars['String']>
  salesId: Scalars['ID']
}

/**  Inputs: room reservation */
export type AccommodationGroupCreateRoomReservationInput = {
  accommodationId: Scalars['ID']
  allowOverlapping?: InputMaybe<Scalars['Boolean']>
  /**
   * Total beds needed for use
   * Default: all beds in the room
   */
  beds?: InputMaybe<Scalars['Int']>
  checkIn: CheckInInput
  checkOut: CheckOutInput
  /** Total extra beds needed for use */
  extraBeds?: InputMaybe<Scalars['Int']>
  /** Special customer needs. RoomLabel ids */
  featureIds?: InputMaybe<Array<Scalars['ID']>>
  /** Additional important information */
  info?: InputMaybe<Scalars['String']>
  roomId: Scalars['ID']
  targetId?: InputMaybe<Scalars['ID']>
}

/**  Inputs: room type reservation */
export type AccommodationGroupCreateRoomTypeReservationInput = {
  accommodationId: Scalars['ID']
  /**
   * Total beds needed for use
   * Default: all beds in the reserved rooms; rooms multiplied by beds in room type room
   */
  beds?: InputMaybe<Scalars['Int']>
  checkIn: CheckInInput
  checkOut: CheckOutInput
  /** Total extra beds needed for use */
  extraBeds?: InputMaybe<Scalars['Int']>
  /** Special customer needs. RoomLabel ids */
  featureIds?: InputMaybe<Array<Scalars['ID']>>
  /** Additional important information */
  info?: InputMaybe<Scalars['String']>
  roomTypeId: Scalars['ID']
  /** Room count */
  rooms: Scalars['Int']
  targetId?: InputMaybe<Scalars['ID']>
}

export type AccommodationGroupDelete = {
  id: Scalars['ID']
}

export type AccommodationGroupSetNameInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type AccommodationGroupSetSettingsInput = {
  id: Scalars['ID']
  settings: AccommodationGroupSettingsInput
}

export type AccommodationGroupSettingsInput = {
  consumptionDurationType?: InputMaybe<AccommodationConsumptionDurationType>
  consumptionType?: InputMaybe<AccommodationConsumptionType>
}

export type AccommodationReservationFilterInput = {
  salesId: Scalars['ID']
}

export type AccommodationRoomReservationCheckInput = {
  /** Specify action to check-in or check-out. Check-out is possible only if check-in is done */
  action: RoomReservationCheckAction
  /** Optional timestamp, default is current tenant local time. If null, the check-in / check-out is cleared */
  checkDate?: InputMaybe<Scalars['LocalDateTime']>
  /** RoomReservation ids to run the action. All participants in these reservation will be checked, if none exists, RoomReservation is skipped and not returned */
  ids: Array<Scalars['ID']>
}

export type AccommodationRoomReservationDeleteInput = {
  /** Delete participants inside this room */
  deleteParticipants?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
}

export type AccommodationRoomReservationSetBedQuantityInput = {
  beds: Scalars['Int']
  extraBeds: Scalars['Int']
  id: Scalars['ID']
}

export type AccommodationRoomReservationSetDatesInput = {
  allowOverlapping?: InputMaybe<Scalars['Boolean']>
  checkIn: CheckInInput
  checkOut: CheckOutInput
  id: Scalars['ID']
}

export type AccommodationRoomReservationSetNeedsInput = {
  featureIds: Array<Scalars['ID']>
  id: Scalars['ID']
  info?: InputMaybe<Scalars['String']>
}

export type AccommodationRoomReservationSetRoomInput = {
  allowOverlapping?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  roomId: Scalars['ID']
}

export type AccommodationRoomTargetInput = {
  /**
   * Targeted reservation check-in. Reservation dates can be narrowed from original dates.
   * Default is room reservation check-in
   */
  checkIn?: InputMaybe<CheckInInput>
  /**
   * Targeted reservation check-out. Reservation dates can be narrowed from original dates.
   * Default is room reservation check-out
   */
  checkOut?: InputMaybe<CheckOutInput>
  roomReservationId: Scalars['ID']
  targetId: Scalars['ID']
}

export type AccommodationRoomTypeReservationDeleteInput = {
  id: Scalars['ID']
}

export type AccommodationRoomTypeReservationReserveRoomInput = {
  allowOverlapping?: InputMaybe<Scalars['Boolean']>
  /**
   * Total beds needed for use
   * Default: all beds in the room
   */
  beds?: InputMaybe<Scalars['Int']>
  /**
   * Total extra beds needed for use
   * Default is calculated based on room type reservation status and this room extra beds count
   */
  extraBeds?: InputMaybe<Scalars['Int']>
  /**
   * Special customer needs. RoomLabel ids
   * Default: inherited from room type reservation
   */
  featureIds?: InputMaybe<Array<Scalars['ID']>>
  fixedCheckIn?: InputMaybe<CheckInInput>
  fixedCheckOut?: InputMaybe<CheckOutInput>
  /** Additional important information */
  info?: InputMaybe<Scalars['String']>
  roomId: Scalars['ID']
  roomTypeReservationId: Scalars['ID']
}

export type AccommodationRoomTypeReservationSetBedQuantityInput = {
  beds: Scalars['Int']
  extraBeds: Scalars['Int']
  id: Scalars['ID']
}

export type AccommodationRoomTypeReservationSetDatesInput = {
  checkIn: CheckInInput
  checkOut: CheckOutInput
  id: Scalars['ID']
}

export type AccommodationRoomTypeReservationSetFulfilledByRoomsInput = {
  /**
   * If the reserved rooms totally fulfill the room type reservation
   * If true, all needed rooms are reserved
   */
  fulfilledByRooms: Scalars['Boolean']
  id: Scalars['ID']
}

export type AccommodationRoomTypeReservationSetNeedsInput = {
  featureIds: Array<Scalars['ID']>
  id: Scalars['ID']
  info?: InputMaybe<Scalars['String']>
}

export type AccommodationRoomTypeReservationSetRoomQuantityInput = {
  id: Scalars['ID']
  rooms: Scalars['Int']
}

/**  Inputs and payloads: target reservations */
export type AccommodationRoomTypeTargetInput = {
  /**
   * Targeted reservation check-in. Reservation dates can be narrowed from original dates.
   * Default is room type reservation check-in
   */
  checkIn?: InputMaybe<CheckInInput>
  /**
   * Targeted reservation check-out. Reservation dates can be narrowed from original dates.
   * Default is room type reservation check-out
   */
  checkOut?: InputMaybe<CheckOutInput>
  roomTypeReservationId: Scalars['ID']
  /**
   * Room count to extract to the target.
   * Valid range 1..RoomTypeReservation.request.rooms.
   * Null or undefined is equal to reservation rooms.
   */
  rooms?: InputMaybe<Scalars['Int']>
  targetId: Scalars['ID']
}

/**  Inputs and payloads: target */
export type AccommodationTargetCreateInput = {
  accommodationGroupId: Scalars['ID']
  name: Scalars['String']
  shareToSalesId?: InputMaybe<Scalars['ID']>
}

export type AccommodationTargetDeleteInput = {
  deleteReservations: Scalars['Boolean']
  id: Scalars['ID']
}

export type AccommodationTargetSalesInput = {
  id: Scalars['ID']
  salesId: Scalars['ID']
}

/** Patch update input */
export type AccommodationTargetUpdateInput = {
  floating?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  nonBlockingCapacity?: InputMaybe<Scalars['Boolean']>
}

/** If `roomReservationIds` and `roomTypeReservationIds` are not defined, move all reservations from Sales */
export type AccommodationUpdateAllInput = {
  /** Specify the amount of days to move forward (positive) or backward (negative). Each reservation start and end is shifted by offset */
  offset: DayOffsetInput
  /** `salesId` is ignored if any `targetIds` exists. Otherwise move all `AccommodationTargets` and `AccommodationReservations` from Sales */
  salesId?: InputMaybe<Scalars['ID']>
  /** Move all reservations inside specified `AccommodationTarget`s */
  targetIds?: InputMaybe<Array<Scalars['ID']>>
}

export enum ActionRuleType {
  /**
   * If set, the price is added on top of the base price.
   * e.g. Coke               10$
   * Rule#01  Mon, Tue  10$
   * All the values from from Mon, Tue will be 20$.
   */
  Amount = 'AMOUNT',
  /**
   * If set, the associated duration is ignored to the final price. In case it overlaps with other rules, EXCLUDE has priority"
   * e.g. Rule#01  Mon, Tue       10$
   * Rule#02  01.01 - 31.12  20%
   * Rule#03  01.01 - 01.06  EXCLUDE
   * All the values from 01.01 - 01.06 will be excluded.
   */
  Exclude = 'EXCLUDE',
  /**
   * If set, this action will override all the others and will be applied.
   * ONLY means that the product item will be override by the the condition (specific date, weekday) instead of purchase dates
   */
  Only = 'ONLY',
}

export enum AdvanceType {
  /**
   * Automatic advance contains always full order products minus already invoiced products.
   * First automatic advance of the order contains all order products while next automatic advances contains just change
   * after last advance.
   * Products are kept up-to-date with order automatically when invoice is in DRAFT state.
   */
  Automatic = 'AUTOMATIC',
  /**
   * Manual advance is used to collect fixed amount of money for the order. Advance products are managed manually not
   * depending on the order products. First productItem is added automatically to the created advance.
   */
  Manual = 'MANUAL',
  /**
   * Create one refund invoice from a sum of open invoices.
   * This enables the final invoice accepting and asking the missing money through it instead of open advances
   */
  RefundUnpaidAdvances = 'REFUND_UNPAID_ADVANCES',
}

export enum AgeField {
  Age = 'AGE',
  AgeCategory = 'AGE_CATEGORY',
  Birthday = 'BIRTHDAY',
}

export type AssigneeInput = {
  id: Scalars['ID']
  type: AssigneeType
}

export enum AssigneeType {
  User = 'USER',
  UserGroup = 'USER_GROUP',
}

export enum AttributeSource {
  /** Purchase attributes are set manually */
  Manual = 'MANUAL',
  /** Purchase attributes are coming from the reservation duration and resource quantity */
  Reservation = 'RESERVATION',
}

export type BirthdayInput = {
  date?: InputMaybe<Scalars['Int']>
  month?: InputMaybe<Scalars['Int']>
  year: Scalars['Int']
}

export enum BoundType {
  End = 'END',
  Start = 'START',
}

export type CatalogProductAddItemsFromCatalogInput = {
  /** Add items from other CatalogProduct */
  catalogProductId: Scalars['ID']
  /** CatalogProduct ID to update */
  id: Scalars['ID']
}

export type CatalogProductCopyInput = {
  copyTo: CopyToCatalogInput
  id: Scalars['ID']
}

export type CatalogProductCreateInput = {
  catalogId: Scalars['String']
  categoryIds: Array<Scalars['String']>
  name: Scalars['String']
  notes?: InputMaybe<Scalars['String']>
  productTitleIds: Array<Scalars['String']>
}

export type CatalogProductItemAddInput = {
  productId: Scalars['ID']
  productTitleId: Scalars['String']
}

export type CatalogProductUpdateInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  targets?: InputMaybe<Array<ProductTarget>>
}

/**  TODO still valid type? */
export enum CatalogSalesType {
  Accommodation = 'ACCOMMODATION',
  Basic = 'BASIC',
  Calendar = 'CALENDAR',
  Meal = 'MEAL',
}

export type CategoryTargetInput = {
  /** The key value of a CategoryTarget entity. */
  targetKey: Scalars['String']
}

/**  Inputs: common */
export type CheckInInput = {
  date: Scalars['LocalDate']
  type: CheckInType
}

export enum CheckInType {
  /** Before 14.00 */
  Early = 'EARLY',
  /** After 18.00 */
  Late = 'LATE',
  /** Between 14.00 and 18.00 */
  Standard = 'STANDARD',
}

export type CheckOutInput = {
  date: Scalars['LocalDate']
  type: CheckOutType
}

export enum CheckOutType {
  /** After 12.00 */
  Late = 'LATE',
  /** Before 12.00 */
  Standard = 'STANDARD',
}

export enum CommissionMode {
  /** Commission amount is subtracted from the original unit price */
  GrossPrice = 'GROSS_PRICE',
  /**
   * Commission rate does not affect the unit price of the item.
   * The commission amount is added on top of the price
   */
  NetPrice = 'NET_PRICE',
  /** Commission does not apply at all for this item */
  NoCommission = 'NO_COMMISSION',
}

export enum ConditionRuleType {
  AgeCategory = 'AGE_CATEGORY',
  Boundary = 'BOUNDARY',
  Date = 'DATE',
  Quantity = 'QUANTITY',
  Weekday = 'WEEKDAY',
}

export type CopyToCatalogInput = {
  catalogId: Scalars['String']
  customerId?: InputMaybe<Scalars['String']>
}

export enum CreateOrderType {
  Offer = 'OFFER',
  Order = 'ORDER',
}

/**  Inputs */
export type CreateTaskInput = {
  assignee?: InputMaybe<AssigneeInput>
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['LocalDate']>
  dueTime?: InputMaybe<Scalars['LocalTime']>
  name?: InputMaybe<Scalars['String']>
  reservationId?: InputMaybe<Scalars['ID']>
  salesId?: InputMaybe<Scalars['ID']>
}

/**  Address */
export type CustomerAddressInput = {
  label?: InputMaybe<Scalars['String']>
  postalAddress: PostalAddressInput
}

/**  Contact */
export type CustomerContactInput = {
  email?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  notes?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  sourceId?: InputMaybe<Scalars['String']>
}

export type CustomerCreateAddressInput = {
  address: CustomerAddressInput
  customerId: Scalars['ID']
}

export type CustomerFilterInput = {
  customerId: Scalars['ID']
}

export type CustomerOrganizationCreateContactInput = {
  contact?: InputMaybe<CustomerContactInput>
  customerId: Scalars['ID']
}

/**  Organization */
export type CustomerOrganizationCreateInput = {
  defaultAddress?: InputMaybe<CustomerAddressInput>
  defaultCustomerContact?: InputMaybe<CustomerContactInput>
  eInvoicingAddress?: InputMaybe<EInvoicingAddressInput>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes?: InputMaybe<Scalars['String']>
  organization: CustomerOrganizationDataInput
  publicName?: InputMaybe<Scalars['String']>
  published?: InputMaybe<Scalars['Boolean']>
  sourceId?: InputMaybe<Scalars['String']>
}

export type CustomerOrganizationDataInput = {
  businessId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type CustomerOrganizationRemoveContactInput = {
  contactId: Scalars['ID']
  customerId: Scalars['ID']
}

export type CustomerOrganizationSetDefaultContactInput = {
  contactId: Scalars['ID']
  customerId: Scalars['ID']
}

export type CustomerOrganizationUpdateContactInput = {
  contact: CustomerContactInput
  contactId: Scalars['ID']
  customerId: Scalars['ID']
}

export type CustomerOrganizationUpdateEInvoicingAddressInput = {
  customerId: Scalars['ID']
  eInvoicingAddress: EInvoicingAddressInput
}

export type CustomerOrganizationUpdateInput = {
  /** Create or update customer address */
  address?: InputMaybe<UpsertAddressInput>
  /** Create or update customer contact */
  contact?: InputMaybe<UpsertContactInput>
  customerId: Scalars['ID']
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Keep current value if field not provided */
  notes?: InputMaybe<Scalars['String']>
  organization: CustomerOrganizationDataInput
  /** Keep current value if field not provided */
  publicName?: InputMaybe<Scalars['String']>
  /** Keep current value if field not provided */
  published?: InputMaybe<Scalars['Boolean']>
}

/**  Person */
export type CustomerPersonCreateInput = {
  defaultAddress?: InputMaybe<CustomerAddressInput>
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes?: InputMaybe<Scalars['String']>
  person: PersonInput
  publicName?: InputMaybe<Scalars['String']>
  published?: InputMaybe<Scalars['Boolean']>
  sourceId?: InputMaybe<Scalars['String']>
}

export type CustomerPersonUpdateInput = {
  /** Create or update customer address */
  address?: InputMaybe<UpsertAddressInput>
  customerId: Scalars['ID']
  /** Keep current value if field not provided */
  labelIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** Keep current value if field not provided */
  notes?: InputMaybe<Scalars['String']>
  person: PersonInput
  /** Keep current value if field not provided */
  publicName?: InputMaybe<Scalars['String']>
  /** Keep current value if field not provided */
  published?: InputMaybe<Scalars['Boolean']>
}

export type CustomerRemoveAddressInput = {
  addressId: Scalars['ID']
  customerId: Scalars['ID']
}

export type CustomerSetDefaultAddressInput = {
  addressId: Scalars['ID']
  customerId: Scalars['ID']
}

export type CustomerSetTypeInput = {
  /** Target customer ID */
  customerId: Scalars['ID']
  /** Target customer type */
  type: CustomerType
}

/**  Model */
export enum CustomerType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

export type CustomerUpdateAddressInput = {
  address: CustomerAddressInput
  addressId: Scalars['ID']
  customerId: Scalars['ID']
}

export type DatesInput = {
  /** Inclusive */
  end: Scalars['LocalDate']
  /** Inclusive */
  start: Scalars['LocalDate']
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DayOffsetInput = {
  days: Scalars['Int']
}

export enum DimensionLabelValidationCategory {
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export type DimensionReferenceLabelInput = {
  dimensionId: Scalars['ID']
  /** Null or missing value set the selection to empty */
  labelId?: InputMaybe<Scalars['ID']>
}

export enum DimensionTarget {
  Bookkeeping = 'BOOKKEEPING',
  CalendarReservationHumanResource = 'CALENDAR_RESERVATION_HUMAN_RESOURCE',
  CalendarReservationProperty = 'CALENDAR_RESERVATION_PROPERTY',
  DayVisitor = 'DAY_VISITOR',
  Event = 'EVENT',
  FrontDeskSearch = 'FRONT_DESK_SEARCH',
  QuickSales = 'QUICK_SALES',
  Sales = 'SALES',
  SalesListSearch = 'SALES_LIST_SEARCH',
}

export enum DisplayOption {
  Hidden = 'HIDDEN',
  ShowAlways = 'SHOW_ALWAYS',
  ShowMenu = 'SHOW_MENU',
}

export type DocumentCreateInput = {
  name?: InputMaybe<Scalars['String']>
  ownerId: Scalars['ID']
  templateId: Scalars['ID']
}

export type DocumentDeleteInput = {
  id: Scalars['ID']
}

export type DocumentDownloadInput = {
  id: Scalars['ID']
}

export enum DocumentFileStatus {
  Final = 'FINAL',
  Preview = 'PREVIEW',
}

export type DocumentFilterInput = {
  documentId: Scalars['ID']
}

export type DocumentSetTemplateInput = {
  id: Scalars['ID']
  templateId: Scalars['ID']
}

export enum DocumentStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum DocumentType {
  Invoice = 'INVOICE',
  Offer = 'OFFER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  ParticipantList = 'PARTICIPANT_LIST',
  Program = 'PROGRAM',
  Vst = 'VST',
}

export type DocumentUpdateInput = {
  id: Scalars['ID']
  /** Patch update document-level language */
  language?: InputMaybe<Scalars['Language']>
  name: Scalars['String']
}

export type DocumentUserAttributeUpdateInput = {
  active: Scalars['Boolean']
  documentId: Scalars['ID']
  editorValue?: InputMaybe<Scalars['String']>
  key: Scalars['String']
  templateValue?: InputMaybe<Scalars['String']>
}

/**
 * Set the duration of the purchase
 * e.g. For accommodation, the duration is represented by dates. From 01.01.2019 to 10.01.2019 means 10 nights.
 * e.g. For reservation equipment, the duration is represented by hours. From 01.01.2019 10:00 to 01.01.2019 12:00 means 2 hours.
 */
export type DurationInput = {
  from: Scalars['LocalDateTime']
  to: Scalars['LocalDateTime']
}

export type EInvoicingAddressInput = {
  address: Scalars['String']
  operator: Scalars['String']
}

export enum EducationLevel {
  Elementary = 'ELEMENTARY',
  Higher = 'HIGHER',
  Secondary = 'SECONDARY',
}

export enum EventSort {
  Name = 'NAME',
  StartDate = 'START_DATE',
}

export enum EventType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export enum EventVisibility {
  Hidden = 'HIDDEN',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type EventsInput = {
  page: PageInput
  query?: InputMaybe<EventsQueryInput>
  sort?: InputMaybe<EventSort>
}

export type EventsQueryInput = {
  /** Event categories filter. Returned event must contain all the specified categories */
  categoryIds?: InputMaybe<Array<Scalars['ID']>>
  /** Event dates should overlap time until end date */
  end?: InputMaybe<Scalars['LocalDate']>
  /** Filter based on popular event condition, unspecified returns all */
  popularEvent?: InputMaybe<Scalars['Boolean']>
  /** Event dates should overlap time from start date */
  start?: InputMaybe<Scalars['LocalDate']>
}

export type ExtractTargetRoom = {
  quantity: Scalars['Int']
  roomReservationId: Scalars['ID']
}

export enum Family {
  Accommodation = 'ACCOMMODATION',
  Meal = 'MEAL',
  Reservation = 'RESERVATION',
  Simple = 'SIMPLE',
}

export enum Feature {
  Accommodation = 'ACCOMMODATION',
  Attachments = 'ATTACHMENTS',
  Commission = 'COMMISSION',
  Enrollments = 'ENROLLMENTS',
  Meals = 'MEALS',
  Orders = 'ORDERS',
  Overview = 'OVERVIEW',
  Participants = 'PARTICIPANTS',
  Products = 'PRODUCTS',
  Publish = 'PUBLISH',
  PublishRoom = 'PUBLISH_ROOM',
  Reservations = 'RESERVATIONS',
  Tasks = 'TASKS',
  Vst = 'VST',
}

export type FeatureFlagInput = {
  enabled: Scalars['Boolean']
  name: FeatureType
}

export enum FeatureStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum FeatureType {
  PublishedRoom = 'PUBLISHED_ROOM',
}

export enum FieldConfigStatus {
  /** Input is not shown at all */
  Hidden = 'HIDDEN',
  /** Input field is visible and required */
  Required = 'REQUIRED',
  /** Input field is visible but optional */
  Visible = 'VISIBLE',
}

export type FileCopyToInput = {
  id: FileIdentifierInput
  target: FileIdentifierInput
}

export type FileDeleteInput = {
  id: FileIdentifierInput
}

export type FileIdentifierInput = {
  fileName: Scalars['String']
  folder: Scalars['String']
  ownerId: Scalars['ID']
  ownerType: FileOwner
}

export enum FileOwner {
  Document = 'DOCUMENT',
  Sales = 'SALES',
}

export type FileRenameInput = {
  fileName: Scalars['String']
  id: FileIdentifierInput
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  /**  TODO rename OTHER */
  Unknown = 'UNKNOWN',
}

/**  Common inputs */
export type IntRangeConstraintInput = {
  /** Inclusive */
  max?: InputMaybe<Scalars['Int']>
  /** Inclusive */
  min?: InputMaybe<Scalars['Int']>
}

export enum InvoiceAction {
  AcceptInvoice = 'ACCEPT_INVOICE',
  /** Start any possible accept process */
  AcceptProcess = 'ACCEPT_PROCESS',
  /** Start payment process using CASH-related payments (CASH, CREDIT_CARD, VOUCHER, GIFT_CARD) */
  AddManualPayment = 'ADD_MANUAL_PAYMENT',
  /** Add manual reference transfer payment */
  AddManualReferenceTransferPayment = 'ADD_MANUAL_REFERENCE_TRANSFER_PAYMENT',
  /** Add manual other payment */
  AddOtherPayment = 'ADD_OTHER_PAYMENT',
  /** Create new refund from this invoice */
  CreateRefund = 'CREATE_REFUND',
  /** Delete this invoice */
  Delete = 'DELETE',
  /** Change invoice payment type to PDF and ACCEPT_INVOICE */
  PrintInvoice = 'PRINT_INVOICE',
  /** Change invoice payment type to INVOICE and ACCEPT_INVOICE */
  SendInvoice = 'SEND_INVOICE',
  /** Transfer invoice to the accounting system */
  TransferAccounting = 'TRANSFER_ACCOUNTING',
  /** Update any of the Invoice basic details */
  Update = 'UPDATE',
  /** Overwrite productItem-level accounts (not implemented yet) */
  UpdateAccounts = 'UPDATE_ACCOUNTS',
  /** Overwrite invoice-level dimensions (not implemented yet) */
  UpdateDimensions = 'UPDATE_DIMENSIONS',
  /** Manually add or remove lines of the RESERVATION_FEE Invoice */
  UpdateLines = 'UPDATE_LINES',
}

export type InvoiceCheckInput = {
  invoiceId: Scalars['ID']
  isChecked: Scalars['Boolean']
}

/** Patch update input */
export type InvoiceConfigInput = {
  /** If null, not update */
  packetProducts?: InputMaybe<Visibility>
  /** If null, not update */
  participantInfo?: InputMaybe<Visibility>
}

export enum InvoiceMassAction {
  PrintZeroReceipt = 'PRINT_ZERO_RECEIPT',
}

export type InvoiceMassActionInput = {
  action: InvoiceMassAction
  invoiceIds: Array<Scalars['ID']>
}

export enum InvoiceOrigin {
  Cancel = 'CANCEL',
  Original = 'ORIGINAL',
}

export enum InvoicePaymentType {
  /** Paid using cash and other payment options or just printing zero receipt */
  Cash = 'CASH',
  /** Willba transfers invoice to customer automatically through e.g. Maventa channel */
  Invoice = 'INVOICE',
  /** Customer pays online through on webshop */
  OnlinePayment = 'ONLINE_PAYMENT',
  /** Willba won't send invoice automatically to customer, it is user's responsibility and decision */
  Pdf = 'PDF',
}

export enum InvoiceState {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
}

export enum InvoiceType {
  /** Pre-invoice to collect or refund money for specific order */
  Advance = 'ADVANCE',
  /** Final invoice or refund of specific order */
  Invoice = 'INVOICE',
}

export type InvoicesByIdsInput = {
  ids: Array<Scalars['ID']>
}

export enum IssueLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

export enum IssueType {
  Invalid = 'INVALID',
  Missing = 'MISSING',
}

export enum ItemType {
  Accommodation = 'ACCOMMODATION',
  Meal = 'MEAL',
  Reservation = 'RESERVATION',
  Simple = 'SIMPLE',
}

export type LocalDateRangeInput = {
  end: Scalars['LocalDate']
  start: Scalars['LocalDate']
}

export type LocationInput = {
  /** ID of the location entity */
  id: Scalars['ID']
  /** Location type of the provided id. The actual entity must exists */
  type: LocationType
}

/** Meal location can be Restaurant, Room, or ResourceProperty or ResourcePropertyNested */
export enum LocationType {
  /** ResourceProperty or ResourcePropertyNested */
  Property = 'PROPERTY',
  /** Restaurant */
  Restaurant = 'RESTAURANT',
  /** Room */
  Room = 'ROOM',
}

export type MarginalTaxInput = {
  /** Defines if the marginal tax rule applies */
  active?: InputMaybe<Scalars['Boolean']>
  /** Tax rate from the profit margin */
  marginalTaxRate?: InputMaybe<Scalars['Float']>
}

export type MarkCheckedInput = {
  participantRoomReservationIds: Array<Scalars['ID']>
  timestamp?: InputMaybe<Scalars['LocalDateTime']>
}

export enum MassOperationStatus {
  Completed = 'COMPLETED',
  CompletedWithErrors = 'COMPLETED_WITH_ERRORS',
  Started = 'STARTED',
}

export enum MealBoundary {
  ExcludeEndDate = 'EXCLUDE_END_DATE',
  ExcludeStartAndEndDate = 'EXCLUDE_START_AND_END_DATE',
  ExcludeStartDate = 'EXCLUDE_START_DATE',
  IncludeStartAndEndDate = 'INCLUDE_START_AND_END_DATE',
}

export type MealParticipantsFilter = {
  /**
   * Defines if participants are filtered by participant allergy or diet
   * Default `true` means only participants having any allergy or diet is returned
   */
  mustHaveAllergyOrDiet?: InputMaybe<Scalars['Boolean']>
}

export type MealParticipantsInput = {
  filter?: InputMaybe<MealParticipantsFilter>
}

export enum MediaFormat {
  /** High resolution */
  Large = 'LARGE',
  /** Mid-size */
  Medium = 'MEDIUM',
  /** Small-size */
  Small = 'SMALL',
  /** Really small size for thumbnails */
  Thumbnail = 'THUMBNAIL',
}

export type MinuteOffsetInput = {
  days: Scalars['Int']
  minutes: Scalars['Int']
}

export type MovePublicRoomInput = {
  checkIn: CheckInInput
  checkOut: CheckOutInput
  roomReservationId: Scalars['ID']
}

export type NamedValueInput = {
  label?: InputMaybe<Scalars['String']>
  values: Array<Scalars['String']>
}

/** Main application root-level view */
export enum NavInternalViewType {
  BookingAccommodationReservation = 'BOOKING_ACCOMMODATION_RESERVATION',
  BookingResourceCalendar = 'BOOKING_RESOURCE_CALENDAR',
  BookingRoomLayout = 'BOOKING_ROOM_LAYOUT',
  ExternalDocs = 'EXTERNAL_DOCS',
  ExternalReleases = 'EXTERNAL_RELEASES',
  FrontDeskRoom = 'FRONT_DESK_ROOM',
  FrontDeskSales = 'FRONT_DESK_SALES',
  ListingGuest = 'LISTING_GUEST',
  ListingPacketMeal = 'LISTING_PACKET_MEAL',
  ListingReservation = 'LISTING_RESERVATION',
  ListingRoomReservation = 'LISTING_ROOM_RESERVATION',
  ListingRoomReservationPajulahti = 'LISTING_ROOM_RESERVATION_PAJULAHTI',
  ListingSales = 'LISTING_SALES',
  RegistryBuilding = 'REGISTRY_BUILDING',
  RegistryCatalog = 'REGISTRY_CATALOG',
  RegistryCustomer = 'REGISTRY_CUSTOMER',
  RegistryProduct = 'REGISTRY_PRODUCT',
  RegistryRoom = 'REGISTRY_ROOM',
  RegistryRoomType = 'REGISTRY_ROOM_TYPE',
  RegistryStrapi = 'REGISTRY_STRAPI',
  RegistryUser = 'REGISTRY_USER',
  ReportOpenAdvance = 'REPORT_OPEN_ADVANCE',
  ReportPayment = 'REPORT_PAYMENT',
  ReportReservation = 'REPORT_RESERVATION',
  SearchEnrollment = 'SEARCH_ENROLLMENT',
  SearchInvoice = 'SEARCH_INVOICE',
  SearchSales = 'SEARCH_SALES',
  SearchTask = 'SEARCH_TASK',
}

export enum NavItemType {
  /**
   * Possible dropdown items:
   * BOOKING_ROOM_LAYOUT
   * BOOKING_ACCOMMODATION_RESERVATION
   * BOOKING_RESOURCE_CALENDAR
   */
  Booking = 'BOOKING',
  /**
   * Possible dropdown items:
   * FRONT_DESK_SALES
   * FRONT_DESK_ROOM
   */
  FrontDesk = 'FRONT_DESK',
  /**
   * Possible dropdown items:
   * LISTING_GUEST
   * LISTING_PACKET_MEAL
   * LISTING_ROOM_RESERVATION_PAJULAHTI
   * LISTING_ROOM_RESERVATION
   * LISTING_SALES
   * LISTING_RESERVATION
   */
  Listing = 'LISTING',
  /**
   * Possible dropdown items:
   * REGISTRY_BUILDING
   * REGISTRY_CATALOG
   * REGISTRY_CUSTOMER
   * REGISTRY_PRODUCT
   * REGISTRY_ROOM_TYPE
   * REGISTRY_ROOM
   */
  Registry = 'REGISTRY',
  /**
   * Possible dropdown items:
   * REPORT_PAYMENT
   * REPORT_RESERVATION
   */
  Report = 'REPORT',
  /**
   * Possible dropdown items:
   * SEARCH_SALES
   * SEARCH_ENROLLMENT
   * SEARCH_INVOICE
   * SEARCH_TASK
   */
  Search = 'SEARCH',
}

export enum NavSortBy {
  /** Sort dropdown items by localised link display name */
  Name = 'NAME',
  /** Keep dropdown items sort as it is returned */
  Order = 'ORDER',
}

export enum OfferAction {
  Accept = 'ACCEPT',
  Delete = 'DELETE',
  MarkWaitForApproval = 'MARK_WAIT_FOR_APPROVAL',
  RejectOfferIterate = 'REJECT_OFFER_ITERATE',
  RollBackLatest = 'ROLL_BACK_LATEST',
  Update = 'UPDATE',
}

export type OfferPerformActionInput = {
  action: OfferAction
  offerId: Scalars['ID']
}

export enum OfferState {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  WaitingForApproval = 'WAITING_FOR_APPROVAL',
}

export type OpenAdvancesInput = {
  date: Scalars['LocalDate']
}

export enum OrderAction {
  CancelOrder = 'CANCEL_ORDER',
  CreateAutomaticAdvance = 'CREATE_AUTOMATIC_ADVANCE',
  CreateConfirmation = 'CREATE_CONFIRMATION',
  CreateInvoice = 'CREATE_INVOICE',
  CreateManualAdvance = 'CREATE_MANUAL_ADVANCE',
  CreateOffer = 'CREATE_OFFER',
  CreateRefundUnpaidAdvances = 'CREATE_REFUND_UNPAID_ADVANCES',
  Delete = 'DELETE',
  Update = 'UPDATE',
  UpdateCustomer = 'UPDATE_CUSTOMER',
  UpdateSeller = 'UPDATE_SELLER',
}

export enum OrderConfirmationAction {
  Accept = 'ACCEPT',
  Delete = 'DELETE',
}

/**  Order confirmation */
export type OrderConfirmationActionInput = {
  action: OrderConfirmationAction
  confirmationId: Scalars['ID']
}

export enum OrderConfirmationState {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
}

export type OrderCreateAdvanceInput = {
  advanceType?: InputMaybe<AdvanceType>
  orderId: Scalars['ID']
  paymentType?: InputMaybe<InvoicePaymentType>
}

export type OrderCreateConfirmationInput = {
  orderId: Scalars['ID']
}

/**  Order */
export type OrderCreateInput = {
  name?: InputMaybe<Scalars['String']>
  orderType: CreateOrderType
  salesId: Scalars['ID']
}

export type OrderCreateInvoiceInput = {
  orderId: Scalars['ID']
  paymentType: InvoicePaymentType
}

/**  Offer */
export type OrderCreateOfferInput = {
  orderId: Scalars['ID']
}

export type OrderCreateRefundInput = {
  /** Default to current date */
  dueDate?: InputMaybe<Scalars['LocalDate']>
  /** Default to current date */
  invoiceDate?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  refundAction?: InputMaybe<RefundAction>
}

export type OrderDeleteConfirmationInput = {
  confirmationId: Scalars['ID']
}

export type OrderDeleteInvoiceInput = {
  invoiceId: Scalars['ID']
}

export type OrderDeleteOfferInput = {
  offerId: Scalars['ID']
}

export type OrderFilterInput = {
  salesId: Scalars['ID']
}

/**  Invoice */
export type OrderInvoiceAcceptInput = {
  invoiceId: Scalars['ID']
}

export type OrderInvoiceSetPaymentTypeInput = {
  invoiceId: Scalars['ID']
  paymentType: InvoicePaymentType
}

export enum OrderPacketSourceType {
  Manual = 'MANUAL',
  SalesPurchase = 'SALES_PURCHASE',
  ServiceFee = 'SERVICE_FEE',
}

/**  TODO types are outdated */
export enum OrderPhaseType {
  Invoice = 'INVOICE',
  InvoiceRefund = 'INVOICE_REFUND',
  InvoiceReservationFee = 'INVOICE_RESERVATION_FEE',
  InvoiceReservationFeeRefund = 'INVOICE_RESERVATION_FEE_REFUND',
  Offer = 'OFFER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
}

export type OrderSetCustomerAddressInput = {
  addressId: Scalars['ID']
  orderId: Scalars['ID']
}

export type OrderSetCustomerContactInput = {
  customerContactId?: InputMaybe<Scalars['ID']>
  orderId: Scalars['ID']
}

export type OrderSetCustomerInput = {
  addressId?: InputMaybe<Scalars['ID']>
  customerContactId?: InputMaybe<Scalars['ID']>
  customerId?: InputMaybe<Scalars['ID']>
  orderId: Scalars['ID']
}

export enum OrderState {
  Cancelled = 'CANCELLED',
  CancelProcess = 'CANCEL_PROCESS',
  Closed = 'CLOSED',
  Correction = 'CORRECTION',
  Open = 'OPEN',
  ReInvoiceProcess = 'RE_INVOICE_PROCESS',
}

export type OrderUpdateInput = {
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** Patch update input. If field is not given, field value is not updated */
export type OrderUpdateInvoiceInput = {
  config?: InputMaybe<InvoiceConfigInput>
  dueDate?: InputMaybe<Scalars['LocalDate']>
  dueDateDuration?: InputMaybe<Scalars['Int']>
  freeText?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  invoiceDate?: InputMaybe<Scalars['LocalDate']>
  numberSeriesId?: InputMaybe<Scalars['ID']>
  paymentTerms?: InputMaybe<PaymentTermsInput>
  sellerContact?: InputMaybe<Scalars['String']>
  valueDate?: InputMaybe<Scalars['LocalDate']>
}

export type OrderUpdateOfferInput = {
  offerId: Scalars['ID']
  /** Specify date or duration, not both */
  validTo?: InputMaybe<Scalars['LocalDate']>
  validToDuration?: InputMaybe<Scalars['Int']>
}

export type PageInput = {
  /** Page number starting from 0, default 0 */
  page?: InputMaybe<Scalars['Int']>
  /** Page size, min 1 max 200, default 10 */
  size?: InputMaybe<Scalars['Int']>
}

/**  TODO most scalable API structure would include pagination with type `first: Int, cursor: String` https://graphql.org/learn/pagination/ Do we need this scalability? */
export type ParticipantConnectionInput = {
  /** Filter participants, by default without filtering */
  filter?: InputMaybe<ParticipantsFilterInput>
  /** Participants page spec, default PageInput values if not provided */
  pagination?: InputMaybe<PageInput>
  /**
   * Array of ordered sort elements
   * Main sort is defined by first element, secondary sorts can be specified with rest of elements
   * Default is SORT_ORDER
   */
  sort?: InputMaybe<Array<ParticipantsSort>>
}

export type ParticipantCustomFieldInput = {
  name: Scalars['String']
  value: Scalars['String']
}

export enum ParticipantField {
  AdditionalInfo = 'ADDITIONAL_INFO',
  Allergies = 'ALLERGIES',
  BirthDate = 'BIRTH_DATE',
  Diets = 'DIETS',
  DrugAllergies = 'DRUG_ALLERGIES',
  Education = 'EDUCATION',
  Email = 'EMAIL',
  Gender = 'GENDER',
  Group = 'GROUP',
  Language = 'LANGUAGE',
  LifeStage = 'LIFE_STAGE',
  Name = 'NAME',
  Nationality = 'NATIONALITY',
  PostalAddress = 'POSTAL_ADDRESS',
  Products = 'PRODUCTS',
  ProductsDates = 'PRODUCTS_DATES',
}

/**  TODO rename to ParticipantSubscriptionFilterInput */
export type ParticipantFilterInput = {
  salesId?: InputMaybe<Scalars['ID']>
}

export enum ParticipantLifeStage {
  Employed = 'EMPLOYED',
  Other = 'OTHER',
  Pensioner = 'PENSIONER',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
}

export enum ParticipantSortProperty {
  FirstName = 'FIRST_NAME',
  FirstRoomNumber = 'FIRST_ROOM_NUMBER',
  LastName = 'LAST_NAME',
  Sales = 'SALES',
  SortOrder = 'SORT_ORDER',
}

/** Patch update input. If field is not given, field value is not updated */
export type ParticipantVstSetAttributesInput = {
  included?: InputMaybe<Scalars['Boolean']>
  lessonFee?: InputMaybe<Scalars['Float']>
  lessonHours?: InputMaybe<Scalars['Float']>
  participantId: Scalars['ID']
}

export type ParticipantVstSetDatesDefaultInput = {
  /**
   * Specify context Sales: root Event or Sales of the participant
   * Default is the sales of the participant
   */
  contextSalesId?: InputMaybe<Scalars['ID']>
  participantId: Scalars['ID']
  setDefaultEnd?: InputMaybe<Scalars['Boolean']>
  setDefaultStart?: InputMaybe<Scalars['Boolean']>
  /**
   * When true then participant own default start is patched to all participant with same original start
   * and own default end is patched to all participants with same original end.
   * All participants means participants in the context Sales. In Event, it is also all from Ennrollemnts
   */
  updateAll?: InputMaybe<Scalars['Boolean']>
}

/** Patch update input. If field is not given, field value is not updated */
export type ParticipantVstSetDatesInput = {
  /**
   * Specify context Sales: root Event or Sales of the participant
   * Default is the sales of the participant
   */
  contextSalesId?: InputMaybe<Scalars['ID']>
  end?: InputMaybe<Scalars['LocalDateTime']>
  participantId: Scalars['ID']
  start?: InputMaybe<Scalars['LocalDateTime']>
  /**
   * When true then start is patched to all participant with same start
   * and end is patched to all participants with same end.
   * All participants means participants in the context Sales. In Event, it is also all from Ennrollemnts
   */
  updateAll?: InputMaybe<Scalars['Boolean']>
}

export enum ParticipantVstState {
  /** Participant VST data is complete */
  Complete = 'COMPLETE',
  /** Participant is non-VST participant */
  Excluded = 'EXCLUDED',
  /** VST data is missing */
  Incomplete = 'INCOMPLETE',
}

export type ParticipantsFilterInput = {
  accommodationTargetIds?: InputMaybe<Array<Scalars['ID']>>
  ageCategoryIds?: InputMaybe<Array<Scalars['ID']>>
  /**
   * Enrollment state filter, default to all states with system state OPEN or CLOSED
   * Affects only if called from `EVENT`
   */
  enrollmentStates?: InputMaybe<Array<Scalars['String']>>
  /** Matches participant gender. Match all participants if not provided */
  genders?: InputMaybe<Array<Gender>>
  /** Matches registry Room ids. Match all participants if not provided */
  roomIds?: InputMaybe<Array<Scalars['ID']>>
  /**
   * Matches to any substring of firstName or lastName or exact sales number
   * Match any participant if not provided or blank
   */
  search?: InputMaybe<Scalars['String']>
}

export type ParticipantsSort = {
  field: ParticipantSortProperty
  order?: InputMaybe<SortOrder>
}

export type PaymentCashAddInput = {
  amount: Scalars['Float']
  date?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  notes: Scalars['String']
  pointOfSaleId?: InputMaybe<Scalars['String']>
}

export type PaymentCreditCardAddInput = {
  amount: Scalars['Float']
  date?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
  pointOfSaleId: Scalars['String']
  terminalId?: InputMaybe<Scalars['String']>
}

export type PaymentGiftCardAddInput = {
  amount: Scalars['Float']
  /** Unique identifier of the gift card */
  code: Scalars['String']
  date?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
  pointOfSaleId: Scalars['String']
}

export type PaymentInputFilter = {
  orderId: Scalars['ID']
}

export enum PaymentOperation {
  /**
   * Cancel an external payment
   * For Nets this means delete the transaction
   */
  CancelExternalPayment = 'CANCEL_EXTERNAL_PAYMENT',
  /** Delete a payment that is created by a willba user */
  DeleteManualPayment = 'DELETE_MANUAL_PAYMENT',
  /** Update a payment that is created by a willba user */
  UpdateManualPayment = 'UPDATE_MANUAL_PAYMENT',
}

export type PaymentOtherInput = {
  amount: Scalars['Float']
  author?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  invoiceReference?: InputMaybe<Scalars['String']>
  notes: Scalars['String']
  salesReference?: InputMaybe<Scalars['String']>
  terminalId?: InputMaybe<Scalars['String']>
  /** Reference to other payment type id */
  typeId: Scalars['ID']
}

export type PaymentReferenceAddInput = {
  amount: Scalars['Float']
  /** The original date of the payment */
  date: Scalars['LocalDate']
  invoiceId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
  /** Original reference of the payment, defaults to invoice reference if not specified */
  reference?: InputMaybe<Scalars['String']>
}

export enum PaymentSource {
  Manual = 'MANUAL',
  /** Internal source */
  ManualPointOfSale = 'MANUAL_POINT_OF_SALE',
  ManualVoucherRegistration = 'MANUAL_VOUCHER_REGISTRATION',
  Nets = 'NETS',
  Paytrail = 'PAYTRAIL',
  RefundInvoice = 'REFUND_INVOICE',
  ReservationFeeInvoice = 'RESERVATION_FEE_INVOICE',
  /** External source */
  VivaWallet = 'VIVA_WALLET',
  Wintime = 'WINTIME',
}

export enum PaymentState {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Paid = 'PAID',
  PaidPending = 'PAID_PENDING',
}

export enum PaymentStatus {
  Overpaid = 'OVERPAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Unpaid = 'UNPAID',
}

/** Patch update input. If field is not given, field value is not updated */
export type PaymentTermsInput = {
  freeText?: InputMaybe<Scalars['String']>
  overdueFixedAmount?: InputMaybe<Scalars['Float']>
  overduePercent?: InputMaybe<Scalars['Float']>
}

export enum PaymentType {
  Cash = 'CASH',
  CreditCard = 'CREDIT_CARD',
  GiftCard = 'GIFT_CARD',
  Online = 'ONLINE',
  Other = 'OTHER',
  ReferenceTransfer = 'REFERENCE_TRANSFER',
  RefundInvoice = 'REFUND_INVOICE',
  ReservationFeeInvoice = 'RESERVATION_FEE_INVOICE',
  Voucher = 'VOUCHER',
}

export type PaymentTypeOptionInput = {
  pointOfSaleId?: InputMaybe<Scalars['ID']>
  sellerId: Scalars['ID']
}

export type PaymentUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>
  /** Affect only with PaymentOther */
  author?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['LocalDate']>
  id: Scalars['ID']
  /** Affect only with PaymentOther */
  invoiceReference?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  pointOfSaleId?: InputMaybe<Scalars['String']>
  /** Affect only with PaymentOther */
  salesReference?: InputMaybe<Scalars['String']>
  /** Affect only with `PaymentOther` or `PaymentVoucher` */
  typeId?: InputMaybe<Scalars['String']>
}

export type PaymentVoucherAddInput = {
  amount: Scalars['Float']
  date?: InputMaybe<Scalars['LocalDate']>
  invoiceId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
  pointOfSaleId: Scalars['String']
  /** Id of the selected Registry.voucherProviders */
  providerId: Scalars['String']
}

export type PaymentsInput = {
  from: Scalars['Timestamp']
  states?: InputMaybe<Array<PaymentState>>
  to: Scalars['Timestamp']
  types: Array<PaymentType>
}

export type PersonInput = {
  email?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
}

/** Input type for productItem adding */
export type PosProductAddInput = {
  /** Catalog product ID to add */
  catalogProductId: Scalars['ID']
  /** Product id in source */
  externalProductId?: InputMaybe<Scalars['String']>
  /** Optionally override CatalogProduct name with specific name */
  name?: InputMaybe<Scalars['String']>
  /** Quantity of added products */
  quantity: Scalars['Int']
  /** Override catalog product unitPrice with specific unitPrice */
  unitPrice: UnitPriceInput
}

/** External products addition mutation input type */
export type PosProductsAddInput = {
  /** Specify target Order by orderNumber */
  orderNumber: Scalars['Int']
  /** Products to add */
  products: Array<PosProductAddInput>
}

/** Input type for products removal */
export type PosProductsDeleteInput = {
  batchId: Scalars['ID']
}

export type PostalAddressInput = {
  address1?: InputMaybe<Scalars['String']>
  address2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  postcode?: InputMaybe<Scalars['String']>
}

export enum PriceType {
  VatExcluded = 'VAT_EXCLUDED',
  VatIncluded = 'VAT_INCLUDED',
}

export enum ProbabilitySource {
  Enrollment = 'ENROLLMENT',
  Event = 'EVENT',
}

/** Add new ProductRule to ProductItem */
export type ProductItemAddRuleInput = {
  /** Target product item id to add the rule */
  id: Scalars['ID']
  ruleType?: InputMaybe<ConditionRuleType>
}

export type ProductMealInput = {
  /** Optionally update meal duration in minutes */
  duration?: InputMaybe<Scalars['Int']>
  /** Meal id */
  id: Scalars['ID']
  /** Optionally update location of the meal */
  location?: InputMaybe<LocationInput>
  /** Optionally update meal notes */
  notes?: InputMaybe<Scalars['String']>
  /** Optionally update meal start time */
  startTime?: InputMaybe<Scalars['LocalTime']>
}

/** Where this quantity should be taken from */
export enum ProductQuantitySource {
  /** Quantity is taken from Participant (PAX) */
  Participant = 'PARTICIPANT',
  /** Default one - quantity is taken from Product */
  Product = 'PRODUCT',
}

export type ProductRuleActionInput = {
  actionType: ActionRuleType
  amount?: InputMaybe<Scalars['Float']>
}

export type ProductRuleConditionInput = {
  ageCategoryIds?: InputMaybe<Array<Scalars['String']>>
  bounds?: InputMaybe<Array<BoundType>>
  dates?: InputMaybe<Array<Scalars['LocalDate']>>
  quantity?: InputMaybe<Scalars['Int']>
  ruleType?: InputMaybe<ConditionRuleType>
  weekdays?: InputMaybe<Array<DayOfWeek>>
}

/**
 * Set the quantity, weekdays or dates for rule.
 * The type will respect the change if not manually specified.
 * Coerce the rule type with rule type field
 */
export type ProductRuleUpdateInput = {
  action?: InputMaybe<ProductRuleActionInput>
  condition?: InputMaybe<ProductRuleConditionInput>
  productRuleId: Scalars['ID']
}

export enum ProductTarget {
  /** Product is connectable to Participant */
  Participant = 'PARTICIPANT',
  /** Product is connected to sales */
  Product = 'PRODUCT',
  /** Product is connectable to calendar Reservation */
  Reservation = 'RESERVATION',
  /** Product is connectable to accommodation RoomReservation or RoomTypeReservation  */
  Room = 'ROOM',
}

/** Patch update input. If field is not in the input,  */
export type ProductUpdateItemInput = {
  /** Define product price meaning and affect to purchase and commission amounts if commission rate is defined */
  commissionMode?: InputMaybe<CommissionMode>
  id: Scalars['ID']
  /** Total internal cost of the item for the provider company per unit */
  internalCost?: InputMaybe<Scalars['Float']>
  /** Cost of the purchase of the item per unit for the provider company */
  internalPurchase?: InputMaybe<Scalars['Float']>
  marginalTax?: InputMaybe<MarginalTaxInput>
  name?: InputMaybe<Scalars['String']>
  quantityUnit?: InputMaybe<QuantityUnit>
  sourceQuantity?: InputMaybe<ProductQuantitySource>
  unitPrice?: InputMaybe<UnitPricePatchInput>
}

export enum ProgramTimeFormat {
  Full = 'full',
  StartOnly = 'start_only',
}

export enum ProgramTimePlacement {
  Left = 'left',
  Right = 'right',
}

export type PublicRoomAddInput = {
  add?: InputMaybe<PublicRoomInput>
  /** Add PublicRoom into this Sales */
  salesId: Scalars['ID']
}

export type PublicRoomAvailabilityInput = {
  from: Scalars['LocalDate']
  /** If fetching possible availabilities for existing reservation, pass the reservation ID as a parameter */
  reservationId?: InputMaybe<Scalars['ID']>
  to: Scalars['LocalDate']
}

export type PublicRoomAvailabilityStatusInput = {
  checkIn: Scalars['LocalDate']
  checkOut: Scalars['LocalDate']
  /** Check availability status for different dates for existing public room reservation */
  reservationId?: InputMaybe<Scalars['ID']>
  /** Check availability status for a reservation for existing Sales */
  salesId?: InputMaybe<Scalars['ID']>
}

/** Patch update input, only specified values updates the content */
export type PublicRoomInput = {
  /** If marketingPriceType is TEXT, this text will be shown */
  marketingPriceText?: InputMaybe<Scalars['String']>
  /** Defines what kind of marketing price is shown with the room */
  marketingPriceType?: InputMaybe<RoomMarketingPriceType>
  /** Specify custom max limit of guests should be added into this room. By default limit is the capacity of the room */
  maxGuests?: InputMaybe<Scalars['Int']>
  /** Specify custom min limit of guests should be added into this room. By default limit is 1 */
  minGuests?: InputMaybe<Scalars['Int']>
  /** SalesProduct ID, defines the accommodation product that will be used when ordering the room */
  productId?: InputMaybe<Scalars['ID']>
  /** This room is live starting from this date */
  publishFrom?: InputMaybe<Scalars['LocalDate']>
  /** This room is live until this date */
  publishTo?: InputMaybe<Scalars['LocalDate']>
  /** RoomMarketing ID, includes the room details and images for public audience */
  roomMarketingId?: InputMaybe<Scalars['ID']>
  /** AccommodationTarget ID, defines the capacity this PublicRoom will expose */
  targetId?: InputMaybe<Scalars['ID']>
}

export type PublicRoomParticipantAmountsInput = {
  ageCategoryId?: InputMaybe<Scalars['ID']>
  /** Value must be greater than zero */
  quantity: Scalars['Int']
}

export type PublicRoomRemoveInput = {
  /** PublicRoom ID */
  id: Scalars['ID']
}

export type PublicRoomUpdateInput = {
  /** PublicRoom ID */
  id: Scalars['ID']
  update: PublicRoomInput
}

export type PurchaseFilter = {
  /** Included families or purchase types */
  families: Array<Family>
  /** Included purchase direct targets */
  targets: Array<PurchaseTarget>
}

export type PurchaseInvoiceInput = {
  /** Invoice id when purchase is only for specific invoice */
  invoiceId: Scalars['ID']
  /** True when purchase is added only for specific invoice as service fee */
  serviceFee?: InputMaybe<Scalars['Boolean']>
}

export enum PurchaseProductAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export type PurchaseProductAddFromCatalogProductInput = {
  add: PurchaseProductAddInput
  catalogProductId: Scalars['ID']
  invoice?: InputMaybe<PurchaseInvoiceInput>
}

export type PurchaseProductAddFromProductTitleInput = {
  add: PurchaseProductAddInput
  invoice?: InputMaybe<PurchaseInvoiceInput>
  productTitleId: Scalars['ID']
}

export type PurchaseProductAddFromSalesProductInput = {
  add: PurchaseProductAddInput
  salesProductId: Scalars['ID']
}

export type PurchaseProductAddInput = {
  attributes?: InputMaybe<PurchaseProductAttributesInput>
  link: PurchaseProductAddLinkInput
  orderId?: InputMaybe<Scalars['ID']>
  target?: InputMaybe<ProductTarget>
}

export type PurchaseProductAddLinkInput = {
  participantId?: InputMaybe<Scalars['ID']>
  reservationId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
  /** Specify Participant Service if adding Purchase into specific Participant */
  serviceId?: InputMaybe<Scalars['ID']>
  taskId?: InputMaybe<Scalars['ID']>
}

/** Patch update object */
export type PurchaseProductAttributesInput = {
  ageCategoryId?: InputMaybe<Scalars['ID']>
  duration?: InputMaybe<DurationInput>
  quantity?: InputMaybe<Scalars['Int']>
}

export type PurchaseProductExtractInput = {
  purchaseId: Scalars['ID']
  /**
   * Defines how many purchases will be extracted.
   * Generates the entities for each extracted purchase.
   * Use only with targets `PARTICIPANT` or `TASK`
   * Reservations and invoices must be created before extracting
   */
  quantity?: InputMaybe<Scalars['Int']>
  /**
   * Defies the room reservations in which the new participants can be extracted
   * In case of this is defined, the quantity and targetIds must be undefined and target must be `PARTICIPANT`
   */
  rooms?: InputMaybe<Array<ExtractTargetRoom>>
  target: TargetType
  /** Defines specific targetIds to link with. Extract purchase into these entities */
  targetIds?: InputMaybe<Array<Scalars['ID']>>
}

export type PurchaseProductMassDeleteInput = {
  purchaseIds: Array<Scalars['ID']>
}

export type PurchaseProductSetItemPriceInput = {
  /** If price is null, reset the value back to the calculated value */
  price?: InputMaybe<Scalars['Float']>
  /** If price is defined, define the meaning of the given new price */
  priceType?: InputMaybe<PriceType>
  purchaseProductItemId: Scalars['ID']
}

export type PurchaseProductUpdateInput = {
  attributes?: InputMaybe<PurchaseProductAttributesInput>
  link?: InputMaybe<PurchaseProductUpdateLinkInput>
  /** Set order of purchase */
  orderId?: InputMaybe<Scalars['ID']>
  purchaseId: Scalars['ID']
}

export type PurchaseProductUpdateLinkInput = {
  participantId?: InputMaybe<Scalars['ID']>
  reservationId?: InputMaybe<Scalars['ID']>
  /** Specify Participant Service if adding Purchase into specific Participant */
  serviceId?: InputMaybe<Scalars['ID']>
  taskId?: InputMaybe<Scalars['ID']>
}

export enum PurchaseTarget {
  Reservation = 'RESERVATION',
  Sales = 'SALES',
  Task = 'TASK',
}

export enum QuantityUnit {
  /**
   * Product quantity is determined by the amount of days the purchase overlaps.
   * E.g. recurring lunch over one week can be priced with day-based price: one lunch occurs every day
   * Purchase from 1.1. - 3.1. is 3 days without further rules
   */
  Day = 'DAY',
  /**
   * Product quantity is determined in the hours of the purchase.
   * E.g. calendar reservation can be priced with HOUR-based price
   */
  Hour = 'HOUR',
  /**
   * Product quantity is determined by the amount of nights the purchase contains.
   * E.g. accommodation can be priced per night. The purchase from 1.1. - 3.1. is just 2 nights
   */
  Night = 'NIGHT',
  /** Product quantity doesn't depend on the duration of the purchase, instead it is constant PIECE */
  Piece = 'PIECE',
}

export enum RefundAction {
  /**
   * Advance: negative advance from all sent advances, delete Order Products, cancel Order
   * Invoice: full negative order Invoice, delete the Sales and Order Products
   */
  Cancel = 'CANCEL',
  /**
   * Advance: new update advance to match current order
   * Invoice: partial refund aka correction Invoice
   */
  CorrectInvoice = 'CORRECT_INVOICE',
  /**
   * Advance: full negative advance from original advance
   * Invoice: full re-invoice creates two Invoices, first full negative refund and then full new Invoice
   */
  ReInvoice = 'RE_INVOICE',
}

export type ReservationSearchInput = {
  date: Scalars['LocalDate']
  /** Filter by registry RoomType ids. Null returns all, empty list nothing */
  roomTypeIds?: InputMaybe<Array<Scalars['ID']>>
}

export type ReservePublicRoomInput = {
  /** Start date for the room */
  checkIn: CheckInInput
  /** End date for the room */
  checkOut: CheckOutInput
  /** Optionally create the participants directly into this new room. Value must be greater than zero if passed and max the beds + extra beds in this room */
  participants?: InputMaybe<Array<PublicRoomParticipantAmountsInput>>
  /** PublicRoom ID to reserve */
  publicRoomId: Scalars['ID']
  /** If sales ID is not passed, creates new sales with suitable token, and creates reservations into this Sales */
  salesId?: InputMaybe<Scalars['ID']>
}

export type ResourceAvailabilityInput = {
  /** Default start plus one day */
  end?: InputMaybe<Scalars['LocalDateTime']>
  /** Slot size in minutes. Default is 15 minutes */
  slotSize?: InputMaybe<Scalars['Int']>
  start: Scalars['LocalDateTime']
}

export type ResourceReservationAcceptInput = {
  reservationId: Scalars['ID']
}

export type ResourceReservationCreateGroupInput = {
  /** Name of the group */
  name?: InputMaybe<Scalars['String']>
  reservationId: Scalars['ID']
}

export type ResourceReservationCreateInput = {
  /** Only salesperson can allow reservations for not available resource in certain cases */
  allowOverbooking?: InputMaybe<Scalars['Boolean']>
  color?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  /** exclusive */
  end: Scalars['LocalDateTime']
  groupId?: InputMaybe<Scalars['ID']>
  /** The content of internalNote is never shown to the end customer */
  internalNote?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  resourceId?: InputMaybe<Scalars['ID']>
  /** Attach reservation to sales if id is given else new sales is created for reservation */
  salesId?: InputMaybe<Scalars['ID']>
  /** inclusive */
  start: Scalars['LocalDateTime']
}

export enum ResourceReservationCustomerVisibility {
  /**
   * Hides the reservation totally
   * Default for ResourceType.EQUIPMENT
   */
  Hidden = 'HIDDEN',
  /**
   * Hides the resource name
   * Default for ResourceType.HUMAN_RESOURCE
   */
  ResourceNameHidden = 'RESOURCE_NAME_HIDDEN',
  /**
   * Totally visible
   * Default for ResourceType.PROPERTY
   */
  Visible = 'VISIBLE',
}

export type ResourceReservationDeleteGroupInput = {
  deleteReservations?: InputMaybe<Scalars['Boolean']>
  reservationGroupId: Scalars['ID']
}

export type ResourceReservationDeleteInput = {
  reservationId: Scalars['ID']
}

export type ResourceReservationDimensionsSetLabelsInput = {
  reservationId: Scalars['ID']
  update: Array<DimensionReferenceLabelInput>
}

export type ResourceReservationMoveInput = {
  /** Only salesperson can allow reservations for not available resource in certain cases */
  allowOverbooking?: InputMaybe<Scalars['Boolean']>
  end: Scalars['LocalDateTime']
  reservationId: Scalars['ID']
  /** Patch input to change the resource if specified */
  resourceId?: InputMaybe<Scalars['ID']>
  start: Scalars['LocalDateTime']
}

export type ResourceReservationRenameGroupInput = {
  name?: InputMaybe<Scalars['String']>
  reservationGroupId: Scalars['ID']
}

export type ResourceReservationSetColorInput = {
  color?: InputMaybe<Scalars['String']>
  reservationId: Scalars['ID']
}

export type ResourceReservationSetDescriptionInput = {
  description?: InputMaybe<Scalars['String']>
  reservationId: Scalars['ID']
}

export type ResourceReservationSetDisplayMessageInput = {
  displayMessage?: InputMaybe<Scalars['String']>
  reservationId: Scalars['ID']
}

export type ResourceReservationSetGroupInput = {
  /** Group where to move the reservation */
  reservationGroupId?: InputMaybe<Scalars['ID']>
  /** Reservation to move */
  reservationId: Scalars['ID']
}

export type ResourceReservationSetInternalNoteInput = {
  internalNote?: InputMaybe<Scalars['String']>
  reservationId: Scalars['ID']
}

export type ResourceReservationSetResourceInput = {
  /** Only salesperson can allow reservations for not available resource in certain cases */
  allowOverbooking?: InputMaybe<Scalars['Boolean']>
  quantity: Scalars['Int']
  reservationId: Scalars['ID']
  resourceId: Scalars['ID']
}

export type ResourceReservationSetSalesInput = {
  reservationId: Scalars['ID']
  salesId: Scalars['ID']
}

export type ResourceReservationSetTimesInput = {
  /** Only salesperson can allow reservations for not available resource in certain cases */
  allowOverbooking?: InputMaybe<Scalars['Boolean']>
  end: Scalars['LocalDateTime']
  reservationId: Scalars['ID']
  start: Scalars['LocalDateTime']
}

export type ResourceReservationSetVisibilityInput = {
  customerTimeVisibility?: InputMaybe<Visibility>
  customerVisibility?: InputMaybe<ResourceReservationCustomerVisibility>
  reservationId: Scalars['ID']
}

export enum ResourceReservationState {
  /** Cancelled reservation does not reserve resource anymore */
  Cancelled = 'CANCELLED',
  /** Closed reservation is in read-only state and cannot be updated */
  Closed = 'CLOSED',
  /**
   * Confirmed reservation is confirmed by customer
   * Cancellation is possible, but it requires good reason from customer side
   */
  Confirmed = 'CONFIRMED',
  /**
   * Draft reservation is created from webstore to collect the products before purchase
   * Unused draft reservations will be deleted automatically
   */
  Draft = 'DRAFT',
  /**
   * Open reservation is made by real intention, but customer has not yet confirmed the reservation
   * The reservation can more likely be cancelled than confirmed reservation
   */
  Open = 'OPEN',
  /**
   * Temporal reservation is not reserving the capacity.
   * It is used for temporal state for example to support persistent copy process of resources
   */
  Temporal = 'TEMPORAL',
}

export type ResourceReservationUpdateAllInput = {
  offset?: InputMaybe<MinuteOffsetInput>
  /** Reservations to update */
  reservationIds?: InputMaybe<Array<Scalars['ID']>>
  /** Update all reservations from the Sales. SalesId is ignored if reservationIds are specified */
  salesId?: InputMaybe<Scalars['ID']>
}

export type ResourceReservationsInput = {
  end: Scalars['LocalDateTime']
  resourceIds: Array<Scalars['ID']>
  start: Scalars['LocalDateTime']
}

export enum ResourceType {
  Equipment = 'EQUIPMENT',
  HumanResource = 'HUMAN_RESOURCE',
  Property = 'PROPERTY',
}

/** Patch update input */
export type ResourceUpdateInput = {
  id: Scalars['ID']
  internalInfo?: InputMaybe<Scalars['String']>
}

export type ResourcesAvailabilityInput = {
  /** Default start plus one day */
  end?: InputMaybe<Scalars['LocalDateTime']>
  resourceIds: Array<Scalars['ID']>
  /** Slot size in minutes. Default is 15 minutes */
  slotSize?: InputMaybe<Scalars['Int']>
  start: Scalars['LocalDateTime']
}

export enum RoomCleaningChangeSourceType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum RoomCleaningStatus {
  Clean = 'CLEAN',
  Dirty = 'DIRTY',
}

export enum RoomMarketingPriceType {
  None = 'NONE',
  Product = 'PRODUCT',
  Text = 'TEXT',
}

export enum RoomParticipantMatchLabel {
  /** Room belongs to the service's accommodation group */
  AccommodationGroup = 'ACCOMMODATION_GROUP',
  /** Room belongs the target of the service. Primary default visibility */
  AccommodationTarget = 'ACCOMMODATION_TARGET',
  /** Room belongs to any accommodation group in the context of the participant */
  Context = 'CONTEXT',
  /** Room belongs to any target visible to the context of the participant */
  SharedTarget = 'SHARED_TARGET',
  /** Room belongs to target that is used by any service in the Sales */
  UsedTarget = 'USED_TARGET',
}

export enum RoomReservationCheckAction {
  CheckIn = 'CHECK_IN',
  CheckOut = 'CHECK_OUT',
}

export enum RoomReservationStatus {
  Occupied = 'OCCUPIED',
  Reserved = 'RESERVED',
  Vacant = 'VACANT',
}

export enum RoomReservationType {
  Independent = 'INDEPENDENT',
  RoomTypeRoom = 'ROOM_TYPE_ROOM',
}

export type RoomSetStatusInput = {
  cleaningStatus: RoomCleaningStatus
  roomIds: Array<Scalars['String']>
}

export enum RoomSort {
  Guest = 'GUEST',
}

export enum RoomStatusCode {
  /** Due Out - Room reservation checkout is exceeded but the room is still occupied */
  Do = 'DO',
  /** Occupied - Room is unavailable */
  O = 'O',
  /** Occupied Cleaned - Room is unavailable and clean */
  Oc = 'OC',
  /** Occupied Dirty - Room is unavailable and dirty */
  Od = 'OD',
  /** Reserved Clean - Room is reserved and clean, but the participants did not check in */
  Rc = 'RC',
  /** Reserved Dirty - Room is reserved and dirty, but the participants did not check in */
  Rd = 'RD',
  /** Vacant Cleaned - Room is available and clean */
  Vc = 'VC',
  /** Vacant Dirty - Room is available and dirty */
  Vd = 'VD',
}

/** Patch update input */
export type RoomTypeUpdateInput = {
  /** RoomType ID */
  id: Scalars['ID']
  internalInfo?: InputMaybe<Scalars['String']>
}

/** Patch update input */
export type RoomUpdateInput = {
  /** Room ID */
  id: Scalars['ID']
  internalInfo?: InputMaybe<Scalars['String']>
}

export type RoomsInput = {
  page: PageInput
  query: RoomsQueryInput
  sort?: InputMaybe<RoomSort>
}

export type RoomsQueryInput = {
  /** Room categories filter */
  categoryIds?: InputMaybe<Array<Scalars['ID']>>
  /** Room dates should overlap time until end date */
  end: Scalars['LocalDate']
  /** Number of guests */
  guests?: InputMaybe<Scalars['Int']>
  /** Room dates should overlap time from start date */
  start: Scalars['LocalDate']
}

export enum SalesAccommodationFilter {
  All = 'ALL',
  Default = 'DEFAULT',
}

export type SalesAddWebshopSettingsInput = {
  salesId: Scalars['ID']
}

/** Patch update input */
export type SalesAttributesInput = {
  assignee?: InputMaybe<Scalars['ID']>
  estimatedDates?: InputMaybe<LocalDateRangeInput>
  language?: InputMaybe<Scalars['Language']>
  notes?: InputMaybe<Scalars['String']>
  participants?: InputMaybe<Scalars['Int']>
  paymentAgreementId?: InputMaybe<Scalars['ID']>
  probabilityEstimate?: InputMaybe<Scalars['Int']>
  /** Only for updating events. The update propagates to all its enrollments */
  probabilitySource?: InputMaybe<ProbabilitySource>
}

export type SalesCancelInput = {
  salesId: Scalars['ID']
  specifier?: InputMaybe<Scalars['String']>
}

export enum SalesCopyComponent {
  /** If selected, copy groups and targets as original sales */
  AccommodationGroupsAndTargets = 'ACCOMMODATION_GROUPS_AND_TARGETS',
  Customer = 'CUSTOMER',
  Dimensions = 'DIMENSIONS',
  /** If selected, copy orders as original sales */
  Orders = 'ORDERS',
  /**
   * Copy all participants, if there is reservation or productItem associated with one participant and those are also selected
   * Then it will automatically link the participant to the reservation or productItem
   */
  Participants = 'PARTICIPANTS',
  /** Copy the purchase products associated with the sales */
  PurchaseProducts = 'PURCHASE_PRODUCTS',
  /** Copy calendar reservations */
  Reservations = 'RESERVATIONS',
  /** Copy room reservation groups if any */
  ReservationsGroups = 'RESERVATIONS_GROUPS',
  /** Tasks related to reservations */
  ReservationTasks = 'RESERVATION_TASKS',
  /** Copy room reservations */
  RoomReservations = 'ROOM_RESERVATIONS',
  /** Copy room types reservations */
  RoomTypeReservations = 'ROOM_TYPE_RESERVATIONS',
  SalesNotes = 'SALES_NOTES',
  /** Copy the products associated with the sales */
  SalesProduct = 'SALES_PRODUCT',
  SalesSettings = 'SALES_SETTINGS',
  SalesSettingsParticipantGroup = 'SALES_SETTINGS_PARTICIPANT_GROUP',
  /** Tasks related to sales */
  SalesTasks = 'SALES_TASKS',
}

export type SalesCopyInput = {
  components: Array<SalesCopyComponent>
  newStartDate: Scalars['LocalDate']
  salesId: Scalars['ID']
}

export type SalesCreateEnrollmentInput = {
  eventId: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  source?: InputMaybe<SalesSource>
}

export type SalesCreateEventInput = {
  attributes?: InputMaybe<SalesAttributesInput>
  name?: InputMaybe<Scalars['String']>
  sellerId?: InputMaybe<Scalars['String']>
}

export type SalesCreateInput = {
  attributes?: InputMaybe<SalesAttributesInput>
  facetId?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sellerId?: InputMaybe<Scalars['String']>
}

export type SalesDeleteInput = {
  salesId: Scalars['ID']
}

export type SalesDimensionsSetLabels = {
  salesId: Scalars['ID']
  update: Array<DimensionReferenceLabelInput>
}

export type SalesDimensionsSetShortcut = {
  salesId: Scalars['ID']
  shortcutId: Scalars['ID']
}

export type SalesEnrollmentsInput = {
  /** Enrollment state filter, default to all concrete states */
  enrollmentStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  page?: InputMaybe<PageInput>
}

export type SalesEventSettingsInput = {
  enrollEnd?: InputMaybe<Scalars['LocalDate']>
  enrollStart?: InputMaybe<Scalars['LocalDate']>
  id: Scalars['ID']
  requireConfirm?: InputMaybe<Scalars['Boolean']>
  visibility: EventVisibility
}

export type SalesFilterInput = {
  salesId: Scalars['ID']
}

export type SalesForDateInput = {
  date: Scalars['LocalDate']
}

export enum SalesForDateType {
  Arriving = 'ARRIVING',
  DayVisitor = 'DAY_VISITOR',
  Departing = 'DEPARTING',
  Present = 'PRESENT',
}

export type SalesGroupsInput = {
  /** Groups label and actual group values */
  groups: NamedValueInput
  /** Sales id */
  id: Scalars['ID']
}

export enum SalesLifecycleReason {
  AutomaticallyCancelledReservation = 'AUTOMATICALLY_CANCELLED_RESERVATION',
  AutomaticallyCancelledWebStore = 'AUTOMATICALLY_CANCELLED_WEB_STORE',
  CopyProcess = 'COPY_PROCESS',
  Refunded = 'REFUNDED',
  RefundProcess = 'REFUND_PROCESS',
  ReservationProcess = 'RESERVATION_PROCESS',
  SaleCancelled = 'SALE_CANCELLED',
  WebStoreProcess = 'WEB_STORE_PROCESS',
}

export type SalesParticipantAddRoomInput = {
  /** Default is the room reservation check in */
  fixedCheckIn?: InputMaybe<CheckInInput>
  /** Default is the room reservation check in */
  fixedCheckOut?: InputMaybe<CheckOutInput>
  id: Scalars['ID']
  info?: InputMaybe<Scalars['String']>
  roomReservationId: Scalars['ID']
  /** Creates new service if not specified */
  serviceId?: InputMaybe<Scalars['ID']>
}

/**  Inputs */
export type SalesParticipantCreateInput = {
  accommodationRequest?: InputMaybe<Scalars['String']>
  additionalInfo?: InputMaybe<Scalars['String']>
  age?: InputMaybe<Scalars['Int']>
  ageCategoryKey?: InputMaybe<Scalars['ID']>
  allergies?: InputMaybe<Array<Scalars['String']>>
  allergyDescription?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<BirthdayInput>
  customFields?: InputMaybe<Array<ParticipantCustomFieldInput>>
  diets?: InputMaybe<Array<Scalars['String']>>
  drugAllergyDescription?: InputMaybe<Scalars['String']>
  education?: InputMaybe<EducationLevel>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Gender>
  group?: InputMaybe<Scalars['String']>
  isDayVisitor?: InputMaybe<Scalars['Boolean']>
  language?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  lifeStage?: InputMaybe<ParticipantLifeStage>
  nationality?: InputMaybe<Scalars['String']>
  postalAddress?: InputMaybe<PostalAddressInput>
  roomReservationId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export type SalesParticipantDeleteInput = {
  id: Scalars['ID']
}

export type SalesParticipantDeleteRoomInput = {
  participantRoomReservationId: Scalars['ID']
}

export type SalesParticipantMoveToSalesInput = {
  id: Scalars['ID']
  targetSalesId: Scalars['ID']
}

export type SalesParticipantSetAccommodationInput = {
  id: Scalars['ID']
  serviceId?: InputMaybe<Scalars['ID']>
  targetId: Scalars['ID']
}

export type SalesParticipantSetRoomFriendsInput = {
  id: Scalars['ID']
  roomFriends: Array<Scalars['String']>
}

export type SalesParticipantSetVisitStatusInput = {
  /** Specify only when isDayVisitor = true */
  dayVisitorDates?: InputMaybe<LocalDateRangeInput>
  id: Scalars['ID']
  isDayVisitor: Scalars['Boolean']
}

/** Patch update object. If field is not provided, previous value is kept */
export type SalesParticipantUpdateInput = {
  accommodationRequest?: InputMaybe<Scalars['String']>
  additionalInfo?: InputMaybe<Scalars['String']>
  age?: InputMaybe<Scalars['Int']>
  ageCategoryKey?: InputMaybe<Scalars['ID']>
  allergies?: InputMaybe<Array<Scalars['String']>>
  allergyDescription?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<BirthdayInput>
  customFields?: InputMaybe<Array<ParticipantCustomFieldInput>>
  diets?: InputMaybe<Array<Scalars['String']>>
  drugAllergyDescription?: InputMaybe<Scalars['String']>
  education?: InputMaybe<EducationLevel>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Gender>
  group?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  language?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  lifeStage?: InputMaybe<ParticipantLifeStage>
  nationality?: InputMaybe<Scalars['String']>
  postalAddress?: InputMaybe<PostalAddressInput>
}

export type SalesParticipantUpdateRoomInput = {
  /** Default is the room reservation check in */
  fixedCheckIn?: InputMaybe<CheckInInput>
  /** Default is the room reservation check in */
  fixedCheckOut?: InputMaybe<CheckOutInput>
  info?: InputMaybe<Scalars['String']>
  participantRoomReservationId: Scalars['ID']
}

export type SalesProductAddItemsInput = {
  catalogProductId: Scalars['ID']
  id: Scalars['ID']
}

export type SalesProductCopyToCatalogInput = {
  copyTo: CopyToCatalogInput
  id: Scalars['ID']
}

export type SalesProductCopyToSalesInput = {
  id: Scalars['ID']
  salesId: Scalars['ID']
}

export type SalesProductCreateInput = {
  catalogProductId: Scalars['ID']
  salesId: Scalars['ID']
}

export enum SalesProductOrigin {
  /** SalesProduct is owned by context Sales */
  Own = 'OWN',
  /** SalesProduct is shared from Event into context Enrollment */
  Shared = 'SHARED',
}

/** Patch update input */
export type SalesProductSettingsInput = {
  /** Connect SalesProduct to consume the specific AccommodationGroup */
  accommodationGroupId?: InputMaybe<Scalars['ID']>
  /** Description for Webshop Single Sales */
  description?: InputMaybe<Scalars['String']>
  /** Fixed max purchase capacity */
  maxPurchases?: InputMaybe<Scalars['Int']>
  /** Published in Store */
  published?: InputMaybe<Scalars['Boolean']>
  /** Default purchase dates */
  purchaseDates?: InputMaybe<LocalDateRangeInput>
  /** Define purchase min and max duration */
  purchaseDurationDays?: InputMaybe<IntRangeConstraintInput>
}

export enum SalesProductState {
  /** All purchases are invoiced */
  Closed = 'CLOSED',
  /** No purchase is invoiced */
  Open = 'OPEN',
  /** Part of the purchases are invoiced */
  PartiallyClosed = 'PARTIALLY_CLOSED',
}

/** Patch update input */
export type SalesProductUpdateInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>
  group?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  settings?: InputMaybe<SalesProductSettingsInput>
  targets?: InputMaybe<Array<ProductTarget>>
}

export type SalesProductsRefreshVatInput = {
  salesId: Scalars['ID']
}

export type SalesProductsUpdateGroupInput = {
  /** Select group by name for update */
  currentGroup: Scalars['String']
  /** Set new name for selected group */
  newGroup: Scalars['String']
  salesId: Scalars['ID']
}

export type SalesPurchaseInput = {
  /** Specify included purchases with this filter */
  filter: PurchaseFilter
  /** Context Sales id */
  salesId: Scalars['ID']
}

export type SalesRemoveWebshopSettingsInput = {
  salesId: Scalars['ID']
  tokenId: Scalars['String']
}

export type SalesSetCustomerAddressInput = {
  addressId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export type SalesSetCustomerContactInput = {
  customerContactId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export type SalesSetCustomerInput = {
  addressId?: InputMaybe<Scalars['ID']>
  customerContactId?: InputMaybe<Scalars['ID']>
  customerId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export type SalesSetStateInput = {
  salesId: Scalars['ID']
  /** Specify State change reason manually */
  specifier?: InputMaybe<Scalars['String']>
  stateKey: Scalars['ID']
}

export type SalesSettingsInput = {
  invoiceId?: InputMaybe<Scalars['ID']>
  orderId?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
  scopes: Array<WebshopPermissionScope>
  tokenDescription?: InputMaybe<Scalars['String']>
  /** Public Token Id */
  tokenId: Scalars['String']
  validFrom?: InputMaybe<Scalars['LocalDate']>
  validTo?: InputMaybe<Scalars['LocalDate']>
}

export enum SalesSource {
  Admin = 'ADMIN',
  Webshop = 'WEBSHOP',
}

export enum SalesSystemState {
  Cancelled = 'CANCELLED',
  CancelProcess = 'CANCEL_PROCESS',
  Closed = 'CLOSED',
  /**  or ACTIVE */
  Deleted = 'DELETED',
  /**  or COMPLETED */
  Draft = 'DRAFT',
  Open = 'OPEN',
}

export type SalesTaskInput = {
  /** Include specific task types only. Default to all types */
  types?: InputMaybe<Array<TaskType>>
}

export type SalesTokenCompletedInput = {
  salesId: Scalars['ID']
  tokenId: Scalars['String']
}

export enum SalesType {
  Enrollment = 'ENROLLMENT',
  Event = 'EVENT',
  Sales = 'SALES',
}

export type SalesUpdateAssigneeInput = {
  assignee?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export type SalesUpdateAttributesInput = {
  attributes: SalesAttributesInput
  salesId: Scalars['ID']
}

export type SalesUpdateCommissionInput = {
  commissionRate: Scalars['Int']
  salesId: Scalars['ID']
}

export type SalesUpdateFacetInput = {
  /** Id of selected facet id; null resets to default */
  facetId?: InputMaybe<Scalars['String']>
  salesId: Scalars['ID']
}

export type SalesUpdateNameInput = {
  name?: InputMaybe<Scalars['String']>
  salesId: Scalars['ID']
}

export type SalesUpdateSellerInput = {
  salesId: Scalars['ID']
  sellerId: Scalars['ID']
}

/** Patch update input. If field is not given, field value is not updated */
export type SalesVstSetAttributesInput = {
  otherLessonFee?: InputMaybe<Scalars['Float']>
  otherLessonHours?: InputMaybe<Scalars['Float']>
  salesId: Scalars['ID']
}

/** Set VST document signer for the Sales. Participant id must exists in the context of the Sales */
export type SalesVstSetSignerInput = {
  /** SalesParticipant id who is supposed to sign the VST document */
  documentSigner?: InputMaybe<Scalars['ID']>
  salesId: Scalars['ID']
}

export enum SalesVstState {
  /** All participants are ready EXCLUDED or COMPLETE */
  Complete = 'COMPLETE',
  /** Some INCOMPLETE participant exists */
  Incomplete = 'INCOMPLETE',
}

export type SalesWebshopContactInput = {
  contactIds: Array<Scalars['String']>
  salesId: Scalars['ID']
}

export type SalesWebshopSettingsInput = {
  customerInformation?: InputMaybe<WebshopCustomerInformationInput>
  eventSettings?: InputMaybe<SalesEventSettingsInput>
  salesSettings?: InputMaybe<SalesSettingsInput>
  /** Webshop theme settings */
  theme?: InputMaybe<WebshopThemeInput>
}

export type ScheduledMealCreateInput = {
  duration?: InputMaybe<Scalars['Int']>
  location: LocationInput
  mealId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
  quantities: Array<ScheduledMealSetQuantity>
  salesId: Scalars['ID']
  start: Scalars['LocalDateTime']
}

export type ScheduledMealSetDuration = {
  duration?: InputMaybe<Scalars['Int']>
}

export type ScheduledMealSetNotes = {
  notes?: InputMaybe<Scalars['String']>
}

export type ScheduledMealSetQuantity = {
  ageCategoryKey: Scalars['ID']
  quantity: Scalars['Int']
}

export type ScheduledMealSetRestaurant = {
  restaurantKey: Scalars['ID']
}

export type ScheduledMealSetStartTime = {
  startTime: Scalars['LocalTime']
}

/**  Type */
export enum ScheduledMealStatus {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  ManuallyRemoved = 'MANUALLY_REMOVED',
  ManuallyUpdated = 'MANUALLY_UPDATED',
  QuantityUpdated = 'QUANTITY_UPDATED',
}

/** Update scheduled meals with patch update */
export type ScheduledMealUpdateInput = {
  /** Meal duration in minutes. Null on undefined keeps the value as it was */
  duration?: InputMaybe<Scalars['Int']>
  /** Meal restaurant. Null on undefined keeps the value as it was */
  restaurantId?: InputMaybe<Scalars['ID']>
  scheduledMealIds: Array<Scalars['ID']>
  /** Meal start time. Null on undefined keeps the value as it was */
  startTime?: InputMaybe<Scalars['LocalTime']>
}

export type ScheduledMealsInput = {
  /** Default current date */
  from?: InputMaybe<Scalars['LocalDate']>
  /** Include Sales states filter. Default to [OPEN, CONFIRMED, CLOSED] */
  salesStates?: InputMaybe<Array<Scalars['ID']>>
  /** Default current date */
  to?: InputMaybe<Scalars['LocalDate']>
}

export type ServiceFilterInput = {
  /** Included service types */
  types: Array<ServiceType>
}

export type ServiceSetDatesInput = {
  checkIn: CheckInInput
  checkOut: CheckOutInput
  id: Scalars['ID']
}

export type ServiceSetTargetInput = {
  accommodationTargetId: Scalars['ID']
  id: Scalars['ID']
}

export enum ServiceType {
  ParticipantBed = 'PARTICIPANT_BED',
  Purchase = 'PURCHASE',
}

export type SimulateProductSalesInput = {
  from?: InputMaybe<Scalars['LocalDateTime']>
  quantity?: InputMaybe<Scalars['Int']>
  to?: InputMaybe<Scalars['LocalDateTime']>
}

export enum SortOrder {
  /** Ascending sort order */
  Asc = 'ASC',
  /** Descending sort order */
  Desc = 'DESC',
}

export enum SuitableFilter {
  All = 'ALL',
  Default = 'DEFAULT',
}

export type SuitableParticipantsInput = {
  filter?: InputMaybe<RoomParticipantMatchLabel>
}

export type SuitableRoomsInput = {
  filter?: InputMaybe<SuitableFilter>
  salesId: Scalars['ID']
  serviceId?: InputMaybe<Scalars['ID']>
}

export type SuitableTargetInput = {
  filter?: InputMaybe<SuitableFilter>
  salesId: Scalars['ID']
  serviceId?: InputMaybe<Scalars['ID']>
}

export type SystemUpdateInput = {
  features: Array<FeatureFlagInput>
}

export type TargetFilterInput = {
  filter?: InputMaybe<SalesAccommodationFilter>
  salesId: Scalars['ID']
}

export enum TargetMatchLabel {
  /** Target in preferred accommodation group */
  AccommodationGroup = 'ACCOMMODATION_GROUP',
  /** Possible target */
  Context = 'CONTEXT',
  /** Target is public or shared to this sales */
  SharedTarget = 'SHARED_TARGET',
}

export enum TargetType {
  Invoice = 'INVOICE',
  Participant = 'PARTICIPANT',
  /** Sales-level purchase */
  PurchaseOrder = 'PURCHASE_ORDER',
  Reservation = 'RESERVATION',
  Task = 'TASK',
}

export enum TaskAction {
  Close = 'CLOSE',
  Open = 'OPEN',
}

export enum TaskContext {
  Reservation = 'RESERVATION',
  Sales = 'SALES',
}

export type TaskSetAssigneeInput = {
  assignee?: InputMaybe<AssigneeInput>
  id: Scalars['ID']
}

export enum TaskState {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export enum TaskType {
  Other = 'OTHER',
  SalesReadyForInvoicing = 'SALES_READY_FOR_INVOICING',
}

export type TasksInput = {
  assignees?: InputMaybe<Array<AssigneeInput>>
  context?: InputMaybe<TaskContext>
  date: Scalars['LocalDate']
  states?: InputMaybe<Array<TaskState>>
  type?: InputMaybe<TaskType>
}

export type UnitPriceInput = {
  /** Unit price amount in currency, example 10.25 means 10.25 € */
  price: Scalars['Float']
  /**
   * Define if price is VAT included or VAT excluded unitPrice.
   * Example: add product with VAT rate 24 %
   * a) `price = 124` and `type = VAT_INCLUDED` means `unitPrice` VAT included is 124 € and `unitPrice` VAT excluded is 100 €
   * b) `price = 100` and `type = VAT_EXCLUDED` means `unitPrice` VAT included is 124 € and `unitPrice` VAT excluded is 100 €
   */
  type: PriceType
}

export type UnitPricePatchInput = {
  /** Unit price amount in currency, example 10.25 means 10.25 € */
  price?: InputMaybe<Scalars['Float']>
  /**
   * Define if price is VAT included or VAT excluded unitPrice.
   * Example: add product with VAT rate 24 %
   * a) `price = 124` and `type = VAT_INCLUDED` means `unitPrice` VAT included is 124 € and `unitPrice` VAT excluded is 100 €
   * b) `price = 100` and `type = VAT_EXCLUDED` means `unitPrice` VAT included is 124 € and `unitPrice` VAT excluded is 100 €
   */
  type?: InputMaybe<PriceType>
}

export type UpdateTaskInput = {
  description?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['LocalDate']>
  dueTime?: InputMaybe<Scalars['LocalTime']>
  name?: InputMaybe<Scalars['String']>
}

export type UpsertAddressInput = {
  address: CustomerAddressInput
  /** Create new address if id is not provided */
  id?: InputMaybe<Scalars['ID']>
}

export type UpsertContactInput = {
  contact: CustomerContactInput
  /** Create new contact if id is not provided, update existing one if id is provided */
  id?: InputMaybe<Scalars['ID']>
}

export enum Visibility {
  Hidden = 'HIDDEN',
  Shown = 'SHOWN',
}

export enum VstField {
  AgeCategory = 'AGE_CATEGORY',
  Education = 'EDUCATION',
  Gender = 'GENDER',
  Language = 'LANGUAGE',
  LifeStage = 'LIFE_STAGE',
  VstDates = 'VST_DATES',
  YearOfBirth = 'YEAR_OF_BIRTH',
}

export enum VstParticipantsVisibility {
  /** Group participants based on age category and VST times */
  Grouped = 'GROUPED',
  /** Show participants individually with names */
  Individual = 'INDIVIDUAL',
}

export enum WebshopAvailabilityStatus {
  /** Resource is available and valid to be reserved */
  Available = 'AVAILABLE',
  /** Check-in must be before check-out */
  InvalidInputDates = 'INVALID_INPUT_DATES',
  /** Resource is not valid within given inputs. Sales cannot contain reservations from multiple time periods */
  InvalidTimePeriodInSales = 'INVALID_TIME_PERIOD_IN_SALES',
  /** Resource free capacity does not exists, the reservation is not possible with given inputs */
  NotAvailable = 'NOT_AVAILABLE',
}

export type WebshopAvailableScopesInput = {
  salesId: Scalars['ID']
}

export type WebshopCustomerInformationInput = {
  information: Scalars['String']
  salesId: Scalars['ID']
}

export type WebshopFindScopesInput = {
  salesId: Scalars['ID']
  /** Token optional for sales of type Event or Enrollment and mandatory for Single Sales */
  token?: InputMaybe<Scalars['String']>
}

export enum WebshopPaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export enum WebshopPermissionScope {
  CreateParticipant = 'CREATE_PARTICIPANT',
  CreateRooms = 'CREATE_ROOMS',
  Payment = 'PAYMENT',
  QueryCustomer = 'QUERY_CUSTOMER',
  QueryParticipants = 'QUERY_PARTICIPANTS',
  QueryRooms = 'QUERY_ROOMS',
  RemoveParticipant = 'REMOVE_PARTICIPANT',
  RemoveRooms = 'REMOVE_ROOMS',
  UpdateCustomer = 'UPDATE_CUSTOMER',
  UpdateParticipantData = 'UPDATE_PARTICIPANT_DATA',
  UpdateParticipantProducts = 'UPDATE_PARTICIPANT_PRODUCTS',
  UpdateRooms = 'UPDATE_ROOMS',
}

export type WebshopSalesInput = {
  salesId: Scalars['ID']
  /**
   * Published event can be fetched without a token.
   * If a Sales is secured with a token, it must be provided and valid.
   * Error will be thrown in case of token is invalid or missing when required
   */
  token?: InputMaybe<Scalars['String']>
}

export type WebshopThemeInput = {
  id: Scalars['String']
  salesId: Scalars['ID']
}

export type WebshopVerifyScopeInput = {
  salesId: Scalars['ID']
  scope: WebshopPermissionScope
  token: Scalars['String']
}

export type LogErrorMutationVariables = Exact<{
  error: Scalars['String']
}>

export type LogErrorMutation = {
  __typename: 'Mutation'
  logExternal: { __typename: 'LogExternalPayload'; success: boolean }
}

export type OrderSelectorQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrderSelectorQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    orders: Array<{
      __typename: 'Order'
      id: string
      name: string | null
      orderNumber: number
      sortOrder: number
      customer: {
        __typename: 'OrderCustomer'
        details: {
          __typename: 'ParsedCustomerDetails'
          name: string | null
          type: CustomerType | null
        }
      } | null
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: {
        __typename: 'PaymentInfo'
        totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
      }
    }>
  }
}

export type NavExternalViewFragment = {
  __typename: 'NavExternalView'
  isEmbedded: boolean
  name: string
  openInNewTab: boolean
  slug: string
  url: string
}

export type NavInternalViewFragment = {
  __typename: 'NavInternalView'
  openInNewTab: boolean
  type: NavInternalViewType
}

export type WillbaConfigQueryVariables = Exact<{ [key: string]: never }>

export type WillbaConfigQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    config: {
      __typename: 'Config'
      catalogInternalPriceEditing: FeatureStatus
      marginalTax: FeatureStatus
      salesCommission: FeatureStatus
      salesInternalPriceEditing: FeatureStatus
      navbar: {
        __typename: 'NavbarConfig'
        items: Array<{
          __typename: 'NavItem'
          sort: NavSortBy
          type: NavItemType
          items: Array<
            | {
                __typename: 'NavExternalView'
                isEmbedded: boolean
                name: string
                openInNewTab: boolean
                slug: string
                url: string
              }
            | {
                __typename: 'NavInternalView'
                openInNewTab: boolean
                type: NavInternalViewType
              }
          >
        }>
      }
      timezone: { __typename: 'Timezone'; name: string }
    }
  }
}

export type AccommodationIssueFragment = {
  __typename: 'ValidationIssue'
  key: string
  code: number
  level: IssueLevel
}

export type ReservationStatusFragment = {
  __typename: 'ReservationStatus'
  reservedCapacity: {
    __typename: 'RoomsAndBeds'
    rooms: number
    beds: number
    extraBeds: number
  }
  consumptionBreakdown: {
    __typename: 'ReservationConsumption'
    type: AccommodationConsumptionType
    consumption: number
    floatingConsumption: number
  }
}

export type CapacityStatusFragment = {
  __typename: 'TargetCapacityStatus'
  status: {
    __typename: 'ReservationStatus'
    reservedCapacity: {
      __typename: 'RoomsAndBeds'
      rooms: number
      beds: number
      extraBeds: number
    }
    consumptionBreakdown: {
      __typename: 'ReservationConsumption'
      type: AccommodationConsumptionType
      consumption: number
      floatingConsumption: number
    }
  }
  dailyStatus: Array<{
    __typename: 'DailyReservationStatus'
    date: string
    status: {
      __typename: 'ReservationStatus'
      reservedCapacity: {
        __typename: 'RoomsAndBeds'
        rooms: number
        beds: number
        extraBeds: number
      }
      consumptionBreakdown: {
        __typename: 'ReservationConsumption'
        type: AccommodationConsumptionType
        consumption: number
        floatingConsumption: number
      }
    }
  }>
}

export type RoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  type: RoomReservationType
  issues: Array<{
    __typename: 'ValidationIssue'
    key: string
    code: number
    level: IssueLevel
  }>
  request: {
    __typename: 'RoomRequest'
    beds: number
    info: string | null
    extraBeds: number
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      floor: number | null
      id: string
      number: string
      beds: number
      extraBeds: number
      internalInfo: string | null
      roomType: {
        __typename: 'RoomType'
        id: string
        name: string
        accommodationLevel: {
          __typename: 'AccommodationLevel'
          id: string
          name: string
          shortName: string
        }
        category: { __typename: 'RoomTypeCategory'; id: string; name: string }
      }
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
      }
    }
  }
  participantRooms: Array<{
    __typename: 'ParticipantRoom'
    id: string
    participant: { __typename: 'SalesParticipant'; id: string }
  }>
  roomTypeReservation: { __typename: 'RoomTypeReservation'; id: string } | null
  target: { __typename: 'AccommodationTarget'; id: string } | null
}

export type RoomTypeReservationFragment = {
  __typename: 'RoomTypeReservation'
  id: string
  fulfilledByRooms: boolean
  request: {
    __typename: 'RoomTypeRequest'
    info: string | null
    beds: number
    extraBeds: number
    rooms: number
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    roomType: {
      __typename: 'RoomType'
      id: string
      name: string
      beds: number | null
      extraBeds: number | null
      accommodationLevel: {
        __typename: 'AccommodationLevel'
        id: string
        name: string
        shortName: string
      }
      category: { __typename: 'RoomTypeCategory'; id: string; name: string }
    }
  }
  target: { __typename: 'AccommodationTarget'; id: string } | null
}

export type AccommodationSettingsFragment = {
  __typename: 'AccommodationGroup'
  id: string
  settings: {
    __typename: 'AccommodationGroupSettings'
    consumptionType: AccommodationConsumptionType | null
    consumptionDurationType: AccommodationConsumptionDurationType | null
  } | null
  status: {
    __typename: 'AccommodationReservationStatus'
    available: number
    reserved: number
    type: AccommodationConsumptionType
    dates: {
      __typename: 'AccommodationDates'
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
    } | null
    reservedDetailed: {
      __typename: 'RoomsAndBeds'
      rooms: number
      beds: number
      extraBeds: number
    }
    status: Array<{
      __typename: 'DailyAccommodationGroupStatus'
      available: number
      date: string
      reserved: number
      reservedDetailed: {
        __typename: 'RoomsAndBeds'
        rooms: number
        beds: number
        extraBeds: number
      }
    }>
  }
}

export type AccommodationTargetFragment = {
  __typename: 'AccommodationTarget'
  default: boolean
  floating: boolean
  id: string
  name: string
  nonBlockingCapacity: boolean
  private: boolean
  sortOrder: number
  accommodationGroup: {
    __typename: 'AccommodationGroup'
    id: string
    name: string | null
    sortOrder: number
  }
  sharedTo: Array<{
    __typename: 'Sales'
    id: string
    orderNumber: number | null
  }>
  status: {
    __typename: 'TargetCapacityStatus'
    status: {
      __typename: 'ReservationStatus'
      reservedCapacity: {
        __typename: 'RoomsAndBeds'
        rooms: number
        beds: number
        extraBeds: number
      }
      consumptionBreakdown: {
        __typename: 'ReservationConsumption'
        type: AccommodationConsumptionType
        consumption: number
        floatingConsumption: number
      }
    }
    dailyStatus: Array<{
      __typename: 'DailyReservationStatus'
      date: string
      status: {
        __typename: 'ReservationStatus'
        reservedCapacity: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        consumptionBreakdown: {
          __typename: 'ReservationConsumption'
          type: AccommodationConsumptionType
          consumption: number
          floatingConsumption: number
        }
      }
    }>
  }
}

export type AccommodationGroupFragment = {
  __typename: 'AccommodationGroup'
  id: string
  name: string | null
  sortOrder: number
  sales: { __typename: 'Sales'; id: string; type: SalesType }
  targets: Array<{
    __typename: 'AccommodationTarget'
    default: boolean
    floating: boolean
    id: string
    name: string
    nonBlockingCapacity: boolean
    private: boolean
    sortOrder: number
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
    }
    sharedTo: Array<{
      __typename: 'Sales'
      id: string
      orderNumber: number | null
    }>
    status: {
      __typename: 'TargetCapacityStatus'
      status: {
        __typename: 'ReservationStatus'
        reservedCapacity: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        consumptionBreakdown: {
          __typename: 'ReservationConsumption'
          type: AccommodationConsumptionType
          consumption: number
          floatingConsumption: number
        }
      }
      dailyStatus: Array<{
        __typename: 'DailyReservationStatus'
        date: string
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
      }>
    }
  }>
  roomReservations: Array<{
    __typename: 'RoomReservation'
    id: string
    type: RoomReservationType
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    request: {
      __typename: 'RoomRequest'
      beds: number
      info: string | null
      extraBeds: number
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      room: {
        __typename: 'Room'
        floor: number | null
        id: string
        number: string
        beds: number
        extraBeds: number
        internalInfo: string | null
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
        status: {
          __typename: 'RoomStatus'
          cleaningStatus: RoomCleaningStatus | null
        }
      }
    }
    participantRooms: Array<{
      __typename: 'ParticipantRoom'
      id: string
      participant: { __typename: 'SalesParticipant'; id: string }
    }>
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
    } | null
    target: { __typename: 'AccommodationTarget'; id: string } | null
  }>
  roomTypeReservations: Array<{
    __typename: 'RoomTypeReservation'
    id: string
    fulfilledByRooms: boolean
    request: {
      __typename: 'RoomTypeRequest'
      info: string | null
      beds: number
      extraBeds: number
      rooms: number
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      roomType: {
        __typename: 'RoomType'
        id: string
        name: string
        beds: number | null
        extraBeds: number | null
        accommodationLevel: {
          __typename: 'AccommodationLevel'
          id: string
          name: string
          shortName: string
        }
        category: { __typename: 'RoomTypeCategory'; id: string; name: string }
      }
    }
    target: { __typename: 'AccommodationTarget'; id: string } | null
  }>
  settings: {
    __typename: 'AccommodationGroupSettings'
    consumptionType: AccommodationConsumptionType | null
    consumptionDurationType: AccommodationConsumptionDurationType | null
  } | null
  status: {
    __typename: 'AccommodationReservationStatus'
    available: number
    reserved: number
    type: AccommodationConsumptionType
    dates: {
      __typename: 'AccommodationDates'
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
    } | null
    reservedDetailed: {
      __typename: 'RoomsAndBeds'
      rooms: number
      beds: number
      extraBeds: number
    }
    status: Array<{
      __typename: 'DailyAccommodationGroupStatus'
      available: number
      date: string
      reserved: number
      reservedDetailed: {
        __typename: 'RoomsAndBeds'
        rooms: number
        beds: number
        extraBeds: number
      }
    }>
  }
}

export type RoomsAndBedsFragment = {
  __typename: 'RoomsAndBeds'
  beds: number
  extraBeds: number
  rooms: number
}

export type DailyAccommodationStatusFragment = {
  __typename: 'DailyAccommodationStatus'
  dates: Array<string>
  confirmed: {
    __typename: 'RoomsAndBeds'
    beds: number
    extraBeds: number
    rooms: number
  } | null
  tentative: {
    __typename: 'RoomsAndBeds'
    beds: number
    extraBeds: number
    rooms: number
  } | null
  totalCapacity: {
    __typename: 'RoomsAndBeds'
    beds: number
    extraBeds: number
    rooms: number
  } | null
}

export type AccommodationAvailabilityQueryVariables = Exact<{
  input: AccommodationAvailabilityInput
}>

export type AccommodationAvailabilityQuery = {
  __typename: 'Query'
  accommodationAvailability: {
    __typename: 'AccommodationAvailability'
    roomTypes: Array<{
      __typename: 'RoomTypeAvailabilityStatus'
      rooms: Array<{
        __typename: 'RoomAvailabilityStatus'
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          floor: number | null
          id: string
          internalInfo: string | null
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          features: Array<{
            __typename: 'RoomLabel'
            id: string
            name: string
            icon: string | null
            shortName: string
          }>
        }
        status: Array<{
          __typename: 'DailyAccommodationStatus'
          dates: Array<string>
          confirmed: {
            __typename: 'RoomsAndBeds'
            beds: number
            extraBeds: number
            rooms: number
          } | null
          tentative: {
            __typename: 'RoomsAndBeds'
            beds: number
            extraBeds: number
            rooms: number
          } | null
          totalCapacity: {
            __typename: 'RoomsAndBeds'
            beds: number
            extraBeds: number
            rooms: number
          } | null
        }>
      }>
      roomType: {
        __typename: 'RoomType'
        id: string
        name: string
        accommodationLevel: {
          __typename: 'AccommodationLevel'
          id: string
          name: string
        }
        category: { __typename: 'RoomTypeCategory'; id: string; name: string }
      }
      status: Array<{
        __typename: 'DailyAccommodationStatus'
        dates: Array<string>
        confirmed: {
          __typename: 'RoomsAndBeds'
          beds: number
          extraBeds: number
          rooms: number
        } | null
        tentative: {
          __typename: 'RoomsAndBeds'
          beds: number
          extraBeds: number
          rooms: number
        } | null
        totalCapacity: {
          __typename: 'RoomsAndBeds'
          beds: number
          extraBeds: number
          rooms: number
        } | null
      }>
    }>
  }
}

export type ReservationsForDateQueryVariables = Exact<{
  input: ReservationSearchInput
}>

export type ReservationsForDateQuery = {
  __typename: 'Query'
  accommodationRoomTypeReservations: {
    __typename: 'RoomTypeReservationsPayload'
    reservations: Array<{
      __typename: 'RoomTypeReservation'
      id: string
      sortOrder: number
      fulfilledByRooms: boolean
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        sales: {
          __typename: 'Sales'
          id: string
          name: string | null
          orderNumber: number | null
          customer: {
            __typename: 'CustomerReference'
            address: { __typename: 'CustomerAddress'; id: string } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              id: string
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  defaultContactId: string | null
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  contacts: Array<{
                    __typename: 'CustomerOrganizationContact'
                    email: string | null
                    firstName: string
                    id: string
                    lastName: string
                    notes: string | null
                    phone: string | null
                  }>
                  eInvoicingAddress: {
                    __typename: 'EInvoicingAddress'
                    address: string
                    operator: string
                  } | null
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
          } | null
        }
      }
      request: {
        __typename: 'RoomTypeRequest'
        rooms: number
        beds: number
        extraBeds: number
        roomType: {
          __typename: 'RoomType'
          id: string
          beds: number | null
          extraBeds: number | null
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          id: string
          name: string
          icon: string | null
          shortName: string
        }>
      }
    }>
  }
  accommodationRoomReservations: {
    __typename: 'RoomReservationsPayload'
    reservations: Array<{
      __typename: 'RoomReservation'
      id: string
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        sales: {
          __typename: 'Sales'
          id: string
          name: string | null
          orderNumber: number | null
          customer: {
            __typename: 'CustomerReference'
            address: { __typename: 'CustomerAddress'; id: string } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              id: string
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  defaultContactId: string | null
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  contacts: Array<{
                    __typename: 'CustomerOrganizationContact'
                    email: string | null
                    firstName: string
                    id: string
                    lastName: string
                    notes: string | null
                    phone: string | null
                  }>
                  eInvoicingAddress: {
                    __typename: 'EInvoicingAddress'
                    address: string
                    operator: string
                  } | null
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
          } | null
        }
      }
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        room: {
          __typename: 'Room'
          id: string
          beds: number
          extraBeds: number
        }
      }
    }>
  }
}

export type AccommodationTargetAddSalesMutationVariables = Exact<{
  input: AccommodationTargetSalesInput
}>

export type AccommodationTargetAddSalesMutation = {
  __typename: 'Mutation'
  accommodationTargetAddSales: {
    __typename: 'AccommodationTargetPayload'
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
  }
}

export type AccommodationTargetRemoveSalesMutationVariables = Exact<{
  input: AccommodationTargetSalesInput
}>

export type AccommodationTargetRemoveSalesMutation = {
  __typename: 'Mutation'
  accommodationTargetRemoveSales: {
    __typename: 'AccommodationTargetPayload'
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
  }
}

export type AccommodationGroupCreateMutationVariables = Exact<{
  input: AccommodationGroupCreate
}>

export type AccommodationGroupCreateMutation = {
  __typename: 'Mutation'
  accommodationGroupCreate: {
    __typename: 'AccommodationGroupPayload'
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
      sales: { __typename: 'Sales'; id: string; type: SalesType }
      targets: Array<{
        __typename: 'AccommodationTarget'
        default: boolean
        floating: boolean
        id: string
        name: string
        nonBlockingCapacity: boolean
        private: boolean
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
        sharedTo: Array<{
          __typename: 'Sales'
          id: string
          orderNumber: number | null
        }>
        status: {
          __typename: 'TargetCapacityStatus'
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
          dailyStatus: Array<{
            __typename: 'DailyReservationStatus'
            date: string
            status: {
              __typename: 'ReservationStatus'
              reservedCapacity: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              consumptionBreakdown: {
                __typename: 'ReservationConsumption'
                type: AccommodationConsumptionType
                consumption: number
                floatingConsumption: number
              }
            }
          }>
        }
      }>
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        type: RoomReservationType
        issues: Array<{
          __typename: 'ValidationIssue'
          key: string
          code: number
          level: IssueLevel
        }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          info: string | null
          extraBeds: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            floor: number | null
            id: string
            number: string
            beds: number
            extraBeds: number
            internalInfo: string | null
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
              category: {
                __typename: 'RoomTypeCategory'
                id: string
                name: string
              }
            }
            status: {
              __typename: 'RoomStatus'
              cleaningStatus: RoomCleaningStatus | null
            }
          }
        }
        participantRooms: Array<{
          __typename: 'ParticipantRoom'
          id: string
          participant: { __typename: 'SalesParticipant'; id: string }
        }>
        roomTypeReservation: {
          __typename: 'RoomTypeReservation'
          id: string
        } | null
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      roomTypeReservations: Array<{
        __typename: 'RoomTypeReservation'
        id: string
        fulfilledByRooms: boolean
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          beds: number
          extraBeds: number
          rooms: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            beds: number | null
            extraBeds: number | null
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              name: string
              shortName: string
            }
            category: {
              __typename: 'RoomTypeCategory'
              id: string
              name: string
            }
          }
        }
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type AccommodationGroupSetSettingsMutationVariables = Exact<{
  input: AccommodationGroupSetSettingsInput
}>

export type AccommodationGroupSetSettingsMutation = {
  __typename: 'Mutation'
  accommodationGroupSetSettings: {
    __typename: 'AccommodationGroupPayload'
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type AccommodationTargetUpdateMutationVariables = Exact<{
  input: AccommodationTargetUpdateInput
}>

export type AccommodationTargetUpdateMutation = {
  __typename: 'Mutation'
  accommodationTargetUpdate: {
    __typename: 'AccommodationTargetPayload'
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
  }
}

export type GroupStatusDataQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GroupStatusDataQuery = {
  __typename: 'Query'
  accommodationGroup: {
    __typename: 'AccommodationGroup'
    id: string
    targets: Array<{
      __typename: 'AccommodationTarget'
      id: string
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }>
  } | null
}

export type SalesAccommodationAvailabilityQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesAccommodationAvailabilityQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    type: SalesType
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
        sales: { __typename: 'Sales'; id: string; type: SalesType }
        targets: Array<{
          __typename: 'AccommodationTarget'
          default: boolean
          floating: boolean
          id: string
          name: string
          nonBlockingCapacity: boolean
          private: boolean
          sortOrder: number
          accommodationGroup: {
            __typename: 'AccommodationGroup'
            id: string
            name: string | null
            sortOrder: number
          }
          sharedTo: Array<{
            __typename: 'Sales'
            id: string
            orderNumber: number | null
          }>
          status: {
            __typename: 'TargetCapacityStatus'
            status: {
              __typename: 'ReservationStatus'
              reservedCapacity: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              consumptionBreakdown: {
                __typename: 'ReservationConsumption'
                type: AccommodationConsumptionType
                consumption: number
                floatingConsumption: number
              }
            }
            dailyStatus: Array<{
              __typename: 'DailyReservationStatus'
              date: string
              status: {
                __typename: 'ReservationStatus'
                reservedCapacity: {
                  __typename: 'RoomsAndBeds'
                  rooms: number
                  beds: number
                  extraBeds: number
                }
                consumptionBreakdown: {
                  __typename: 'ReservationConsumption'
                  type: AccommodationConsumptionType
                  consumption: number
                  floatingConsumption: number
                }
              }
            }>
          }
        }>
        roomReservations: Array<{
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }>
        roomTypeReservations: Array<{
          __typename: 'RoomTypeReservation'
          id: string
          fulfilledByRooms: boolean
          request: {
            __typename: 'RoomTypeRequest'
            info: string | null
            beds: number
            extraBeds: number
            rooms: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              beds: number | null
              extraBeds: number | null
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
              category: {
                __typename: 'RoomTypeCategory'
                id: string
                name: string
              }
            }
          }
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }>
        settings: {
          __typename: 'AccommodationGroupSettings'
          consumptionType: AccommodationConsumptionType | null
          consumptionDurationType: AccommodationConsumptionDurationType | null
        } | null
        status: {
          __typename: 'AccommodationReservationStatus'
          available: number
          reserved: number
          type: AccommodationConsumptionType
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
          } | null
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          status: Array<{
            __typename: 'DailyAccommodationGroupStatus'
            available: number
            date: string
            reserved: number
            reservedDetailed: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
          }>
        }
      }>
    }
  }
}

export type AccommodationSubscriptionVariables = Exact<{
  filter: AccommodationReservationFilterInput
}>

export type AccommodationSubscription = {
  __typename: 'Subscription'
  accommodationReservation: {
    __typename: 'AccommodationReservationContextEvent'
    salesId: string
    accommodationGroupId: string
    event:
      | {
          __typename: 'AccommodationGroupEvent'
          id: string
          type: EventType
          accommodationGroupData: {
            __typename: 'AccommodationGroupBaseImpl'
            id: string
            name: string | null
            sortOrder: number
            settings: {
              __typename: 'AccommodationGroupSettings'
              consumptionType: AccommodationConsumptionType | null
              consumptionDurationType: AccommodationConsumptionDurationType | null
            } | null
            status: {
              __typename: 'AccommodationReservationStatus'
              available: number
              reserved: number
              type: AccommodationConsumptionType
              dates: {
                __typename: 'AccommodationDates'
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
              } | null
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              status: Array<{
                __typename: 'DailyAccommodationGroupStatus'
                available: number
                date: string
                reserved: number
                reservedDetailed: {
                  __typename: 'RoomsAndBeds'
                  rooms: number
                  beds: number
                  extraBeds: number
                }
              }>
            }
          } | null
        }
      | {
          __typename: 'RoomEvent'
          id: string
          type: EventType
          roomData: {
            __typename: 'RoomReservation'
            id: string
            type: RoomReservationType
            issues: Array<{
              __typename: 'ValidationIssue'
              key: string
              code: number
              level: IssueLevel
            }>
            request: {
              __typename: 'RoomRequest'
              beds: number
              info: string | null
              extraBeds: number
              checkIn: {
                __typename: 'CheckIn'
                date: string
                type: CheckInType
              }
              checkOut: {
                __typename: 'CheckOut'
                date: string
                type: CheckOutType
              }
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
              room: {
                __typename: 'Room'
                floor: number | null
                id: string
                number: string
                beds: number
                extraBeds: number
                internalInfo: string | null
                roomType: {
                  __typename: 'RoomType'
                  id: string
                  name: string
                  accommodationLevel: {
                    __typename: 'AccommodationLevel'
                    id: string
                    name: string
                    shortName: string
                  }
                  category: {
                    __typename: 'RoomTypeCategory'
                    id: string
                    name: string
                  }
                }
                status: {
                  __typename: 'RoomStatus'
                  cleaningStatus: RoomCleaningStatus | null
                }
              }
            }
            participantRooms: Array<{
              __typename: 'ParticipantRoom'
              id: string
              participant: { __typename: 'SalesParticipant'; id: string }
            }>
            roomTypeReservation: {
              __typename: 'RoomTypeReservation'
              id: string
            } | null
            target: { __typename: 'AccommodationTarget'; id: string } | null
          } | null
        }
      | {
          __typename: 'RoomTypeEvent'
          id: string
          type: EventType
          roomTypeData: {
            __typename: 'RoomTypeReservation'
            id: string
            fulfilledByRooms: boolean
            request: {
              __typename: 'RoomTypeRequest'
              info: string | null
              beds: number
              extraBeds: number
              rooms: number
              checkIn: {
                __typename: 'CheckIn'
                date: string
                type: CheckInType
              }
              checkOut: {
                __typename: 'CheckOut'
                date: string
                type: CheckOutType
              }
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                beds: number | null
                extraBeds: number | null
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
            }
            target: { __typename: 'AccommodationTarget'; id: string } | null
          } | null
        }
      | {
          __typename: 'TargetEvent'
          id: string
          type: EventType
          targetData: {
            __typename: 'AccommodationTarget'
            default: boolean
            floating: boolean
            id: string
            name: string
            nonBlockingCapacity: boolean
            private: boolean
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
            sharedTo: Array<{
              __typename: 'Sales'
              id: string
              orderNumber: number | null
            }>
            status: {
              __typename: 'TargetCapacityStatus'
              status: {
                __typename: 'ReservationStatus'
                reservedCapacity: {
                  __typename: 'RoomsAndBeds'
                  rooms: number
                  beds: number
                  extraBeds: number
                }
                consumptionBreakdown: {
                  __typename: 'ReservationConsumption'
                  type: AccommodationConsumptionType
                  consumption: number
                  floatingConsumption: number
                }
              }
              dailyStatus: Array<{
                __typename: 'DailyReservationStatus'
                date: string
                status: {
                  __typename: 'ReservationStatus'
                  reservedCapacity: {
                    __typename: 'RoomsAndBeds'
                    rooms: number
                    beds: number
                    extraBeds: number
                  }
                  consumptionBreakdown: {
                    __typename: 'ReservationConsumption'
                    type: AccommodationConsumptionType
                    consumption: number
                    floatingConsumption: number
                  }
                }
              }>
            }
          } | null
        }
  }
}

export type AccommodationTargetRoomMutationVariables = Exact<{
  input: AccommodationRoomTargetInput
}>

export type AccommodationTargetRoomMutation = {
  __typename: 'Mutation'
  accommodationRoomTarget: {
    __typename: 'AccommodationRoomTargetPayload'
    accommodationTarget: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      type: RoomReservationType
      roomTypeReservation: {
        __typename: 'RoomTypeReservation'
        id: string
        fulfilledByRooms: boolean
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          beds: number
          extraBeds: number
          rooms: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            beds: number | null
            extraBeds: number | null
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              name: string
              shortName: string
            }
            category: {
              __typename: 'RoomTypeCategory'
              id: string
              name: string
            }
          }
        }
        target: { __typename: 'AccommodationTarget'; id: string } | null
      } | null
      issues: Array<{
        __typename: 'ValidationIssue'
        key: string
        code: number
        level: IssueLevel
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        info: string | null
        extraBeds: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          floor: number | null
          id: string
          number: string
          beds: number
          extraBeds: number
          internalInfo: string | null
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              name: string
              shortName: string
            }
            category: {
              __typename: 'RoomTypeCategory'
              id: string
              name: string
            }
          }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
          }
        }
      }
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: { __typename: 'SalesParticipant'; id: string }
      }>
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
  }
}

export type AccommodationTargetRoomTypeMutationVariables = Exact<{
  input: AccommodationRoomTypeTargetInput
}>

export type AccommodationTargetRoomTypeMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeTarget: {
    __typename: 'AccommodationRoomTypeTargetPayload'
    accommodationTarget: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
  }
}

export type AccommodationTargetCreateMutationVariables = Exact<{
  input: AccommodationTargetCreateInput
}>

export type AccommodationTargetCreateMutation = {
  __typename: 'Mutation'
  accommodationTargetCreate: {
    __typename: 'AccommodationTargetPayload'
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      floating: boolean
      id: string
      name: string
      nonBlockingCapacity: boolean
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
  }
}

export type AccommodationTargetDeleteMutationVariables = Exact<{
  input: AccommodationTargetDeleteInput
}>

export type AccommodationTargetDeleteMutation = {
  __typename: 'Mutation'
  accommodationTargetDelete: {
    __typename: 'AccommodationTargetDeletePayload'
    id: string
    deleted: boolean
  }
}

export type AccommodationDateOffsetMutationVariables = Exact<{
  input: AccommodationUpdateAllInput
}>

export type AccommodationDateOffsetMutation = {
  __typename: 'Mutation'
  accommodationUpdateAll:
    | {
        __typename: 'AccommodationUpdateAllError'
        issues: Array<{
          __typename: 'ValidationIssue'
          key: string
          code: number
          level: IssueLevel
        }>
      }
    | {
        __typename: 'AccommodationUpdateAllPayloadOk'
        roomReservations: Array<{
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }>
        roomTypeReservations: Array<{
          __typename: 'RoomTypeReservation'
          id: string
          fulfilledByRooms: boolean
          request: {
            __typename: 'RoomTypeRequest'
            info: string | null
            beds: number
            extraBeds: number
            rooms: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              beds: number | null
              extraBeds: number | null
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
              category: {
                __typename: 'RoomTypeCategory'
                id: string
                name: string
              }
            }
          }
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }>
      }
}

export type CreateRoomReservationMutationVariables = Exact<{
  input: AccommodationGroupCreateRoomReservationInput
}>

export type CreateRoomReservationMutation = {
  __typename: 'Mutation'
  accommodationGroupCreateRoomReservation:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          settings: {
            __typename: 'AccommodationGroupSettings'
            consumptionType: AccommodationConsumptionType | null
            consumptionDurationType: AccommodationConsumptionDurationType | null
          } | null
          status: {
            __typename: 'AccommodationReservationStatus'
            available: number
            reserved: number
            type: AccommodationConsumptionType
            dates: {
              __typename: 'AccommodationDates'
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
            } | null
            reservedDetailed: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            status: Array<{
              __typename: 'DailyAccommodationGroupStatus'
              available: number
              date: string
              reserved: number
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted'; message: string }
    | { __typename: 'RoomReservationOverlappingError'; message: string }
}

export type CreateRoomTypeReservationMutationVariables = Exact<{
  input: AccommodationGroupCreateRoomTypeReservationInput
}>

export type CreateRoomTypeReservationMutation = {
  __typename: 'Mutation'
  accommodationGroupCreateRoomTypeReservation: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type AccommodationGroupSetNameMutationVariables = Exact<{
  input: AccommodationGroupSetNameInput
}>

export type AccommodationGroupSetNameMutation = {
  __typename: 'Mutation'
  accommodationGroupSetName: {
    __typename: 'AccommodationGroupPayload'
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
    }
  }
}

export type AccommodationGroupDeleteMutationVariables = Exact<{
  input: AccommodationGroupDelete
}>

export type AccommodationGroupDeleteMutation = {
  __typename: 'Mutation'
  accommodationGroupDelete: {
    __typename: 'AccommodationGroupDeletePayload'
    id: string
    deleted: boolean | null
  }
}

export type RoomReservationDeleteMutationVariables = Exact<{
  input: AccommodationRoomReservationDeleteInput
}>

export type RoomReservationDeleteMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationDelete: {
    __typename: 'AccommodationRoomReservationDeletePayload'
    deleted: boolean
    id: string
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    } | null
  }
}

export type RoomReservationSetBedQuantityMutationVariables = Exact<{
  input: AccommodationRoomReservationSetBedQuantityInput
}>

export type RoomReservationSetBedQuantityMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetBedQuantity:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          settings: {
            __typename: 'AccommodationGroupSettings'
            consumptionType: AccommodationConsumptionType | null
            consumptionDurationType: AccommodationConsumptionDurationType | null
          } | null
          status: {
            __typename: 'AccommodationReservationStatus'
            available: number
            reserved: number
            type: AccommodationConsumptionType
            dates: {
              __typename: 'AccommodationDates'
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
            } | null
            reservedDetailed: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            status: Array<{
              __typename: 'DailyAccommodationGroupStatus'
              available: number
              date: string
              reserved: number
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError' }
}

export type RoomReservationSetDatesMutationVariables = Exact<{
  input: AccommodationRoomReservationSetDatesInput
}>

export type RoomReservationSetDatesMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetDates:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          settings: {
            __typename: 'AccommodationGroupSettings'
            consumptionType: AccommodationConsumptionType | null
            consumptionDurationType: AccommodationConsumptionDurationType | null
          } | null
          status: {
            __typename: 'AccommodationReservationStatus'
            available: number
            reserved: number
            type: AccommodationConsumptionType
            dates: {
              __typename: 'AccommodationDates'
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
            } | null
            reservedDetailed: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            status: Array<{
              __typename: 'DailyAccommodationGroupStatus'
              available: number
              date: string
              reserved: number
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted'; message: string }
    | { __typename: 'RoomReservationOverlappingError'; message: string }
}

export type RoomReservationSetNeedsMutationVariables = Exact<{
  input: AccommodationRoomReservationSetNeedsInput
}>

export type RoomReservationSetNeedsMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetNeeds:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          type: RoomReservationType
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              number: string
              beds: number
              extraBeds: number
              internalInfo: string | null
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            id: string
            participant: { __typename: 'SalesParticipant'; id: string }
          }>
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            id: string
          } | null
          target: { __typename: 'AccommodationTarget'; id: string } | null
        }
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          settings: {
            __typename: 'AccommodationGroupSettings'
            consumptionType: AccommodationConsumptionType | null
            consumptionDurationType: AccommodationConsumptionDurationType | null
          } | null
          status: {
            __typename: 'AccommodationReservationStatus'
            available: number
            reserved: number
            type: AccommodationConsumptionType
            dates: {
              __typename: 'AccommodationDates'
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
            } | null
            reservedDetailed: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            status: Array<{
              __typename: 'DailyAccommodationGroupStatus'
              available: number
              date: string
              reserved: number
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError' }
}

export type RoomTypeReservationDeleteMutationVariables = Exact<{
  input: AccommodationRoomTypeReservationDeleteInput
}>

export type RoomTypeReservationDeleteMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationDelete: {
    __typename: 'AccommodationRoomTypeReservationDeletePayload'
    deleted: boolean
    id: string
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    } | null
  }
}

export type RoomTypeReservationSetBedQuantityMutationVariables = Exact<{
  input: AccommodationRoomTypeReservationSetBedQuantityInput
}>

export type RoomTypeReservationSetBedQuantityMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetBedQuantity: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type RoomTypeReservationSetDatesMutationVariables = Exact<{
  input: AccommodationRoomTypeReservationSetDatesInput
}>

export type RoomTypeReservationSetDatesMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetDates: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type RoomTypeReservationSetNeedsMutationVariables = Exact<{
  input: AccommodationRoomTypeReservationSetNeedsInput
}>

export type RoomTypeReservationSetNeedsMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetNeeds: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type RoomTypeReservationSetRoomQuantityMutationVariables = Exact<{
  input: AccommodationRoomTypeReservationSetRoomQuantityInput
}>

export type RoomTypeReservationSetRoomQuantityMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetRoomQuantity: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        beds: number
        extraBeds: number
        rooms: number
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
          category: { __typename: 'RoomTypeCategory'; id: string; name: string }
        }
      }
      target: { __typename: 'AccommodationTarget'; id: string } | null
    }
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    }
  }
}

export type UpdateRoomMutationVariables = Exact<{
  input: RoomUpdateInput
}>

export type UpdateRoomMutation = {
  __typename: 'Mutation'
  roomUpdate: { __typename: 'Room'; id: string; internalInfo: string | null }
}

export type AttachmentFragment = {
  __typename: 'FileItemInfo'
  created: string
  etag: string | null
  fileName: string
  lastModified: string
  mimeType: string
  id: string
  size: number
  info: {
    __typename: 'FileIdentifier'
    fileName: string
    folder: string
    ownerType: FileOwner
    ownerId: string
  }
}

export type CopyAttachmentMutationVariables = Exact<{
  input: FileCopyToInput
}>

export type CopyAttachmentMutation = {
  __typename: 'Mutation'
  fileCopyTo: {
    __typename: 'FileItemInfo'
    created: string
    etag: string | null
    fileName: string
    lastModified: string
    mimeType: string
    id: string
    size: number
    info: {
      __typename: 'FileIdentifier'
      fileName: string
      folder: string
      ownerType: FileOwner
      ownerId: string
    }
  }
}

export type RemoveAttachmentMutationVariables = Exact<{
  input: FileDeleteInput
}>

export type RemoveAttachmentMutation = {
  __typename: 'Mutation'
  fileDelete: {
    __typename: 'FileDeletePayload'
    deleted: boolean
    id: {
      __typename: 'FileIdentifier'
      fileName: string
      folder: string
      ownerType: FileOwner
      ownerId: string
    }
  }
}

export type RenameAttachmentMutationVariables = Exact<{
  input: FileRenameInput
}>

export type RenameAttachmentMutation = {
  __typename: 'Mutation'
  fileUpdate: {
    __typename: 'FileItemInfo'
    created: string
    etag: string | null
    fileName: string
    lastModified: string
    mimeType: string
    id: string
    size: number
    info: {
      __typename: 'FileIdentifier'
      fileName: string
      folder: string
      ownerType: FileOwner
      ownerId: string
    }
  }
}

export type RefreshUserMutationVariables = Exact<{
  externalId: Scalars['ID']
}>

export type RefreshUserMutation = {
  __typename: 'Mutation'
  refreshUserByExternalId: {
    __typename: 'User'
    abbreviation: string | null
    avatarUrl: string | null
    department: string | null
    email: string | null
    firstName: string | null
    id: string
    lastName: string | null
  } | null
}

export type DimensionReferenceFragment = {
  __typename: 'DimensionReference'
  id: string
  selection: Array<{
    __typename: 'DimensionSelectionItem'
    id: string
    dimension: {
      __typename: 'Dimension'
      dimensionHierarchyRoot: string | null
      hierarchyName: string | null
      id: string
      name: string
      required: boolean
      sortOrder: number
    }
    selectedLabel: {
      __typename: 'DimensionLabel'
      id: string
      name: string
    } | null
    validatedOptions: Array<{
      __typename: 'DimensionLabelOption'
      id: string
      validationCategory: DimensionLabelValidationCategory
      label: { __typename: 'DimensionLabel'; id: string; name: string }
    }>
  }>
}

export type SetDimensionLabelsMutationVariables = Exact<{
  input: SalesDimensionsSetLabels
}>

export type SetDimensionLabelsMutation = {
  __typename: 'Mutation'
  salesDimensionsSetLabels: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      id: string
      dimension: {
        __typename: 'Dimension'
        dimensionHierarchyRoot: string | null
        hierarchyName: string | null
        id: string
        name: string
        required: boolean
        sortOrder: number
      }
      selectedLabel: {
        __typename: 'DimensionLabel'
        id: string
        name: string
      } | null
      validatedOptions: Array<{
        __typename: 'DimensionLabelOption'
        id: string
        validationCategory: DimensionLabelValidationCategory
        label: { __typename: 'DimensionLabel'; id: string; name: string }
      }>
    }>
  }
}

export type SetDimensionShortcutMutationVariables = Exact<{
  input: SalesDimensionsSetShortcut
}>

export type SetDimensionShortcutMutation = {
  __typename: 'Mutation'
  salesDimensionsSetShortcut: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      id: string
      dimension: {
        __typename: 'Dimension'
        dimensionHierarchyRoot: string | null
        hierarchyName: string | null
        id: string
        name: string
        required: boolean
        sortOrder: number
      }
      selectedLabel: {
        __typename: 'DimensionLabel'
        id: string
        name: string
      } | null
      validatedOptions: Array<{
        __typename: 'DimensionLabelOption'
        id: string
        validationCategory: DimensionLabelValidationCategory
        label: { __typename: 'DimensionLabel'; id: string; name: string }
      }>
    }>
  }
}

export type DimensionHierarchyQueryVariables = Exact<{
  dimensionId: Scalars['ID']
}>

export type DimensionHierarchyQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    dimensionHierarchy: {
      __typename: 'DimensionHierarchy'
      name: string
      dimension: { __typename: 'Dimension'; id: string; name: string }
      labels: Array<{
        __typename: 'DimensionLabelNode'
        uniquePath: string
        label: { __typename: 'DimensionLabel'; id: string; name: string }
        childHierarchy: {
          __typename: 'DimensionHierarchy'
          dimension: { __typename: 'Dimension'; id: string; name: string }
          labels: Array<{
            __typename: 'DimensionLabelNode'
            uniquePath: string
            label: { __typename: 'DimensionLabel'; id: string; name: string }
            childHierarchy: {
              __typename: 'DimensionHierarchy'
              dimension: { __typename: 'Dimension'; id: string; name: string }
              labels: Array<{
                __typename: 'DimensionLabelNode'
                uniquePath: string
                label: {
                  __typename: 'DimensionLabel'
                  id: string
                  name: string
                }
                childHierarchy: {
                  __typename: 'DimensionHierarchy'
                  dimension: {
                    __typename: 'Dimension'
                    id: string
                    name: string
                  }
                  labels: Array<{
                    __typename: 'DimensionLabelNode'
                    uniquePath: string
                    label: {
                      __typename: 'DimensionLabel'
                      id: string
                      name: string
                    }
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null
  }
}

export type DimensionShortcutsQueryVariables = Exact<{
  salesId: InputMaybe<Scalars['ID']>
}>

export type DimensionShortcutsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    dimensionShortcuts: Array<{
      __typename: 'DimensionShortcut'
      id: string
      name: string
    }>
  }
}

export type SalesDimensionsQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type SalesDimensionsQuery = {
  __typename: 'Query'
  salesDimensions: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      id: string
      dimension: {
        __typename: 'Dimension'
        dimensionHierarchyRoot: string | null
        hierarchyName: string | null
        id: string
        name: string
        required: boolean
        sortOrder: number
      }
      selectedLabel: {
        __typename: 'DimensionLabel'
        id: string
        name: string
      } | null
      validatedOptions: Array<{
        __typename: 'DimensionLabelOption'
        id: string
        validationCategory: DimensionLabelValidationCategory
        label: { __typename: 'DimensionLabel'; id: string; name: string }
      }>
    }>
  }
}

export type DocumentFragment = {
  __typename: 'Document'
  downloadedAt: string | null
  fileStatus: DocumentFileStatus | null
  id: string
  language: string | null
  name: string
  status: DocumentStatus
  attachments: Array<{
    __typename: 'FileItemInfo'
    created: string
    etag: string | null
    fileName: string
    lastModified: string
    mimeType: string
    id: string
    size: number
    info: {
      __typename: 'FileIdentifier'
      fileName: string
      folder: string
      ownerType: FileOwner
      ownerId: string
    }
  }>
  file: {
    __typename: 'FileInfo'
    objectName: string
    fileName: string
    mimeType: string
    created: string
  } | null
  template: {
    __typename: 'DocumentTemplate'
    documentName: string
    documentType: DocumentType
    filesAllowed: boolean
    id: string
    name: string
  }
  templateOptions: Array<{
    __typename: 'DocumentTemplate'
    documentName: string
    documentType: DocumentType
    filesAllowed: boolean
    id: string
    name: string
  }>
  userAttributes: Array<{
    __typename: 'DocumentUserAttribute'
    active: boolean
    editorValue: string | null
    id: string
    key: string
    name: string
    templateValue: string | null
  }>
}

export type UserAttributeFragment = {
  __typename: 'DocumentUserAttribute'
  active: boolean
  editorValue: string | null
  id: string
  key: string
  name: string
  templateValue: string | null
}

export type UseOwnerDocumentsQueryVariables = Exact<{
  ownerId: Scalars['ID']
}>

export type UseOwnerDocumentsQuery = {
  __typename: 'Query'
  documentsByOwnerId: Array<{
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  }>
}

export type DownloadDocumentMutationVariables = Exact<{
  input: DocumentDownloadInput
}>

export type DownloadDocumentMutation = {
  __typename: 'Mutation'
  documentDownload: {
    __typename: 'Document'
    downloadedAt: string | null
    id: string
  }
}

export type SetDocumentTemplateMutationVariables = Exact<{
  input: DocumentSetTemplateInput
}>

export type SetDocumentTemplateMutation = {
  __typename: 'Mutation'
  documentSetTemplate: {
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  }
}

export type UpdateDocumentMutationVariables = Exact<{
  input: DocumentUpdateInput
}>

export type UpdateDocumentMutation = {
  __typename: 'Mutation'
  documentUpdate: {
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  }
}

export type UpdateDocumentUserAttributeMutationVariables = Exact<{
  input: DocumentUserAttributeUpdateInput
}>

export type UpdateDocumentUserAttributeMutation = {
  __typename: 'Mutation'
  documentUserAttributeUpdate: {
    __typename: 'DocumentUserAttributePayload'
    userAttribute: {
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    } | null
  }
}

export type KeycardExcelForDateParticipantFragment = {
  __typename: 'SalesParticipant'
  firstName: string
  id: string
  lastName: string
  services: Array<
    | {
        __typename: 'ServiceParticipantBed'
        id: string
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        participantRoom: {
          __typename: 'ParticipantRoom'
          id: string
          roomReservation: {
            __typename: 'RoomReservation'
            id: string
            request: {
              __typename: 'RoomRequest'
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
              room: { __typename: 'Room'; id: string; number: string }
            }
          }
        } | null
      }
    | { __typename: 'ServicePurchase'; id: string }
  >
}

export type KeycardExcelForDateQueryVariables = Exact<{
  input: SalesForDateInput
  enrollmentStates: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type KeycardExcelForDateQuery = {
  __typename: 'Query'
  arrivingSalesForDate: Array<{
    __typename: 'Sales'
    id: string
    name: string | null
    type: SalesType
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        participantRooms: Array<{ __typename: 'ParticipantRoom'; id: string }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
          room: { __typename: 'Room'; id: string; number: string }
        }
      }>
    }
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            id: string
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              dates: {
                __typename: 'AccommodationDates'
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    checkIn: { __typename: 'CheckIn'; date: string }
                    checkOut: { __typename: 'CheckOut'; date: string }
                    room: { __typename: 'Room'; id: string; number: string }
                  }
                }
              } | null
            }
          | { __typename: 'ServicePurchase'; id: string }
        >
      }>
    }
  }>
}

export type DayVisitorsFragment = {
  __typename: 'DayVisitors'
  estimatedParticipants: number | null
  participants: number
}

export type RoomsAndParticipantsFragment = {
  __typename: 'RoomsAndParticipants'
  beds: number
  checkedParticipants: number
  checkedRooms: number
  extraBeds: number
  participants: number
  rooms: number
}

export type SalesForDateSalesFragment = {
  __typename: 'Sales'
  id: string
  name: string | null
  orderNumber: number | null
  type: SalesType
  facet: {
    __typename: 'SalesFacet'
    id: string
    name: string
    abbreviation: string
    color: string
  }
  estimatedDates: {
    __typename: 'LocalDateRange'
    end: string
    start: string
  } | null
  lifecycle: {
    __typename: 'SalesLifecycle'
    state: {
      __typename: 'SalesState'
      key: string
      systemState: SalesSystemState
    }
  }
  reservationDates: {
    __typename: 'LocalDateRange'
    end: string
    start: string
  } | null
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
  paymentAgreement: {
    __typename: 'PaymentAgreement'
    id: string
    code: string
    name: string
  } | null
}

export type SalesByIdsStatsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
  date: Scalars['LocalDate']
}>

export type SalesByIdsStatsQuery = {
  __typename: 'Query'
  salesAll: Array<{
    __typename: 'Sales'
    id: string
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            id: string
            customerNumber: string
            organization: {
              __typename: 'CustomerOrganizationData'
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            id: string
            customerNumber: string
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
    visitStats: {
      __typename: 'VisitStats'
      types: Array<SalesForDateType>
      arriving: {
        __typename: 'RoomsAndParticipants'
        beds: number
        checkedParticipants: number
        checkedRooms: number
        extraBeds: number
        participants: number
        rooms: number
      }
      dayVisitors: {
        __typename: 'DayVisitors'
        estimatedParticipants: number | null
        participants: number
      }
      departing: {
        __typename: 'RoomsAndParticipants'
        beds: number
        checkedParticipants: number
        checkedRooms: number
        extraBeds: number
        participants: number
        rooms: number
      }
      present: {
        __typename: 'RoomsAndParticipants'
        beds: number
        checkedParticipants: number
        checkedRooms: number
        extraBeds: number
        participants: number
        rooms: number
      }
    }
  }>
}

export type SalesForDateQueryVariables = Exact<{
  date: Scalars['LocalDate']
}>

export type SalesForDateQuery = {
  __typename: 'Query'
  salesForDate: Array<{
    __typename: 'Sales'
    id: string
    name: string | null
    orderNumber: number | null
    type: SalesType
    facet: {
      __typename: 'SalesFacet'
      id: string
      name: string
      abbreviation: string
      color: string
    }
    estimatedDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }
    reservationDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      code: string
      name: string
    } | null
  }>
}

export type RoomReservationsForDateParticipantFragment = {
  __typename: 'SalesParticipant'
  age: number | null
  ageSource: AgeField | null
  firstName: string
  gender: Gender | null
  id: string
  lastName: string
  ageCategory: {
    __typename: 'AgeCategory'
    abbreviation: string | null
    key: string
    name: string
    shortName: string
  } | null
  birthday: {
    __typename: 'Birthday'
    date: number | null
    month: number | null
    year: number
  } | null
  postalAddress: {
    __typename: 'PostalAddressType'
    address1: string | null
    address2: string | null
    postcode: string | null
    city: string | null
    country: string | null
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    locked: boolean
    name: string | null
    orderNumber: number | null
    type: SalesType
    facet: {
      __typename: 'SalesFacet'
      abbreviation: string
      color: string
      id: string
      name: string
    }
    orders: Array<{
      __typename: 'Order'
      id: string
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }>
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      code: string
      id: string
      name: string
    } | null
  }
}

export type RoomReservationsForDateParticipantRoomFragment = {
  __typename: 'ParticipantRoom'
  checkIn: string | null
  checkOut: string | null
  id: string
  participant: {
    __typename: 'SalesParticipant'
    age: number | null
    ageSource: AgeField | null
    firstName: string
    gender: Gender | null
    id: string
    lastName: string
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
      shortName: string
    } | null
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      postcode: string | null
      city: string | null
      country: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      facet: {
        __typename: 'SalesFacet'
        abbreviation: string
        color: string
        id: string
        name: string
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }>
      paymentAgreement: {
        __typename: 'PaymentAgreement'
        code: string
        id: string
        name: string
      } | null
    }
  }
  request: {
    __typename: 'ParticipantRoomRequest'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  }
}

export type RoomReservationsForDateRequestFragment = {
  __typename: 'RoomRequest'
  beds: number
  extraBeds: number
  info: string | null
  checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
  checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  features: Array<{
    __typename: 'RoomLabel'
    icon: string | null
    id: string
    name: string
    shortName: string
  }>
  room: {
    __typename: 'Room'
    beds: number
    extraBeds: number
    id: string
    number: string
    building: { __typename: 'Building'; id: string; name: string }
    status: {
      __typename: 'RoomStatus'
      cleaningStatus: RoomCleaningStatus | null
      reservationStatus: RoomReservationStatus | null
      statusCode: RoomStatusCode | null
      occupants: Array<{
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        participant: {
          __typename: 'SalesParticipant'
          age: number | null
          ageSource: AgeField | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          ageCategory: {
            __typename: 'AgeCategory'
            abbreviation: string | null
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            postcode: string | null
            city: string | null
            country: string | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
    }
  }
}

export type RoomReservationsForDateRoomFragment = {
  __typename: 'Room'
  beds: number
  extraBeds: number
  id: string
  number: string
  building: { __typename: 'Building'; id: string; name: string }
  status: {
    __typename: 'RoomStatus'
    cleaningStatus: RoomCleaningStatus | null
    reservationStatus: RoomReservationStatus | null
    statusCode: RoomStatusCode | null
    occupants: Array<{
      __typename: 'ParticipantRoom'
      checkIn: string | null
      checkOut: string | null
      id: string
      participant: {
        __typename: 'SalesParticipant'
        age: number | null
        ageSource: AgeField | null
        firstName: string
        gender: Gender | null
        id: string
        lastName: string
        ageCategory: {
          __typename: 'AgeCategory'
          abbreviation: string | null
          key: string
          name: string
          shortName: string
        } | null
        birthday: {
          __typename: 'Birthday'
          date: number | null
          month: number | null
          year: number
        } | null
        postalAddress: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          postcode: string | null
          city: string | null
          country: string | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          locked: boolean
          name: string | null
          orderNumber: number | null
          type: SalesType
          facet: {
            __typename: 'SalesFacet'
            abbreviation: string
            color: string
            id: string
            name: string
          }
          orders: Array<{
            __typename: 'Order'
            id: string
            paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
          }>
          paymentAgreement: {
            __typename: 'PaymentAgreement'
            code: string
            id: string
            name: string
          } | null
        }
      }
      request: {
        __typename: 'ParticipantRoomRequest'
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      }
    }>
  }
}

export type RoomReservationsForDateRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  checkIn: string | null
  checkOut: string | null
  estimatedCheckIn: string
  estimatedCheckOut: string
  participantRooms: Array<{
    __typename: 'ParticipantRoom'
    checkIn: string | null
    checkOut: string | null
    id: string
    participant: {
      __typename: 'SalesParticipant'
      age: number | null
      ageSource: AgeField | null
      firstName: string
      gender: Gender | null
      id: string
      lastName: string
      ageCategory: {
        __typename: 'AgeCategory'
        abbreviation: string | null
        key: string
        name: string
        shortName: string
      } | null
      birthday: {
        __typename: 'Birthday'
        date: number | null
        month: number | null
        year: number
      } | null
      postalAddress: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        postcode: string | null
        city: string | null
        country: string | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          code: string
          id: string
          name: string
        } | null
      }
    }
    request: {
      __typename: 'ParticipantRoomRequest'
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    }
  }>
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      beds: number
      extraBeds: number
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
        reservationStatus: RoomReservationStatus | null
        statusCode: RoomStatusCode | null
        occupants: Array<{
          __typename: 'ParticipantRoom'
          checkIn: string | null
          checkOut: string | null
          id: string
          participant: {
            __typename: 'SalesParticipant'
            age: number | null
            ageSource: AgeField | null
            firstName: string
            gender: Gender | null
            id: string
            lastName: string
            ageCategory: {
              __typename: 'AgeCategory'
              abbreviation: string | null
              key: string
              name: string
              shortName: string
            } | null
            birthday: {
              __typename: 'Birthday'
              date: number | null
              month: number | null
              year: number
            } | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              postcode: string | null
              city: string | null
              country: string | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              facet: {
                __typename: 'SalesFacet'
                abbreviation: string
                color: string
                id: string
                name: string
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }>
              paymentAgreement: {
                __typename: 'PaymentAgreement'
                code: string
                id: string
                name: string
              } | null
            }
          }
          request: {
            __typename: 'ParticipantRoomRequest'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          }
        }>
      }
    }
  }
  sales: {
    __typename: 'Sales'
    id: string
    locked: boolean
    name: string | null
    notes: string | null
    orderNumber: number | null
    type: SalesType
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            id: string
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      abbreviation: string
      color: string
      id: string
      name: string
    }
    orders: Array<{
      __typename: 'Order'
      id: string
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }>
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      code: string
      id: string
      name: string
    } | null
  }
}

export type RoomReservationsForDateSalesFragment = {
  __typename: 'Sales'
  id: string
  locked: boolean
  name: string | null
  notes: string | null
  orderNumber: number | null
  type: SalesType
  customer: {
    __typename: 'CustomerReference'
    customer:
      | {
          __typename: 'CustomerOrganization'
          customerNumber: string
          id: string
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          id: string
          person: {
            __typename: 'CustomerPersonData'
            firstName: string
            lastName: string
          }
        }
  } | null
  facet: {
    __typename: 'SalesFacet'
    abbreviation: string
    color: string
    id: string
    name: string
  }
  orders: Array<{
    __typename: 'Order'
    id: string
    paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
  }>
  paymentAgreement: {
    __typename: 'PaymentAgreement'
    code: string
    id: string
    name: string
  } | null
}

export type RoomReservationsForDateQueryVariables = Exact<{
  input: ReservationSearchInput
}>

export type RoomReservationsForDateQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    rooms: Array<{
      __typename: 'Room'
      beds: number
      extraBeds: number
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
        reservationStatus: RoomReservationStatus | null
        statusCode: RoomStatusCode | null
        occupants: Array<{
          __typename: 'ParticipantRoom'
          checkIn: string | null
          checkOut: string | null
          id: string
          participant: {
            __typename: 'SalesParticipant'
            age: number | null
            ageSource: AgeField | null
            firstName: string
            gender: Gender | null
            id: string
            lastName: string
            ageCategory: {
              __typename: 'AgeCategory'
              abbreviation: string | null
              key: string
              name: string
              shortName: string
            } | null
            birthday: {
              __typename: 'Birthday'
              date: number | null
              month: number | null
              year: number
            } | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              postcode: string | null
              city: string | null
              country: string | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              facet: {
                __typename: 'SalesFacet'
                abbreviation: string
                color: string
                id: string
                name: string
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }>
              paymentAgreement: {
                __typename: 'PaymentAgreement'
                code: string
                id: string
                name: string
              } | null
            }
          }
          request: {
            __typename: 'ParticipantRoomRequest'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          }
        }>
      }
    }>
  }
  accommodationRoomReservations: {
    __typename: 'RoomReservationsPayload'
    reservations: Array<{
      __typename: 'RoomReservation'
      id: string
      checkIn: string | null
      checkOut: string | null
      estimatedCheckIn: string
      estimatedCheckOut: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        participant: {
          __typename: 'SalesParticipant'
          age: number | null
          ageSource: AgeField | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          ageCategory: {
            __typename: 'AgeCategory'
            abbreviation: string | null
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            postcode: string | null
            city: string | null
            country: string | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
            reservationStatus: RoomReservationStatus | null
            statusCode: RoomStatusCode | null
            occupants: Array<{
              __typename: 'ParticipantRoom'
              checkIn: string | null
              checkOut: string | null
              id: string
              participant: {
                __typename: 'SalesParticipant'
                age: number | null
                ageSource: AgeField | null
                firstName: string
                gender: Gender | null
                id: string
                lastName: string
                ageCategory: {
                  __typename: 'AgeCategory'
                  abbreviation: string | null
                  key: string
                  name: string
                  shortName: string
                } | null
                birthday: {
                  __typename: 'Birthday'
                  date: number | null
                  month: number | null
                  year: number
                } | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  postcode: string | null
                  city: string | null
                  country: string | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  locked: boolean
                  name: string | null
                  orderNumber: number | null
                  type: SalesType
                  facet: {
                    __typename: 'SalesFacet'
                    abbreviation: string
                    color: string
                    id: string
                    name: string
                  }
                  orders: Array<{
                    __typename: 'Order'
                    id: string
                    paymentInfo: {
                      __typename: 'PaymentInfo'
                      status: PaymentStatus
                    }
                  }>
                  paymentAgreement: {
                    __typename: 'PaymentAgreement'
                    code: string
                    id: string
                    name: string
                  } | null
                }
              }
              request: {
                __typename: 'ParticipantRoomRequest'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              }
            }>
          }
        }
      }
      sales: {
        __typename: 'Sales'
        id: string
        locked: boolean
        name: string | null
        notes: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          code: string
          id: string
          name: string
        } | null
      }
    }>
  }
}

export type SetParticipantCheckInMutationVariables = Exact<{
  input: MarkCheckedInput
}>

export type SetParticipantCheckInMutation = {
  __typename: 'Mutation'
  salesParticipantCheckIn: Array<{
    __typename: 'ParticipantRoom'
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      checkIn: string | null
      checkOut: string | null
      estimatedCheckIn: string
      estimatedCheckOut: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        participant: {
          __typename: 'SalesParticipant'
          age: number | null
          ageSource: AgeField | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          ageCategory: {
            __typename: 'AgeCategory'
            abbreviation: string | null
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            postcode: string | null
            city: string | null
            country: string | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
            reservationStatus: RoomReservationStatus | null
            statusCode: RoomStatusCode | null
            occupants: Array<{
              __typename: 'ParticipantRoom'
              checkIn: string | null
              checkOut: string | null
              id: string
              participant: {
                __typename: 'SalesParticipant'
                age: number | null
                ageSource: AgeField | null
                firstName: string
                gender: Gender | null
                id: string
                lastName: string
                ageCategory: {
                  __typename: 'AgeCategory'
                  abbreviation: string | null
                  key: string
                  name: string
                  shortName: string
                } | null
                birthday: {
                  __typename: 'Birthday'
                  date: number | null
                  month: number | null
                  year: number
                } | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  postcode: string | null
                  city: string | null
                  country: string | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  locked: boolean
                  name: string | null
                  orderNumber: number | null
                  type: SalesType
                  facet: {
                    __typename: 'SalesFacet'
                    abbreviation: string
                    color: string
                    id: string
                    name: string
                  }
                  orders: Array<{
                    __typename: 'Order'
                    id: string
                    paymentInfo: {
                      __typename: 'PaymentInfo'
                      status: PaymentStatus
                    }
                  }>
                  paymentAgreement: {
                    __typename: 'PaymentAgreement'
                    code: string
                    id: string
                    name: string
                  } | null
                }
              }
              request: {
                __typename: 'ParticipantRoomRequest'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              }
            }>
          }
        }
      }
      sales: {
        __typename: 'Sales'
        id: string
        locked: boolean
        name: string | null
        notes: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          code: string
          id: string
          name: string
        } | null
      }
    }
  }>
}

export type SetParticipantCheckOutMutationVariables = Exact<{
  input: MarkCheckedInput
}>

export type SetParticipantCheckOutMutation = {
  __typename: 'Mutation'
  salesParticipantCheckOut: Array<{
    __typename: 'ParticipantRoom'
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      checkIn: string | null
      checkOut: string | null
      estimatedCheckIn: string
      estimatedCheckOut: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        participant: {
          __typename: 'SalesParticipant'
          age: number | null
          ageSource: AgeField | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          ageCategory: {
            __typename: 'AgeCategory'
            abbreviation: string | null
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            postcode: string | null
            city: string | null
            country: string | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
            reservationStatus: RoomReservationStatus | null
            statusCode: RoomStatusCode | null
            occupants: Array<{
              __typename: 'ParticipantRoom'
              checkIn: string | null
              checkOut: string | null
              id: string
              participant: {
                __typename: 'SalesParticipant'
                age: number | null
                ageSource: AgeField | null
                firstName: string
                gender: Gender | null
                id: string
                lastName: string
                ageCategory: {
                  __typename: 'AgeCategory'
                  abbreviation: string | null
                  key: string
                  name: string
                  shortName: string
                } | null
                birthday: {
                  __typename: 'Birthday'
                  date: number | null
                  month: number | null
                  year: number
                } | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  postcode: string | null
                  city: string | null
                  country: string | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  locked: boolean
                  name: string | null
                  orderNumber: number | null
                  type: SalesType
                  facet: {
                    __typename: 'SalesFacet'
                    abbreviation: string
                    color: string
                    id: string
                    name: string
                  }
                  orders: Array<{
                    __typename: 'Order'
                    id: string
                    paymentInfo: {
                      __typename: 'PaymentInfo'
                      status: PaymentStatus
                    }
                  }>
                  paymentAgreement: {
                    __typename: 'PaymentAgreement'
                    code: string
                    id: string
                    name: string
                  } | null
                }
              }
              request: {
                __typename: 'ParticipantRoomRequest'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              }
            }>
          }
        }
      }
      sales: {
        __typename: 'Sales'
        id: string
        locked: boolean
        name: string | null
        notes: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          code: string
          id: string
          name: string
        } | null
      }
    }
  }>
}

export type SetRoomReservationCheckMutationVariables = Exact<{
  input: AccommodationRoomReservationCheckInput
}>

export type SetRoomReservationCheckMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetCheck: {
    __typename: 'AccommodationRoomReservationCheckPayload'
    roomReservations: Array<{
      __typename: 'RoomReservation'
      id: string
      checkIn: string | null
      checkOut: string | null
      estimatedCheckIn: string
      estimatedCheckOut: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        participant: {
          __typename: 'SalesParticipant'
          age: number | null
          ageSource: AgeField | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          ageCategory: {
            __typename: 'AgeCategory'
            abbreviation: string | null
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            postcode: string | null
            city: string | null
            country: string | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
            reservationStatus: RoomReservationStatus | null
            statusCode: RoomStatusCode | null
            occupants: Array<{
              __typename: 'ParticipantRoom'
              checkIn: string | null
              checkOut: string | null
              id: string
              participant: {
                __typename: 'SalesParticipant'
                age: number | null
                ageSource: AgeField | null
                firstName: string
                gender: Gender | null
                id: string
                lastName: string
                ageCategory: {
                  __typename: 'AgeCategory'
                  abbreviation: string | null
                  key: string
                  name: string
                  shortName: string
                } | null
                birthday: {
                  __typename: 'Birthday'
                  date: number | null
                  month: number | null
                  year: number
                } | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  postcode: string | null
                  city: string | null
                  country: string | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  locked: boolean
                  name: string | null
                  orderNumber: number | null
                  type: SalesType
                  facet: {
                    __typename: 'SalesFacet'
                    abbreviation: string
                    color: string
                    id: string
                    name: string
                  }
                  orders: Array<{
                    __typename: 'Order'
                    id: string
                    paymentInfo: {
                      __typename: 'PaymentInfo'
                      status: PaymentStatus
                    }
                  }>
                  paymentAgreement: {
                    __typename: 'PaymentAgreement'
                    code: string
                    id: string
                    name: string
                  } | null
                }
              }
              request: {
                __typename: 'ParticipantRoomRequest'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              }
            }>
          }
        }
      }
      sales: {
        __typename: 'Sales'
        id: string
        locked: boolean
        name: string | null
        notes: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          code: string
          id: string
          name: string
        } | null
      }
    }>
  }
}

export type SetRoomReservationCleaningStatusMutationVariables = Exact<{
  input: RoomSetStatusInput
}>

export type SetRoomReservationCleaningStatusMutation = {
  __typename: 'Mutation'
  roomSetStatus: Array<{
    __typename: 'RoomStatusChange'
    id: string
    newCleaningStatus: RoomCleaningStatus | null
  }>
}

export type SetRoomReservationNotesMutationVariables = Exact<{
  input: AccommodationRoomReservationSetNeedsInput
}>

export type SetRoomReservationNotesMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetNeeds:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          checkIn: string | null
          checkOut: string | null
          estimatedCheckIn: string
          estimatedCheckOut: string
          participantRooms: Array<{
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            participant: {
              __typename: 'SalesParticipant'
              age: number | null
              ageSource: AgeField | null
              firstName: string
              gender: Gender | null
              id: string
              lastName: string
              ageCategory: {
                __typename: 'AgeCategory'
                abbreviation: string | null
                key: string
                name: string
                shortName: string
              } | null
              birthday: {
                __typename: 'Birthday'
                date: number | null
                month: number | null
                year: number
              } | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                postcode: string | null
                city: string | null
                country: string | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                locked: boolean
                name: string | null
                orderNumber: number | null
                type: SalesType
                facet: {
                  __typename: 'SalesFacet'
                  abbreviation: string
                  color: string
                  id: string
                  name: string
                }
                orders: Array<{
                  __typename: 'Order'
                  id: string
                  paymentInfo: {
                    __typename: 'PaymentInfo'
                    status: PaymentStatus
                  }
                }>
                paymentAgreement: {
                  __typename: 'PaymentAgreement'
                  code: string
                  id: string
                  name: string
                } | null
              }
            }
            request: {
              __typename: 'ParticipantRoomRequest'
              checkIn: {
                __typename: 'CheckIn'
                date: string
                type: CheckInType
              }
              checkOut: {
                __typename: 'CheckOut'
                date: string
                type: CheckOutType
              }
            }
          }>
          request: {
            __typename: 'RoomRequest'
            beds: number
            extraBeds: number
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              beds: number
              extraBeds: number
              id: string
              number: string
              building: { __typename: 'Building'; id: string; name: string }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
                reservationStatus: RoomReservationStatus | null
                statusCode: RoomStatusCode | null
                occupants: Array<{
                  __typename: 'ParticipantRoom'
                  checkIn: string | null
                  checkOut: string | null
                  id: string
                  participant: {
                    __typename: 'SalesParticipant'
                    age: number | null
                    ageSource: AgeField | null
                    firstName: string
                    gender: Gender | null
                    id: string
                    lastName: string
                    ageCategory: {
                      __typename: 'AgeCategory'
                      abbreviation: string | null
                      key: string
                      name: string
                      shortName: string
                    } | null
                    birthday: {
                      __typename: 'Birthday'
                      date: number | null
                      month: number | null
                      year: number
                    } | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      postcode: string | null
                      city: string | null
                      country: string | null
                    } | null
                    sales: {
                      __typename: 'Sales'
                      id: string
                      locked: boolean
                      name: string | null
                      orderNumber: number | null
                      type: SalesType
                      facet: {
                        __typename: 'SalesFacet'
                        abbreviation: string
                        color: string
                        id: string
                        name: string
                      }
                      orders: Array<{
                        __typename: 'Order'
                        id: string
                        paymentInfo: {
                          __typename: 'PaymentInfo'
                          status: PaymentStatus
                        }
                      }>
                      paymentAgreement: {
                        __typename: 'PaymentAgreement'
                        code: string
                        id: string
                        name: string
                      } | null
                    }
                  }
                  request: {
                    __typename: 'ParticipantRoomRequest'
                    checkIn: {
                      __typename: 'CheckIn'
                      date: string
                      type: CheckInType
                    }
                    checkOut: {
                      __typename: 'CheckOut'
                      date: string
                      type: CheckOutType
                    }
                  }
                }>
              }
            }
          }
          sales: {
            __typename: 'Sales'
            id: string
            locked: boolean
            name: string | null
            notes: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }>
            paymentAgreement: {
              __typename: 'PaymentAgreement'
              code: string
              id: string
              name: string
            } | null
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError' }
}

export type UpdateParticipantMutationVariables = Exact<{
  input: SalesParticipantUpdateInput
}>

export type UpdateParticipantMutation = {
  __typename: 'Mutation'
  salesParticipantUpdate: {
    __typename: 'SalesParticipant'
    age: number | null
    ageSource: AgeField | null
    firstName: string
    gender: Gender | null
    id: string
    lastName: string
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
      shortName: string
    } | null
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      postcode: string | null
      city: string | null
      country: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      facet: {
        __typename: 'SalesFacet'
        abbreviation: string
        color: string
        id: string
        name: string
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }>
      paymentAgreement: {
        __typename: 'PaymentAgreement'
        code: string
        id: string
        name: string
      } | null
    }
  }
}

export type InvoicesQueryVariables = Exact<{
  input: InvoicesByIdsInput
}>

export type InvoicesQuery = {
  __typename: 'Query'
  invoicesByIds: {
    __typename: 'InvoicesPayload'
    invoices: Array<{
      __typename: 'Invoice'
      id: string
      invoiceNumber: string | null
      paymentInfo: {
        __typename: 'PaymentInfo'
        payableAmount: number
        totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
      }
      order: {
        __typename: 'Order'
        sales: {
          __typename: 'Sales'
          id: string
          paymentAgreement: {
            __typename: 'PaymentAgreement'
            id: string
            name: string
            code: string
          } | null
        }
      }
    }>
  }
}

export type AccommodationGuestsListingRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    room: {
      __typename: 'Room'
      beds: number
      extraBeds: number
      floor: number | null
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      roomType: {
        __typename: 'RoomType'
        name: string
        accommodationLevel: {
          __typename: 'AccommodationLevel'
          name: string
          shortName: string
        }
      }
    }
  }
  participantRooms: Array<{
    __typename: 'ParticipantRoom'
    id: string
    participant: {
      __typename: 'SalesParticipant'
      accommodationRequest: string | null
      additionalInfo: string | null
      age: number | null
      firstName: string
      gender: Gender | null
      id: string
      lastName: string
      nationality: string | null
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
      } | null
      birthday: {
        __typename: 'Birthday'
        date: number | null
        month: number | null
        year: number
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
          }
        }
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
      }
    }
    request: {
      __typename: 'ParticipantRoomRequest'
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    }
  }>
}

export type AccommodationGuestsListingQueryVariables = Exact<{
  input: ReservationSearchInput
}>

export type AccommodationGuestsListingQuery = {
  __typename: 'Query'
  accommodationRoomReservations: {
    __typename: 'RoomReservationsPayload'
    arriving: Array<{
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          floor: number | null
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            name: string
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              name: string
              shortName: string
            }
          }
        }
      }
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
              }
            }
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
    }>
    present: Array<{
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          floor: number | null
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            name: string
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              name: string
              shortName: string
            }
          }
        }
      }
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
              }
            }
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
    }>
  }
}

type UseArrivalsForDateCustomer_CustomerOrganization_Fragment = {
  __typename: 'CustomerOrganization'
  customerNumber: string
  id: string
  organization: {
    __typename: 'CustomerOrganizationData'
    businessId: string | null
    name: string | null
  }
}

type UseArrivalsForDateCustomer_CustomerPerson_Fragment = {
  __typename: 'CustomerPerson'
  customerNumber: string
  id: string
  person: {
    __typename: 'CustomerPersonData'
    email: string | null
    firstName: string
    lastName: string
    phone: string | null
  }
}

export type UseArrivalsForDateCustomerFragment =
  | UseArrivalsForDateCustomer_CustomerOrganization_Fragment
  | UseArrivalsForDateCustomer_CustomerPerson_Fragment

export type UseArrivalsForDateRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      beds: number
      extraBeds: number
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
    }
  }
  target: { __typename: 'AccommodationTarget'; id: string; name: string } | null
}

export type UseArrivalsForDateBedServiceFragment = {
  __typename: 'ServiceParticipantBed'
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    id: string
    name: string
  } | null
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    id: string
    roomReservation: { __typename: 'RoomReservation'; id: string }
  } | null
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type UseArrivalsForDateParticipantFragment = {
  __typename: 'SalesParticipant'
  additionalInfo: string | null
  firstName: string
  id: string
  lastName: string
  ageCategory: {
    __typename: 'AgeCategory'
    key: string
    shortName: string
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    orderNumber: number | null
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }
  services: Array<
    | {
        __typename: 'ServiceParticipantBed'
        id: string
        accommodationTarget: {
          __typename: 'AccommodationTarget'
          id: string
          name: string
        } | null
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        } | null
        participantRoom: {
          __typename: 'ParticipantRoom'
          id: string
          roomReservation: { __typename: 'RoomReservation'; id: string }
        } | null
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          product: { __typename: 'SalesProduct'; id: string; name: string }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
    | { __typename: 'ServicePurchase'; id: string }
  >
}

export type UseArrivalsForDateQueryVariables = Exact<{
  input: SalesForDateInput
  enrollmentStates: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UseArrivalsForDateQuery = {
  __typename: 'Query'
  arrivingSalesForDate: Array<{
    __typename: 'Sales'
    id: string
    name: string | null
    orderNumber: number | null
    type: SalesType
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            beds: number
            extraBeds: number
            id: string
            number: string
            building: { __typename: 'Building'; id: string; name: string }
          }
        }
        target: {
          __typename: 'AccommodationTarget'
          id: string
          name: string
        } | null
      }>
    }
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            id: string
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      name: string
      abbreviation: string
      color: string
      dimensionTarget: DimensionTarget
      features: Array<{
        __typename: 'SalesFacetFeature'
        feature: Feature
        defaultVisibility: Visibility
      }>
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        additionalInfo: string | null
        firstName: string
        id: string
        lastName: string
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          shortName: string
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          paymentAgreement: {
            __typename: 'PaymentAgreement'
            id: string
            name: string
            code: string
          } | null
        }
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              accommodationTarget: {
                __typename: 'AccommodationTarget'
                id: string
                name: string
              } | null
              dates: {
                __typename: 'AccommodationDates'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: { __typename: 'RoomReservation'; id: string }
              } | null
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
          | { __typename: 'ServicePurchase'; id: string }
        >
      }>
    }
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }>
}

type UseDeparturesForDateCustomer_CustomerOrganization_Fragment = {
  __typename: 'CustomerOrganization'
  customerNumber: string
  id: string
  organization: {
    __typename: 'CustomerOrganizationData'
    businessId: string | null
    name: string | null
  }
}

type UseDeparturesForDateCustomer_CustomerPerson_Fragment = {
  __typename: 'CustomerPerson'
  customerNumber: string
  id: string
  person: {
    __typename: 'CustomerPersonData'
    email: string | null
    firstName: string
    lastName: string
    phone: string | null
  }
}

export type UseDeparturesForDateCustomerFragment =
  | UseDeparturesForDateCustomer_CustomerOrganization_Fragment
  | UseDeparturesForDateCustomer_CustomerPerson_Fragment

export type UseDeparturesForDateRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      beds: number
      extraBeds: number
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
    }
  }
  target: { __typename: 'AccommodationTarget'; id: string; name: string } | null
}

export type UseDeparturesForDateBedServiceFragment = {
  __typename: 'ServiceParticipantBed'
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    id: string
    name: string
  } | null
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    id: string
    roomReservation: { __typename: 'RoomReservation'; id: string }
  } | null
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type UseDeparturesForDateParticipantFragment = {
  __typename: 'SalesParticipant'
  additionalInfo: string | null
  firstName: string
  id: string
  lastName: string
  ageCategory: {
    __typename: 'AgeCategory'
    key: string
    shortName: string
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    orderNumber: number | null
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }
  services: Array<
    | {
        __typename: 'ServiceParticipantBed'
        id: string
        accommodationTarget: {
          __typename: 'AccommodationTarget'
          id: string
          name: string
        } | null
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        } | null
        participantRoom: {
          __typename: 'ParticipantRoom'
          id: string
          roomReservation: { __typename: 'RoomReservation'; id: string }
        } | null
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          product: { __typename: 'SalesProduct'; id: string; name: string }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
    | { __typename: 'ServicePurchase'; id: string }
  >
}

export type UseDeparturesForDateQueryVariables = Exact<{
  input: SalesForDateInput
  enrollmentStates: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UseDeparturesForDateQuery = {
  __typename: 'Query'
  departingSalesForDate: Array<{
    __typename: 'Sales'
    id: string
    name: string | null
    orderNumber: number | null
    type: SalesType
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            beds: number
            extraBeds: number
            id: string
            number: string
            building: { __typename: 'Building'; id: string; name: string }
          }
        }
        target: {
          __typename: 'AccommodationTarget'
          id: string
          name: string
        } | null
      }>
    }
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            id: string
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      name: string
      abbreviation: string
      color: string
      dimensionTarget: DimensionTarget
      features: Array<{
        __typename: 'SalesFacetFeature'
        feature: Feature
        defaultVisibility: Visibility
      }>
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        additionalInfo: string | null
        firstName: string
        id: string
        lastName: string
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          shortName: string
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          paymentAgreement: {
            __typename: 'PaymentAgreement'
            id: string
            name: string
            code: string
          } | null
        }
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              accommodationTarget: {
                __typename: 'AccommodationTarget'
                id: string
                name: string
              } | null
              dates: {
                __typename: 'AccommodationDates'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: { __typename: 'RoomReservation'; id: string }
              } | null
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
          | { __typename: 'ServicePurchase'; id: string }
        >
      }>
    }
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }>
}

export type ResourceReservationListingReservationFragment = {
  __typename: 'ResourceReservation'
  customerVisibility: ResourceReservationCustomerVisibility
  description: string | null
  internalNote: string | null
  end: string
  id: string
  resourceQuantity: number
  start: string
  dimensions: {
    __typename: 'DimensionReference'
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      selectedLabel: { __typename: 'DimensionLabel'; name: string } | null
    }>
  } | null
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
  }>
  resource:
    | { __typename: 'ResourceEquipment'; id: string; name: string }
    | { __typename: 'ResourceHuman'; id: string; name: string }
    | { __typename: 'ResourceProperty'; id: string; name: string }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        name: string
        parentResource: {
          __typename: 'ResourceProperty'
          id: string
          name: string
        }
      }
    | null
  tasks: Array<{
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    number: number
    assignee:
      | { __typename: 'User'; email: string | null; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      attributes: { __typename: 'PurchaseProductAttributes'; quantity: number }
      product: {
        __typename: 'SalesProduct'
        id: string
        name: string
        items: Array<{
          __typename: 'SalesProductItem'
          id: string
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
        }>
      }
      totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
    }>
  }>
}

export type ResourceReservationsListingQueryVariables = Exact<{
  input: ResourceReservationsInput
}>

export type ResourceReservationsListingQuery = {
  __typename: 'Query'
  resourceReservations: {
    __typename: 'ReservationsPayload'
    reservations: Array<{
      __typename: 'ReservationsByResource'
      reservations: Array<{
        __typename: 'ResourceReservation'
        customerVisibility: ResourceReservationCustomerVisibility
        description: string | null
        internalNote: string | null
        end: string
        id: string
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          id: string
          name: string | null
          reservations: Array<{
            __typename: 'ResourceReservation'
            customerVisibility: ResourceReservationCustomerVisibility
            description: string | null
            internalNote: string | null
            end: string
            id: string
            resourceQuantity: number
            start: string
            sales: {
              __typename: 'Sales'
              id: string
              estimatedParticipants: number | null
            }
            dimensions: {
              __typename: 'DimensionReference'
              selection: Array<{
                __typename: 'DimensionSelectionItem'
                selectedLabel: {
                  __typename: 'DimensionLabel'
                  name: string
                } | null
              }>
            } | null
            purchaseProducts: Array<{
              __typename: 'PurchaseProduct'
              id: string
              product: { __typename: 'SalesProduct'; id: string; name: string }
              totalPrice: {
                __typename: 'PriceElements'
                amountVatIncluded: number
              }
            }>
            resource:
              | { __typename: 'ResourceEquipment'; id: string; name: string }
              | { __typename: 'ResourceHuman'; id: string; name: string }
              | { __typename: 'ResourceProperty'; id: string; name: string }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  name: string
                  parentResource: {
                    __typename: 'ResourceProperty'
                    id: string
                    name: string
                  }
                }
              | null
            tasks: Array<{
              __typename: 'Task'
              description: string | null
              dueDate: string | null
              dueTime: string | null
              id: string
              number: number
              assignee:
                | { __typename: 'User'; email: string | null; id: string }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
              purchaseProducts: Array<{
                __typename: 'PurchaseProduct'
                id: string
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                  items: Array<{
                    __typename: 'SalesProductItem'
                    id: string
                    unitPrice: {
                      __typename: 'UnitPrice'
                      id: string
                      price: number
                      vat: {
                        __typename: 'VatCode'
                        id: string
                        vatRate: number
                      }
                    }
                  }>
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amountVatIncluded: number
                }
              }>
            }>
          }>
        } | null
        sales: {
          __typename: 'Sales'
          estimatedParticipants: number | null
          id: string
          name: string | null
          orderNumber: number | null
          type: SalesType
          customer: {
            __typename: 'CustomerReference'
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  customerNumber: string
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    name: string | null
                  }
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  person: {
                    __typename: 'CustomerPersonData'
                    firstName: string
                    lastName: string
                  }
                }
          } | null
          facet: {
            __typename: 'SalesFacet'
            abbreviation: string
            color: string
            id: string
            name: string
          }
          seller: {
            __typename: 'Seller'
            id: string
            name: string
            shortName: string | null
          } | null
        }
        dimensions: {
          __typename: 'DimensionReference'
          selection: Array<{
            __typename: 'DimensionSelectionItem'
            selectedLabel: { __typename: 'DimensionLabel'; name: string } | null
          }>
        } | null
        purchaseProducts: Array<{
          __typename: 'PurchaseProduct'
          id: string
          product: { __typename: 'SalesProduct'; id: string; name: string }
          totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
        }>
        resource:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              parentResource: {
                __typename: 'ResourceProperty'
                id: string
                name: string
              }
            }
          | null
        tasks: Array<{
          __typename: 'Task'
          description: string | null
          dueDate: string | null
          dueTime: string | null
          id: string
          number: number
          assignee:
            | { __typename: 'User'; email: string | null; id: string }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
          purchaseProducts: Array<{
            __typename: 'PurchaseProduct'
            id: string
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
            }
            product: {
              __typename: 'SalesProduct'
              id: string
              name: string
              items: Array<{
                __typename: 'SalesProductItem'
                id: string
                unitPrice: {
                  __typename: 'UnitPrice'
                  id: string
                  price: number
                  vat: { __typename: 'VatCode'; id: string; vatRate: number }
                }
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amountVatIncluded: number
            }
          }>
        }>
      }>
    }>
  }
}

export type UseTaskListQueryVariables = Exact<{
  input: TasksInput
}>

export type UseTaskListQuery = {
  __typename: 'Query'
  tasks: Array<{
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    number: number
    assignee:
      | { __typename: 'User'; email: string | null; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      attributes: { __typename: 'PurchaseProductAttributes'; quantity: number }
      product: {
        __typename: 'SalesProduct'
        id: string
        name: string
        items: Array<{
          __typename: 'SalesProductItem'
          id: string
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
        }>
      }
      totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
    }>
    reservation: { __typename: 'ResourceReservation'; id: string } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        customer:
          | {
              __typename: 'CustomerOrganization'
              customerNumber: string
              organization: {
                __typename: 'CustomerOrganizationData'
                name: string | null
              }
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              person: {
                __typename: 'CustomerPersonData'
                firstName: string
                lastName: string
              }
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        abbreviation: string
        color: string
        id: string
        name: string
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
    }
  }>
}

export type PacketMealsListingCustomerFragment = {
  __typename: 'CustomerReference'
  customer:
    | {
        __typename: 'CustomerOrganization'
        id: string
        organization: {
          __typename: 'CustomerOrganizationData'
          businessId: string | null
          name: string | null
        }
      }
    | {
        __typename: 'CustomerPerson'
        id: string
        person: {
          __typename: 'CustomerPersonData'
          firstName: string
          lastName: string
        }
      }
}

export type PacketMealsListingQueryVariables = Exact<{
  input: ScheduledMealsInput
}>

export type PacketMealsListingQuery = {
  __typename: 'Query'
  scheduledMeals: Array<{
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    totalQuantity: number
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    participants: Array<{
      __typename: 'SalesParticipant'
      allergies: Array<string>
      allergyDescription: string | null
      diets: Array<string>
      firstName: string
      lastName: string
      id: string
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
      } | null
    }>
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
      } | null
    }>
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        customer:
          | {
              __typename: 'CustomerOrganization'
              id: string
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
            }
          | {
              __typename: 'CustomerPerson'
              id: string
              person: {
                __typename: 'CustomerPersonData'
                firstName: string
                lastName: string
              }
            }
      } | null
      estimatedDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
      event: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
        estimatedDates: {
          __typename: 'LocalDateRange'
          end: string
          start: string
        } | null
        facet: {
          __typename: 'SalesFacet'
          abbreviation: string
          color: string
          id: string
          name: string
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
          }
        }
      } | null
      facet: {
        __typename: 'SalesFacet'
        abbreviation: string
        color: string
        id: string
        name: string
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }
    }
  }>
}

export type RoomReservationsListingSalesFragment = {
  __typename: 'Sales'
  id: string
  name: string | null
  orderNumber: number | null
  type: SalesType
  customer: {
    __typename: 'CustomerReference'
    customer:
      | {
          __typename: 'CustomerOrganization'
          customerNumber: string
          id: string
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          id: string
          person: {
            __typename: 'CustomerPersonData'
            firstName: string
            lastName: string
          }
        }
  } | null
}

export type RoomReservationsListingParticipantRoomsFragment = {
  __typename: 'ParticipantRoom'
  id: string
  participant: {
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    firstName: string
    gender: Gender | null
    id: string
    lastName: string
    nationality: string | null
    ageCategory: {
      __typename: 'AgeCategory'
      key: string
      name: string
      shortName: string
    } | null
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        customer:
          | {
              __typename: 'CustomerOrganization'
              customerNumber: string
              id: string
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              id: string
              person: {
                __typename: 'CustomerPersonData'
                firstName: string
                lastName: string
              }
            }
      } | null
    }
  }
  request: {
    __typename: 'ParticipantRoomRequest'
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  }
}

export type RoomReservationsListingRequestFragment = {
  __typename: 'RoomRequest'
  beds: number
  extraBeds: number
  info: string | null
  checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
  checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  features: Array<{
    __typename: 'RoomLabel'
    icon: string | null
    id: string
    name: string
    shortName: string
  }>
  room: { __typename: 'Room'; id: string }
}

export type RoomReservationsListingRoomFragment = {
  __typename: 'Room'
  beds: number
  extraBeds: number
  id: string
  number: string
  building: { __typename: 'Building'; id: string; name: string }
  status: {
    __typename: 'RoomStatus'
    cleaningStatus: RoomCleaningStatus | null
  }
}

export type RoomReservationsListingRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  participantRooms: Array<{
    __typename: 'ParticipantRoom'
    id: string
    participant: {
      __typename: 'SalesParticipant'
      accommodationRequest: string | null
      additionalInfo: string | null
      age: number | null
      firstName: string
      gender: Gender | null
      id: string
      lastName: string
      nationality: string | null
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
      } | null
      birthday: {
        __typename: 'Birthday'
        date: number | null
        month: number | null
        year: number
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
      }
    }
    request: {
      __typename: 'ParticipantRoomRequest'
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    }
  }>
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: { __typename: 'Room'; id: string }
  }
}

export type RoomReservationsListingQueryVariables = Exact<{
  input: ReservationSearchInput
}>

export type RoomReservationsListingQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    rooms: Array<{
      __typename: 'Room'
      beds: number
      extraBeds: number
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
      }
    }>
  }
  accommodationRoomReservations: {
    __typename: 'RoomReservationsPayload'
    arriving: Array<{
      __typename: 'RoomReservation'
      id: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: { __typename: 'Room'; id: string }
      }
    }>
    departing: Array<{
      __typename: 'RoomReservation'
      id: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: { __typename: 'Room'; id: string }
      }
    }>
    present: Array<{
      __typename: 'RoomReservation'
      id: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: { __typename: 'Room'; id: string }
      }
    }>
  }
}

export type RoomReservationsListingSalesPajulahtiFragment = {
  __typename: 'Sales'
  id: string
  name: string | null
  orderNumber: number | null
  type: SalesType
  customer: {
    __typename: 'CustomerReference'
    customer:
      | {
          __typename: 'CustomerOrganization'
          customerNumber: string
          id: string
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          id: string
          person: {
            __typename: 'CustomerPersonData'
            firstName: string
            lastName: string
          }
        }
  } | null
}

export type RoomReservationsListingParticipantRoomsPajulahtiFragment = {
  __typename: 'ParticipantRoom'
  id: string
  participant: {
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    firstName: string
    gender: Gender | null
    id: string
    lastName: string
    nationality: string | null
    ageCategory: {
      __typename: 'AgeCategory'
      key: string
      name: string
      shortName: string
    } | null
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        customer:
          | {
              __typename: 'CustomerOrganization'
              customerNumber: string
              id: string
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              id: string
              person: {
                __typename: 'CustomerPersonData'
                firstName: string
                lastName: string
              }
            }
      } | null
    }
  }
  request: {
    __typename: 'ParticipantRoomRequest'
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  }
}

export type RoomReservationsListingRequestPajulahtiFragment = {
  __typename: 'RoomRequest'
  beds: number
  extraBeds: number
  info: string | null
  checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
  checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  features: Array<{
    __typename: 'RoomLabel'
    icon: string | null
    id: string
    name: string
    shortName: string
  }>
}

export type RoomReservationsListingRoomReservationPajulahtiFragment = {
  __typename: 'RoomReservation'
  id: string
  nextInRoom: {
    __typename: 'RoomReservation'
    id: string
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
      }
    }
    participantRooms: Array<{
      __typename: 'ParticipantRoom'
      id: string
      participant: {
        __typename: 'SalesParticipant'
        accommodationRequest: string | null
        additionalInfo: string | null
        age: number | null
        firstName: string
        gender: Gender | null
        id: string
        lastName: string
        nationality: string | null
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          name: string
          shortName: string
        } | null
        birthday: {
          __typename: 'Birthday'
          date: number | null
          month: number | null
          year: number
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          name: string | null
          orderNumber: number | null
          type: SalesType
          customer: {
            __typename: 'CustomerReference'
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  customerNumber: string
                  id: string
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  id: string
                  person: {
                    __typename: 'CustomerPersonData'
                    firstName: string
                    lastName: string
                  }
                }
          } | null
        }
      }
      request: {
        __typename: 'ParticipantRoomRequest'
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      }
    }>
    request: {
      __typename: 'RoomRequest'
      beds: number
      extraBeds: number
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
    }
  } | null
  participantRooms: Array<{
    __typename: 'ParticipantRoom'
    id: string
    participant: {
      __typename: 'SalesParticipant'
      accommodationRequest: string | null
      additionalInfo: string | null
      age: number | null
      firstName: string
      gender: Gender | null
      id: string
      lastName: string
      nationality: string | null
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
      } | null
      birthday: {
        __typename: 'Birthday'
        date: number | null
        month: number | null
        year: number
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                customerNumber: string
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
      }
    }
    request: {
      __typename: 'ParticipantRoomRequest'
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    }
  }>
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    room: {
      __typename: 'Room'
      beds: number
      extraBeds: number
      floor: number | null
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
      }
    }
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
  }
}

export type RoomReservationsListingPajulahtiQueryVariables = Exact<{
  input: ReservationSearchInput
}>

export type RoomReservationsListingPajulahtiQuery = {
  __typename: 'Query'
  accommodationRoomReservations: {
    __typename: 'RoomReservationsPayload'
    arriving: Array<{
      __typename: 'RoomReservation'
      id: string
      nextInRoom: {
        __typename: 'RoomReservation'
        id: string
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        participantRooms: Array<{
          __typename: 'ParticipantRoom'
          id: string
          participant: {
            __typename: 'SalesParticipant'
            accommodationRequest: string | null
            additionalInfo: string | null
            age: number | null
            firstName: string
            gender: Gender | null
            id: string
            lastName: string
            nationality: string | null
            ageCategory: {
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
            } | null
            birthday: {
              __typename: 'Birthday'
              date: number | null
              month: number | null
              year: number
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      customerNumber: string
                      id: string
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      id: string
                      person: {
                        __typename: 'CustomerPersonData'
                        firstName: string
                        lastName: string
                      }
                    }
              } | null
            }
          }
          request: {
            __typename: 'ParticipantRoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          }
        }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
        }
      } | null
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          floor: number | null
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
          }
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
      }
    }>
    departing: Array<{
      __typename: 'RoomReservation'
      id: string
      nextInRoom: {
        __typename: 'RoomReservation'
        id: string
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        participantRooms: Array<{
          __typename: 'ParticipantRoom'
          id: string
          participant: {
            __typename: 'SalesParticipant'
            accommodationRequest: string | null
            additionalInfo: string | null
            age: number | null
            firstName: string
            gender: Gender | null
            id: string
            lastName: string
            nationality: string | null
            ageCategory: {
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
            } | null
            birthday: {
              __typename: 'Birthday'
              date: number | null
              month: number | null
              year: number
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      customerNumber: string
                      id: string
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      id: string
                      person: {
                        __typename: 'CustomerPersonData'
                        firstName: string
                        lastName: string
                      }
                    }
              } | null
            }
          }
          request: {
            __typename: 'ParticipantRoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          }
        }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
        }
      } | null
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          accommodationRequest: string | null
          additionalInfo: string | null
          age: number | null
          firstName: string
          gender: Gender | null
          id: string
          lastName: string
          nationality: string | null
          ageCategory: {
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
          } | null
          birthday: {
            __typename: 'Birthday'
            date: number | null
            month: number | null
            year: number
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    customerNumber: string
                    id: string
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    id: string
                    person: {
                      __typename: 'CustomerPersonData'
                      firstName: string
                      lastName: string
                    }
                  }
            } | null
          }
        }
        request: {
          __typename: 'ParticipantRoomRequest'
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        }
      }>
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        room: {
          __typename: 'Room'
          beds: number
          extraBeds: number
          floor: number | null
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
          }
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
      }
    }>
  }
}

export type UseSalesAccommodationCountsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UseSalesAccommodationCountsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        status: {
          __typename: 'AccommodationReservationStatus'
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            beds: number
            extraBeds: number
            rooms: number
          }
        }
      }>
    }
  }
}

export type UseSalesArrivalsCountsForDateQueryVariables = Exact<{
  input: SalesForDateInput
  date: Scalars['LocalDate']
}>

export type UseSalesArrivalsCountsForDateQuery = {
  __typename: 'Query'
  salesForDate: Array<{
    __typename: 'Sales'
    id: string
    type: SalesType
    visitStats: {
      __typename: 'VisitStats'
      arriving: {
        __typename: 'RoomsAndParticipants'
        beds: number
        extraBeds: number
      }
      departing: {
        __typename: 'RoomsAndParticipants'
        beds: number
        extraBeds: number
      }
    }
  }>
}

export type SalesForIsoWeekQueryVariables = Exact<{ [key: string]: never }>

export type SalesForIsoWeekQuery = {
  __typename: 'Query'
  sales: Array<{
    __typename: 'Sales'
    estimatedParticipants: number | null
    id: string
    name: string | null
    orderNumber: number | null
    resourceReservationCount: number
    type: SalesType
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            id: string
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }
    reservationDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      name: string
      abbreviation: string
      color: string
    }
  }>
}

export type UseSalesTaskCountsByAssigneeQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UseSalesTaskCountsByAssigneeQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
    }>
  }
}

export type ListingRoomFeatureFragment = {
  __typename: 'RoomLabel'
  icon: string | null
  id: string
  name: string
  shortName: string
}

export type MealFragment = {
  __typename: 'ScheduledMeal'
  duration: number | null
  id: string
  notes: string | null
  start: string
  status: ScheduledMealStatus
  location:
    | { __typename: 'ResourceEquipment'; id: string; name: string }
    | { __typename: 'ResourceHuman'; id: string; name: string }
    | { __typename: 'ResourceProperty'; id: string; name: string }
    | { __typename: 'ResourcePropertyNested'; id: string; name: string }
    | { __typename: 'Restaurant'; id: string; name: string }
    | { __typename: 'Room'; id: string; name: string }
  meal: { __typename: 'Meal'; id: string; name: string }
  quantities: Array<{
    __typename: 'AgeCategoryQuantity'
    quantity: number
    ageCategory: {
      __typename: 'AgeCategory'
      key: string
      name: string
      shortName: string
      sortOrder: number
    } | null
  }>
}

export type MealTypeFragment = {
  __typename: 'Meal'
  id: string
  name: string
  defaultStartTime: string | null
  defaultDuration: number | null
  defaultRestaurant: {
    __typename: 'Restaurant'
    id: string
    name: string
  } | null
}

export type RestaurantFragment = {
  __typename: 'Restaurant'
  id: string
  name: string
}

export type MealsBySalesQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type MealsBySalesQuery = {
  __typename: 'Query'
  scheduledMealsBySales: Array<{
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }>
}

export type MealsConfigQueryVariables = Exact<{ [key: string]: never }>

export type MealsConfigQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    ageCategories: Array<{
      __typename: 'AgeCategory'
      key: string
      name: string
      shortName: string
      sortOrder: number
    }>
    meals: Array<{
      __typename: 'Meal'
      id: string
      name: string
      defaultStartTime: string | null
      defaultDuration: number | null
      defaultRestaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
      } | null
    }>
    restaurants: Array<{ __typename: 'Restaurant'; id: string; name: string }>
  }
}

export type CreateMealMutationVariables = Exact<{
  input: ScheduledMealCreateInput
}>

export type CreateMealMutation = {
  __typename: 'Mutation'
  scheduledMealCreate: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }
}

export type DeleteMealMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteMealMutation = {
  __typename: 'Mutation'
  scheduledMealDelete: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  } | null
}

export type ResetMealMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ResetMealMutation = {
  __typename: 'Mutation'
  scheduledMealResetDefaults: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }
}

export type RestoreMealMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RestoreMealMutation = {
  __typename: 'Mutation'
  scheduledMealRestore: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }
}

export type SetMealNotesMutationVariables = Exact<{
  id: Scalars['ID']
  input: ScheduledMealSetNotes
}>

export type SetMealNotesMutation = {
  __typename: 'Mutation'
  scheduledMealSetNotes: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }
}

export type SetMealQuantityMutationVariables = Exact<{
  id: Scalars['ID']
  input: ScheduledMealSetQuantity
}>

export type SetMealQuantityMutation = {
  __typename: 'Mutation'
  scheduledMealSetQuantity: {
    __typename: 'ScheduledMeal'
    duration: number | null
    id: string
    notes: string | null
    start: string
    status: ScheduledMealStatus
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
    meal: { __typename: 'Meal'; id: string; name: string }
    quantities: Array<{
      __typename: 'AgeCategoryQuantity'
      quantity: number
      ageCategory: {
        __typename: 'AgeCategory'
        key: string
        name: string
        shortName: string
        sortOrder: number
      } | null
    }>
  }
}

export type UpdateMealMutationVariables = Exact<{
  input: ScheduledMealUpdateInput
}>

export type UpdateMealMutation = {
  __typename: 'Mutation'
  scheduledMealUpdate: {
    __typename: 'ScheduledMealUpdatePayload'
    scheduledMeals: Array<{
      __typename: 'ScheduledMeal'
      duration: number | null
      id: string
      notes: string | null
      start: string
      status: ScheduledMealStatus
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
      meal: { __typename: 'Meal'; id: string; name: string }
      quantities: Array<{
        __typename: 'AgeCategoryQuantity'
        quantity: number
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          name: string
          shortName: string
          sortOrder: number
        } | null
      }>
    }>
  }
}

export type InvoiceCostingQueryVariables = Exact<{
  input: Scalars['ID']
}>

export type InvoiceCostingQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    config: { __typename: 'Config'; marginalTax: FeatureStatus }
  }
  invoice: {
    __typename: 'Invoice'
    totalWithCommission: number
    invoiceDate: string | null
    dueDate: string | null
    id: string
    invoiceNumber: string | null
    referenceNumber: string | null
    invoiceType: InvoiceType
    paymentType: InvoicePaymentType
    order: {
      __typename: 'Order'
      id: string
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        commissionRate: number
        searchDates: {
          __typename: 'LocalDateRange'
          start: string
          end: string
        } | null
      }
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      customerNumber: string | null
      name: string | null
      businessId: string | null
      phone: string | null
      email: string | null
      contactPerson: string | null
      type: CustomerType | null
    } | null
    lifecycle: { __typename: 'InvoiceLifecycle'; state: InvoiceState }
    seller: { __typename: 'Seller'; id: string; name: string } | null
    paymentInfo: {
      __typename: 'PaymentInfo'
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    payments: Array<{
      __typename: 'Payment'
      id: string
      amount: number
      type: PaymentType
      date: string
      number: string
      details:
        | { __typename: 'PaymentCashDetails' }
        | { __typename: 'PaymentCreditCardDetails' }
        | { __typename: 'PaymentGiftCardDetails' }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | { __typename: 'PaymentReferenceTransferDetails' }
        | { __typename: 'PaymentRefundDetails' }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
    }>
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      dates: {
        __typename: 'OrderPacketDates'
        startDate: string
        startTime: string | null
        endDate: string
        endTime: string | null
      } | null
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        id: string
        name: string
        internalCost: number | null
        internalPurchase: number | null
        quantity: number
        commissionMode: CommissionMode
        marginalTax: {
          __typename: 'MarginalTax'
          marginalTaxRate: number
          active: boolean
        } | null
        unitPrice: {
          __typename: 'UnitPrice'
          price: number
          priceVatIncluded: number
          priceVatExcluded: number
          type: PriceType
          vat: {
            __typename: 'VatCode'
            id: string
            code: string
            vatRate: number
          }
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
    }>
  }
}

export type ConfirmationFragment = {
  __typename: 'OrderConfirmation'
  id: string
  type: OrderPhaseType
  version: number
  document: {
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  } | null
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'OrderConfirmationLifecycle'
    state: OrderConfirmationState
    validatedActions: Array<{
      __typename: 'OrderConfirmationActionValidated'
      action: OrderConfirmationAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
  totals: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type ConfirmationInfoFragment = {
  __typename: 'OrderConfirmation'
  id: string
  type: OrderPhaseType
  version: number
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'OrderConfirmationLifecycle'
    state: OrderConfirmationState
    validatedActions: Array<{
      __typename: 'OrderConfirmationActionValidated'
      action: OrderConfirmationAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
  totals: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type InvoiceFragment = {
  __typename: 'Invoice'
  dueDate: string | null
  dueDateDuration: number | null
  dueDateDurationOptions: Array<number>
  freeText: string | null
  id: string
  invoiceDate: string | null
  invoiceNumber: string | null
  paymentType: InvoicePaymentType
  referenceNumber: string | null
  sellerContact: string | null
  type: OrderPhaseType
  valueDate: string | null
  version: number
  document: {
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  } | null
  items: Array<{
    __typename: 'OrderPacket'
    id: string
    name: string
    quantity: number
    packetTotal: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
    products: Array<{
      __typename: 'OrderProduct'
      code: string | null
      id: string
      name: string
      quantity: number
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
      } | null
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: { __typename: 'VatCode'; id: string; vatRate: number }
      }
      validationStatus: {
        __typename: 'OrderProductValidationStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }
    }>
    source: {
      __typename: 'OrderPacketSource'
      type: OrderPacketSourceType
      purchase: {
        __typename: 'PurchaseProduct'
        id: string
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        product: { __typename: 'SalesProduct'; id: string }
      } | null
    }
  }>
  payments: Array<{
    __typename: 'Payment'
    amount: number
    date: string
    id: string
    number: string
    source: PaymentSource
    state: PaymentState
    type: PaymentType
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    externalPayment: {
      __typename: 'ExternalPaymentDetails'
      errorCode: string | null
      errorMessage: string | null
      externalId: string | null
      referer: string | null
      sourceId: string | null
    } | null
    details:
      | {
          __typename: 'PaymentCashDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentCreditCardDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentGiftCardDetails'
          code: string
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentOtherDetails'
          author: string | null
          invoiceReference: string | null
          notes: string
          salesReference: string | null
          paymentType: {
            __typename: 'OtherPaymentType'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentReferenceTransferDetails'
          reference: string
          notes: string
          source: string
        }
      | {
          __typename: 'PaymentRefundDetails'
          refund: { __typename: 'Invoice'; invoiceNumber: string | null }
        }
      | {
          __typename: 'PaymentReservationFeeDetails'
          reservationFee: {
            __typename: 'Invoice'
            invoiceNumber: string | null
          }
        }
      | {
          __typename: 'PaymentVoucherDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
          type: { __typename: 'VoucherProvider'; id: string; name: string }
        }
      | null
    lifecycle: {
      __typename: 'PaymentLifecycle'
      validatedActions: Array<{
        __typename: 'PaymentActionValidated'
        action: PaymentOperation
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
  }>
  auditLog: {
    __typename: 'InvoiceAuditLog'
    bookkeepingTransferred: string | null
    createdAt: string
    invoiceAccepted: string | null
    updatedAt: string
  }
  config: {
    __typename: 'InvoiceConfig'
    packetProducts: Visibility
    participantInfo: Visibility
  }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'InvoiceLifecycle'
    isChecked: boolean
    state: InvoiceState
    validatedActions: Array<{
      __typename: 'InvoiceActionValidated'
      action: InvoiceAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    status: PaymentStatus
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  refundDetails: {
    __typename: 'RefundDetails'
    action: RefundAction
    originalInvoice: { __typename: 'Invoice'; id: string } | null
  } | null
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
    productsConfig: {
      __typename: 'SellerProducts'
      advanceProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
      serviceFeeProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
    }
  } | null
}

export type InvoiceInfoFragment = {
  __typename: 'Invoice'
  dueDate: string | null
  dueDateDuration: number | null
  dueDateDurationOptions: Array<number>
  freeText: string | null
  id: string
  invoiceDate: string | null
  invoiceNumber: string | null
  paymentType: InvoicePaymentType
  referenceNumber: string | null
  sellerContact: string | null
  type: OrderPhaseType
  valueDate: string | null
  version: number
  auditLog: {
    __typename: 'InvoiceAuditLog'
    bookkeepingTransferred: string | null
    createdAt: string
    invoiceAccepted: string | null
    updatedAt: string
  }
  config: {
    __typename: 'InvoiceConfig'
    packetProducts: Visibility
    participantInfo: Visibility
  }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'InvoiceLifecycle'
    isChecked: boolean
    state: InvoiceState
    validatedActions: Array<{
      __typename: 'InvoiceActionValidated'
      action: InvoiceAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    status: PaymentStatus
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  refundDetails: {
    __typename: 'RefundDetails'
    action: RefundAction
    originalInvoice: { __typename: 'Invoice'; id: string } | null
  } | null
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
    productsConfig: {
      __typename: 'SellerProducts'
      advanceProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
      serviceFeeProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
    }
  } | null
}

export type InvoicePaymentInfoFragment = {
  __typename: 'Invoice'
  id: string
  auditLog: {
    __typename: 'InvoiceAuditLog'
    bookkeepingTransferred: string | null
    createdAt: string
    invoiceAccepted: string | null
    updatedAt: string
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    status: PaymentStatus
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type InvoiceWithPaymentsFragment = {
  __typename: 'Invoice'
  dueDate: string | null
  dueDateDuration: number | null
  dueDateDurationOptions: Array<number>
  freeText: string | null
  id: string
  invoiceDate: string | null
  invoiceNumber: string | null
  paymentType: InvoicePaymentType
  referenceNumber: string | null
  sellerContact: string | null
  type: OrderPhaseType
  valueDate: string | null
  version: number
  payments: Array<{
    __typename: 'Payment'
    amount: number
    date: string
    id: string
    number: string
    source: PaymentSource
    state: PaymentState
    type: PaymentType
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    externalPayment: {
      __typename: 'ExternalPaymentDetails'
      errorCode: string | null
      errorMessage: string | null
      externalId: string | null
      referer: string | null
      sourceId: string | null
    } | null
    details:
      | {
          __typename: 'PaymentCashDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentCreditCardDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentGiftCardDetails'
          code: string
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentOtherDetails'
          author: string | null
          invoiceReference: string | null
          notes: string
          salesReference: string | null
          paymentType: {
            __typename: 'OtherPaymentType'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentReferenceTransferDetails'
          reference: string
          notes: string
          source: string
        }
      | {
          __typename: 'PaymentRefundDetails'
          refund: { __typename: 'Invoice'; invoiceNumber: string | null }
        }
      | {
          __typename: 'PaymentReservationFeeDetails'
          reservationFee: {
            __typename: 'Invoice'
            invoiceNumber: string | null
          }
        }
      | {
          __typename: 'PaymentVoucherDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
          type: { __typename: 'VoucherProvider'; id: string; name: string }
        }
      | null
    lifecycle: {
      __typename: 'PaymentLifecycle'
      validatedActions: Array<{
        __typename: 'PaymentActionValidated'
        action: PaymentOperation
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
  }>
  auditLog: {
    __typename: 'InvoiceAuditLog'
    bookkeepingTransferred: string | null
    createdAt: string
    invoiceAccepted: string | null
    updatedAt: string
  }
  config: {
    __typename: 'InvoiceConfig'
    packetProducts: Visibility
    participantInfo: Visibility
  }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'InvoiceLifecycle'
    isChecked: boolean
    state: InvoiceState
    validatedActions: Array<{
      __typename: 'InvoiceActionValidated'
      action: InvoiceAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    status: PaymentStatus
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  refundDetails: {
    __typename: 'RefundDetails'
    action: RefundAction
    originalInvoice: { __typename: 'Invoice'; id: string } | null
  } | null
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
    productsConfig: {
      __typename: 'SellerProducts'
      advanceProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
      serviceFeeProducts: Array<
        | {
            __typename: 'ProductTitleAccommodation'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleBasic'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitleCalendar'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
        | {
            __typename: 'ProductTitlePacketMeal'
            id: string
            name: string
            quantityUnit: QuantityUnit
            unitPrice: number
            vatRate: number
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }
      >
    }
  } | null
}

export type OfferFragment = {
  __typename: 'Offer'
  id: string
  type: OrderPhaseType
  validTo: string | null
  validToDuration: number | null
  validToDurationOptions: Array<number>
  version: number
  document: {
    __typename: 'Document'
    downloadedAt: string | null
    fileStatus: DocumentFileStatus | null
    id: string
    language: string | null
    name: string
    status: DocumentStatus
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    file: {
      __typename: 'FileInfo'
      objectName: string
      fileName: string
      mimeType: string
      created: string
    } | null
    template: {
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }
    templateOptions: Array<{
      __typename: 'DocumentTemplate'
      documentName: string
      documentType: DocumentType
      filesAllowed: boolean
      id: string
      name: string
    }>
    userAttributes: Array<{
      __typename: 'DocumentUserAttribute'
      active: boolean
      editorValue: string | null
      id: string
      key: string
      name: string
      templateValue: string | null
    }>
  } | null
  auditLog: {
    __typename: 'OfferAuditLog'
    createdAt: string
    updatedAt: string
  }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'OfferLifecycle'
    state: OfferState
    validatedActions: Array<{
      __typename: 'OfferActionValidated'
      action: OfferAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
  totals: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type OfferInfoFragment = {
  __typename: 'Offer'
  id: string
  type: OrderPhaseType
  validTo: string | null
  validToDuration: number | null
  validToDurationOptions: Array<number>
  version: number
  auditLog: {
    __typename: 'OfferAuditLog'
    createdAt: string
    updatedAt: string
  }
  customer: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  } | null
  lifecycle: {
    __typename: 'OfferLifecycle'
    state: OfferState
    validatedActions: Array<{
      __typename: 'OfferActionValidated'
      action: OfferAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
  totals: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type OrderFragment = {
  __typename: 'Order'
  id: string
  name: string | null
  orderNumber: number
  sortOrder: number
  items: Array<{
    __typename: 'OrderPacket'
    id: string
    name: string
    quantity: number
    packetTotal: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
    products: Array<{
      __typename: 'OrderProduct'
      code: string | null
      id: string
      name: string
      quantity: number
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
      } | null
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: { __typename: 'VatCode'; id: string; vatRate: number }
      }
      validationStatus: {
        __typename: 'OrderProductValidationStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }
    }>
    source: {
      __typename: 'OrderPacketSource'
      type: OrderPacketSourceType
      purchase: {
        __typename: 'PurchaseProduct'
        id: string
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        product: { __typename: 'SalesProduct'; id: string }
      } | null
    }
  }>
  confirmations: Array<{
    __typename: 'OrderConfirmation'
    id: string
    type: OrderPhaseType
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OrderConfirmationLifecycle'
      state: OrderConfirmationState
      validatedActions: Array<{
        __typename: 'OrderConfirmationActionValidated'
        action: OrderConfirmationAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  invoices: Array<{
    __typename: 'Invoice'
    dueDate: string | null
    dueDateDuration: number | null
    dueDateDurationOptions: Array<number>
    freeText: string | null
    id: string
    invoiceDate: string | null
    invoiceNumber: string | null
    paymentType: InvoicePaymentType
    referenceNumber: string | null
    sellerContact: string | null
    type: OrderPhaseType
    valueDate: string | null
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        code: string | null
        id: string
        name: string
        quantity: number
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
        } | null
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: { __typename: 'VatCode'; id: string; vatRate: number }
        }
        validationStatus: {
          __typename: 'OrderProductValidationStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }
      }>
      source: {
        __typename: 'OrderPacketSource'
        type: OrderPacketSourceType
        purchase: {
          __typename: 'PurchaseProduct'
          id: string
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          product: { __typename: 'SalesProduct'; id: string }
        } | null
      }
    }>
    payments: Array<{
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }>
    auditLog: {
      __typename: 'InvoiceAuditLog'
      bookkeepingTransferred: string | null
      createdAt: string
      invoiceAccepted: string | null
      updatedAt: string
    }
    config: {
      __typename: 'InvoiceConfig'
      packetProducts: Visibility
      participantInfo: Visibility
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'InvoiceLifecycle'
      isChecked: boolean
      state: InvoiceState
      validatedActions: Array<{
        __typename: 'InvoiceActionValidated'
        action: InvoiceAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      status: PaymentStatus
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    refundDetails: {
      __typename: 'RefundDetails'
      action: RefundAction
      originalInvoice: { __typename: 'Invoice'; id: string } | null
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
      productsConfig: {
        __typename: 'SellerProducts'
        advanceProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
        serviceFeeProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
      }
    } | null
  }>
  offers: Array<{
    __typename: 'Offer'
    id: string
    type: OrderPhaseType
    validTo: string | null
    validToDuration: number | null
    validToDurationOptions: Array<number>
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    auditLog: {
      __typename: 'OfferAuditLog'
      createdAt: string
      updatedAt: string
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OfferLifecycle'
      state: OfferState
      validatedActions: Array<{
        __typename: 'OfferActionValidated'
        action: OfferAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  customer: {
    __typename: 'OrderCustomer'
    detailsLocked: boolean
    details: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    }
    inheritedCustomer: {
      __typename: 'CustomerReference'
      customer:
        | { __typename: 'CustomerOrganization'; customerNumber: string }
        | { __typename: 'CustomerPerson'; customerNumber: string }
    } | null
  } | null
  lifecycle: {
    __typename: 'OrderLifecycle'
    state: OrderState
    validatedActions: Array<{
      __typename: 'OrderActionValidated'
      action: OrderAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  sales: {
    __typename: 'Sales'
    commissionRate: number
    id: string
    type: SalesType
    facet: {
      __typename: 'SalesFacet'
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
}

export type OrderCustomerFragment = {
  __typename: 'OrderCustomer'
  detailsLocked: boolean
  details: {
    __typename: 'ParsedCustomerDetails'
    businessId: string | null
    contactPerson: string | null
    customerNumber: string | null
    email: string | null
    name: string | null
    phone: string | null
    type: CustomerType | null
    address: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }
  inheritedCustomer: {
    __typename: 'CustomerReference'
    customer:
      | { __typename: 'CustomerOrganization'; customerNumber: string }
      | { __typename: 'CustomerPerson'; customerNumber: string }
  } | null
}

export type OrderInfoFragment = {
  __typename: 'Order'
  id: string
  name: string | null
  orderNumber: number
  sortOrder: number
  confirmations: Array<{
    __typename: 'OrderConfirmation'
    id: string
    type: OrderPhaseType
    version: number
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OrderConfirmationLifecycle'
      state: OrderConfirmationState
      validatedActions: Array<{
        __typename: 'OrderConfirmationActionValidated'
        action: OrderConfirmationAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  invoices: Array<{
    __typename: 'Invoice'
    dueDate: string | null
    dueDateDuration: number | null
    dueDateDurationOptions: Array<number>
    freeText: string | null
    id: string
    invoiceDate: string | null
    invoiceNumber: string | null
    paymentType: InvoicePaymentType
    referenceNumber: string | null
    sellerContact: string | null
    type: OrderPhaseType
    valueDate: string | null
    version: number
    auditLog: {
      __typename: 'InvoiceAuditLog'
      bookkeepingTransferred: string | null
      createdAt: string
      invoiceAccepted: string | null
      updatedAt: string
    }
    config: {
      __typename: 'InvoiceConfig'
      packetProducts: Visibility
      participantInfo: Visibility
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'InvoiceLifecycle'
      isChecked: boolean
      state: InvoiceState
      validatedActions: Array<{
        __typename: 'InvoiceActionValidated'
        action: InvoiceAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      status: PaymentStatus
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    refundDetails: {
      __typename: 'RefundDetails'
      action: RefundAction
      originalInvoice: { __typename: 'Invoice'; id: string } | null
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
      productsConfig: {
        __typename: 'SellerProducts'
        advanceProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
        serviceFeeProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
      }
    } | null
  }>
  offers: Array<{
    __typename: 'Offer'
    id: string
    type: OrderPhaseType
    validTo: string | null
    validToDuration: number | null
    validToDurationOptions: Array<number>
    version: number
    auditLog: {
      __typename: 'OfferAuditLog'
      createdAt: string
      updatedAt: string
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OfferLifecycle'
      state: OfferState
      validatedActions: Array<{
        __typename: 'OfferActionValidated'
        action: OfferAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  customer: {
    __typename: 'OrderCustomer'
    detailsLocked: boolean
    details: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    }
    inheritedCustomer: {
      __typename: 'CustomerReference'
      customer:
        | { __typename: 'CustomerOrganization'; customerNumber: string }
        | { __typename: 'CustomerPerson'; customerNumber: string }
    } | null
  } | null
  items: Array<{
    __typename: 'OrderPacket'
    id: string
    name: string
    quantity: number
    packetTotal: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
    products: Array<{
      __typename: 'OrderProduct'
      code: string | null
      id: string
      name: string
      quantity: number
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
      } | null
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: { __typename: 'VatCode'; id: string; vatRate: number }
      }
      validationStatus: {
        __typename: 'OrderProductValidationStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }
    }>
    source: {
      __typename: 'OrderPacketSource'
      type: OrderPacketSourceType
      purchase: {
        __typename: 'PurchaseProduct'
        id: string
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        product: { __typename: 'SalesProduct'; id: string }
      } | null
    }
  }>
  lifecycle: {
    __typename: 'OrderLifecycle'
    state: OrderState
    validatedActions: Array<{
      __typename: 'OrderActionValidated'
      action: OrderAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  sales: {
    __typename: 'Sales'
    commissionRate: number
    id: string
    type: SalesType
    facet: {
      __typename: 'SalesFacet'
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
}

export type OrderPacketFragment = {
  __typename: 'OrderPacket'
  id: string
  name: string
  quantity: number
  packetTotal: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
  products: Array<{
    __typename: 'OrderProduct'
    code: string | null
    id: string
    name: string
    quantity: number
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
    } | null
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; id: string; vatRate: number }
    }
    validationStatus: {
      __typename: 'OrderProductValidationStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
    }
  }>
  source: {
    __typename: 'OrderPacketSource'
    type: OrderPacketSourceType
    purchase: {
      __typename: 'PurchaseProduct'
      id: string
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      product: { __typename: 'SalesProduct'; id: string }
    } | null
  }
}

export type OrderProductFragment = {
  __typename: 'OrderProduct'
  code: string | null
  id: string
  name: string
  quantity: number
  marginalTax: { __typename: 'MarginalTax'; active: boolean; id: string } | null
  totalPrice: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
  unitPrice: {
    __typename: 'UnitPrice'
    id: string
    price: number
    type: PriceType
    vat: { __typename: 'VatCode'; id: string; vatRate: number }
  }
  validationStatus: {
    __typename: 'OrderProductValidationStatus'
    issues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
  }
}

export type OrderShortInfoFragment = {
  __typename: 'Order'
  id: string
  name: string | null
  orderNumber: number
  sortOrder: number
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  customer: {
    __typename: 'OrderCustomer'
    detailsLocked: boolean
    details: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    }
    inheritedCustomer: {
      __typename: 'CustomerReference'
      customer:
        | { __typename: 'CustomerOrganization'; customerNumber: string }
        | { __typename: 'CustomerPerson'; customerNumber: string }
    } | null
  } | null
  items: Array<{
    __typename: 'OrderPacket'
    id: string
    name: string
    quantity: number
    packetTotal: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
    products: Array<{
      __typename: 'OrderProduct'
      code: string | null
      id: string
      name: string
      quantity: number
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
      } | null
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: { __typename: 'VatCode'; id: string; vatRate: number }
      }
      validationStatus: {
        __typename: 'OrderProductValidationStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }
    }>
    source: {
      __typename: 'OrderPacketSource'
      type: OrderPacketSourceType
      purchase: {
        __typename: 'PurchaseProduct'
        id: string
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        product: { __typename: 'SalesProduct'; id: string }
      } | null
    }
  }>
  lifecycle: {
    __typename: 'OrderLifecycle'
    state: OrderState
    validatedActions: Array<{
      __typename: 'OrderActionValidated'
      action: OrderAction
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
  paymentInfo: {
    __typename: 'PaymentInfo'
    paid: number
    payableAmount: number
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
  sales: {
    __typename: 'Sales'
    commissionRate: number
    id: string
    type: SalesType
    facet: {
      __typename: 'SalesFacet'
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
  }
  seller: {
    __typename: 'Seller'
    id: string
    name: string
    shortName: string | null
  } | null
}

export type PaymentFragment = {
  __typename: 'Payment'
  amount: number
  date: string
  id: string
  number: string
  source: PaymentSource
  state: PaymentState
  type: PaymentType
  auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
  externalPayment: {
    __typename: 'ExternalPaymentDetails'
    errorCode: string | null
    errorMessage: string | null
    externalId: string | null
    referer: string | null
    sourceId: string | null
  } | null
  details:
    | {
        __typename: 'PaymentCashDetails'
        notes: string
        pointOfSale: {
          __typename: 'PointOfSale'
          id: string
          name: string
        } | null
      }
    | {
        __typename: 'PaymentCreditCardDetails'
        notes: string
        pointOfSale: {
          __typename: 'PointOfSale'
          id: string
          name: string
        } | null
      }
    | {
        __typename: 'PaymentGiftCardDetails'
        code: string
        notes: string
        pointOfSale: {
          __typename: 'PointOfSale'
          id: string
          name: string
        } | null
      }
    | {
        __typename: 'PaymentOtherDetails'
        author: string | null
        invoiceReference: string | null
        notes: string
        salesReference: string | null
        paymentType: {
          __typename: 'OtherPaymentType'
          id: string
          name: string
        } | null
      }
    | {
        __typename: 'PaymentReferenceTransferDetails'
        reference: string
        notes: string
        source: string
      }
    | {
        __typename: 'PaymentRefundDetails'
        refund: { __typename: 'Invoice'; invoiceNumber: string | null }
      }
    | {
        __typename: 'PaymentReservationFeeDetails'
        reservationFee: { __typename: 'Invoice'; invoiceNumber: string | null }
      }
    | {
        __typename: 'PaymentVoucherDetails'
        notes: string
        pointOfSale: {
          __typename: 'PointOfSale'
          id: string
          name: string
        } | null
        type: { __typename: 'VoucherProvider'; id: string; name: string }
      }
    | null
  lifecycle: {
    __typename: 'PaymentLifecycle'
    validatedActions: Array<{
      __typename: 'PaymentActionValidated'
      action: PaymentOperation
      valid: boolean
      issues: Array<{ __typename: 'ValidationIssue'; code: number }>
    }>
  }
}

export type PaymentPointOfSaleFragment = {
  __typename: 'PointOfSale'
  id: string
  name: string
}

export type PaymentCashDetailsFragment = {
  __typename: 'PaymentCashDetails'
  notes: string
  pointOfSale: { __typename: 'PointOfSale'; id: string; name: string } | null
}

export type PaymentCreditCardDetailsFragment = {
  __typename: 'PaymentCreditCardDetails'
  notes: string
  pointOfSale: { __typename: 'PointOfSale'; id: string; name: string } | null
}

export type PaymentGiftCardDetailsFragment = {
  __typename: 'PaymentGiftCardDetails'
  code: string
  notes: string
  pointOfSale: { __typename: 'PointOfSale'; id: string; name: string } | null
}

export type PaymentOtherDetailsFragment = {
  __typename: 'PaymentOtherDetails'
  author: string | null
  invoiceReference: string | null
  notes: string
  salesReference: string | null
  paymentType: {
    __typename: 'OtherPaymentType'
    id: string
    name: string
  } | null
}

export type PaymentReferenceTransferDetailsFragment = {
  __typename: 'PaymentReferenceTransferDetails'
  reference: string
  notes: string
  source: string
}

export type PaymentRefundDetailsFragment = {
  __typename: 'PaymentRefundDetails'
  refund: { __typename: 'Invoice'; invoiceNumber: string | null }
}

export type PaymentReservationFeeDetailsFragment = {
  __typename: 'PaymentReservationFeeDetails'
  reservationFee: { __typename: 'Invoice'; invoiceNumber: string | null }
}

export type PaymentVoucherDetailsFragment = {
  __typename: 'PaymentVoucherDetails'
  notes: string
  pointOfSale: { __typename: 'PointOfSale'; id: string; name: string } | null
  type: { __typename: 'VoucherProvider'; id: string; name: string }
}

export type AcceptInvoiceMutationVariables = Exact<{
  input: OrderInvoiceAcceptInput
}>

export type AcceptInvoiceMutation = {
  __typename: 'Mutation'
  orderInvoiceAccept: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type AddCashPaymentMutationVariables = Exact<{
  input: PaymentCashAddInput
}>

export type AddCashPaymentMutation = {
  __typename: 'Mutation'
  paymentCashAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type AddCreditCardPaymentMutationVariables = Exact<{
  input: PaymentCreditCardAddInput
}>

export type AddCreditCardPaymentMutation = {
  __typename: 'Mutation'
  paymentCreditCardAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type AddGiftCardPaymentMutationVariables = Exact<{
  input: PaymentGiftCardAddInput
}>

export type AddGiftCardPaymentMutation = {
  __typename: 'Mutation'
  paymentGiftCardAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type AddOtherPaymentMutationVariables = Exact<{
  input: PaymentOtherInput
}>

export type AddOtherPaymentMutation = {
  __typename: 'Mutation'
  paymentOtherAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type AddReferencePaymentMutationVariables = Exact<{
  input: PaymentReferenceAddInput
}>

export type AddReferencePaymentMutation = {
  __typename: 'Mutation'
  paymentReferenceAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type AddVoucherPaymentMutationVariables = Exact<{
  input: PaymentVoucherAddInput
}>

export type AddVoucherPaymentMutation = {
  __typename: 'Mutation'
  paymentVoucherAdd: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type CancelExternalPaymentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelExternalPaymentMutation = {
  __typename: 'Mutation'
  externalPaymentCancel: {
    __typename: 'PaymentCancelPayload'
    cancelInitiated: boolean
    id: string
  }
}

export type CreateAdvanceMutationVariables = Exact<{
  input: OrderCreateAdvanceInput
}>

export type CreateAdvanceMutation = {
  __typename: 'Mutation'
  orderCreateAdvance: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type CreateConfirmationMutationVariables = Exact<{
  input: OrderCreateConfirmationInput
}>

export type CreateConfirmationMutation = {
  __typename: 'Mutation'
  orderCreateConfirmation: {
    __typename: 'OrderConfirmationPayload'
    confirmation: {
      __typename: 'OrderConfirmation'
      id: string
      type: OrderPhaseType
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderConfirmationLifecycle'
        state: OrderConfirmationState
        validatedActions: Array<{
          __typename: 'OrderConfirmationActionValidated'
          action: OrderConfirmationAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
  }
}

export type CreateInvoiceMutationVariables = Exact<{
  input: OrderCreateInvoiceInput
}>

export type CreateInvoiceMutation = {
  __typename: 'Mutation'
  orderCreateInvoice: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type CreateOfferMutationVariables = Exact<{
  input: OrderCreateOfferInput
}>

export type CreateOfferMutation = {
  __typename: 'Mutation'
  orderCreateOffer: {
    __typename: 'OrderOfferPayload'
    offer: {
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
  }
}

export type CreateOrderMutationVariables = Exact<{
  input: OrderCreateInput
}>

export type CreateOrderMutation = {
  __typename: 'Mutation'
  orderCreate: {
    __typename: 'Order'
    id: string
    name: string | null
    orderNumber: number
    sortOrder: number
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        code: string | null
        id: string
        name: string
        quantity: number
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
        } | null
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: { __typename: 'VatCode'; id: string; vatRate: number }
        }
        validationStatus: {
          __typename: 'OrderProductValidationStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }
      }>
      source: {
        __typename: 'OrderPacketSource'
        type: OrderPacketSourceType
        purchase: {
          __typename: 'PurchaseProduct'
          id: string
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          product: { __typename: 'SalesProduct'; id: string }
        } | null
      }
    }>
    confirmations: Array<{
      __typename: 'OrderConfirmation'
      id: string
      type: OrderPhaseType
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderConfirmationLifecycle'
        state: OrderConfirmationState
        validatedActions: Array<{
          __typename: 'OrderConfirmationActionValidated'
          action: OrderConfirmationAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    invoices: Array<{
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }>
    offers: Array<{
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'OrderCustomer'
      detailsLocked: boolean
      details: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      }
      inheritedCustomer: {
        __typename: 'CustomerReference'
        customer:
          | { __typename: 'CustomerOrganization'; customerNumber: string }
          | { __typename: 'CustomerPerson'; customerNumber: string }
      } | null
    } | null
    lifecycle: {
      __typename: 'OrderLifecycle'
      state: OrderState
      validatedActions: Array<{
        __typename: 'OrderActionValidated'
        action: OrderAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      type: SalesType
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
  }
}

export type CreateRefundMutationVariables = Exact<{
  input: OrderCreateRefundInput
}>

export type CreateRefundMutation = {
  __typename: 'Mutation'
  orderCreateRefund: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type RefreshPaymentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RefreshPaymentMutation = {
  __typename: 'Mutation'
  paymentRefresh: {
    __typename: 'Payment'
    amount: number
    date: string
    id: string
    number: string
    source: PaymentSource
    state: PaymentState
    type: PaymentType
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    externalPayment: {
      __typename: 'ExternalPaymentDetails'
      errorCode: string | null
      errorMessage: string | null
      externalId: string | null
      referer: string | null
      sourceId: string | null
    } | null
    details:
      | {
          __typename: 'PaymentCashDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentCreditCardDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentGiftCardDetails'
          code: string
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentOtherDetails'
          author: string | null
          invoiceReference: string | null
          notes: string
          salesReference: string | null
          paymentType: {
            __typename: 'OtherPaymentType'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentReferenceTransferDetails'
          reference: string
          notes: string
          source: string
        }
      | {
          __typename: 'PaymentRefundDetails'
          refund: { __typename: 'Invoice'; invoiceNumber: string | null }
        }
      | {
          __typename: 'PaymentReservationFeeDetails'
          reservationFee: {
            __typename: 'Invoice'
            invoiceNumber: string | null
          }
        }
      | {
          __typename: 'PaymentVoucherDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
          type: { __typename: 'VoucherProvider'; id: string; name: string }
        }
      | null
    lifecycle: {
      __typename: 'PaymentLifecycle'
      validatedActions: Array<{
        __typename: 'PaymentActionValidated'
        action: PaymentOperation
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
  } | null
}

export type RemoveConfirmationMutationVariables = Exact<{
  input: OrderDeleteConfirmationInput
}>

export type RemoveConfirmationMutation = {
  __typename: 'Mutation'
  orderDeleteConfirmation: {
    __typename: 'OrderDeleteConfirmationPayload'
    confirmationId: string
    deleted: boolean
  }
}

export type RemoveInvoiceMutationVariables = Exact<{
  input: OrderDeleteInvoiceInput
}>

export type RemoveInvoiceMutation = {
  __typename: 'Mutation'
  orderDeleteInvoice: {
    __typename: 'OrderDeleteInvoicePayload'
    deleted: boolean
    invoiceId: string
  }
}

export type RemoveManualPaymentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoveManualPaymentMutation = {
  __typename: 'Mutation'
  manualPaymentDelete: {
    __typename: 'PaymentDeletePayload'
    id: string
    deleted: boolean
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    } | null
  }
}

export type RemoveOfferMutationVariables = Exact<{
  input: OrderDeleteOfferInput
}>

export type RemoveOfferMutation = {
  __typename: 'Mutation'
  orderDeleteOffer: {
    __typename: 'OrderDeleteOfferPayload'
    deleted: boolean
    offerId: string
  }
}

export type RemoveOrderMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoveOrderMutation = {
  __typename: 'Mutation'
  orderDelete: {
    __typename: 'OrderDeletePayload'
    deleted: boolean
    id: string
  }
}

export type SetOrderCustomerMutationVariables = Exact<{
  input: OrderSetCustomerInput
}>

export type SetOrderCustomerMutation = {
  __typename: 'Mutation'
  orderSetCustomer: {
    __typename: 'OrderSetCustomerPayload'
    order: {
      __typename: 'Order'
      id: string
      name: string | null
      orderNumber: number
      sortOrder: number
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      confirmations: Array<{
        __typename: 'OrderConfirmation'
        id: string
        type: OrderPhaseType
        version: number
        document: {
          __typename: 'Document'
          downloadedAt: string | null
          fileStatus: DocumentFileStatus | null
          id: string
          language: string | null
          name: string
          status: DocumentStatus
          attachments: Array<{
            __typename: 'FileItemInfo'
            created: string
            etag: string | null
            fileName: string
            lastModified: string
            mimeType: string
            id: string
            size: number
            info: {
              __typename: 'FileIdentifier'
              fileName: string
              folder: string
              ownerType: FileOwner
              ownerId: string
            }
          }>
          file: {
            __typename: 'FileInfo'
            objectName: string
            fileName: string
            mimeType: string
            created: string
          } | null
          template: {
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }
          templateOptions: Array<{
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }>
          userAttributes: Array<{
            __typename: 'DocumentUserAttribute'
            active: boolean
            editorValue: string | null
            id: string
            key: string
            name: string
            templateValue: string | null
          }>
        } | null
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        customer: {
          __typename: 'ParsedCustomerDetails'
          businessId: string | null
          contactPerson: string | null
          customerNumber: string | null
          email: string | null
          name: string | null
          phone: string | null
          type: CustomerType | null
          address: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        } | null
        lifecycle: {
          __typename: 'OrderConfirmationLifecycle'
          state: OrderConfirmationState
          validatedActions: Array<{
            __typename: 'OrderConfirmationActionValidated'
            action: OrderConfirmationAction
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
        seller: {
          __typename: 'Seller'
          id: string
          name: string
          shortName: string | null
        } | null
        totals: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      invoices: Array<{
        __typename: 'Invoice'
        dueDate: string | null
        dueDateDuration: number | null
        dueDateDurationOptions: Array<number>
        freeText: string | null
        id: string
        invoiceDate: string | null
        invoiceNumber: string | null
        paymentType: InvoicePaymentType
        referenceNumber: string | null
        sellerContact: string | null
        type: OrderPhaseType
        valueDate: string | null
        version: number
        document: {
          __typename: 'Document'
          downloadedAt: string | null
          fileStatus: DocumentFileStatus | null
          id: string
          language: string | null
          name: string
          status: DocumentStatus
          attachments: Array<{
            __typename: 'FileItemInfo'
            created: string
            etag: string | null
            fileName: string
            lastModified: string
            mimeType: string
            id: string
            size: number
            info: {
              __typename: 'FileIdentifier'
              fileName: string
              folder: string
              ownerType: FileOwner
              ownerId: string
            }
          }>
          file: {
            __typename: 'FileInfo'
            objectName: string
            fileName: string
            mimeType: string
            created: string
          } | null
          template: {
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }
          templateOptions: Array<{
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }>
          userAttributes: Array<{
            __typename: 'DocumentUserAttribute'
            active: boolean
            editorValue: string | null
            id: string
            key: string
            name: string
            templateValue: string | null
          }>
        } | null
        items: Array<{
          __typename: 'OrderPacket'
          id: string
          name: string
          quantity: number
          packetTotal: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          products: Array<{
            __typename: 'OrderProduct'
            code: string | null
            id: string
            name: string
            quantity: number
            marginalTax: {
              __typename: 'MarginalTax'
              active: boolean
              id: string
            } | null
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
            unitPrice: {
              __typename: 'UnitPrice'
              id: string
              price: number
              type: PriceType
              vat: { __typename: 'VatCode'; id: string; vatRate: number }
            }
            validationStatus: {
              __typename: 'OrderProductValidationStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }
          }>
          source: {
            __typename: 'OrderPacketSource'
            type: OrderPacketSourceType
            purchase: {
              __typename: 'PurchaseProduct'
              id: string
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              product: { __typename: 'SalesProduct'; id: string }
            } | null
          }
        }>
        payments: Array<{
          __typename: 'Payment'
          amount: number
          date: string
          id: string
          number: string
          source: PaymentSource
          state: PaymentState
          type: PaymentType
          auditLog: {
            __typename: 'AuditLog'
            createdAt: string
            updatedAt: string
          }
          externalPayment: {
            __typename: 'ExternalPaymentDetails'
            errorCode: string | null
            errorMessage: string | null
            externalId: string | null
            referer: string | null
            sourceId: string | null
          } | null
          details:
            | {
                __typename: 'PaymentCashDetails'
                notes: string
                pointOfSale: {
                  __typename: 'PointOfSale'
                  id: string
                  name: string
                } | null
              }
            | {
                __typename: 'PaymentCreditCardDetails'
                notes: string
                pointOfSale: {
                  __typename: 'PointOfSale'
                  id: string
                  name: string
                } | null
              }
            | {
                __typename: 'PaymentGiftCardDetails'
                code: string
                notes: string
                pointOfSale: {
                  __typename: 'PointOfSale'
                  id: string
                  name: string
                } | null
              }
            | {
                __typename: 'PaymentOtherDetails'
                author: string | null
                invoiceReference: string | null
                notes: string
                salesReference: string | null
                paymentType: {
                  __typename: 'OtherPaymentType'
                  id: string
                  name: string
                } | null
              }
            | {
                __typename: 'PaymentReferenceTransferDetails'
                reference: string
                notes: string
                source: string
              }
            | {
                __typename: 'PaymentRefundDetails'
                refund: { __typename: 'Invoice'; invoiceNumber: string | null }
              }
            | {
                __typename: 'PaymentReservationFeeDetails'
                reservationFee: {
                  __typename: 'Invoice'
                  invoiceNumber: string | null
                }
              }
            | {
                __typename: 'PaymentVoucherDetails'
                notes: string
                pointOfSale: {
                  __typename: 'PointOfSale'
                  id: string
                  name: string
                } | null
                type: {
                  __typename: 'VoucherProvider'
                  id: string
                  name: string
                }
              }
            | null
          lifecycle: {
            __typename: 'PaymentLifecycle'
            validatedActions: Array<{
              __typename: 'PaymentActionValidated'
              action: PaymentOperation
              valid: boolean
              issues: Array<{ __typename: 'ValidationIssue'; code: number }>
            }>
          }
        }>
        auditLog: {
          __typename: 'InvoiceAuditLog'
          bookkeepingTransferred: string | null
          createdAt: string
          invoiceAccepted: string | null
          updatedAt: string
        }
        config: {
          __typename: 'InvoiceConfig'
          packetProducts: Visibility
          participantInfo: Visibility
        }
        customer: {
          __typename: 'ParsedCustomerDetails'
          businessId: string | null
          contactPerson: string | null
          customerNumber: string | null
          email: string | null
          name: string | null
          phone: string | null
          type: CustomerType | null
          address: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        } | null
        lifecycle: {
          __typename: 'InvoiceLifecycle'
          isChecked: boolean
          state: InvoiceState
          validatedActions: Array<{
            __typename: 'InvoiceActionValidated'
            action: InvoiceAction
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
        paymentInfo: {
          __typename: 'PaymentInfo'
          paid: number
          payableAmount: number
          status: PaymentStatus
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        }
        refundDetails: {
          __typename: 'RefundDetails'
          action: RefundAction
          originalInvoice: { __typename: 'Invoice'; id: string } | null
        } | null
        seller: {
          __typename: 'Seller'
          id: string
          name: string
          shortName: string | null
          productsConfig: {
            __typename: 'SellerProducts'
            advanceProducts: Array<
              | {
                  __typename: 'ProductTitleAccommodation'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitleBasic'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitleCalendar'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitlePacketMeal'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
            >
            serviceFeeProducts: Array<
              | {
                  __typename: 'ProductTitleAccommodation'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitleBasic'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitleCalendar'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
              | {
                  __typename: 'ProductTitlePacketMeal'
                  id: string
                  name: string
                  quantityUnit: QuantityUnit
                  unitPrice: number
                  vatRate: number
                  catalog: { __typename: 'Catalog'; id: string; name: string }
                }
            >
          }
        } | null
      }>
      offers: Array<{
        __typename: 'Offer'
        id: string
        type: OrderPhaseType
        validTo: string | null
        validToDuration: number | null
        validToDurationOptions: Array<number>
        version: number
        document: {
          __typename: 'Document'
          downloadedAt: string | null
          fileStatus: DocumentFileStatus | null
          id: string
          language: string | null
          name: string
          status: DocumentStatus
          attachments: Array<{
            __typename: 'FileItemInfo'
            created: string
            etag: string | null
            fileName: string
            lastModified: string
            mimeType: string
            id: string
            size: number
            info: {
              __typename: 'FileIdentifier'
              fileName: string
              folder: string
              ownerType: FileOwner
              ownerId: string
            }
          }>
          file: {
            __typename: 'FileInfo'
            objectName: string
            fileName: string
            mimeType: string
            created: string
          } | null
          template: {
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }
          templateOptions: Array<{
            __typename: 'DocumentTemplate'
            documentName: string
            documentType: DocumentType
            filesAllowed: boolean
            id: string
            name: string
          }>
          userAttributes: Array<{
            __typename: 'DocumentUserAttribute'
            active: boolean
            editorValue: string | null
            id: string
            key: string
            name: string
            templateValue: string | null
          }>
        } | null
        auditLog: {
          __typename: 'OfferAuditLog'
          createdAt: string
          updatedAt: string
        }
        customer: {
          __typename: 'ParsedCustomerDetails'
          businessId: string | null
          contactPerson: string | null
          customerNumber: string | null
          email: string | null
          name: string | null
          phone: string | null
          type: CustomerType | null
          address: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        } | null
        lifecycle: {
          __typename: 'OfferLifecycle'
          state: OfferState
          validatedActions: Array<{
            __typename: 'OfferActionValidated'
            action: OfferAction
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
        seller: {
          __typename: 'Seller'
          id: string
          name: string
          shortName: string | null
        } | null
        totals: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'OrderCustomer'
        detailsLocked: boolean
        details: {
          __typename: 'ParsedCustomerDetails'
          businessId: string | null
          contactPerson: string | null
          customerNumber: string | null
          email: string | null
          name: string | null
          phone: string | null
          type: CustomerType | null
          address: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        }
        inheritedCustomer: {
          __typename: 'CustomerReference'
          customer:
            | { __typename: 'CustomerOrganization'; customerNumber: string }
            | { __typename: 'CustomerPerson'; customerNumber: string }
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderLifecycle'
        state: OrderState
        validatedActions: Array<{
          __typename: 'OrderActionValidated'
          action: OrderAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        type: SalesType
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
    }
  }
}

export type SetPaymentTypeMutationVariables = Exact<{
  input: OrderInvoiceSetPaymentTypeInput
}>

export type SetPaymentTypeMutation = {
  __typename: 'Mutation'
  orderInvoiceSetPaymentType: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type TriggerConfirmationActionMutationVariables = Exact<{
  input: OrderConfirmationActionInput
}>

export type TriggerConfirmationActionMutation = {
  __typename: 'Mutation'
  orderConfirmationAction: {
    __typename: 'OrderConfirmationPayload'
    confirmation: {
      __typename: 'OrderConfirmation'
      id: string
      type: OrderPhaseType
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderConfirmationLifecycle'
        state: OrderConfirmationState
        validatedActions: Array<{
          __typename: 'OrderConfirmationActionValidated'
          action: OrderConfirmationAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
  }
}

export type TriggerOfferActionMutationVariables = Exact<{
  input: OfferPerformActionInput
}>

export type TriggerOfferActionMutation = {
  __typename: 'Mutation'
  orderOfferAction: {
    __typename: 'OfferPerformActionPayload'
    offer: {
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
  }
}

export type UpdateInvoiceMutationVariables = Exact<{
  input: OrderUpdateInvoiceInput
}>

export type UpdateInvoiceMutation = {
  __typename: 'Mutation'
  orderUpdateInvoice: {
    __typename: 'OrderInvoicePayload'
    invoice: {
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }
  }
}

export type UpdateManualPaymentMutationVariables = Exact<{
  input: PaymentUpdateInput
}>

export type UpdateManualPaymentMutation = {
  __typename: 'Mutation'
  manualPaymentUpdate: {
    __typename: 'PaymentPayload'
    invoice: {
      __typename: 'Invoice'
      id: string
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }
    payment: {
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }
  }
}

export type UpdateOfferMutationVariables = Exact<{
  input: OrderUpdateOfferInput
}>

export type UpdateOfferMutation = {
  __typename: 'Mutation'
  orderUpdateOffer: {
    __typename: 'OrderOfferPayload'
    offer: {
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
  }
}

export type UpdateOrderMutationVariables = Exact<{
  input: OrderUpdateInput
}>

export type UpdateOrderMutation = {
  __typename: 'Mutation'
  orderUpdate: {
    __typename: 'Order'
    id: string
    name: string | null
    orderNumber: number
    sortOrder: number
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        code: string | null
        id: string
        name: string
        quantity: number
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
        } | null
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: { __typename: 'VatCode'; id: string; vatRate: number }
        }
        validationStatus: {
          __typename: 'OrderProductValidationStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }
      }>
      source: {
        __typename: 'OrderPacketSource'
        type: OrderPacketSourceType
        purchase: {
          __typename: 'PurchaseProduct'
          id: string
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          product: { __typename: 'SalesProduct'; id: string }
        } | null
      }
    }>
    confirmations: Array<{
      __typename: 'OrderConfirmation'
      id: string
      type: OrderPhaseType
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderConfirmationLifecycle'
        state: OrderConfirmationState
        validatedActions: Array<{
          __typename: 'OrderConfirmationActionValidated'
          action: OrderConfirmationAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    invoices: Array<{
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          quantity: number
          marginalTax: {
            __typename: 'MarginalTax'
            active: boolean
            id: string
          } | null
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          unitPrice: {
            __typename: 'UnitPrice'
            id: string
            price: number
            type: PriceType
            vat: { __typename: 'VatCode'; id: string; vatRate: number }
          }
          validationStatus: {
            __typename: 'OrderProductValidationStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }
        }>
        source: {
          __typename: 'OrderPacketSource'
          type: OrderPacketSourceType
          purchase: {
            __typename: 'PurchaseProduct'
            id: string
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            product: { __typename: 'SalesProduct'; id: string }
          } | null
        }
      }>
      payments: Array<{
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      }>
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }>
    offers: Array<{
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'OrderCustomer'
      detailsLocked: boolean
      details: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      }
      inheritedCustomer: {
        __typename: 'CustomerReference'
        customer:
          | { __typename: 'CustomerOrganization'; customerNumber: string }
          | { __typename: 'CustomerPerson'; customerNumber: string }
      } | null
    } | null
    lifecycle: {
      __typename: 'OrderLifecycle'
      state: OrderState
      validatedActions: Array<{
        __typename: 'OrderActionValidated'
        action: OrderAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      type: SalesType
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
  }
}

export type ConfirmationByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConfirmationByIdQuery = {
  __typename: 'Query'
  orderConfirmation: {
    __typename: 'OrderConfirmation'
    id: string
    type: OrderPhaseType
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OrderConfirmationLifecycle'
      state: OrderConfirmationState
      validatedActions: Array<{
        __typename: 'OrderConfirmationActionValidated'
        action: OrderConfirmationAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type InvoiceByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type InvoiceByIdQuery = {
  __typename: 'Query'
  invoice: {
    __typename: 'Invoice'
    dueDate: string | null
    dueDateDuration: number | null
    dueDateDurationOptions: Array<number>
    freeText: string | null
    id: string
    invoiceDate: string | null
    invoiceNumber: string | null
    paymentType: InvoicePaymentType
    referenceNumber: string | null
    sellerContact: string | null
    type: OrderPhaseType
    valueDate: string | null
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        code: string | null
        id: string
        name: string
        quantity: number
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
        } | null
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: { __typename: 'VatCode'; id: string; vatRate: number }
        }
        validationStatus: {
          __typename: 'OrderProductValidationStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }
      }>
      source: {
        __typename: 'OrderPacketSource'
        type: OrderPacketSourceType
        purchase: {
          __typename: 'PurchaseProduct'
          id: string
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          product: { __typename: 'SalesProduct'; id: string }
        } | null
      }
    }>
    payments: Array<{
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }>
    auditLog: {
      __typename: 'InvoiceAuditLog'
      bookkeepingTransferred: string | null
      createdAt: string
      invoiceAccepted: string | null
      updatedAt: string
    }
    config: {
      __typename: 'InvoiceConfig'
      packetProducts: Visibility
      participantInfo: Visibility
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'InvoiceLifecycle'
      isChecked: boolean
      state: InvoiceState
      validatedActions: Array<{
        __typename: 'InvoiceActionValidated'
        action: InvoiceAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      status: PaymentStatus
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    refundDetails: {
      __typename: 'RefundDetails'
      action: RefundAction
      originalInvoice: { __typename: 'Invoice'; id: string } | null
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
      productsConfig: {
        __typename: 'SellerProducts'
        advanceProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
        serviceFeeProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
      }
    } | null
  }
}

export type InvoiceWithPaymentsByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type InvoiceWithPaymentsByIdQuery = {
  __typename: 'Query'
  invoice: {
    __typename: 'Invoice'
    dueDate: string | null
    dueDateDuration: number | null
    dueDateDurationOptions: Array<number>
    freeText: string | null
    id: string
    invoiceDate: string | null
    invoiceNumber: string | null
    paymentType: InvoicePaymentType
    referenceNumber: string | null
    sellerContact: string | null
    type: OrderPhaseType
    valueDate: string | null
    version: number
    payments: Array<{
      __typename: 'Payment'
      amount: number
      date: string
      id: string
      number: string
      source: PaymentSource
      state: PaymentState
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      externalPayment: {
        __typename: 'ExternalPaymentDetails'
        errorCode: string | null
        errorMessage: string | null
        externalId: string | null
        referer: string | null
        sourceId: string | null
      } | null
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            code: string
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentOtherDetails'
            author: string | null
            invoiceReference: string | null
            notes: string
            salesReference: string | null
            paymentType: {
              __typename: 'OtherPaymentType'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentReferenceTransferDetails'
            reference: string
            notes: string
            source: string
          }
        | {
            __typename: 'PaymentRefundDetails'
            refund: { __typename: 'Invoice'; invoiceNumber: string | null }
          }
        | {
            __typename: 'PaymentReservationFeeDetails'
            reservationFee: {
              __typename: 'Invoice'
              invoiceNumber: string | null
            }
          }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      lifecycle: {
        __typename: 'PaymentLifecycle'
        validatedActions: Array<{
          __typename: 'PaymentActionValidated'
          action: PaymentOperation
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
    }>
    auditLog: {
      __typename: 'InvoiceAuditLog'
      bookkeepingTransferred: string | null
      createdAt: string
      invoiceAccepted: string | null
      updatedAt: string
    }
    config: {
      __typename: 'InvoiceConfig'
      packetProducts: Visibility
      participantInfo: Visibility
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'InvoiceLifecycle'
      isChecked: boolean
      state: InvoiceState
      validatedActions: Array<{
        __typename: 'InvoiceActionValidated'
        action: InvoiceAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      status: PaymentStatus
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    refundDetails: {
      __typename: 'RefundDetails'
      action: RefundAction
      originalInvoice: { __typename: 'Invoice'; id: string } | null
    } | null
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
      productsConfig: {
        __typename: 'SellerProducts'
        advanceProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
        serviceFeeProducts: Array<
          | {
              __typename: 'ProductTitleAccommodation'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleBasic'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitleCalendar'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
          | {
              __typename: 'ProductTitlePacketMeal'
              id: string
              name: string
              quantityUnit: QuantityUnit
              unitPrice: number
              vatRate: number
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }
        >
      }
    } | null
  }
}

export type OfferByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OfferByIdQuery = {
  __typename: 'Query'
  offer: {
    __typename: 'Offer'
    id: string
    type: OrderPhaseType
    validTo: string | null
    validToDuration: number | null
    validToDurationOptions: Array<number>
    version: number
    document: {
      __typename: 'Document'
      downloadedAt: string | null
      fileStatus: DocumentFileStatus | null
      id: string
      language: string | null
      name: string
      status: DocumentStatus
      attachments: Array<{
        __typename: 'FileItemInfo'
        created: string
        etag: string | null
        fileName: string
        lastModified: string
        mimeType: string
        id: string
        size: number
        info: {
          __typename: 'FileIdentifier'
          fileName: string
          folder: string
          ownerType: FileOwner
          ownerId: string
        }
      }>
      file: {
        __typename: 'FileInfo'
        objectName: string
        fileName: string
        mimeType: string
        created: string
      } | null
      template: {
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }
      templateOptions: Array<{
        __typename: 'DocumentTemplate'
        documentName: string
        documentType: DocumentType
        filesAllowed: boolean
        id: string
        name: string
      }>
      userAttributes: Array<{
        __typename: 'DocumentUserAttribute'
        active: boolean
        editorValue: string | null
        id: string
        key: string
        name: string
        templateValue: string | null
      }>
    } | null
    auditLog: {
      __typename: 'OfferAuditLog'
      createdAt: string
      updatedAt: string
    }
    customer: {
      __typename: 'ParsedCustomerDetails'
      businessId: string | null
      contactPerson: string | null
      customerNumber: string | null
      email: string | null
      name: string | null
      phone: string | null
      type: CustomerType | null
      address: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    } | null
    lifecycle: {
      __typename: 'OfferLifecycle'
      state: OfferState
      validatedActions: Array<{
        __typename: 'OfferActionValidated'
        action: OfferAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
    totals: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type OrderLifecycleQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrderLifecycleQuery = {
  __typename: 'Query'
  order: {
    __typename: 'Order'
    lifecycle: {
      __typename: 'OrderLifecycle'
      state: OrderState
      validatedActions: Array<{
        __typename: 'OrderActionValidated'
        action: OrderAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
  }
}

export type OrdersQueryVariables = Exact<{
  ownerId: Scalars['ID']
}>

export type OrdersQuery = {
  __typename: 'Query'
  ordersBySales: Array<{
    __typename: 'Order'
    id: string
    name: string | null
    orderNumber: number
    sortOrder: number
    confirmations: Array<{
      __typename: 'OrderConfirmation'
      id: string
      type: OrderPhaseType
      version: number
      auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OrderConfirmationLifecycle'
        state: OrderConfirmationState
        validatedActions: Array<{
          __typename: 'OrderConfirmationActionValidated'
          action: OrderConfirmationAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    invoices: Array<{
      __typename: 'Invoice'
      dueDate: string | null
      dueDateDuration: number | null
      dueDateDurationOptions: Array<number>
      freeText: string | null
      id: string
      invoiceDate: string | null
      invoiceNumber: string | null
      paymentType: InvoicePaymentType
      referenceNumber: string | null
      sellerContact: string | null
      type: OrderPhaseType
      valueDate: string | null
      version: number
      auditLog: {
        __typename: 'InvoiceAuditLog'
        bookkeepingTransferred: string | null
        createdAt: string
        invoiceAccepted: string | null
        updatedAt: string
      }
      config: {
        __typename: 'InvoiceConfig'
        packetProducts: Visibility
        participantInfo: Visibility
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'InvoiceLifecycle'
        isChecked: boolean
        state: InvoiceState
        validatedActions: Array<{
          __typename: 'InvoiceActionValidated'
          action: InvoiceAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      paymentInfo: {
        __typename: 'PaymentInfo'
        paid: number
        payableAmount: number
        status: PaymentStatus
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
      refundDetails: {
        __typename: 'RefundDetails'
        action: RefundAction
        originalInvoice: { __typename: 'Invoice'; id: string } | null
      } | null
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
        productsConfig: {
          __typename: 'SellerProducts'
          advanceProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
          serviceFeeProducts: Array<
            | {
                __typename: 'ProductTitleAccommodation'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleBasic'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitleCalendar'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
            | {
                __typename: 'ProductTitlePacketMeal'
                id: string
                name: string
                quantityUnit: QuantityUnit
                unitPrice: number
                vatRate: number
                catalog: { __typename: 'Catalog'; id: string; name: string }
              }
          >
        }
      } | null
    }>
    offers: Array<{
      __typename: 'Offer'
      id: string
      type: OrderPhaseType
      validTo: string | null
      validToDuration: number | null
      validToDurationOptions: Array<number>
      version: number
      auditLog: {
        __typename: 'OfferAuditLog'
        createdAt: string
        updatedAt: string
      }
      customer: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      lifecycle: {
        __typename: 'OfferLifecycle'
        state: OfferState
        validatedActions: Array<{
          __typename: 'OfferActionValidated'
          action: OfferAction
          valid: boolean
          issues: Array<{ __typename: 'ValidationIssue'; code: number }>
        }>
      }
      seller: {
        __typename: 'Seller'
        id: string
        name: string
        shortName: string | null
      } | null
      totals: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    customer: {
      __typename: 'OrderCustomer'
      detailsLocked: boolean
      details: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      }
      inheritedCustomer: {
        __typename: 'CustomerReference'
        customer:
          | { __typename: 'CustomerOrganization'; customerNumber: string }
          | { __typename: 'CustomerPerson'; customerNumber: string }
      } | null
    } | null
    items: Array<{
      __typename: 'OrderPacket'
      id: string
      name: string
      quantity: number
      packetTotal: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
      products: Array<{
        __typename: 'OrderProduct'
        code: string | null
        id: string
        name: string
        quantity: number
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
        } | null
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: { __typename: 'VatCode'; id: string; vatRate: number }
        }
        validationStatus: {
          __typename: 'OrderProductValidationStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }
      }>
      source: {
        __typename: 'OrderPacketSource'
        type: OrderPacketSourceType
        purchase: {
          __typename: 'PurchaseProduct'
          id: string
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          product: { __typename: 'SalesProduct'; id: string }
        } | null
      }
    }>
    lifecycle: {
      __typename: 'OrderLifecycle'
      state: OrderState
      validatedActions: Array<{
        __typename: 'OrderActionValidated'
        action: OrderAction
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
    paymentInfo: {
      __typename: 'PaymentInfo'
      paid: number
      payableAmount: number
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      type: SalesType
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
    }
    seller: {
      __typename: 'Seller'
      id: string
      name: string
      shortName: string | null
    } | null
  }>
}

export type PaymentByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type PaymentByIdQuery = {
  __typename: 'Query'
  payment: {
    __typename: 'Payment'
    amount: number
    date: string
    id: string
    number: string
    source: PaymentSource
    state: PaymentState
    type: PaymentType
    auditLog: { __typename: 'AuditLog'; createdAt: string; updatedAt: string }
    externalPayment: {
      __typename: 'ExternalPaymentDetails'
      errorCode: string | null
      errorMessage: string | null
      externalId: string | null
      referer: string | null
      sourceId: string | null
    } | null
    details:
      | {
          __typename: 'PaymentCashDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentCreditCardDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentGiftCardDetails'
          code: string
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentOtherDetails'
          author: string | null
          invoiceReference: string | null
          notes: string
          salesReference: string | null
          paymentType: {
            __typename: 'OtherPaymentType'
            id: string
            name: string
          } | null
        }
      | {
          __typename: 'PaymentReferenceTransferDetails'
          reference: string
          notes: string
          source: string
        }
      | {
          __typename: 'PaymentRefundDetails'
          refund: { __typename: 'Invoice'; invoiceNumber: string | null }
        }
      | {
          __typename: 'PaymentReservationFeeDetails'
          reservationFee: {
            __typename: 'Invoice'
            invoiceNumber: string | null
          }
        }
      | {
          __typename: 'PaymentVoucherDetails'
          notes: string
          pointOfSale: {
            __typename: 'PointOfSale'
            id: string
            name: string
          } | null
          type: { __typename: 'VoucherProvider'; id: string; name: string }
        }
      | null
    lifecycle: {
      __typename: 'PaymentLifecycle'
      validatedActions: Array<{
        __typename: 'PaymentActionValidated'
        action: PaymentOperation
        valid: boolean
        issues: Array<{ __typename: 'ValidationIssue'; code: number }>
      }>
    }
  }
}

export type DocumentSubscriptionVariables = Exact<{
  input: DocumentFilterInput
}>

export type DocumentSubscription = {
  __typename: 'Subscription'
  document: {
    __typename: 'DocumentContextEvent'
    documentId: string
    event: {
      __typename: 'DocumentEvent'
      id: string
      type: EventType
      data: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
    }
  }
}

export type OrderSubscriptionVariables = Exact<{
  filter: OrderFilterInput
}>

export type OrderSubscription = {
  __typename: 'Subscription'
  order: {
    __typename: 'OrderContextEvent'
    orderId: string
    salesId: string
    event:
      | {
          __typename: 'OrderConfirmationEvent'
          id: string
          type: EventType
          confirmationData: {
            __typename: 'OrderConfirmation'
            id: string
            type: OrderPhaseType
            version: number
            auditLog: {
              __typename: 'AuditLog'
              createdAt: string
              updatedAt: string
            }
            customer: {
              __typename: 'ParsedCustomerDetails'
              businessId: string | null
              contactPerson: string | null
              customerNumber: string | null
              email: string | null
              name: string | null
              phone: string | null
              type: CustomerType | null
              address: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            } | null
            lifecycle: {
              __typename: 'OrderConfirmationLifecycle'
              state: OrderConfirmationState
              validatedActions: Array<{
                __typename: 'OrderConfirmationActionValidated'
                action: OrderConfirmationAction
                valid: boolean
                issues: Array<{ __typename: 'ValidationIssue'; code: number }>
              }>
            }
            seller: {
              __typename: 'Seller'
              id: string
              name: string
              shortName: string | null
            } | null
            totals: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'OrderEvent'
          id: string
          type: EventType
          orderData: {
            __typename: 'Order'
            id: string
            name: string | null
            orderNumber: number
            sortOrder: number
            auditLog: {
              __typename: 'AuditLog'
              createdAt: string
              updatedAt: string
            }
            customer: {
              __typename: 'OrderCustomer'
              detailsLocked: boolean
              details: {
                __typename: 'ParsedCustomerDetails'
                businessId: string | null
                contactPerson: string | null
                customerNumber: string | null
                email: string | null
                name: string | null
                phone: string | null
                type: CustomerType | null
                address: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }
              inheritedCustomer: {
                __typename: 'CustomerReference'
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      customerNumber: string
                    }
                  | { __typename: 'CustomerPerson'; customerNumber: string }
              } | null
            } | null
            items: Array<{
              __typename: 'OrderPacket'
              id: string
              name: string
              quantity: number
              packetTotal: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
              products: Array<{
                __typename: 'OrderProduct'
                code: string | null
                id: string
                name: string
                quantity: number
                marginalTax: {
                  __typename: 'MarginalTax'
                  active: boolean
                  id: string
                } | null
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
                unitPrice: {
                  __typename: 'UnitPrice'
                  id: string
                  price: number
                  type: PriceType
                  vat: { __typename: 'VatCode'; id: string; vatRate: number }
                }
                validationStatus: {
                  __typename: 'OrderProductValidationStatus'
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }
              }>
              source: {
                __typename: 'OrderPacketSource'
                type: OrderPacketSourceType
                purchase: {
                  __typename: 'PurchaseProduct'
                  id: string
                  link: {
                    __typename: 'PurchaseProductLink'
                    participant: {
                      __typename: 'SalesParticipant'
                      firstName: string
                      id: string
                      lastName: string
                    } | null
                    reservation: {
                      __typename: 'ResourceReservation'
                      id: string
                      resourceQuantity: number
                      resource:
                        | {
                            __typename: 'ResourceEquipment'
                            id: string
                            isPooled: boolean
                            name: string
                          }
                        | {
                            __typename: 'ResourceHuman'
                            id: string
                            isPooled: boolean
                            name: string
                          }
                        | {
                            __typename: 'ResourceProperty'
                            id: string
                            isPooled: boolean
                            name: string
                          }
                        | {
                            __typename: 'ResourcePropertyNested'
                            id: string
                            isPooled: boolean
                            name: string
                          }
                        | null
                    } | null
                    sales: {
                      __typename: 'Sales'
                      id: string
                      orderNumber: number | null
                      type: SalesType
                    }
                    task: {
                      __typename: 'Task'
                      id: string
                      assignee:
                        | { __typename: 'User' }
                        | { __typename: 'UserGroup'; id: string; name: string }
                        | null
                    } | null
                  }
                  product: { __typename: 'SalesProduct'; id: string }
                } | null
              }
            }>
            lifecycle: {
              __typename: 'OrderLifecycle'
              state: OrderState
              validatedActions: Array<{
                __typename: 'OrderActionValidated'
                action: OrderAction
                valid: boolean
                issues: Array<{ __typename: 'ValidationIssue'; code: number }>
              }>
            }
            paymentInfo: {
              __typename: 'PaymentInfo'
              paid: number
              payableAmount: number
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }
            sales: {
              __typename: 'Sales'
              commissionRate: number
              id: string
              type: SalesType
              facet: {
                __typename: 'SalesFacet'
                id: string
                features: Array<{
                  __typename: 'SalesFacetFeature'
                  feature: Feature
                }>
              }
            }
            seller: {
              __typename: 'Seller'
              id: string
              name: string
              shortName: string | null
            } | null
          } | null
        }
      | {
          __typename: 'OrderInvoiceEvent'
          id: string
          type: EventType
          invoiceData: {
            __typename: 'Invoice'
            dueDate: string | null
            dueDateDuration: number | null
            dueDateDurationOptions: Array<number>
            freeText: string | null
            id: string
            invoiceDate: string | null
            invoiceNumber: string | null
            paymentType: InvoicePaymentType
            referenceNumber: string | null
            sellerContact: string | null
            type: OrderPhaseType
            valueDate: string | null
            version: number
            auditLog: {
              __typename: 'InvoiceAuditLog'
              bookkeepingTransferred: string | null
              createdAt: string
              invoiceAccepted: string | null
              updatedAt: string
            }
            config: {
              __typename: 'InvoiceConfig'
              packetProducts: Visibility
              participantInfo: Visibility
            }
            customer: {
              __typename: 'ParsedCustomerDetails'
              businessId: string | null
              contactPerson: string | null
              customerNumber: string | null
              email: string | null
              name: string | null
              phone: string | null
              type: CustomerType | null
              address: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            } | null
            lifecycle: {
              __typename: 'InvoiceLifecycle'
              isChecked: boolean
              state: InvoiceState
              validatedActions: Array<{
                __typename: 'InvoiceActionValidated'
                action: InvoiceAction
                valid: boolean
                issues: Array<{ __typename: 'ValidationIssue'; code: number }>
              }>
            }
            paymentInfo: {
              __typename: 'PaymentInfo'
              paid: number
              payableAmount: number
              status: PaymentStatus
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }
            refundDetails: {
              __typename: 'RefundDetails'
              action: RefundAction
              originalInvoice: { __typename: 'Invoice'; id: string } | null
            } | null
            seller: {
              __typename: 'Seller'
              id: string
              name: string
              shortName: string | null
              productsConfig: {
                __typename: 'SellerProducts'
                advanceProducts: Array<
                  | {
                      __typename: 'ProductTitleAccommodation'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitleBasic'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitleCalendar'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitlePacketMeal'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                >
                serviceFeeProducts: Array<
                  | {
                      __typename: 'ProductTitleAccommodation'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitleBasic'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitleCalendar'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                  | {
                      __typename: 'ProductTitlePacketMeal'
                      id: string
                      name: string
                      quantityUnit: QuantityUnit
                      unitPrice: number
                      vatRate: number
                      catalog: {
                        __typename: 'Catalog'
                        id: string
                        name: string
                      }
                    }
                >
              }
            } | null
          } | null
        }
      | {
          __typename: 'OrderItemEvent'
          id: string
          type: EventType
          itemData:
            | { __typename: 'OrderPacket'; id: string }
            | { __typename: 'OrderProduct'; id: string }
            | null
        }
      | {
          __typename: 'OrderOfferEvent'
          id: string
          type: EventType
          offerData: {
            __typename: 'Offer'
            id: string
            type: OrderPhaseType
            validTo: string | null
            validToDuration: number | null
            validToDurationOptions: Array<number>
            version: number
            auditLog: {
              __typename: 'OfferAuditLog'
              createdAt: string
              updatedAt: string
            }
            customer: {
              __typename: 'ParsedCustomerDetails'
              businessId: string | null
              contactPerson: string | null
              customerNumber: string | null
              email: string | null
              name: string | null
              phone: string | null
              type: CustomerType | null
              address: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            } | null
            lifecycle: {
              __typename: 'OfferLifecycle'
              state: OfferState
              validatedActions: Array<{
                __typename: 'OfferActionValidated'
                action: OfferAction
                valid: boolean
                issues: Array<{ __typename: 'ValidationIssue'; code: number }>
              }>
            }
            seller: {
              __typename: 'Seller'
              id: string
              name: string
              shortName: string | null
            } | null
            totals: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
  }
}

export type PaymentSubscriptionVariables = Exact<{
  input: PaymentInputFilter
}>

export type PaymentSubscription = {
  __typename: 'Subscription'
  payment: {
    __typename: 'PaymentContextEvent'
    invoiceId: string | null
    orderId: string
    paymentId: string
    event: {
      __typename: 'PaymentEvent'
      id: string
      type: EventType
      data: {
        __typename: 'Payment'
        amount: number
        date: string
        id: string
        number: string
        source: PaymentSource
        state: PaymentState
        type: PaymentType
        auditLog: {
          __typename: 'AuditLog'
          createdAt: string
          updatedAt: string
        }
        externalPayment: {
          __typename: 'ExternalPaymentDetails'
          errorCode: string | null
          errorMessage: string | null
          externalId: string | null
          referer: string | null
          sourceId: string | null
        } | null
        details:
          | {
              __typename: 'PaymentCashDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentCreditCardDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentGiftCardDetails'
              code: string
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentOtherDetails'
              author: string | null
              invoiceReference: string | null
              notes: string
              salesReference: string | null
              paymentType: {
                __typename: 'OtherPaymentType'
                id: string
                name: string
              } | null
            }
          | {
              __typename: 'PaymentReferenceTransferDetails'
              reference: string
              notes: string
              source: string
            }
          | {
              __typename: 'PaymentRefundDetails'
              refund: { __typename: 'Invoice'; invoiceNumber: string | null }
            }
          | {
              __typename: 'PaymentReservationFeeDetails'
              reservationFee: {
                __typename: 'Invoice'
                invoiceNumber: string | null
              }
            }
          | {
              __typename: 'PaymentVoucherDetails'
              notes: string
              pointOfSale: {
                __typename: 'PointOfSale'
                id: string
                name: string
              } | null
              type: { __typename: 'VoucherProvider'; id: string; name: string }
            }
          | null
        lifecycle: {
          __typename: 'PaymentLifecycle'
          validatedActions: Array<{
            __typename: 'PaymentActionValidated'
            action: PaymentOperation
            valid: boolean
            issues: Array<{ __typename: 'ValidationIssue'; code: number }>
          }>
        }
      } | null
    }
  }
}

export type ParticipantVstFragment = {
  __typename: 'ParticipantVst'
  end: string | null
  included: boolean
  state: ParticipantVstState
  start: string | null
  vstDays: number | null
  lesson: {
    __typename: 'Lesson'
    fee: number | null
    hours: number | null
  } | null
}

export type ParticipantRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string }
    checkOut: { __typename: 'CheckOut'; date: string }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      id: string
      number: string
      building: { __typename: 'Building'; id: string; name: string }
      roomType: { __typename: 'RoomType'; id: string; name: string }
    }
  }
  roomTypeReservation: {
    __typename: 'RoomTypeReservation'
    request: {
      __typename: 'RoomTypeRequest'
      info: string | null
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
    }
  } | null
}

export type ParticipantRoomFragment = {
  __typename: 'ParticipantRoom'
  checkIn: string | null
  checkOut: string | null
  id: string
  roomReservation: {
    __typename: 'RoomReservation'
    id: string
    request: {
      __typename: 'RoomRequest'
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      room: {
        __typename: 'Room'
        id: string
        number: string
        building: { __typename: 'Building'; id: string; name: string }
        roomType: { __typename: 'RoomType'; id: string; name: string }
      }
    }
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      request: {
        __typename: 'RoomTypeRequest'
        info: string | null
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
      }
    } | null
  }
}

export type TargetFragment = {
  __typename: 'AccommodationTarget'
  default: boolean
  id: string
  name: string
  private: boolean
  sortOrder: number
  accommodationGroup: {
    __typename: 'AccommodationGroup'
    id: string
    name: string | null
    sortOrder: number
  }
  sharedTo: Array<{
    __typename: 'Sales'
    id: string
    orderNumber: number | null
  }>
  status: {
    __typename: 'TargetCapacityStatus'
    status: {
      __typename: 'ReservationStatus'
      reservedCapacity: {
        __typename: 'RoomsAndBeds'
        rooms: number
        beds: number
        extraBeds: number
      }
      consumptionBreakdown: {
        __typename: 'ReservationConsumption'
        type: AccommodationConsumptionType
        consumption: number
        floatingConsumption: number
      }
    }
    dailyStatus: Array<{
      __typename: 'DailyReservationStatus'
      date: string
      status: {
        __typename: 'ReservationStatus'
        reservedCapacity: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        consumptionBreakdown: {
          __typename: 'ReservationConsumption'
          type: AccommodationConsumptionType
          consumption: number
          floatingConsumption: number
        }
      }
    }>
  }
}

export type ParticipantTargetFragment = {
  __typename: 'AccommodationTarget'
  default: boolean
  id: string
  name: string
  sortOrder: number
  accommodationGroup: {
    __typename: 'AccommodationGroup'
    id: string
    name: string | null
    sortOrder: number
  }
}

export type ServiceParticipantBedFragment = {
  __typename: 'ServiceParticipantBed'
  id: string
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    sortOrder: number
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
    }
  } | null
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    checkIn: string | null
    checkOut: string | null
    id: string
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string }
        checkOut: { __typename: 'CheckOut'; date: string }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          roomType: { __typename: 'RoomType'; id: string; name: string }
        }
      }
      roomTypeReservation: {
        __typename: 'RoomTypeReservation'
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
        }
      } | null
    }
  } | null
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type ServicePurchaseFragment = {
  __typename: 'ServicePurchase'
  id: string
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

type ParticipantService_ServiceParticipantBed_Fragment = {
  __typename: 'ServiceParticipantBed'
  id: string
  type: ServiceType
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    sortOrder: number
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
    }
  } | null
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    checkIn: string | null
    checkOut: string | null
    id: string
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string }
        checkOut: { __typename: 'CheckOut'; date: string }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          id: string
          number: string
          building: { __typename: 'Building'; id: string; name: string }
          roomType: { __typename: 'RoomType'; id: string; name: string }
        }
      }
      roomTypeReservation: {
        __typename: 'RoomTypeReservation'
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
        }
      } | null
    }
  } | null
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

type ParticipantService_ServicePurchase_Fragment = {
  __typename: 'ServicePurchase'
  id: string
  type: ServiceType
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type ParticipantServiceFragment =
  | ParticipantService_ServiceParticipantBed_Fragment
  | ParticipantService_ServicePurchase_Fragment

export type ParticipantPersonalDataFragment = {
  __typename: 'SalesParticipant'
  accommodationRequest: string | null
  additionalInfo: string | null
  age: number | null
  ageSource: AgeField | null
  allergyDescription: string | null
  allergies: Array<string>
  diets: Array<string>
  drugAllergyDescription: string | null
  education: EducationLevel | null
  email: string | null
  firstName: string
  gender: Gender | null
  group: string | null
  id: string
  language: string | null
  lastName: string
  lifeStage: ParticipantLifeStage | null
  nationality: string | null
  roomFriends: Array<string>
  sortOrder: number
  ageCategory: {
    __typename: 'AgeCategory'
    abbreviation: string | null
    key: string
    name: string
  } | null
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  birthday: {
    __typename: 'Birthday'
    date: number | null
    month: number | null
    year: number
  } | null
  postalAddress: {
    __typename: 'PostalAddressType'
    address1: string | null
    address2: string | null
    city: string | null
    country: string | null
    postcode: string | null
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    name: string | null
    orderNumber: number | null
    type: SalesType
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
  }
  visitStatus: {
    __typename: 'VisitStatus'
    isDayVisitor: boolean
    dayVisitorDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
  }
  vst: {
    __typename: 'ParticipantVst'
    end: string | null
    included: boolean
    state: ParticipantVstState
    start: string | null
    vstDays: number | null
    lesson: {
      __typename: 'Lesson'
      fee: number | null
      hours: number | null
    } | null
  } | null
}

export type SalesParticipantFullFragment = {
  __typename: 'SalesParticipant'
  accommodationRequest: string | null
  additionalInfo: string | null
  age: number | null
  ageSource: AgeField | null
  allergyDescription: string | null
  allergies: Array<string>
  diets: Array<string>
  drugAllergyDescription: string | null
  education: EducationLevel | null
  email: string | null
  firstName: string
  gender: Gender | null
  group: string | null
  id: string
  language: string | null
  lastName: string
  lifeStage: ParticipantLifeStage | null
  nationality: string | null
  roomFriends: Array<string>
  sortOrder: number
  services: Array<
    | {
        __typename: 'ServiceParticipantBed'
        id: string
        type: ServiceType
        accommodationTarget: {
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
          accommodationGroup: {
            __typename: 'AccommodationGroup'
            id: string
            name: string | null
            sortOrder: number
          }
        } | null
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        } | null
        participantRoom: {
          __typename: 'ParticipantRoom'
          checkIn: string | null
          checkOut: string | null
          id: string
          roomReservation: {
            __typename: 'RoomReservation'
            id: string
            request: {
              __typename: 'RoomRequest'
              info: string | null
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
              room: {
                __typename: 'Room'
                id: string
                number: string
                building: { __typename: 'Building'; id: string; name: string }
                roomType: { __typename: 'RoomType'; id: string; name: string }
              }
            }
            roomTypeReservation: {
              __typename: 'RoomTypeReservation'
              request: {
                __typename: 'RoomTypeRequest'
                info: string | null
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
              }
            } | null
          }
        } | null
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          targetType: TargetType
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            duration: { __typename: 'Duration'; from: string; to: string }
          }
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          order: {
            __typename: 'Order'
            id: string
            orderNumber: number
            lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          status: {
            __typename: 'PurchaseProductStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
            validatedActions: Array<{
              __typename: 'PurchaseProductActionValidated'
              action: PurchaseProductAction
              valid: boolean
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }>
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
    | {
        __typename: 'ServicePurchase'
        id: string
        type: ServiceType
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          targetType: TargetType
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            duration: { __typename: 'Duration'; from: string; to: string }
          }
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          order: {
            __typename: 'Order'
            id: string
            orderNumber: number
            lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          status: {
            __typename: 'PurchaseProductStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
            validatedActions: Array<{
              __typename: 'PurchaseProductActionValidated'
              action: PurchaseProductAction
              valid: boolean
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }>
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
  >
  ageCategory: {
    __typename: 'AgeCategory'
    abbreviation: string | null
    key: string
    name: string
  } | null
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  birthday: {
    __typename: 'Birthday'
    date: number | null
    month: number | null
    year: number
  } | null
  postalAddress: {
    __typename: 'PostalAddressType'
    address1: string | null
    address2: string | null
    city: string | null
    country: string | null
    postcode: string | null
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    name: string | null
    orderNumber: number | null
    type: SalesType
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
  }
  visitStatus: {
    __typename: 'VisitStatus'
    isDayVisitor: boolean
    dayVisitorDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
  }
  vst: {
    __typename: 'ParticipantVst'
    end: string | null
    included: boolean
    state: ParticipantVstState
    start: string | null
    vstDays: number | null
    lesson: {
      __typename: 'Lesson'
      fee: number | null
      hours: number | null
    } | null
  } | null
}

export type AccommodationGroupTargetsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type AccommodationGroupTargetsQuery = {
  __typename: 'Query'
  accommodationGroup: {
    __typename: 'AccommodationGroup'
    id: string
    targets: Array<{
      __typename: 'AccommodationTarget'
      default: boolean
      id: string
      name: string
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }>
  } | null
}

export type AccommodationTargetsQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type AccommodationTargetsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
        targets: Array<{
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
        }>
      }>
    }
  }
}

export type LanguagesRegistryQueryVariables = Exact<{
  locale: InputMaybe<Scalars['Language']>
}>

export type LanguagesRegistryQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    registryLanguages: Array<{
      __typename: 'RegistryLanguage'
      code: string
      id: string
      name: string
    }>
  }
}

export type NationalitiesRegistryQueryVariables = Exact<{
  [key: string]: never
}>

export type NationalitiesRegistryQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    countries: Array<{
      __typename: 'Country'
      id: string
      name: string
      nationality: string | null
      frequentlyUsed: boolean
    }>
  }
}

export type SaleOrdersDataQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SaleOrdersDataQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    type: SalesType
    orders: Array<{
      __typename: 'Order'
      id: string
      name: string | null
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
    }>
  }
}

export type SalesParticipantCountsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesParticipantCountsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    participantCounts: {
      __typename: 'ParticipantCounts'
      byAgeCategory: Array<{
        __typename: 'ParticipantCountByAgeCategory'
        count: number
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          name: string
          shortName: string
        } | null
      }>
    }
  }
}

export type ParticipantDefaultVstDatesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ParticipantDefaultVstDatesQuery = {
  __typename: 'Query'
  salesParticipant: {
    __typename: 'SalesParticipant'
    id: string
    vst: {
      __typename: 'ParticipantVst'
      defaults: { __typename: 'VstTimes'; end: string; start: string } | null
    } | null
  } | null
}

export type ParticipantsByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
}>

export type ParticipantsByIdQuery = {
  __typename: 'Query'
  salesParticipants: Array<{
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    ageSource: AgeField | null
    allergyDescription: string | null
    allergies: Array<string>
    diets: Array<string>
    drugAllergyDescription: string | null
    education: EducationLevel | null
    email: string | null
    firstName: string
    gender: Gender | null
    group: string | null
    id: string
    language: string | null
    lastName: string
    lifeStage: ParticipantLifeStage | null
    nationality: string | null
    roomFriends: Array<string>
    sortOrder: number
    services: Array<
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
    >
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
    }
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    }
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    } | null
  }>
}

export type SuitableParticipantsQueryVariables = Exact<{
  id: Scalars['ID']
  input: InputMaybe<SuitableParticipantsInput>
}>

export type SuitableParticipantsQuery = {
  __typename: 'Query'
  accommodationRoomReservation: {
    __typename: 'RoomReservation'
    id: string
    suitableParticipants: Array<{
      __typename: 'SuitableParticipant'
      labels: Array<RoomParticipantMatchLabel>
      participant: {
        __typename: 'SalesParticipant'
        id: string
        age: number | null
        ageSource: AgeField | null
        firstName: string
        lastName: string
        gender: Gender | null
        ageCategory: {
          __typename: 'AgeCategory'
          abbreviation: string | null
          key: string
          name: string
        } | null
        birthday: {
          __typename: 'Birthday'
          year: number
          month: number | null
          date: number | null
        } | null
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    room: { __typename: 'Room'; id: string; number: string }
                  }
                }
              } | null
            }
          | { __typename: 'ServicePurchase'; id: string }
        >
        sales: { __typename: 'Sales'; id: string }
      }
    }>
  }
}

export type SaleAndEnrollmentsBaseQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SaleAndEnrollmentsBaseQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    type: SalesType
    orderNumber: number | null
    participantConnection: {
      __typename: 'ParticipantConnection'
      totalElements: number
    }
    enrollments: {
      __typename: 'SalesConnection'
      totalPages: number
      totalElements: number
      hasNextPage: boolean
      hasPreviousPage: boolean
      nodes: Array<{
        __typename: 'Sales'
        id: string
        type: SalesType
        orderNumber: number | null
        name: string | null
        participantConnection: {
          __typename: 'ParticipantConnection'
          totalElements: number
        }
        customer: {
          __typename: 'CustomerReference'
          customer:
            | {
                __typename: 'CustomerOrganization'
                id: string
                organization: {
                  __typename: 'CustomerOrganizationData'
                  name: string | null
                }
              }
            | {
                __typename: 'CustomerPerson'
                id: string
                person: {
                  __typename: 'CustomerPersonData'
                  firstName: string
                  lastName: string
                }
              }
        } | null
      }>
    } | null
  }
}

export type SalesGroupsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesGroupsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    groups: {
      __typename: 'NamedValues'
      label: string | null
      values: Array<string>
    } | null
  }
}

export type ServiceSuitableTargetsQueryVariables = Exact<{
  input: SuitableTargetInput
}>

export type ServiceSuitableTargetsQuery = {
  __typename: 'Query'
  serviceSuitableTargets: Array<{
    __typename: 'SuitableAccommodationTarget'
    labels: Array<TargetMatchLabel>
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      id: string
      name: string
      private: boolean
      sortOrder: number
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }
      sharedTo: Array<{
        __typename: 'Sales'
        id: string
        orderNumber: number | null
      }>
      status: {
        __typename: 'TargetCapacityStatus'
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
        dailyStatus: Array<{
          __typename: 'DailyReservationStatus'
          date: string
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
        }>
      }
    }
  }>
}

export type SpecialDietariesQueryVariables = Exact<{ [key: string]: never }>

export type SpecialDietariesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    allergies: Array<{ __typename: 'Allergy'; name: string }>
  }
}

export type SpecialDietsQueryVariables = Exact<{ [key: string]: never }>

export type SpecialDietsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    diets: Array<{ __typename: 'Diet'; name: string }>
  }
}

export type SaleAccommodationQueryVariables = Exact<{
  input: SuitableRoomsInput
}>

export type SaleAccommodationQuery = {
  __typename: 'Query'
  suitableRooms: Array<{
    __typename: 'SuitableRoomReservation'
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          id: string
          firstName: string
          lastName: string
        }
      }>
      target: {
        __typename: 'AccommodationTarget'
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          id: string
          number: string
          beds: number
          extraBeds: number
          status: {
            __typename: 'RoomStatus'
            cleaningStatus: RoomCleaningStatus | null
          }
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              shortName: string
            }
            category: { __typename: 'RoomTypeCategory'; id: string }
          }
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      }
    }
  }>
}

export type SaleAccommodationBaseQueryVariables = Exact<{
  input: SuitableRoomsInput
}>

export type SaleAccommodationBaseQuery = {
  __typename: 'Query'
  suitableRooms: Array<{
    __typename: 'SuitableRoomReservation'
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      participantRooms: Array<{
        __typename: 'ParticipantRoom'
        id: string
        participant: {
          __typename: 'SalesParticipant'
          id: string
          firstName: string
          lastName: string
        }
      }>
      request: {
        __typename: 'RoomRequest'
        room: { __typename: 'Room'; id: string; number: string }
      }
    }
  }>
}

export type TargetByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TargetByIdQuery = {
  __typename: 'Query'
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    private: boolean
    sortOrder: number
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
    }
    sharedTo: Array<{
      __typename: 'Sales'
      id: string
      orderNumber: number | null
    }>
    status: {
      __typename: 'TargetCapacityStatus'
      status: {
        __typename: 'ReservationStatus'
        reservedCapacity: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        consumptionBreakdown: {
          __typename: 'ReservationConsumption'
          type: AccommodationConsumptionType
          consumption: number
          floatingConsumption: number
        }
      }
      dailyStatus: Array<{
        __typename: 'DailyReservationStatus'
        date: string
        status: {
          __typename: 'ReservationStatus'
          reservedCapacity: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
          consumptionBreakdown: {
            __typename: 'ReservationConsumption'
            type: AccommodationConsumptionType
            consumption: number
            floatingConsumption: number
          }
        }
      }>
    }
  } | null
}

export type ParticipantsPersonalSubscriptionVariables = Exact<{
  filter: ParticipantFilterInput
}>

export type ParticipantsPersonalSubscription = {
  __typename: 'Subscription'
  participant: {
    __typename: 'ParticipantContextEvent'
    participantId: string
    salesId: string
    event:
      | {
          __typename: 'ParticipantEvent'
          id: string
          type: EventType
          data: {
            __typename: 'SalesParticipant'
            id: string
            sortOrder: number
            firstName: string
            lastName: string
            gender: Gender | null
            age: number | null
            ageSource: AgeField | null
            additionalInfo: string | null
            nationality: string | null
            accommodationRequest: string | null
            allergyDescription: string | null
            roomFriends: Array<string>
            allergies: Array<string>
            visitStatus: {
              __typename: 'VisitStatus'
              isDayVisitor: boolean
              dayVisitorDates: {
                __typename: 'LocalDateRange'
                start: string
                end: string
              } | null
            }
            birthday: {
              __typename: 'Birthday'
              year: number
              month: number | null
              date: number | null
            } | null
            ageCategory: {
              __typename: 'AgeCategory'
              abbreviation: string | null
              key: string
              name: string
            } | null
          } | null
        }
      | { __typename: 'ServiceParticipantEvent'; id: string; type: EventType }
  }
}

export type ParticipantsRoomsSubscriptionSubscriptionVariables = Exact<{
  filter: AccommodationReservationFilterInput
}>

export type ParticipantsRoomsSubscriptionSubscription = {
  __typename: 'Subscription'
  accommodationReservation: {
    __typename: 'AccommodationReservationContextEvent'
    salesId: string
    accommodationGroupId: string
    event:
      | { __typename: 'AccommodationGroupEvent' }
      | { __typename: 'RoomEvent'; id: string }
      | { __typename: 'RoomTypeEvent' }
      | { __typename: 'TargetEvent' }
  }
}

export type ParticipantSalesFragment = {
  __typename: 'SalesParticipant'
  sales: {
    __typename: 'Sales'
    id: string
    customer: {
      __typename: 'CustomerReference'
      address: {
        __typename: 'CustomerAddress'
        postalAddress: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      } | null
      contact: {
        __typename: 'CustomerOrganizationContact'
        email: string | null
        firstName: string
        lastName: string
        phone: string | null
      } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            id: string
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            id: string
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
          }
    } | null
    orders: Array<{
      __typename: 'Order'
      invoices: Array<{
        __typename: 'Invoice'
        paymentType: InvoicePaymentType
      }>
      paymentInfo: { __typename: 'PaymentInfo'; payableAmount: number }
    }>
  }
}

export type ParticipantExcelQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type ParticipantExcelQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    orderNumber: number | null
    type: SalesType
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        accommodationRequest: string | null
        additionalInfo: string | null
        age: number | null
        ageSource: AgeField | null
        allergyDescription: string | null
        allergies: Array<string>
        diets: Array<string>
        drugAllergyDescription: string | null
        education: EducationLevel | null
        email: string | null
        firstName: string
        gender: Gender | null
        group: string | null
        id: string
        language: string | null
        lastName: string
        lifeStage: ParticipantLifeStage | null
        nationality: string | null
        roomFriends: Array<string>
        sortOrder: number
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              type: ServiceType
              accommodationTarget: {
                __typename: 'AccommodationTarget'
                default: boolean
                id: string
                name: string
                sortOrder: number
                accommodationGroup: {
                  __typename: 'AccommodationGroup'
                  id: string
                  name: string | null
                  sortOrder: number
                }
              } | null
              dates: {
                __typename: 'AccommodationDates'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                checkIn: string | null
                checkOut: string | null
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    info: string | null
                    checkIn: { __typename: 'CheckIn'; date: string }
                    checkOut: { __typename: 'CheckOut'; date: string }
                    features: Array<{
                      __typename: 'RoomLabel'
                      icon: string | null
                      id: string
                      name: string
                      shortName: string
                    }>
                    room: {
                      __typename: 'Room'
                      id: string
                      number: string
                      building: {
                        __typename: 'Building'
                        id: string
                        name: string
                      }
                      roomType: {
                        __typename: 'RoomType'
                        id: string
                        name: string
                      }
                    }
                  }
                  roomTypeReservation: {
                    __typename: 'RoomTypeReservation'
                    request: {
                      __typename: 'RoomTypeRequest'
                      info: string | null
                      features: Array<{
                        __typename: 'RoomLabel'
                        icon: string | null
                        id: string
                        name: string
                        shortName: string
                      }>
                    }
                  } | null
                }
              } | null
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                targetType: TargetType
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  duration: { __typename: 'Duration'; from: string; to: string }
                }
                auditLog: { __typename: 'AuditLog'; createdAt: string }
                link: {
                  __typename: 'PurchaseProductLink'
                  participant: {
                    __typename: 'SalesParticipant'
                    firstName: string
                    id: string
                    lastName: string
                  } | null
                  reservation: {
                    __typename: 'ResourceReservation'
                    id: string
                    resourceQuantity: number
                    resource:
                      | {
                          __typename: 'ResourceEquipment'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceHuman'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceProperty'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourcePropertyNested'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | null
                  } | null
                  sales: {
                    __typename: 'Sales'
                    id: string
                    orderNumber: number | null
                    type: SalesType
                  }
                  task: {
                    __typename: 'Task'
                    id: string
                    assignee:
                      | { __typename: 'User' }
                      | { __typename: 'UserGroup'; id: string; name: string }
                      | null
                  } | null
                }
                order: {
                  __typename: 'Order'
                  id: string
                  orderNumber: number
                  lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                  paymentInfo: {
                    __typename: 'PaymentInfo'
                    status: PaymentStatus
                  }
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                status: {
                  __typename: 'PurchaseProductStatus'
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                  validatedActions: Array<{
                    __typename: 'PurchaseProductActionValidated'
                    action: PurchaseProductAction
                    valid: boolean
                    issues: Array<{
                      __typename: 'ValidationIssue'
                      code: number
                      key: string
                      level: IssueLevel
                    }>
                  }>
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
          | {
              __typename: 'ServicePurchase'
              id: string
              type: ServiceType
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                targetType: TargetType
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  duration: { __typename: 'Duration'; from: string; to: string }
                }
                auditLog: { __typename: 'AuditLog'; createdAt: string }
                link: {
                  __typename: 'PurchaseProductLink'
                  participant: {
                    __typename: 'SalesParticipant'
                    firstName: string
                    id: string
                    lastName: string
                  } | null
                  reservation: {
                    __typename: 'ResourceReservation'
                    id: string
                    resourceQuantity: number
                    resource:
                      | {
                          __typename: 'ResourceEquipment'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceHuman'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceProperty'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourcePropertyNested'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | null
                  } | null
                  sales: {
                    __typename: 'Sales'
                    id: string
                    orderNumber: number | null
                    type: SalesType
                  }
                  task: {
                    __typename: 'Task'
                    id: string
                    assignee:
                      | { __typename: 'User' }
                      | { __typename: 'UserGroup'; id: string; name: string }
                      | null
                  } | null
                }
                order: {
                  __typename: 'Order'
                  id: string
                  orderNumber: number
                  lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                  paymentInfo: {
                    __typename: 'PaymentInfo'
                    status: PaymentStatus
                  }
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                status: {
                  __typename: 'PurchaseProductStatus'
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                  validatedActions: Array<{
                    __typename: 'PurchaseProductActionValidated'
                    action: PurchaseProductAction
                    valid: boolean
                    issues: Array<{
                      __typename: 'ValidationIssue'
                      code: number
                      key: string
                      level: IssueLevel
                    }>
                  }>
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
        >
        sales: {
          __typename: 'Sales'
          id: string
          name: string | null
          orderNumber: number | null
          type: SalesType
          customer: {
            __typename: 'CustomerReference'
            address: {
              __typename: 'CustomerAddress'
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  customerNumber: string
                  id: string
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  id: string
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                }
          } | null
          orders: Array<{
            __typename: 'Order'
            invoices: Array<{
              __typename: 'Invoice'
              paymentType: InvoicePaymentType
            }>
            paymentInfo: { __typename: 'PaymentInfo'; payableAmount: number }
          }>
          lifecycle: {
            __typename: 'SalesLifecycle'
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
              names: Array<{
                __typename: 'LocalizedContent'
                locale: string
                content: string
              }>
            }
          }
        }
        ageCategory: {
          __typename: 'AgeCategory'
          abbreviation: string | null
          key: string
          name: string
        } | null
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        birthday: {
          __typename: 'Birthday'
          date: number | null
          month: number | null
          year: number
        } | null
        postalAddress: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
        visitStatus: {
          __typename: 'VisitStatus'
          isDayVisitor: boolean
          dayVisitorDates: {
            __typename: 'LocalDateRange'
            end: string
            start: string
          } | null
        }
        vst: {
          __typename: 'ParticipantVst'
          end: string | null
          included: boolean
          state: ParticipantVstState
          start: string | null
          vstDays: number | null
          lesson: {
            __typename: 'Lesson'
            fee: number | null
            hours: number | null
          } | null
        } | null
      }>
    }
  }
}

export type ParticipantsListDataQueryVariables = Exact<{
  salesId: Scalars['ID']
  participantConnectionInput: InputMaybe<ParticipantConnectionInput>
}>

export type ParticipantsListDataQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    type: SalesType
    orderNumber: number | null
    participantConnection: {
      __typename: 'ParticipantConnection'
      totalPages: number
      totalElements: number
      hasNextPage: boolean
      hasPreviousPage: boolean
      nodes: Array<{
        __typename: 'SalesParticipant'
        accommodationRequest: string | null
        additionalInfo: string | null
        age: number | null
        ageSource: AgeField | null
        allergyDescription: string | null
        allergies: Array<string>
        diets: Array<string>
        drugAllergyDescription: string | null
        education: EducationLevel | null
        email: string | null
        firstName: string
        gender: Gender | null
        group: string | null
        id: string
        language: string | null
        lastName: string
        lifeStage: ParticipantLifeStage | null
        nationality: string | null
        roomFriends: Array<string>
        sortOrder: number
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              type: ServiceType
              accommodationTarget: {
                __typename: 'AccommodationTarget'
                default: boolean
                id: string
                name: string
                sortOrder: number
                accommodationGroup: {
                  __typename: 'AccommodationGroup'
                  id: string
                  name: string | null
                  sortOrder: number
                }
              } | null
              dates: {
                __typename: 'AccommodationDates'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                checkIn: string | null
                checkOut: string | null
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    info: string | null
                    checkIn: { __typename: 'CheckIn'; date: string }
                    checkOut: { __typename: 'CheckOut'; date: string }
                    features: Array<{
                      __typename: 'RoomLabel'
                      icon: string | null
                      id: string
                      name: string
                      shortName: string
                    }>
                    room: {
                      __typename: 'Room'
                      id: string
                      number: string
                      building: {
                        __typename: 'Building'
                        id: string
                        name: string
                      }
                      roomType: {
                        __typename: 'RoomType'
                        id: string
                        name: string
                      }
                    }
                  }
                  roomTypeReservation: {
                    __typename: 'RoomTypeReservation'
                    request: {
                      __typename: 'RoomTypeRequest'
                      info: string | null
                      features: Array<{
                        __typename: 'RoomLabel'
                        icon: string | null
                        id: string
                        name: string
                        shortName: string
                      }>
                    }
                  } | null
                }
              } | null
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                targetType: TargetType
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  duration: { __typename: 'Duration'; from: string; to: string }
                }
                auditLog: { __typename: 'AuditLog'; createdAt: string }
                link: {
                  __typename: 'PurchaseProductLink'
                  participant: {
                    __typename: 'SalesParticipant'
                    firstName: string
                    id: string
                    lastName: string
                  } | null
                  reservation: {
                    __typename: 'ResourceReservation'
                    id: string
                    resourceQuantity: number
                    resource:
                      | {
                          __typename: 'ResourceEquipment'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceHuman'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceProperty'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourcePropertyNested'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | null
                  } | null
                  sales: {
                    __typename: 'Sales'
                    id: string
                    orderNumber: number | null
                    type: SalesType
                  }
                  task: {
                    __typename: 'Task'
                    id: string
                    assignee:
                      | { __typename: 'User' }
                      | { __typename: 'UserGroup'; id: string; name: string }
                      | null
                  } | null
                }
                order: {
                  __typename: 'Order'
                  id: string
                  orderNumber: number
                  lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                  paymentInfo: {
                    __typename: 'PaymentInfo'
                    status: PaymentStatus
                  }
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                status: {
                  __typename: 'PurchaseProductStatus'
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                  validatedActions: Array<{
                    __typename: 'PurchaseProductActionValidated'
                    action: PurchaseProductAction
                    valid: boolean
                    issues: Array<{
                      __typename: 'ValidationIssue'
                      code: number
                      key: string
                      level: IssueLevel
                    }>
                  }>
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
          | {
              __typename: 'ServicePurchase'
              id: string
              type: ServiceType
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                targetType: TargetType
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  duration: { __typename: 'Duration'; from: string; to: string }
                }
                auditLog: { __typename: 'AuditLog'; createdAt: string }
                link: {
                  __typename: 'PurchaseProductLink'
                  participant: {
                    __typename: 'SalesParticipant'
                    firstName: string
                    id: string
                    lastName: string
                  } | null
                  reservation: {
                    __typename: 'ResourceReservation'
                    id: string
                    resourceQuantity: number
                    resource:
                      | {
                          __typename: 'ResourceEquipment'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceHuman'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourceProperty'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | {
                          __typename: 'ResourcePropertyNested'
                          id: string
                          isPooled: boolean
                          name: string
                        }
                      | null
                  } | null
                  sales: {
                    __typename: 'Sales'
                    id: string
                    orderNumber: number | null
                    type: SalesType
                  }
                  task: {
                    __typename: 'Task'
                    id: string
                    assignee:
                      | { __typename: 'User' }
                      | { __typename: 'UserGroup'; id: string; name: string }
                      | null
                  } | null
                }
                order: {
                  __typename: 'Order'
                  id: string
                  orderNumber: number
                  lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                  paymentInfo: {
                    __typename: 'PaymentInfo'
                    status: PaymentStatus
                  }
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                status: {
                  __typename: 'PurchaseProductStatus'
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                  validatedActions: Array<{
                    __typename: 'PurchaseProductActionValidated'
                    action: PurchaseProductAction
                    valid: boolean
                    issues: Array<{
                      __typename: 'ValidationIssue'
                      code: number
                      key: string
                      level: IssueLevel
                    }>
                  }>
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
        >
        ageCategory: {
          __typename: 'AgeCategory'
          abbreviation: string | null
          key: string
          name: string
        } | null
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        birthday: {
          __typename: 'Birthday'
          date: number | null
          month: number | null
          year: number
        } | null
        postalAddress: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          name: string | null
          orderNumber: number | null
          type: SalesType
          lifecycle: {
            __typename: 'SalesLifecycle'
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
              names: Array<{
                __typename: 'LocalizedContent'
                locale: string
                content: string
              }>
            }
          }
        }
        visitStatus: {
          __typename: 'VisitStatus'
          isDayVisitor: boolean
          dayVisitorDates: {
            __typename: 'LocalDateRange'
            end: string
            start: string
          } | null
        }
        vst: {
          __typename: 'ParticipantVst'
          end: string | null
          included: boolean
          state: ParticipantVstState
          start: string | null
          vstDays: number | null
          lesson: {
            __typename: 'Lesson'
            fee: number | null
            hours: number | null
          } | null
        } | null
      }>
    }
  }
}

export type ParticipantsForPrintServiceBedFragment = {
  __typename: 'ServiceParticipantBed'
  id: string
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    id: string
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        room: { __typename: 'Room'; id: string; number: string }
      }
    }
  } | null
}

export type ParticipantsForPrintQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type ParticipantsForPrintQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
        group: string | null
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              dates: {
                __typename: 'AccommodationDates'
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    room: { __typename: 'Room'; id: string; number: string }
                  }
                }
              } | null
            }
          | { __typename: 'ServicePurchase' }
        >
      }>
    }
  }
}

export type TargetParticipantRoomMutationVariables = Exact<{
  input: AccommodationRoomTargetInput
}>

export type TargetParticipantRoomMutation = {
  __typename: 'Mutation'
  accommodationRoomTarget: {
    __typename: 'AccommodationRoomTargetPayload'
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      target: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
    }
  }
}

export type ParticipantVstSetAttributesMutationVariables = Exact<{
  input: ParticipantVstSetAttributesInput
}>

export type ParticipantVstSetAttributesMutation = {
  __typename: 'Mutation'
  participantVstSetAttributes: {
    __typename: 'ParticipantVstSetOutput'
    participantId: string
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    }
  }
}

export type ParticipantVstSetDatesMutationVariables = Exact<{
  input: ParticipantVstSetDatesInput
}>

export type ParticipantVstSetDatesMutation = {
  __typename: 'Mutation'
  participantVstSetDates: Array<{
    __typename: 'ParticipantVstSetOutput'
    participantId: string
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    }
  }>
}

export type ParticipantVstSetDatesDefaultMutationVariables = Exact<{
  input: ParticipantVstSetDatesDefaultInput
}>

export type ParticipantVstSetDatesDefaultMutation = {
  __typename: 'Mutation'
  participantVstSetDatesDefault: Array<{
    __typename: 'ParticipantVstSetOutput'
    participantId: string
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    }
  }>
}

export type SalesParticipantAddRoomMutationVariables = Exact<{
  input: SalesParticipantAddRoomInput
}>

export type SalesParticipantAddRoomMutation = {
  __typename: 'Mutation'
  salesParticipantAddRoom: {
    __typename: 'ParticipantAccommodationServicePayload'
    service: {
      __typename: 'ServiceParticipantBed'
      id: string
      type: ServiceType
      accommodationTarget: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
      dates: {
        __typename: 'AccommodationDates'
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      } | null
      participantRoom: {
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              id: string
              number: string
              building: { __typename: 'Building'; id: string; name: string }
              roomType: { __typename: 'RoomType'; id: string; name: string }
            }
          }
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            request: {
              __typename: 'RoomTypeRequest'
              info: string | null
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
            }
          } | null
        }
      } | null
      purchaseProduct: {
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      } | null
    }
  }
}

export type SetCheckInMutationVariables = Exact<{
  input: MarkCheckedInput
}>

export type SetCheckInMutation = {
  __typename: 'Mutation'
  salesParticipantCheckIn: Array<{
    __typename: 'ParticipantRoom'
    id: string
    checkIn: string | null
  }>
}

export type SetCheckOutMutationVariables = Exact<{
  input: MarkCheckedInput
}>

export type SetCheckOutMutation = {
  __typename: 'Mutation'
  salesParticipantCheckOut: Array<{
    __typename: 'ParticipantRoom'
    id: string
    checkOut: string | null
  }>
}

export type SalesParticipantCreateMutationVariables = Exact<{
  input: SalesParticipantCreateInput
}>

export type SalesParticipantCreateMutation = {
  __typename: 'Mutation'
  salesParticipantCreate: {
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    ageSource: AgeField | null
    allergyDescription: string | null
    allergies: Array<string>
    diets: Array<string>
    drugAllergyDescription: string | null
    education: EducationLevel | null
    email: string | null
    firstName: string
    gender: Gender | null
    group: string | null
    id: string
    language: string | null
    lastName: string
    lifeStage: ParticipantLifeStage | null
    nationality: string | null
    roomFriends: Array<string>
    sortOrder: number
    services: Array<
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
    >
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
    }
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    }
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    } | null
  }
}

export type SalesParticipantDeleteMutationVariables = Exact<{
  input: SalesParticipantDeleteInput
}>

export type SalesParticipantDeleteMutation = {
  __typename: 'Mutation'
  salesParticipantDelete: {
    __typename: 'ParticipantDeletePayload'
    id: string
    deleted: boolean
    sales: {
      __typename: 'Sales'
      id: string
      participantConnection: {
        __typename: 'ParticipantConnection'
        totalElements: number
      }
    }
  }
}

export type SalesParticipantDeleteRoomMutationVariables = Exact<{
  input: SalesParticipantDeleteRoomInput
}>

export type SalesParticipantDeleteRoomMutation = {
  __typename: 'Mutation'
  salesParticipantDeleteRoom: {
    __typename: 'ParticipantRoomDeletePayload'
    participantRoomId: string
    service: {
      __typename: 'ServiceParticipantBed'
      id: string
      type: ServiceType
      accommodationTarget: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
      dates: {
        __typename: 'AccommodationDates'
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      } | null
      participantRoom: {
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              id: string
              number: string
              building: { __typename: 'Building'; id: string; name: string }
              roomType: { __typename: 'RoomType'; id: string; name: string }
            }
          }
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            request: {
              __typename: 'RoomTypeRequest'
              info: string | null
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
            }
          } | null
        }
      } | null
      purchaseProduct: {
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      } | null
    } | null
  }
}

export type SalesParticipantMassCreateMutationVariables = Exact<{
  input: Array<SalesParticipantCreateInput> | SalesParticipantCreateInput
}>

export type SalesParticipantMassCreateMutation = {
  __typename: 'Mutation'
  salesParticipantMassCreate: Array<{
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    ageSource: AgeField | null
    allergyDescription: string | null
    allergies: Array<string>
    diets: Array<string>
    drugAllergyDescription: string | null
    education: EducationLevel | null
    email: string | null
    firstName: string
    gender: Gender | null
    group: string | null
    id: string
    language: string | null
    lastName: string
    lifeStage: ParticipantLifeStage | null
    nationality: string | null
    roomFriends: Array<string>
    sortOrder: number
    services: Array<
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
    >
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
    }
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    }
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    } | null
  }>
}

export type SalesParticipantMoveToSalesMutationVariables = Exact<{
  input: SalesParticipantMoveToSalesInput
}>

export type SalesParticipantMoveToSalesMutation = {
  __typename: 'Mutation'
  salesParticipantMoveToSales: {
    __typename: 'SalesParticipantMoveToSalesPayload'
    participant: {
      __typename: 'SalesParticipant'
      accommodationRequest: string | null
      additionalInfo: string | null
      age: number | null
      ageSource: AgeField | null
      allergyDescription: string | null
      allergies: Array<string>
      diets: Array<string>
      drugAllergyDescription: string | null
      education: EducationLevel | null
      email: string | null
      firstName: string
      gender: Gender | null
      group: string | null
      id: string
      language: string | null
      lastName: string
      lifeStage: ParticipantLifeStage | null
      nationality: string | null
      roomFriends: Array<string>
      sortOrder: number
      services: Array<
        | {
            __typename: 'ServiceParticipantBed'
            id: string
            type: ServiceType
            accommodationTarget: {
              __typename: 'AccommodationTarget'
              default: boolean
              id: string
              name: string
              sortOrder: number
              accommodationGroup: {
                __typename: 'AccommodationGroup'
                id: string
                name: string | null
                sortOrder: number
              }
            } | null
            dates: {
              __typename: 'AccommodationDates'
              checkIn: {
                __typename: 'CheckIn'
                date: string
                type: CheckInType
              }
              checkOut: {
                __typename: 'CheckOut'
                date: string
                type: CheckOutType
              }
            } | null
            participantRoom: {
              __typename: 'ParticipantRoom'
              checkIn: string | null
              checkOut: string | null
              id: string
              roomReservation: {
                __typename: 'RoomReservation'
                id: string
                request: {
                  __typename: 'RoomRequest'
                  info: string | null
                  checkIn: { __typename: 'CheckIn'; date: string }
                  checkOut: { __typename: 'CheckOut'; date: string }
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                  room: {
                    __typename: 'Room'
                    id: string
                    number: string
                    building: {
                      __typename: 'Building'
                      id: string
                      name: string
                    }
                    roomType: {
                      __typename: 'RoomType'
                      id: string
                      name: string
                    }
                  }
                }
                roomTypeReservation: {
                  __typename: 'RoomTypeReservation'
                  request: {
                    __typename: 'RoomTypeRequest'
                    info: string | null
                    features: Array<{
                      __typename: 'RoomLabel'
                      icon: string | null
                      id: string
                      name: string
                      shortName: string
                    }>
                  }
                } | null
              }
            } | null
            purchaseProduct: {
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            } | null
          }
        | {
            __typename: 'ServicePurchase'
            id: string
            type: ServiceType
            purchaseProduct: {
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            } | null
          }
      >
      ageCategory: {
        __typename: 'AgeCategory'
        abbreviation: string | null
        key: string
        name: string
      } | null
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      birthday: {
        __typename: 'Birthday'
        date: number | null
        month: number | null
        year: number
      } | null
      postalAddress: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        name: string | null
        orderNumber: number | null
        type: SalesType
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
      }
      visitStatus: {
        __typename: 'VisitStatus'
        isDayVisitor: boolean
        dayVisitorDates: {
          __typename: 'LocalDateRange'
          end: string
          start: string
        } | null
      }
      vst: {
        __typename: 'ParticipantVst'
        end: string | null
        included: boolean
        state: ParticipantVstState
        start: string | null
        vstDays: number | null
        lesson: {
          __typename: 'Lesson'
          fee: number | null
          hours: number | null
        } | null
      } | null
    }
  }
}

export type SalesParticipantSetAccommodationMutationVariables = Exact<{
  input: SalesParticipantSetAccommodationInput
}>

export type SalesParticipantSetAccommodationMutation = {
  __typename: 'Mutation'
  salesParticipantSetAccommodation: {
    __typename: 'ParticipantAccommodationServicePayload'
    service: {
      __typename: 'ServiceParticipantBed'
      id: string
      type: ServiceType
      accommodationTarget: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
      dates: {
        __typename: 'AccommodationDates'
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      } | null
      participantRoom: {
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              id: string
              number: string
              building: { __typename: 'Building'; id: string; name: string }
              roomType: { __typename: 'RoomType'; id: string; name: string }
            }
          }
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            request: {
              __typename: 'RoomTypeRequest'
              info: string | null
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
            }
          } | null
        }
      } | null
      purchaseProduct: {
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      } | null
    }
  }
}

export type SalesParticipantSetRoomFriendsMutationVariables = Exact<{
  input: SalesParticipantSetRoomFriendsInput
}>

export type SalesParticipantSetRoomFriendsMutation = {
  __typename: 'Mutation'
  salesParticipantSetRoomFriends: {
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    ageSource: AgeField | null
    allergyDescription: string | null
    allergies: Array<string>
    diets: Array<string>
    drugAllergyDescription: string | null
    education: EducationLevel | null
    email: string | null
    firstName: string
    gender: Gender | null
    group: string | null
    id: string
    language: string | null
    lastName: string
    lifeStage: ParticipantLifeStage | null
    nationality: string | null
    roomFriends: Array<string>
    sortOrder: number
    services: Array<
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
    >
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
    }
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    }
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    } | null
  }
}

export type SalesParticipantSetVisitStatusMutationVariables = Exact<{
  input: SalesParticipantSetVisitStatusInput
}>

export type SalesParticipantSetVisitStatusMutation = {
  __typename: 'Mutation'
  salesParticipantSetVisitStatus: {
    __typename: 'SalesParticipant'
    id: string
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        start: string
        end: string
      } | null
    }
  }
}

export type SalesParticipantUpdateMutationVariables = Exact<{
  input: SalesParticipantUpdateInput
}>

export type SalesParticipantUpdateMutation = {
  __typename: 'Mutation'
  salesParticipantUpdate: {
    __typename: 'SalesParticipant'
    accommodationRequest: string | null
    additionalInfo: string | null
    age: number | null
    ageSource: AgeField | null
    allergyDescription: string | null
    allergies: Array<string>
    diets: Array<string>
    drugAllergyDescription: string | null
    education: EducationLevel | null
    email: string | null
    firstName: string
    gender: Gender | null
    group: string | null
    id: string
    language: string | null
    lastName: string
    lifeStage: ParticipantLifeStage | null
    nationality: string | null
    roomFriends: Array<string>
    sortOrder: number
    services: Array<
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
    >
    ageCategory: {
      __typename: 'AgeCategory'
      abbreviation: string | null
      key: string
      name: string
    } | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    birthday: {
      __typename: 'Birthday'
      date: number | null
      month: number | null
      year: number
    } | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      name: string | null
      orderNumber: number | null
      type: SalesType
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
    }
    visitStatus: {
      __typename: 'VisitStatus'
      isDayVisitor: boolean
      dayVisitorDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    }
    vst: {
      __typename: 'ParticipantVst'
      end: string | null
      included: boolean
      state: ParticipantVstState
      start: string | null
      vstDays: number | null
      lesson: {
        __typename: 'Lesson'
        fee: number | null
        hours: number | null
      } | null
    } | null
  }
}

export type SalesParticipantUpdateRoomMutationVariables = Exact<{
  input: SalesParticipantUpdateRoomInput
}>

export type SalesParticipantUpdateRoomMutation = {
  __typename: 'Mutation'
  salesParticipantUpdateRoom: {
    __typename: 'ParticipantAccommodationServicePayload'
    service: {
      __typename: 'ServiceParticipantBed'
      id: string
      type: ServiceType
      accommodationTarget: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
      } | null
      dates: {
        __typename: 'AccommodationDates'
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      } | null
      participantRoom: {
        __typename: 'ParticipantRoom'
        checkIn: string | null
        checkOut: string | null
        id: string
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              id: string
              number: string
              building: { __typename: 'Building'; id: string; name: string }
              roomType: { __typename: 'RoomType'; id: string; name: string }
            }
          }
          roomTypeReservation: {
            __typename: 'RoomTypeReservation'
            request: {
              __typename: 'RoomTypeRequest'
              info: string | null
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
            }
          } | null
        }
      } | null
      purchaseProduct: {
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      } | null
    }
  }
}

export type ServiceDeleteMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ServiceDeleteMutation = {
  __typename: 'Mutation'
  serviceDelete: {
    __typename: 'ServiceDeletePayload'
    id: string
    deleted: boolean
    participant: { __typename: 'SalesParticipant'; id: string } | null
  }
}

export type ServiceSetDatesMutationVariables = Exact<{
  input: ServiceSetDatesInput
}>

export type ServiceSetDatesMutation = {
  __typename: 'Mutation'
  serviceSetDates: {
    __typename: 'ServicePayload'
    service:
      | {
          __typename: 'ServiceParticipantBed'
          id: string
          type: ServiceType
          accommodationTarget: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
            accommodationGroup: {
              __typename: 'AccommodationGroup'
              id: string
              name: string | null
              sortOrder: number
            }
          } | null
          dates: {
            __typename: 'AccommodationDates'
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
          } | null
          participantRoom: {
            __typename: 'ParticipantRoom'
            checkIn: string | null
            checkOut: string | null
            id: string
            roomReservation: {
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                info: string | null
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  building: { __typename: 'Building'; id: string; name: string }
                  roomType: { __typename: 'RoomType'; id: string; name: string }
                }
              }
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                request: {
                  __typename: 'RoomTypeRequest'
                  info: string | null
                  features: Array<{
                    __typename: 'RoomLabel'
                    icon: string | null
                    id: string
                    name: string
                    shortName: string
                  }>
                }
              } | null
            }
          } | null
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
      | {
          __typename: 'ServicePurchase'
          id: string
          type: ServiceType
          purchaseProduct: {
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          } | null
        }
  }
}

export type CatalogProductFragment = {
  __typename: 'CatalogProduct'
  id: string
  name: string
  notes: string | null
  catalog: {
    __typename: 'Catalog'
    active: boolean
    id: string
    name: string
    company: {
      __typename: 'Seller'
      id: string
      name: string
      succeededBy: { __typename: 'Seller'; id: string } | null
    }
  }
  categories: Array<{
    __typename: 'Category'
    id: string
    isAssignable: boolean
    isEmphasised: boolean
    name: string
    set: { __typename: 'CategorySet'; id: string; name: string }
  }>
  items: Array<{
    __typename: 'CatalogProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }>
}

export type CatalogProductItemFragment = {
  __typename: 'CatalogProductItem'
  code: string
  commissionMode: CommissionMode
  id: string
  internalCost: number | null
  internalPurchase: number | null
  name: string
  quantityUnit: QuantityUnit
  marginalTax: {
    __typename: 'MarginalTax'
    active: boolean
    id: string
    marginalTaxRate: number
  } | null
  meals: Array<{
    __typename: 'ProductMeal'
    boundary: MealBoundary
    duration: number
    id: string
    startTime: string
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
      | null
    meal: {
      __typename: 'Meal'
      defaultDuration: number | null
      defaultStartTime: string | null
      id: string
      name: string
      defaultRestaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
      } | null
    }
  }>
  rules: Array<{
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }>
  unitPrice: {
    __typename: 'UnitPrice'
    id: string
    price: number
    type: PriceType
    vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
  }
}

type CatalogProductOld_ProductTitleAccommodation_Fragment = {
  __typename: 'ProductTitleAccommodation'
  id: string
  type: string
  salesType: CatalogSalesType | null
  code: string | null
  name: string
  vatRate: number
  unitPrice: number
  quantityUnit: QuantityUnit
  catalog: { __typename: 'Catalog'; id: string; name: string }
}

type CatalogProductOld_ProductTitleBasic_Fragment = {
  __typename: 'ProductTitleBasic'
  id: string
  type: string
  salesType: CatalogSalesType | null
  code: string | null
  name: string
  vatRate: number
  unitPrice: number
  quantityUnit: QuantityUnit
  catalog: { __typename: 'Catalog'; id: string; name: string }
}

type CatalogProductOld_ProductTitleCalendar_Fragment = {
  __typename: 'ProductTitleCalendar'
  id: string
  type: string
  salesType: CatalogSalesType | null
  code: string | null
  name: string
  vatRate: number
  unitPrice: number
  quantityUnit: QuantityUnit
  catalog: { __typename: 'Catalog'; id: string; name: string }
}

type CatalogProductOld_ProductTitlePacketMeal_Fragment = {
  __typename: 'ProductTitlePacketMeal'
  id: string
  type: string
  salesType: CatalogSalesType | null
  code: string | null
  name: string
  vatRate: number
  unitPrice: number
  quantityUnit: QuantityUnit
  catalog: { __typename: 'Catalog'; id: string; name: string }
}

export type CatalogProductOldFragment =
  | CatalogProductOld_ProductTitleAccommodation_Fragment
  | CatalogProductOld_ProductTitleBasic_Fragment
  | CatalogProductOld_ProductTitleCalendar_Fragment
  | CatalogProductOld_ProductTitlePacketMeal_Fragment

export type ProductCategoryFragment = {
  __typename: 'Category'
  id: string
  isAssignable: boolean
  isEmphasised: boolean
  name: string
  set: { __typename: 'CategorySet'; id: string; name: string }
}

export type ProductItemMarginalTaxFragment = {
  __typename: 'MarginalTax'
  active: boolean
  id: string
  marginalTaxRate: number
}

export type ProductMealFragment = {
  __typename: 'ProductMeal'
  boundary: MealBoundary
  duration: number
  id: string
  startTime: string
  location:
    | { __typename: 'ResourceEquipment'; id: string; name: string }
    | { __typename: 'ResourceHuman'; id: string; name: string }
    | { __typename: 'ResourceProperty'; id: string; name: string }
    | { __typename: 'ResourcePropertyNested'; id: string; name: string }
    | { __typename: 'Restaurant'; id: string; name: string }
    | { __typename: 'Room'; id: string; name: string }
    | null
  meal: {
    __typename: 'Meal'
    defaultDuration: number | null
    defaultStartTime: string | null
    id: string
    name: string
    defaultRestaurant: {
      __typename: 'Restaurant'
      id: string
      name: string
    } | null
  }
}

export type ProductPurchaseFragment = {
  __typename: 'PurchaseProduct'
  id: string
  targetType: TargetType
  attributes: {
    __typename: 'PurchaseProductAttributes'
    quantity: number
    duration: { __typename: 'Duration'; from: string; to: string }
  }
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  link: {
    __typename: 'PurchaseProductLink'
    participant: {
      __typename: 'SalesParticipant'
      firstName: string
      id: string
      lastName: string
    } | null
    reservation: {
      __typename: 'ResourceReservation'
      id: string
      resourceQuantity: number
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            isPooled: boolean
            name: string
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            isPooled: boolean
            name: string
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            isPooled: boolean
            name: string
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            isPooled: boolean
            name: string
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      id: string
      orderNumber: number | null
      type: SalesType
    }
    task: {
      __typename: 'Task'
      id: string
      assignee:
        | { __typename: 'User' }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
    } | null
  }
  order: {
    __typename: 'Order'
    id: string
    orderNumber: number
    lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
    paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
  }
  product: { __typename: 'SalesProduct'; id: string; name: string }
  status: {
    __typename: 'PurchaseProductStatus'
    issues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
    validatedActions: Array<{
      __typename: 'PurchaseProductActionValidated'
      action: PurchaseProductAction
      valid: boolean
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
    }>
  }
  totalPrice: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type ProductPurchaseLinkFragment = {
  __typename: 'PurchaseProductLink'
  participant: {
    __typename: 'SalesParticipant'
    firstName: string
    id: string
    lastName: string
  } | null
  reservation: {
    __typename: 'ResourceReservation'
    id: string
    resourceQuantity: number
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          isPooled: boolean
          name: string
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          isPooled: boolean
          name: string
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          isPooled: boolean
          name: string
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          isPooled: boolean
          name: string
        }
      | null
  } | null
  sales: {
    __typename: 'Sales'
    id: string
    orderNumber: number | null
    type: SalesType
  }
  task: {
    __typename: 'Task'
    id: string
    assignee:
      | { __typename: 'User' }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
  } | null
}

export type ProductPurchaseStatusFragment = {
  __typename: 'PurchaseProductStatus'
  issues: Array<{
    __typename: 'ValidationIssue'
    code: number
    key: string
    level: IssueLevel
  }>
  validatedActions: Array<{
    __typename: 'PurchaseProductActionValidated'
    action: PurchaseProductAction
    valid: boolean
    issues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
  }>
}

export type ProductRuleFragment = {
  __typename: 'ProductRule'
  id: string
  action:
    | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
    | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
    | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  condition:
    | {
        __typename: 'AgeCategoryRule'
        type: ConditionRuleType
        ageCategories: Array<{
          __typename: 'AgeCategory'
          key: string
          name: string
          shortName: string
          sortOrder: number
        }>
      }
    | {
        __typename: 'BoundaryRule'
        bounds: Array<BoundType>
        type: ConditionRuleType
      }
    | { __typename: 'DateRule'; dates: Array<string>; type: ConditionRuleType }
    | { __typename: 'QuantityRule'; quantity: number; type: ConditionRuleType }
    | {
        __typename: 'WeekdayRule'
        weekdays: Array<DayOfWeek>
        type: ConditionRuleType
      }
}

export type SalesProductFragment = {
  __typename: 'SalesProduct'
  group: string | null
  id: string
  name: string
  notes: string | null
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  catalog: {
    __typename: 'Catalog'
    active: boolean
    id: string
    name: string
    company: {
      __typename: 'Seller'
      id: string
      name: string
      succeededBy: { __typename: 'Seller'; id: string } | null
    }
  }
  categories: Array<{
    __typename: 'Category'
    id: string
    isAssignable: boolean
    isEmphasised: boolean
    name: string
    set: { __typename: 'CategorySet'; id: string; name: string }
  }>
  items: Array<{
    __typename: 'SalesProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }>
  purchases: Array<{
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  sales: { __typename: 'Sales'; id: string }
  settings: {
    __typename: 'SalesProductSettings'
    published: boolean
    description: string | null
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
    } | null
    purchaseDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
  } | null
}

export type SalesProductItemFragment = {
  __typename: 'SalesProductItem'
  code: string
  commissionMode: CommissionMode
  id: string
  internalCost: number | null
  internalPurchase: number | null
  name: string
  quantityUnit: QuantityUnit
  marginalTax: {
    __typename: 'MarginalTax'
    active: boolean
    id: string
    marginalTaxRate: number
  } | null
  meals: Array<{
    __typename: 'ProductMeal'
    boundary: MealBoundary
    duration: number
    id: string
    startTime: string
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
      | null
    meal: {
      __typename: 'Meal'
      defaultDuration: number | null
      defaultStartTime: string | null
      id: string
      name: string
      defaultRestaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
      } | null
    }
  }>
  rules: Array<{
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }>
  unitPrice: {
    __typename: 'UnitPrice'
    id: string
    price: number
    type: PriceType
    vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
  }
}

export type AddCatalogProductItemFromCatalogMutationVariables = Exact<{
  input: CatalogProductAddItemsFromCatalogInput
}>

export type AddCatalogProductItemFromCatalogMutation = {
  __typename: 'Mutation'
  catalogProductAddItemsFromCatalog: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type AddCatalogProductItemFromProductTitleMutationVariables = Exact<{
  input: CatalogProductItemAddInput
}>

export type AddCatalogProductItemFromProductTitleMutation = {
  __typename: 'Mutation'
  catalogProductItemAdd: {
    __typename: 'CatalogProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }
}

export type AddCatalogProductItemRuleMutationVariables = Exact<{
  input: ProductItemAddRuleInput
}>

export type AddCatalogProductItemRuleMutation = {
  __typename: 'Mutation'
  catalogProductItemAddRule: {
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }
}

export type AddPurchaseFromCatalogProductMutationVariables = Exact<{
  input: PurchaseProductAddFromCatalogProductInput
}>

export type AddPurchaseFromCatalogProductMutation = {
  __typename: 'Mutation'
  purchaseProductAddFromCatalogProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type AddPurchaseFromProductMutationVariables = Exact<{
  input: PurchaseProductAddFromProductTitleInput
}>

export type AddPurchaseFromProductMutation = {
  __typename: 'Mutation'
  purchaseProductAddFromProductTitle: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type AddPurchaseFromSalesProductMutationVariables = Exact<{
  input: PurchaseProductAddFromSalesProductInput
}>

export type AddPurchaseFromSalesProductMutation = {
  __typename: 'Mutation'
  purchaseProductAddFromSalesProduct: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type AddPurchasesFromSalesProductMutationVariables = Exact<{
  input:
    | Array<PurchaseProductAddFromSalesProductInput>
    | PurchaseProductAddFromSalesProductInput
}>

export type AddPurchasesFromSalesProductMutation = {
  __typename: 'Mutation'
  purchaseProductMassAdd: Array<{
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
}

export type AddSalesProductItemMutationVariables = Exact<{
  input: SalesProductAddItemsInput
}>

export type AddSalesProductItemMutation = {
  __typename: 'Mutation'
  salesProductAddItems: Array<{
    __typename: 'SalesProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }>
}

export type AddSalesProductItemRuleMutationVariables = Exact<{
  input: ProductItemAddRuleInput
}>

export type AddSalesProductItemRuleMutation = {
  __typename: 'Mutation'
  salesProductItemAddRule: {
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }
}

export type CopyCatalogProductToCatalogMutationVariables = Exact<{
  input: CatalogProductCopyInput
}>

export type CopyCatalogProductToCatalogMutation = {
  __typename: 'Mutation'
  catalogProductCopy: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type CopySalesProductToCatalogMutationVariables = Exact<{
  input: SalesProductCopyToCatalogInput
}>

export type CopySalesProductToCatalogMutation = {
  __typename: 'Mutation'
  salesProductCopyToCatalog: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type CopySalesProductToSalesMutationVariables = Exact<{
  input: SalesProductCopyToSalesInput
}>

export type CopySalesProductToSalesMutation = {
  __typename: 'Mutation'
  salesProductCopyToSales: {
    __typename: 'SalesProduct'
    group: string | null
    id: string
    name: string
    notes: string | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'SalesProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
    purchases: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: { __typename: 'Sales'; id: string }
    settings: {
      __typename: 'SalesProductSettings'
      published: boolean
      description: string | null
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      } | null
      purchaseDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    } | null
  }
}

export type CreateCatalogProductMutationVariables = Exact<{
  input: CatalogProductCreateInput
}>

export type CreateCatalogProductMutation = {
  __typename: 'Mutation'
  catalogProductCreate: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type CreateSalesProductMutationVariables = Exact<{
  input: SalesProductCreateInput
}>

export type CreateSalesProductMutation = {
  __typename: 'Mutation'
  salesProductCreate: {
    __typename: 'SalesProduct'
    group: string | null
    id: string
    name: string
    notes: string | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'SalesProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
    purchases: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: { __typename: 'Sales'; id: string }
    settings: {
      __typename: 'SalesProductSettings'
      published: boolean
      description: string | null
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      } | null
      purchaseDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    } | null
  }
}

export type DeleteCatalogProductMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteCatalogProductMutation = {
  __typename: 'Mutation'
  catalogProductDelete: {
    __typename: 'CatalogProductDeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeleteCatalogProductItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteCatalogProductItemMutation = {
  __typename: 'Mutation'
  catalogProductItemDelete: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeleteCatalogProductItemRuleMutationVariables = Exact<{
  productRuleId: Scalars['ID']
}>

export type DeleteCatalogProductItemRuleMutation = {
  __typename: 'Mutation'
  catalogProductItemDeleteRule: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeletePurchaseMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePurchaseMutation = {
  __typename: 'Mutation'
  purchaseProductDelete: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeletePurchasesMutationVariables = Exact<{
  input: PurchaseProductMassDeleteInput
}>

export type DeletePurchasesMutation = {
  __typename: 'Mutation'
  purchaseProductMassDelete: Array<{
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }>
}

export type DeleteSalesProductMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteSalesProductMutation = {
  __typename: 'Mutation'
  salesProductDelete: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeleteSalesProductItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteSalesProductItemMutation = {
  __typename: 'Mutation'
  salesProductDeleteItem: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type DeleteSalesProductItemRuleMutationVariables = Exact<{
  productRuleId: Scalars['ID']
}>

export type DeleteSalesProductItemRuleMutation = {
  __typename: 'Mutation'
  salesProductItemDeleteRule: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type ExtractPurchaseMutationVariables = Exact<{
  input: PurchaseProductExtractInput
}>

export type ExtractPurchaseMutation = {
  __typename: 'Mutation'
  purchaseProductExtract: {
    __typename: 'PurchaseProductExtractPayload'
    extracted: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    source: {
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    } | null
  }
}

export type UpdateCatalogProductMutationVariables = Exact<{
  input: CatalogProductUpdateInput
}>

export type UpdateCatalogProductMutation = {
  __typename: 'Mutation'
  catalogProductUpdate: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type UpdateCatalogProductItemMutationVariables = Exact<{
  input: ProductUpdateItemInput
}>

export type UpdateCatalogProductItemMutation = {
  __typename: 'Mutation'
  catalogProductItemUpdate: {
    __typename: 'CatalogProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }
}

export type UpdateCatalogProductItemMealMutationVariables = Exact<{
  input: ProductMealInput
}>

export type UpdateCatalogProductItemMealMutation = {
  __typename: 'Mutation'
  catalogProductItemUpdateMeal: {
    __typename: 'ProductMeal'
    boundary: MealBoundary
    duration: number
    id: string
    startTime: string
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
      | null
    meal: {
      __typename: 'Meal'
      defaultDuration: number | null
      defaultStartTime: string | null
      id: string
      name: string
      defaultRestaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
      } | null
    }
  }
}

export type UpdateCatalogProductItemRuleMutationVariables = Exact<{
  input: ProductRuleUpdateInput
}>

export type UpdateCatalogProductItemRuleMutation = {
  __typename: 'Mutation'
  catalogProductItemUpdateRule: {
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }
}

export type UpdatePurchaseMutationVariables = Exact<{
  input: PurchaseProductUpdateInput
}>

export type UpdatePurchaseMutation = {
  __typename: 'Mutation'
  purchaseProductUpdate: {
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }
}

export type UpdateSalesProductMutationVariables = Exact<{
  input: SalesProductUpdateInput
}>

export type UpdateSalesProductMutation = {
  __typename: 'Mutation'
  salesProductUpdate: {
    __typename: 'SalesProduct'
    group: string | null
    id: string
    name: string
    notes: string | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'SalesProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
    purchases: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: { __typename: 'Sales'; id: string }
    settings: {
      __typename: 'SalesProductSettings'
      published: boolean
      description: string | null
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      } | null
      purchaseDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    } | null
  }
}

export type UpdateSalesProductItemMutationVariables = Exact<{
  input: ProductUpdateItemInput
}>

export type UpdateSalesProductItemMutation = {
  __typename: 'Mutation'
  salesProductUpdateItem: {
    __typename: 'SalesProductItem'
    code: string
    commissionMode: CommissionMode
    id: string
    internalCost: number | null
    internalPurchase: number | null
    name: string
    quantityUnit: QuantityUnit
    marginalTax: {
      __typename: 'MarginalTax'
      active: boolean
      id: string
      marginalTaxRate: number
    } | null
    meals: Array<{
      __typename: 'ProductMeal'
      boundary: MealBoundary
      duration: number
      id: string
      startTime: string
      location:
        | { __typename: 'ResourceEquipment'; id: string; name: string }
        | { __typename: 'ResourceHuman'; id: string; name: string }
        | { __typename: 'ResourceProperty'; id: string; name: string }
        | { __typename: 'ResourcePropertyNested'; id: string; name: string }
        | { __typename: 'Restaurant'; id: string; name: string }
        | { __typename: 'Room'; id: string; name: string }
        | null
      meal: {
        __typename: 'Meal'
        defaultDuration: number | null
        defaultStartTime: string | null
        id: string
        name: string
        defaultRestaurant: {
          __typename: 'Restaurant'
          id: string
          name: string
        } | null
      }
    }>
    rules: Array<{
      __typename: 'ProductRule'
      id: string
      action:
        | {
            __typename: 'AmountPriceRule'
            amount: number
            type: ActionRuleType
          }
        | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
        | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      condition:
        | {
            __typename: 'AgeCategoryRule'
            type: ConditionRuleType
            ageCategories: Array<{
              __typename: 'AgeCategory'
              key: string
              name: string
              shortName: string
              sortOrder: number
            }>
          }
        | {
            __typename: 'BoundaryRule'
            bounds: Array<BoundType>
            type: ConditionRuleType
          }
        | {
            __typename: 'DateRule'
            dates: Array<string>
            type: ConditionRuleType
          }
        | {
            __typename: 'QuantityRule'
            quantity: number
            type: ConditionRuleType
          }
        | {
            __typename: 'WeekdayRule'
            weekdays: Array<DayOfWeek>
            type: ConditionRuleType
          }
    }>
    unitPrice: {
      __typename: 'UnitPrice'
      id: string
      price: number
      type: PriceType
      vat: { __typename: 'VatCode'; code: string; id: string; vatRate: number }
    }
  }
}

export type UpdateSalesProductItemMealMutationVariables = Exact<{
  input: ProductMealInput
}>

export type UpdateSalesProductItemMealMutation = {
  __typename: 'Mutation'
  salesProductItemUpdateMeal: {
    __typename: 'ProductMeal'
    boundary: MealBoundary
    duration: number
    id: string
    startTime: string
    location:
      | { __typename: 'ResourceEquipment'; id: string; name: string }
      | { __typename: 'ResourceHuman'; id: string; name: string }
      | { __typename: 'ResourceProperty'; id: string; name: string }
      | { __typename: 'ResourcePropertyNested'; id: string; name: string }
      | { __typename: 'Restaurant'; id: string; name: string }
      | { __typename: 'Room'; id: string; name: string }
      | null
    meal: {
      __typename: 'Meal'
      defaultDuration: number | null
      defaultStartTime: string | null
      id: string
      name: string
      defaultRestaurant: {
        __typename: 'Restaurant'
        id: string
        name: string
      } | null
    }
  }
}

export type UpdateSalesProductItemRuleMutationVariables = Exact<{
  input: ProductRuleUpdateInput
}>

export type UpdateSalesProductItemRuleMutation = {
  __typename: 'Mutation'
  salesProductItemUpdateRule: {
    __typename: 'ProductRule'
    id: string
    action:
      | { __typename: 'AmountPriceRule'; amount: number; type: ActionRuleType }
      | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
      | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    condition:
      | {
          __typename: 'AgeCategoryRule'
          type: ConditionRuleType
          ageCategories: Array<{
            __typename: 'AgeCategory'
            key: string
            name: string
            shortName: string
            sortOrder: number
          }>
        }
      | {
          __typename: 'BoundaryRule'
          bounds: Array<BoundType>
          type: ConditionRuleType
        }
      | {
          __typename: 'DateRule'
          dates: Array<string>
          type: ConditionRuleType
        }
      | {
          __typename: 'QuantityRule'
          quantity: number
          type: ConditionRuleType
        }
      | {
          __typename: 'WeekdayRule'
          weekdays: Array<DayOfWeek>
          type: ConditionRuleType
        }
  }
}

export type CatalogProductByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CatalogProductByIdQuery = {
  __typename: 'Query'
  catalogProduct: {
    __typename: 'CatalogProduct'
    id: string
    name: string
    notes: string | null
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'CatalogProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
  }
}

export type SalesAccommodationGroupsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesAccommodationGroupsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      }>
    }
  }
}

export type SalesProductByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesProductByIdQuery = {
  __typename: 'Query'
  salesProduct: {
    __typename: 'SalesProduct'
    group: string | null
    id: string
    name: string
    notes: string | null
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    catalog: {
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }
    categories: Array<{
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      set: { __typename: 'CategorySet'; id: string; name: string }
    }>
    items: Array<{
      __typename: 'SalesProductItem'
      code: string
      commissionMode: CommissionMode
      id: string
      internalCost: number | null
      internalPurchase: number | null
      name: string
      quantityUnit: QuantityUnit
      marginalTax: {
        __typename: 'MarginalTax'
        active: boolean
        id: string
        marginalTaxRate: number
      } | null
      meals: Array<{
        __typename: 'ProductMeal'
        boundary: MealBoundary
        duration: number
        id: string
        startTime: string
        location:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | { __typename: 'Restaurant'; id: string; name: string }
          | { __typename: 'Room'; id: string; name: string }
          | null
        meal: {
          __typename: 'Meal'
          defaultDuration: number | null
          defaultStartTime: string | null
          id: string
          name: string
          defaultRestaurant: {
            __typename: 'Restaurant'
            id: string
            name: string
          } | null
        }
      }>
      rules: Array<{
        __typename: 'ProductRule'
        id: string
        action:
          | {
              __typename: 'AmountPriceRule'
              amount: number
              type: ActionRuleType
            }
          | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
          | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        condition:
          | {
              __typename: 'AgeCategoryRule'
              type: ConditionRuleType
              ageCategories: Array<{
                __typename: 'AgeCategory'
                key: string
                name: string
                shortName: string
                sortOrder: number
              }>
            }
          | {
              __typename: 'BoundaryRule'
              bounds: Array<BoundType>
              type: ConditionRuleType
            }
          | {
              __typename: 'DateRule'
              dates: Array<string>
              type: ConditionRuleType
            }
          | {
              __typename: 'QuantityRule'
              quantity: number
              type: ConditionRuleType
            }
          | {
              __typename: 'WeekdayRule'
              weekdays: Array<DayOfWeek>
              type: ConditionRuleType
            }
      }>
      unitPrice: {
        __typename: 'UnitPrice'
        id: string
        price: number
        type: PriceType
        vat: {
          __typename: 'VatCode'
          code: string
          id: string
          vatRate: number
        }
      }
    }>
    purchases: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: { __typename: 'Sales'; id: string }
    settings: {
      __typename: 'SalesProductSettings'
      published: boolean
      description: string | null
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      } | null
      purchaseDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
    } | null
  }
}

export type SalesProductsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesProductsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    products: Array<{
      __typename: 'SalesProduct'
      group: string | null
      id: string
      name: string
      notes: string | null
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      catalog: {
        __typename: 'Catalog'
        active: boolean
        id: string
        name: string
        company: {
          __typename: 'Seller'
          id: string
          name: string
          succeededBy: { __typename: 'Seller'; id: string } | null
        }
      }
      categories: Array<{
        __typename: 'Category'
        id: string
        isAssignable: boolean
        isEmphasised: boolean
        name: string
        set: { __typename: 'CategorySet'; id: string; name: string }
      }>
      items: Array<{
        __typename: 'SalesProductItem'
        code: string
        commissionMode: CommissionMode
        id: string
        internalCost: number | null
        internalPurchase: number | null
        name: string
        quantityUnit: QuantityUnit
        marginalTax: {
          __typename: 'MarginalTax'
          active: boolean
          id: string
          marginalTaxRate: number
        } | null
        meals: Array<{
          __typename: 'ProductMeal'
          boundary: MealBoundary
          duration: number
          id: string
          startTime: string
          location:
            | { __typename: 'ResourceEquipment'; id: string; name: string }
            | { __typename: 'ResourceHuman'; id: string; name: string }
            | { __typename: 'ResourceProperty'; id: string; name: string }
            | { __typename: 'ResourcePropertyNested'; id: string; name: string }
            | { __typename: 'Restaurant'; id: string; name: string }
            | { __typename: 'Room'; id: string; name: string }
            | null
          meal: {
            __typename: 'Meal'
            defaultDuration: number | null
            defaultStartTime: string | null
            id: string
            name: string
            defaultRestaurant: {
              __typename: 'Restaurant'
              id: string
              name: string
            } | null
          }
        }>
        rules: Array<{
          __typename: 'ProductRule'
          id: string
          action:
            | {
                __typename: 'AmountPriceRule'
                amount: number
                type: ActionRuleType
              }
            | { __typename: 'ExcludePriceRule'; type: ActionRuleType }
            | { __typename: 'OnlyPriceRule'; type: ActionRuleType }
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          condition:
            | {
                __typename: 'AgeCategoryRule'
                type: ConditionRuleType
                ageCategories: Array<{
                  __typename: 'AgeCategory'
                  key: string
                  name: string
                  shortName: string
                  sortOrder: number
                }>
              }
            | {
                __typename: 'BoundaryRule'
                bounds: Array<BoundType>
                type: ConditionRuleType
              }
            | {
                __typename: 'DateRule'
                dates: Array<string>
                type: ConditionRuleType
              }
            | {
                __typename: 'QuantityRule'
                quantity: number
                type: ConditionRuleType
              }
            | {
                __typename: 'WeekdayRule'
                weekdays: Array<DayOfWeek>
                type: ConditionRuleType
              }
        }>
        unitPrice: {
          __typename: 'UnitPrice'
          id: string
          price: number
          type: PriceType
          vat: {
            __typename: 'VatCode'
            code: string
            id: string
            vatRate: number
          }
        }
      }>
      purchases: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      sales: { __typename: 'Sales'; id: string }
      settings: {
        __typename: 'SalesProductSettings'
        published: boolean
        description: string | null
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        } | null
        purchaseDates: {
          __typename: 'LocalDateRange'
          end: string
          start: string
        } | null
      } | null
    }>
  }
}

export type SalesProductsCompactQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesProductsCompactQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    products: Array<{ __typename: 'SalesProduct'; id: string; name: string }>
  }
}

export type SalesProductsGroupsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesProductsGroupsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    products: Array<{
      __typename: 'SalesProduct'
      group: string | null
      id: string
    }>
  }
}

export type AccommodationLevelFragment = {
  __typename: 'AccommodationLevel'
  id: string
  name: string
}

export type AccommodationLevelsQueryVariables = Exact<{ [key: string]: never }>

export type AccommodationLevelsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    accommodationLevels: Array<{
      __typename: 'AccommodationLevel'
      id: string
      name: string
    }>
  }
}

export type AgeCategoryFragment = {
  __typename: 'AgeCategory'
  key: string
  name: string
  shortName: string
  sortOrder: number
}

export type AgeCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type AgeCategoriesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    ageCategories: Array<{
      __typename: 'AgeCategory'
      key: string
      name: string
      shortName: string
      sortOrder: number
    }>
  }
}

export type BuildingFragment = {
  __typename: 'Building'
  id: string
  name: string
}

export type BuildingsQueryVariables = Exact<{ [key: string]: never }>

export type BuildingsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    buildings: Array<{ __typename: 'Building'; id: string; name: string }>
  }
}

export type ResourceAvailabilitiesQueryVariables = Exact<{
  input: ResourcesAvailabilityInput
}>

export type ResourceAvailabilitiesQuery = {
  __typename: 'Query'
  resourceAvailability: Array<{
    __typename: 'ResourceAvailability'
    resourceId: string
    availability: Array<{
      __typename: 'ResourceAvailabilitySlot'
      available: number
      offset: number
      totalCapacity: number
    }>
  }>
}

export type CatalogFragment = {
  __typename: 'Catalog'
  active: boolean
  id: string
  name: string
  company: {
    __typename: 'Seller'
    id: string
    name: string
    succeededBy: { __typename: 'Seller'; id: string } | null
  }
}

export type CatalogsQueryVariables = Exact<{ [key: string]: never }>

export type CatalogsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    catalogs: Array<{
      __typename: 'Catalog'
      active: boolean
      id: string
      name: string
      company: {
        __typename: 'Seller'
        id: string
        name: string
        succeededBy: { __typename: 'Seller'; id: string } | null
      }
    }>
  }
}

export type CategoryFragment = {
  __typename: 'Category'
  id: string
  isAssignable: boolean
  isEmphasised: boolean
  name: string
  parent: { __typename: 'Category'; id: string } | null
  set: { __typename: 'CategorySet'; id: string; name: string }
}

export type CategoryQueryVariables = Exact<{
  categoryId: Scalars['ID']
}>

export type CategoryQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    category: {
      __typename: 'Category'
      id: string
      isAssignable: boolean
      isEmphasised: boolean
      name: string
      children: Array<{
        __typename: 'Category'
        id: string
        isAssignable: boolean
        isEmphasised: boolean
        name: string
        parent: { __typename: 'Category'; id: string } | null
        set: { __typename: 'CategorySet'; id: string; name: string }
      }>
      parent: { __typename: 'Category'; id: string } | null
      set: { __typename: 'CategorySet'; id: string; name: string }
    }
  }
}

export type CategorySetsByTargetQueryVariables = Exact<{
  targetKey: Scalars['String']
}>

export type CategorySetsByTargetQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    categorySetsByTarget: Array<{
      __typename: 'CategorySet'
      id: string
      name: string
      rootCategories: Array<{
        __typename: 'Category'
        id: string
        isAssignable: boolean
        isEmphasised: boolean
        name: string
        children: Array<{
          __typename: 'Category'
          id: string
          isAssignable: boolean
          isEmphasised: boolean
          name: string
          children: Array<{
            __typename: 'Category'
            id: string
            isAssignable: boolean
            isEmphasised: boolean
            name: string
            children: Array<{
              __typename: 'Category'
              id: string
              isAssignable: boolean
              isEmphasised: boolean
              name: string
              children: Array<{
                __typename: 'Category'
                id: string
                isAssignable: boolean
                isEmphasised: boolean
                name: string
                parent: { __typename: 'Category'; id: string } | null
                set: { __typename: 'CategorySet'; id: string; name: string }
              }>
              parent: { __typename: 'Category'; id: string } | null
              set: { __typename: 'CategorySet'; id: string; name: string }
            }>
            parent: { __typename: 'Category'; id: string } | null
            set: { __typename: 'CategorySet'; id: string; name: string }
          }>
          parent: { __typename: 'Category'; id: string } | null
          set: { __typename: 'CategorySet'; id: string; name: string }
        }>
        parent: { __typename: 'Category'; id: string } | null
        set: { __typename: 'CategorySet'; id: string; name: string }
      }>
    }>
  }
}

type Customer_CustomerOrganization_Fragment = {
  __typename: 'CustomerOrganization'
  defaultContactId: string | null
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  contacts: Array<{
    __typename: 'CustomerOrganizationContact'
    email: string | null
    firstName: string
    id: string
    lastName: string
    notes: string | null
    phone: string | null
  }>
  eInvoicingAddress: {
    __typename: 'EInvoicingAddress'
    address: string
    operator: string
  } | null
  organization: {
    __typename: 'CustomerOrganizationData'
    businessId: string | null
    name: string | null
  }
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

type Customer_CustomerPerson_Fragment = {
  __typename: 'CustomerPerson'
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  person: {
    __typename: 'CustomerPersonData'
    email: string | null
    firstName: string
    lastName: string
    phone: string | null
  }
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

export type CustomerFragment =
  | Customer_CustomerOrganization_Fragment
  | Customer_CustomerPerson_Fragment

type CustomerBase_CustomerOrganization_Fragment = {
  __typename: 'CustomerOrganization'
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

type CustomerBase_CustomerPerson_Fragment = {
  __typename: 'CustomerPerson'
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

export type CustomerBaseFragment =
  | CustomerBase_CustomerOrganization_Fragment
  | CustomerBase_CustomerPerson_Fragment

export type CustomerOrganizationFragment = {
  __typename: 'CustomerOrganization'
  defaultContactId: string | null
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  contacts: Array<{
    __typename: 'CustomerOrganizationContact'
    email: string | null
    firstName: string
    id: string
    lastName: string
    notes: string | null
    phone: string | null
  }>
  eInvoicingAddress: {
    __typename: 'EInvoicingAddress'
    address: string
    operator: string
  } | null
  organization: {
    __typename: 'CustomerOrganizationData'
    businessId: string | null
    name: string | null
  }
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

export type CustomerPersonFragment = {
  __typename: 'CustomerPerson'
  customerNumber: string
  defaultAddressId: string | null
  id: string
  publicName: string | null
  person: {
    __typename: 'CustomerPersonData'
    email: string | null
    firstName: string
    lastName: string
    phone: string | null
  }
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    label: string | null
    postalAddress: {
      __typename: 'PostalAddressType'
      address1: string | null
      address2: string | null
      city: string | null
      country: string | null
      postcode: string | null
    } | null
  }>
  labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
}

export type CustomerReferenceFragment = {
  __typename: 'CustomerReference'
  address: { __typename: 'CustomerAddress'; id: string } | null
  contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
  customer:
    | {
        __typename: 'CustomerOrganization'
        defaultContactId: string | null
        customerNumber: string
        defaultAddressId: string | null
        id: string
        publicName: string | null
        contacts: Array<{
          __typename: 'CustomerOrganizationContact'
          email: string | null
          firstName: string
          id: string
          lastName: string
          notes: string | null
          phone: string | null
        }>
        eInvoicingAddress: {
          __typename: 'EInvoicingAddress'
          address: string
          operator: string
        } | null
        organization: {
          __typename: 'CustomerOrganizationData'
          businessId: string | null
          name: string | null
        }
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          label: string | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        }>
        labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
      }
    | {
        __typename: 'CustomerPerson'
        customerNumber: string
        defaultAddressId: string | null
        id: string
        publicName: string | null
        person: {
          __typename: 'CustomerPersonData'
          email: string | null
          firstName: string
          lastName: string
          phone: string | null
        }
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          label: string | null
          postalAddress: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        }>
        labels: Array<{ __typename: 'CustomerLabel'; id: string; name: string }>
      }
}

export type UseCustomerQueryVariables = Exact<{
  customerNumber: Scalars['String']
}>

export type UseCustomerQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    customer:
      | {
          __typename: 'CustomerOrganization'
          defaultContactId: string | null
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          contacts: Array<{
            __typename: 'CustomerOrganizationContact'
            email: string | null
            firstName: string
            id: string
            lastName: string
            notes: string | null
            phone: string | null
          }>
          eInvoicingAddress: {
            __typename: 'EInvoicingAddress'
            address: string
            operator: string
          } | null
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          person: {
            __typename: 'CustomerPersonData'
            email: string | null
            firstName: string
            lastName: string
            phone: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
      | null
  }
}

export type SalesCustomerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesCustomerQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
  }
}

export type CreateCustomerAddressMutationVariables = Exact<{
  input: CustomerCreateAddressInput
}>

export type CreateCustomerAddressMutation = {
  __typename: 'Mutation'
  customerCreateAddress: {
    __typename: 'CustomerCreateAddressPayload'
    address: {
      __typename: 'CustomerAddress'
      id: string
      label: string | null
      postalAddress: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    }
  }
}

export type CreateCustomerOrganizationMutationVariables = Exact<{
  input: CustomerOrganizationCreateInput
}>

export type CreateCustomerOrganizationMutation = {
  __typename: 'Mutation'
  customerOrganizationCreate: {
    __typename: 'CustomerOrganizationCreatePayload'
    customer: {
      __typename: 'CustomerOrganization'
      customerNumber: string
      defaultAddressId: string | null
      defaultContactId: string | null
      id: string
    }
  }
}

export type CreateCustomerPersonMutationVariables = Exact<{
  input: CustomerPersonCreateInput
}>

export type CreateCustomerPersonMutation = {
  __typename: 'Mutation'
  customerPersonCreate: {
    __typename: 'CustomerPersonCreatePayload'
    customer: {
      __typename: 'CustomerPerson'
      customerNumber: string
      defaultAddressId: string | null
      id: string
    }
  }
}

export type CreateOrganizationCustomerContactMutationVariables = Exact<{
  input: CustomerOrganizationCreateContactInput
}>

export type CreateOrganizationCustomerContactMutation = {
  __typename: 'Mutation'
  customerOrganizationCreateContact: {
    __typename: 'CustomerOrganizationCreateContactPayload'
    contact: {
      __typename: 'CustomerOrganizationContact'
      email: string | null
      firstName: string
      id: string
      lastName: string
      notes: string | null
      phone: string | null
    }
  }
}

export type RemoveCustomerAddressMutationVariables = Exact<{
  input: CustomerRemoveAddressInput
}>

export type RemoveCustomerAddressMutation = {
  __typename: 'Mutation'
  customerRemoveAddress: {
    __typename: 'CustomerRemoveAddressPayload'
    removedAddressId: string
  }
}

export type RemoveOrganizationCustomerContactMutationVariables = Exact<{
  input: CustomerOrganizationRemoveContactInput
}>

export type RemoveOrganizationCustomerContactMutation = {
  __typename: 'Mutation'
  customerOrganizationRemoveContact: {
    __typename: 'CustomerOrganizationRemoveContactPayload'
    removedContactId: string
  }
}

export type SetCustomerDefaultAddressMutationVariables = Exact<{
  input: CustomerSetDefaultAddressInput
}>

export type SetCustomerDefaultAddressMutation = {
  __typename: 'Mutation'
  customerSetDefaultAddress: {
    __typename: 'CustomerSetDefaultAddressPayload'
    customer:
      | {
          __typename: 'CustomerOrganization'
          defaultAddressId: string | null
          id: string
        }
      | {
          __typename: 'CustomerPerson'
          defaultAddressId: string | null
          id: string
        }
  }
}

export type SetOrganizationCustomerDefaultContactMutationVariables = Exact<{
  input: CustomerOrganizationSetDefaultContactInput
}>

export type SetOrganizationCustomerDefaultContactMutation = {
  __typename: 'Mutation'
  customerOrganizationSetDefaultContact: {
    __typename: 'CustomerOrganizationSetDefaultContactPayload'
    customer: {
      __typename: 'CustomerOrganization'
      defaultContactId: string | null
      id: string
    }
  }
}

export type UpdateCustomerAddressMutationVariables = Exact<{
  input: CustomerUpdateAddressInput
}>

export type UpdateCustomerAddressMutation = {
  __typename: 'Mutation'
  customerUpdateAddress: {
    __typename: 'CustomerUpdateAddressPayload'
    address: {
      __typename: 'CustomerAddress'
      id: string
      label: string | null
      postalAddress: {
        __typename: 'PostalAddressType'
        address1: string | null
        address2: string | null
        city: string | null
        country: string | null
        postcode: string | null
      } | null
    }
  }
}

export type UpdateCustomerOrganizationMutationVariables = Exact<{
  input: CustomerOrganizationUpdateInput
}>

export type UpdateCustomerOrganizationMutation = {
  __typename: 'Mutation'
  customerOrganizationUpdate: {
    __typename: 'CustomerOrganizationUpdatePayload'
    customer: {
      __typename: 'CustomerOrganization'
      id: string
      organization: {
        __typename: 'CustomerOrganizationData'
        businessId: string | null
        name: string | null
      }
    }
  }
}

export type UpdateCustomerPersonMutationVariables = Exact<{
  input: CustomerPersonUpdateInput
}>

export type UpdateCustomerPersonMutation = {
  __typename: 'Mutation'
  customerPersonUpdate: {
    __typename: 'CustomerPersonUpdatePayload'
    customer: {
      __typename: 'CustomerPerson'
      id: string
      person: {
        __typename: 'CustomerPersonData'
        email: string | null
        firstName: string
        lastName: string
        phone: string | null
      }
    } | null
  }
}

export type UpdateOrganizationCustomerContactMutationVariables = Exact<{
  input: CustomerOrganizationUpdateContactInput
}>

export type UpdateOrganizationCustomerContactMutation = {
  __typename: 'Mutation'
  customerOrganizationUpdateContact: {
    __typename: 'CustomerOrganizationUpdateContactPayload'
    contact: {
      __typename: 'CustomerOrganizationContact'
      email: string | null
      firstName: string
      id: string
      lastName: string
      notes: string | null
      phone: string | null
    }
  }
}

export type UpdateOrganizationCustomerEInvoicingAddressMutationVariables =
  Exact<{
    input: CustomerOrganizationUpdateEInvoicingAddressInput
  }>

export type UpdateOrganizationCustomerEInvoicingAddressMutation = {
  __typename: 'Mutation'
  customerOrganizationUpdateEInvoicingAddress: {
    __typename: 'CustomerOrganizationUpdateEInvoicingAddressPayload'
    customer: {
      __typename: 'CustomerOrganization'
      id: string
      eInvoicingAddress: {
        __typename: 'EInvoicingAddress'
        address: string
        operator: string
      } | null
    }
  }
}

export type CustomerLabelFragment = {
  __typename: 'CustomerLabel'
  id: string
  name: string
}

export type UseCustomerLabelsQueryVariables = Exact<{ [key: string]: never }>

export type UseCustomerLabelsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    customerLabels: Array<{
      __typename: 'CustomerLabel'
      id: string
      name: string
    }>
  }
}

export type DimensionFragment = {
  __typename: 'Dimension'
  description: string | null
  dimensionHierarchyRoot: string | null
  hierarchyName: string | null
  id: string
  name: string
  sortOrder: number
  labels: Array<{
    __typename: 'DimensionLabel'
    active: boolean
    description: string | null
    id: string
    name: string
  }>
}

export type DimensionsByTargetQueryVariables = Exact<{
  target: InputMaybe<DimensionTarget>
}>

export type DimensionsByTargetQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    dimensionsByTarget: Array<{
      __typename: 'Dimension'
      description: string | null
      dimensionHierarchyRoot: string | null
      hierarchyName: string | null
      id: string
      name: string
      sortOrder: number
      labels: Array<{
        __typename: 'DimensionLabel'
        active: boolean
        description: string | null
        id: string
        name: string
      }>
    }>
  }
}

export type LanguagesQueryVariables = Exact<{ [key: string]: never }>

export type LanguagesQuery = {
  __typename: 'Query'
  registry: { __typename: 'Registry'; languages: Array<string> }
}

export type PaymentTerminalsQueryVariables = Exact<{ [key: string]: never }>

export type PaymentTerminalsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    paymentTerminals: Array<{
      __typename: 'PaymentTerminal'
      id: string
      name: string
      terminalConnected: boolean
      pointOfSale: {
        __typename: 'PointOfSale'
        id: string
        name: string
      } | null
    }>
  }
}

export type PaymentTypeOptionsQueryVariables = Exact<{
  input: PaymentTypeOptionInput
}>

export type PaymentTypeOptionsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    paymentTypeOptions: Array<{
      __typename: 'PaymentTypeOption'
      enabled: boolean
      type: PaymentType
      dynamicType:
        | { __typename: 'OtherPaymentType'; id: string; name: string }
        | { __typename: 'VoucherProvider'; id: string; name: string }
        | null
      reason: {
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      } | null
    }>
  }
}

export type PointsOfSalesQueryVariables = Exact<{ [key: string]: never }>

export type PointsOfSalesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    pointOfSales: Array<{ __typename: 'PointOfSale'; id: string; name: string }>
  }
}

export type RoomFragment = {
  __typename: 'Room'
  beds: number
  extraBeds: number
  floor: number | null
  id: string
  number: string
  building: { __typename: 'Building'; name: string }
  features: Array<{
    __typename: 'RoomLabel'
    name: string
    icon: string | null
    id: string
    shortName: string
  }>
  roomType: { __typename: 'RoomType'; name: string }
}

export type RoomsQueryVariables = Exact<{ [key: string]: never }>

export type RoomsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    rooms: Array<{
      __typename: 'Room'
      beds: number
      extraBeds: number
      floor: number | null
      id: string
      number: string
      building: { __typename: 'Building'; name: string }
      features: Array<{
        __typename: 'RoomLabel'
        name: string
        icon: string | null
        id: string
        shortName: string
      }>
      roomType: { __typename: 'RoomType'; name: string }
    }>
  }
}

export type RoomTypeFragment = {
  __typename: 'RoomType'
  id: string
  name: string
  accommodationLevel: { __typename: 'AccommodationLevel'; name: string }
  rooms: Array<{ __typename: 'Room'; id: string }>
}

export type RoomTypesQueryVariables = Exact<{ [key: string]: never }>

export type RoomTypesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    roomTypes: Array<{
      __typename: 'RoomType'
      id: string
      name: string
      accommodationLevel: { __typename: 'AccommodationLevel'; name: string }
      rooms: Array<{ __typename: 'Room'; id: string }>
    }>
  }
}

export type UserGroupsQueryVariables = Exact<{ [key: string]: never }>

export type UserGroupsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    userGroups: Array<{ __typename: 'UserGroup'; id: string; name: string }>
  }
}

export type OpenAdvancesQueryVariables = Exact<{
  input: OpenAdvancesInput
}>

export type OpenAdvancesQuery = {
  __typename: 'Query'
  openAdvances: Array<{
    __typename: 'OpenAdvance'
    amountWithoutServiceFeeOrVat: number | null
    customerName: string | null
    customerNumber: string | null
    invoiceDate: string | null
    invoiceId: string | null
    invoiceNumber: string | null
    salesEnd: string | null
    salesId: string | null
    salesNumber: string | null
    sellerShortName: string | null
  }>
}

export type UseProductLinesTablePriceElementsFragment = {
  __typename: 'PriceElements'
  amount: number
  amountVatIncluded: number
  vatAmount: number
}

export type UseProductLinesTableQueryVariables = Exact<{
  input: InvoicesByIdsInput
}>

export type UseProductLinesTableQuery = {
  __typename: 'Query'
  invoicesByIds: {
    __typename: 'InvoicesPayload'
    invoices: Array<{
      __typename: 'Invoice'
      items: Array<{
        __typename: 'OrderPacket'
        id: string
        name: string
        quantity: number
        packetTotal: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        packetUnitPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
        products: Array<{
          __typename: 'OrderProduct'
          code: string | null
          id: string
          name: string
          pricesPerPacket: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        }>
      }>
      paymentInfo: {
        __typename: 'PaymentInfo'
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }
    }>
  }
}

export type InvoiceDocumentQueryVariables = Exact<{
  input: InvoicesByIdsInput
}>

export type InvoiceDocumentQuery = {
  __typename: 'Query'
  invoicesByIds: {
    __typename: 'InvoicesPayload'
    invoices: Array<{
      __typename: 'Invoice'
      id: string
      document: {
        __typename: 'Document'
        downloadedAt: string | null
        fileStatus: DocumentFileStatus | null
        id: string
        language: string | null
        name: string
        status: DocumentStatus
        attachments: Array<{
          __typename: 'FileItemInfo'
          created: string
          etag: string | null
          fileName: string
          lastModified: string
          mimeType: string
          id: string
          size: number
          info: {
            __typename: 'FileIdentifier'
            fileName: string
            folder: string
            ownerType: FileOwner
            ownerId: string
          }
        }>
        file: {
          __typename: 'FileInfo'
          objectName: string
          fileName: string
          mimeType: string
          created: string
        } | null
        template: {
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }
        templateOptions: Array<{
          __typename: 'DocumentTemplate'
          documentName: string
          documentType: DocumentType
          filesAllowed: boolean
          id: string
          name: string
        }>
        userAttributes: Array<{
          __typename: 'DocumentUserAttribute'
          active: boolean
          editorValue: string | null
          id: string
          key: string
          name: string
          templateValue: string | null
        }>
      } | null
    }>
  }
}

export type UsePaymentsForIntervalPointOfSaleFragment = {
  __typename: 'PointOfSale'
  id: string
  name: string
}

export type UsePaymentsForIntervalQueryVariables = Exact<{
  input: PaymentsInput
}>

export type UsePaymentsForIntervalQuery = {
  __typename: 'Query'
  payments: {
    __typename: 'PaymentsPayload'
    payments: Array<{
      __typename: 'Payment'
      amount: number
      id: string
      type: PaymentType
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      details:
        | {
            __typename: 'PaymentCashDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentCreditCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | {
            __typename: 'PaymentGiftCardDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
          }
        | { __typename: 'PaymentOtherDetails' }
        | { __typename: 'PaymentReferenceTransferDetails' }
        | { __typename: 'PaymentRefundDetails' }
        | { __typename: 'PaymentReservationFeeDetails' }
        | {
            __typename: 'PaymentVoucherDetails'
            notes: string
            pointOfSale: {
              __typename: 'PointOfSale'
              id: string
              name: string
            } | null
            type: { __typename: 'VoucherProvider'; id: string; name: string }
          }
        | null
      invoice: {
        __typename: 'Invoice'
        id: string
        invoiceNumber: string | null
        customer: {
          __typename: 'ParsedCustomerDetails'
          customerNumber: string | null
          name: string | null
        } | null
        order: {
          __typename: 'Order'
          sales: {
            __typename: 'Sales'
            id: string
            name: string | null
            orderNumber: number | null
            type: SalesType
            facet: {
              __typename: 'SalesFacet'
              abbreviation: string
              color: string
              id: string
              name: string
            }
          }
        }
        seller: {
          __typename: 'Seller'
          id: string
          name: string
          shortName: string | null
        } | null
      } | null
    }>
  }
}

export type ReservationReportDimensionFragment = {
  __typename: 'DimensionReference'
  id: string
  selection: Array<{
    __typename: 'DimensionSelectionItem'
    dimension: {
      __typename: 'Dimension'
      id: string
      name: string
      sortOrder: number
    }
    selectedLabel: {
      __typename: 'DimensionLabel'
      id: string
      name: string
    } | null
  }>
}

export type ReservationReportExcelQueryVariables = Exact<{
  input: ResourceReservationsInput
}>

export type ReservationReportExcelQuery = {
  __typename: 'Query'
  resourceReservations: {
    __typename: 'ReservationsPayload'
    reservations: Array<{
      __typename: 'ReservationsByResource'
      reservations: Array<{
        __typename: 'ResourceReservation'
        end: string
        internalNote: string | null
        start: string
        dimensions: {
          __typename: 'DimensionReference'
          id: string
          selection: Array<{
            __typename: 'DimensionSelectionItem'
            dimension: {
              __typename: 'Dimension'
              id: string
              name: string
              sortOrder: number
            }
            selectedLabel: {
              __typename: 'DimensionLabel'
              id: string
              name: string
            } | null
          }>
        } | null
        resource:
          | { __typename: 'ResourceEquipment'; id: string; name: string }
          | { __typename: 'ResourceHuman'; id: string; name: string }
          | { __typename: 'ResourceProperty'; id: string; name: string }
          | { __typename: 'ResourcePropertyNested'; id: string; name: string }
          | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          name: string | null
          dimensions: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              dimension: {
                __typename: 'Dimension'
                id: string
                name: string
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                id: string
                name: string
              } | null
            }>
          }
          seller: { __typename: 'Seller'; shortName: string | null } | null
        }
      }>
    }>
  }
}

export type CalendarResourceReservationFragment = {
  __typename: 'ResourceReservation'
  description: string | null
  end: string
  id: string
  resourceQuantity: number
  start: string
  group: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  } | null
  resource:
    | { __typename: 'ResourceEquipment'; id: string; isPooled: boolean }
    | { __typename: 'ResourceHuman'; id: string; isPooled: boolean }
    | { __typename: 'ResourceProperty'; id: string; isPooled: boolean }
    | { __typename: 'ResourcePropertyNested'; id: string; isPooled: boolean }
    | null
  sales: {
    __typename: 'Sales'
    id: string
    locked: boolean
    name: string | null
    orderNumber: number | null
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            organization: {
              __typename: 'CustomerOrganizationData'
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      color: string
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
  }
}

export type ResourceReservationFragment = {
  __typename: 'ResourceReservation'
  color: string | null
  customerVisibility: ResourceReservationCustomerVisibility
  customerTimeVisibility: Visibility
  description: string | null
  descriptionOptions: Array<string>
  end: string
  id: string
  internalNote: string | null
  displayMessage: string | null
  resourceQuantity: number
  start: string
  dimensions: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      id: string
      dimension: {
        __typename: 'Dimension'
        description: string | null
        dimensionHierarchyRoot: string | null
        hierarchyName: string | null
        id: string
        name: string
        sortOrder: number
      }
      selectedLabel: {
        __typename: 'DimensionLabel'
        active: boolean
        description: string | null
        id: string
        name: string
      } | null
      validatedOptions: Array<{
        __typename: 'DimensionLabelOption'
        id: string
        validationCategory: DimensionLabelValidationCategory
        label: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        }
      }>
    }>
  } | null
  group: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  } | null
  issues: Array<{
    __typename: 'ValidationIssue'
    key: string
    code: number
    level: IssueLevel
  }>
  recommendedProducts: Array<{
    __typename: 'ProductTitleCalendar'
    id: string
    type: string
    salesType: CatalogSalesType | null
    code: string | null
    name: string
    vatRate: number
    unitPrice: number
    quantityUnit: QuantityUnit
    catalog: { __typename: 'Catalog'; id: string; name: string }
  }>
  resource:
    | {
        __typename: 'ResourceEquipment'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceHuman'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceProperty'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | null
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  sales: {
    __typename: 'Sales'
    commissionRate: number
    id: string
    locked: boolean
    name: string | null
    orderNumber: number | null
    type: SalesType
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      color: string
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
    orders: Array<{
      __typename: 'Order'
      id: string
      name: string | null
      orderNumber: number
      customer: {
        __typename: 'OrderCustomer'
        detailsLocked: boolean
        details: {
          __typename: 'ParsedCustomerDetails'
          businessId: string | null
          contactPerson: string | null
          customerNumber: string | null
          email: string | null
          name: string | null
          phone: string | null
          type: CustomerType | null
          address: {
            __typename: 'PostalAddressType'
            address1: string | null
            address2: string | null
            city: string | null
            country: string | null
            postcode: string | null
          } | null
        }
        inheritedCustomer: {
          __typename: 'CustomerReference'
          customer:
            | { __typename: 'CustomerOrganization'; customerNumber: string }
            | { __typename: 'CustomerPerson'; customerNumber: string }
        } | null
      } | null
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
    }>
    seller: { __typename: 'Seller'; id: string; name: string } | null
  }
  tasks: Array<{
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }>
}

export type ResourceReservationDimensionsFragment = {
  __typename: 'DimensionReference'
  id: string
  selection: Array<{
    __typename: 'DimensionSelectionItem'
    id: string
    dimension: {
      __typename: 'Dimension'
      description: string | null
      dimensionHierarchyRoot: string | null
      hierarchyName: string | null
      id: string
      name: string
      sortOrder: number
    }
    selectedLabel: {
      __typename: 'DimensionLabel'
      active: boolean
      description: string | null
      id: string
      name: string
    } | null
    validatedOptions: Array<{
      __typename: 'DimensionLabelOption'
      id: string
      validationCategory: DimensionLabelValidationCategory
      label: {
        __typename: 'DimensionLabel'
        active: boolean
        description: string | null
        id: string
        name: string
      }
    }>
  }>
}

type ResourceReservationResource_ResourceEquipment_Fragment = {
  __typename: 'ResourceEquipment'
  id: string
  internalInfo: string | null
  isPooled: boolean
  name: string
  type: ResourceType
}

type ResourceReservationResource_ResourceHuman_Fragment = {
  __typename: 'ResourceHuman'
  id: string
  internalInfo: string | null
  isPooled: boolean
  name: string
  type: ResourceType
}

type ResourceReservationResource_ResourceProperty_Fragment = {
  __typename: 'ResourceProperty'
  id: string
  internalInfo: string | null
  isPooled: boolean
  name: string
  type: ResourceType
}

type ResourceReservationResource_ResourcePropertyNested_Fragment = {
  __typename: 'ResourcePropertyNested'
  id: string
  internalInfo: string | null
  isPooled: boolean
  name: string
  type: ResourceType
}

export type ResourceReservationResourceFragment =
  | ResourceReservationResource_ResourceEquipment_Fragment
  | ResourceReservationResource_ResourceHuman_Fragment
  | ResourceReservationResource_ResourceProperty_Fragment
  | ResourceReservationResource_ResourcePropertyNested_Fragment

export type ResourceReservationSalesFragment = {
  __typename: 'Sales'
  commissionRate: number
  id: string
  locked: boolean
  name: string | null
  orderNumber: number | null
  type: SalesType
  customer: {
    __typename: 'CustomerReference'
    address: { __typename: 'CustomerAddress'; id: string } | null
    contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
    customer:
      | {
          __typename: 'CustomerOrganization'
          defaultContactId: string | null
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          contacts: Array<{
            __typename: 'CustomerOrganizationContact'
            email: string | null
            firstName: string
            id: string
            lastName: string
            notes: string | null
            phone: string | null
          }>
          eInvoicingAddress: {
            __typename: 'EInvoicingAddress'
            address: string
            operator: string
          } | null
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          person: {
            __typename: 'CustomerPersonData'
            email: string | null
            firstName: string
            lastName: string
            phone: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
  } | null
  facet: {
    __typename: 'SalesFacet'
    color: string
    id: string
    features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
  }
  lifecycle: {
    __typename: 'SalesLifecycle'
    state: {
      __typename: 'SalesState'
      key: string
      systemState: SalesSystemState
      names: Array<{
        __typename: 'LocalizedContent'
        locale: string
        content: string
      }>
    }
  }
  orders: Array<{
    __typename: 'Order'
    id: string
    name: string | null
    orderNumber: number
    customer: {
      __typename: 'OrderCustomer'
      detailsLocked: boolean
      details: {
        __typename: 'ParsedCustomerDetails'
        businessId: string | null
        contactPerson: string | null
        customerNumber: string | null
        email: string | null
        name: string | null
        phone: string | null
        type: CustomerType | null
        address: {
          __typename: 'PostalAddressType'
          address1: string | null
          address2: string | null
          city: string | null
          country: string | null
          postcode: string | null
        } | null
      }
      inheritedCustomer: {
        __typename: 'CustomerReference'
        customer:
          | { __typename: 'CustomerOrganization'; customerNumber: string }
          | { __typename: 'CustomerPerson'; customerNumber: string }
      } | null
    } | null
    lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
  }>
  seller: { __typename: 'Seller'; id: string; name: string } | null
}

export type SalesResourceReservationFragment = {
  __typename: 'ResourceReservation'
  color: string | null
  customerVisibility: ResourceReservationCustomerVisibility
  description: string | null
  end: string
  id: string
  internalNote: string | null
  displayMessage: string | null
  resourceQuantity: number
  start: string
  dimensions: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      selectedLabel: {
        __typename: 'DimensionLabel'
        active: boolean
        description: string | null
        id: string
        name: string
      } | null
    }>
  } | null
  group: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  } | null
  issues: Array<{
    __typename: 'ValidationIssue'
    key: string
    code: number
    level: IssueLevel
  }>
  resource:
    | {
        __typename: 'ResourceEquipment'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceHuman'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceProperty'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | null
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  tasks: Array<{
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
  }>
}

export type SalesResourceReservationForGroupFragment = {
  __typename: 'ResourceReservation'
  end: string
  id: string
  start: string
  group: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  } | null
  resource:
    | {
        __typename: 'ResourceEquipment'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceHuman'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceProperty'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | null
}

export type SalesResourceReservationPurchaseFragment = {
  __typename: 'PurchaseProduct'
  id: string
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  product: { __typename: 'SalesProduct'; id: string; name: string }
  status: {
    __typename: 'PurchaseProductStatus'
    issues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
    validatedActions: Array<{
      __typename: 'PurchaseProductActionValidated'
      action: PurchaseProductAction
      valid: boolean
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
    }>
  }
  totalPrice: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type ResourceConfigQueryVariables = Exact<{ [key: string]: never }>

export type ResourceConfigQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    resourceConfig: {
      __typename: 'ResourceConfig'
      displayOption: {
        __typename: 'ResourceDisplayOption'
        roomMessageDisplayOption: DisplayOption
      }
    }
  }
}

export type UseResourceReservationQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UseResourceReservationQuery = {
  __typename: 'Query'
  resourceReservation: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type UseSalesResourceReservationsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UseSalesResourceReservationsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    resourceReservations: Array<{
      __typename: 'ResourceReservation'
      color: string | null
      customerVisibility: ResourceReservationCustomerVisibility
      description: string | null
      end: string
      id: string
      internalNote: string | null
      displayMessage: string | null
      resourceQuantity: number
      start: string
      dimensions: {
        __typename: 'DimensionReference'
        id: string
        selection: Array<{
          __typename: 'DimensionSelectionItem'
          selectedLabel: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          } | null
        }>
      } | null
      group: {
        __typename: 'ResourceReservationGroup'
        end: string
        id: string
        name: string | null
        number: number
        start: string
      } | null
      issues: Array<{
        __typename: 'ValidationIssue'
        key: string
        code: number
        level: IssueLevel
      }>
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | null
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      tasks: Array<{
        __typename: 'Task'
        description: string | null
        dueDate: string | null
        dueTime: string | null
        id: string
        isOpen: boolean
        name: string | null
        number: number
        assignee:
          | { __typename: 'User'; id: string }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
        purchaseProducts: Array<{
          __typename: 'PurchaseProduct'
          id: string
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          status: {
            __typename: 'PurchaseProductStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
            validatedActions: Array<{
              __typename: 'PurchaseProductActionValidated'
              action: PurchaseProductAction
              valid: boolean
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }>
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        }>
      }>
    }>
  }
}

export type UseSalesResourceReservationsForGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UseSalesResourceReservationsForGroupQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    resourceReservations: Array<{
      __typename: 'ResourceReservation'
      end: string
      id: string
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        end: string
        id: string
        name: string | null
        number: number
        start: string
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | null
    }>
  }
}

export type CreateResourceReservationMutationVariables = Exact<{
  input: ResourceReservationCreateInput
}>

export type CreateResourceReservationMutation = {
  __typename: 'Mutation'
  resourceReservationCreate:
    | { __typename: 'ReservationAvailabilityError'; message: string }
    | { __typename: 'ReservationAvailabilityWarning'; message: string }
    | {
        __typename: 'ReservationPayloadOk'
        reservation: {
          __typename: 'ResourceReservation'
          color: string | null
          customerVisibility: ResourceReservationCustomerVisibility
          customerTimeVisibility: Visibility
          description: string | null
          descriptionOptions: Array<string>
          end: string
          id: string
          internalNote: string | null
          displayMessage: string | null
          resourceQuantity: number
          start: string
          dimensions: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              id: string
              dimension: {
                __typename: 'Dimension'
                description: string | null
                dimensionHierarchyRoot: string | null
                hierarchyName: string | null
                id: string
                name: string
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                active: boolean
                description: string | null
                id: string
                name: string
              } | null
              validatedOptions: Array<{
                __typename: 'DimensionLabelOption'
                id: string
                validationCategory: DimensionLabelValidationCategory
                label: {
                  __typename: 'DimensionLabel'
                  active: boolean
                  description: string | null
                  id: string
                  name: string
                }
              }>
            }>
          } | null
          group: {
            __typename: 'ResourceReservationGroup'
            end: string
            id: string
            name: string | null
            number: number
            start: string
          } | null
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          recommendedProducts: Array<{
            __typename: 'ProductTitleCalendar'
            id: string
            type: string
            salesType: CatalogSalesType | null
            code: string | null
            name: string
            vatRate: number
            unitPrice: number
            quantityUnit: QuantityUnit
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }>
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | null
          purchaseProducts: Array<{
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          }>
          sales: {
            __typename: 'Sales'
            commissionRate: number
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              address: { __typename: 'CustomerAddress'; id: string } | null
              contact: {
                __typename: 'CustomerOrganizationContact'
                id: string
              } | null
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    defaultContactId: string | null
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    contacts: Array<{
                      __typename: 'CustomerOrganizationContact'
                      email: string | null
                      firstName: string
                      id: string
                      lastName: string
                      notes: string | null
                      phone: string | null
                    }>
                    eInvoicingAddress: {
                      __typename: 'EInvoicingAddress'
                      address: string
                      operator: string
                    } | null
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    person: {
                      __typename: 'CustomerPersonData'
                      email: string | null
                      firstName: string
                      lastName: string
                      phone: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
            } | null
            facet: {
              __typename: 'SalesFacet'
              color: string
              id: string
              features: Array<{
                __typename: 'SalesFacetFeature'
                feature: Feature
              }>
            }
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
                names: Array<{
                  __typename: 'LocalizedContent'
                  locale: string
                  content: string
                }>
              }
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              name: string | null
              orderNumber: number
              customer: {
                __typename: 'OrderCustomer'
                detailsLocked: boolean
                details: {
                  __typename: 'ParsedCustomerDetails'
                  businessId: string | null
                  contactPerson: string | null
                  customerNumber: string | null
                  email: string | null
                  name: string | null
                  phone: string | null
                  type: CustomerType | null
                  address: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }
                inheritedCustomer: {
                  __typename: 'CustomerReference'
                  customer:
                    | {
                        __typename: 'CustomerOrganization'
                        customerNumber: string
                      }
                    | { __typename: 'CustomerPerson'; customerNumber: string }
                } | null
              } | null
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            }>
            seller: { __typename: 'Seller'; id: string; name: string } | null
          }
          tasks: Array<{
            __typename: 'Task'
            description: string | null
            dueDate: string | null
            dueTime: string | null
            id: string
            isOpen: boolean
            name: string | null
            number: number
            assignee:
              | { __typename: 'User'; id: string }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
            auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
            purchaseProducts: Array<{
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }>
            recommendedProducts: Array<{
              __typename: 'ProductTitleBasic'
              id: string
              type: string
              salesType: CatalogSalesType | null
              code: string | null
              name: string
              vatRate: number
              unitPrice: number
              quantityUnit: QuantityUnit
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }>
            reservation: {
              __typename: 'ResourceReservation'
              description: string | null
              end: string
              id: string
              internalNote: string | null
              resourceQuantity: number
              start: string
              group: {
                __typename: 'ResourceReservationGroup'
                name: string | null
                number: number
              } | null
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              commissionRate: number
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                address: { __typename: 'CustomerAddress'; id: string } | null
                contact: {
                  __typename: 'CustomerOrganizationContact'
                  id: string
                } | null
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      defaultContactId: string | null
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      contacts: Array<{
                        __typename: 'CustomerOrganizationContact'
                        email: string | null
                        firstName: string
                        id: string
                        lastName: string
                        notes: string | null
                        phone: string | null
                      }>
                      eInvoicingAddress: {
                        __typename: 'EInvoicingAddress'
                        address: string
                        operator: string
                      } | null
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      person: {
                        __typename: 'CustomerPersonData'
                        email: string | null
                        firstName: string
                        lastName: string
                        phone: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
              } | null
              facet: {
                __typename: 'SalesFacet'
                id: string
                features: Array<{
                  __typename: 'SalesFacetFeature'
                  feature: Feature
                }>
              }
              lifecycle: {
                __typename: 'SalesLifecycle'
                state: {
                  __typename: 'SalesState'
                  key: string
                  systemState: SalesSystemState
                  names: Array<{
                    __typename: 'LocalizedContent'
                    locale: string
                    content: string
                  }>
                }
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                name: string | null
                sortOrder: number
              }>
              seller: { __typename: 'Seller'; id: string; name: string } | null
            }
          }>
        } | null
      }
}

export type CreateResourceReservationGroupMutationVariables = Exact<{
  input: ResourceReservationCreateGroupInput
}>

export type CreateResourceReservationGroupMutation = {
  __typename: 'Mutation'
  resourceReservationCreateGroup: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  }
}

export type MoveResourceReservationMutationVariables = Exact<{
  input: ResourceReservationMoveInput
}>

export type MoveResourceReservationMutation = {
  __typename: 'Mutation'
  resourceReservationMove:
    | { __typename: 'ReservationAvailabilityError'; message: string }
    | { __typename: 'ReservationAvailabilityWarning'; message: string }
    | {
        __typename: 'ReservationPayloadOk'
        reservation: {
          __typename: 'ResourceReservation'
          color: string | null
          customerVisibility: ResourceReservationCustomerVisibility
          customerTimeVisibility: Visibility
          description: string | null
          descriptionOptions: Array<string>
          end: string
          id: string
          internalNote: string | null
          displayMessage: string | null
          resourceQuantity: number
          start: string
          dimensions: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              id: string
              dimension: {
                __typename: 'Dimension'
                description: string | null
                dimensionHierarchyRoot: string | null
                hierarchyName: string | null
                id: string
                name: string
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                active: boolean
                description: string | null
                id: string
                name: string
              } | null
              validatedOptions: Array<{
                __typename: 'DimensionLabelOption'
                id: string
                validationCategory: DimensionLabelValidationCategory
                label: {
                  __typename: 'DimensionLabel'
                  active: boolean
                  description: string | null
                  id: string
                  name: string
                }
              }>
            }>
          } | null
          group: {
            __typename: 'ResourceReservationGroup'
            end: string
            id: string
            name: string | null
            number: number
            start: string
          } | null
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          recommendedProducts: Array<{
            __typename: 'ProductTitleCalendar'
            id: string
            type: string
            salesType: CatalogSalesType | null
            code: string | null
            name: string
            vatRate: number
            unitPrice: number
            quantityUnit: QuantityUnit
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }>
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | null
          purchaseProducts: Array<{
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          }>
          sales: {
            __typename: 'Sales'
            commissionRate: number
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              address: { __typename: 'CustomerAddress'; id: string } | null
              contact: {
                __typename: 'CustomerOrganizationContact'
                id: string
              } | null
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    defaultContactId: string | null
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    contacts: Array<{
                      __typename: 'CustomerOrganizationContact'
                      email: string | null
                      firstName: string
                      id: string
                      lastName: string
                      notes: string | null
                      phone: string | null
                    }>
                    eInvoicingAddress: {
                      __typename: 'EInvoicingAddress'
                      address: string
                      operator: string
                    } | null
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    person: {
                      __typename: 'CustomerPersonData'
                      email: string | null
                      firstName: string
                      lastName: string
                      phone: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
            } | null
            facet: {
              __typename: 'SalesFacet'
              color: string
              id: string
              features: Array<{
                __typename: 'SalesFacetFeature'
                feature: Feature
              }>
            }
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
                names: Array<{
                  __typename: 'LocalizedContent'
                  locale: string
                  content: string
                }>
              }
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              name: string | null
              orderNumber: number
              customer: {
                __typename: 'OrderCustomer'
                detailsLocked: boolean
                details: {
                  __typename: 'ParsedCustomerDetails'
                  businessId: string | null
                  contactPerson: string | null
                  customerNumber: string | null
                  email: string | null
                  name: string | null
                  phone: string | null
                  type: CustomerType | null
                  address: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }
                inheritedCustomer: {
                  __typename: 'CustomerReference'
                  customer:
                    | {
                        __typename: 'CustomerOrganization'
                        customerNumber: string
                      }
                    | { __typename: 'CustomerPerson'; customerNumber: string }
                } | null
              } | null
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            }>
            seller: { __typename: 'Seller'; id: string; name: string } | null
          }
          tasks: Array<{
            __typename: 'Task'
            description: string | null
            dueDate: string | null
            dueTime: string | null
            id: string
            isOpen: boolean
            name: string | null
            number: number
            assignee:
              | { __typename: 'User'; id: string }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
            auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
            purchaseProducts: Array<{
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }>
            recommendedProducts: Array<{
              __typename: 'ProductTitleBasic'
              id: string
              type: string
              salesType: CatalogSalesType | null
              code: string | null
              name: string
              vatRate: number
              unitPrice: number
              quantityUnit: QuantityUnit
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }>
            reservation: {
              __typename: 'ResourceReservation'
              description: string | null
              end: string
              id: string
              internalNote: string | null
              resourceQuantity: number
              start: string
              group: {
                __typename: 'ResourceReservationGroup'
                name: string | null
                number: number
              } | null
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              commissionRate: number
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                address: { __typename: 'CustomerAddress'; id: string } | null
                contact: {
                  __typename: 'CustomerOrganizationContact'
                  id: string
                } | null
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      defaultContactId: string | null
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      contacts: Array<{
                        __typename: 'CustomerOrganizationContact'
                        email: string | null
                        firstName: string
                        id: string
                        lastName: string
                        notes: string | null
                        phone: string | null
                      }>
                      eInvoicingAddress: {
                        __typename: 'EInvoicingAddress'
                        address: string
                        operator: string
                      } | null
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      person: {
                        __typename: 'CustomerPersonData'
                        email: string | null
                        firstName: string
                        lastName: string
                        phone: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
              } | null
              facet: {
                __typename: 'SalesFacet'
                id: string
                features: Array<{
                  __typename: 'SalesFacetFeature'
                  feature: Feature
                }>
              }
              lifecycle: {
                __typename: 'SalesLifecycle'
                state: {
                  __typename: 'SalesState'
                  key: string
                  systemState: SalesSystemState
                  names: Array<{
                    __typename: 'LocalizedContent'
                    locale: string
                    content: string
                  }>
                }
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                name: string | null
                sortOrder: number
              }>
              seller: { __typename: 'Seller'; id: string; name: string } | null
            }
          }>
        } | null
      }
}

export type RemoveResourceReservationMutationVariables = Exact<{
  input: ResourceReservationDeleteInput
}>

export type RemoveResourceReservationMutation = {
  __typename: 'Mutation'
  resourceReservationDelete: {
    __typename: 'ResourceReservationDeletePayload'
    id: string
    deleted: boolean
  }
}

export type RemoveResourceReservationGroupMutationVariables = Exact<{
  input: ResourceReservationDeleteGroupInput
}>

export type RemoveResourceReservationGroupMutation = {
  __typename: 'Mutation'
  resourceReservationDeleteGroup: {
    __typename: 'ResourceReservationGroupDeletePayload'
    reservationGroupId: string
    deleted: boolean
  }
}

export type SetResourceReservationColorMutationVariables = Exact<{
  input: ResourceReservationSetColorInput
}>

export type SetResourceReservationColorMutation = {
  __typename: 'Mutation'
  resourceReservationSetColor: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type SetResourceReservationDescriptionMutationVariables = Exact<{
  input: ResourceReservationSetDescriptionInput
}>

export type SetResourceReservationDescriptionMutation = {
  __typename: 'Mutation'
  resourceReservationSetDescription: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type SetResourceReservationDimensionsMutationVariables = Exact<{
  input: ResourceReservationDimensionsSetLabelsInput
}>

export type SetResourceReservationDimensionsMutation = {
  __typename: 'Mutation'
  resourceReservationDimensionsSetLabels: {
    __typename: 'DimensionReference'
    id: string
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      id: string
      dimension: {
        __typename: 'Dimension'
        description: string | null
        dimensionHierarchyRoot: string | null
        hierarchyName: string | null
        id: string
        name: string
        sortOrder: number
      }
      selectedLabel: {
        __typename: 'DimensionLabel'
        active: boolean
        description: string | null
        id: string
        name: string
      } | null
      validatedOptions: Array<{
        __typename: 'DimensionLabelOption'
        id: string
        validationCategory: DimensionLabelValidationCategory
        label: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        }
      }>
    }>
  }
}

export type ResourceReservationSetDisplayMessageMutationVariables = Exact<{
  input: ResourceReservationSetDisplayMessageInput
}>

export type ResourceReservationSetDisplayMessageMutation = {
  __typename: 'Mutation'
  resourceReservationSetDisplayMessage: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type SetResourceReservationGroupMutationVariables = Exact<{
  input: ResourceReservationSetGroupInput
}>

export type SetResourceReservationGroupMutation = {
  __typename: 'Mutation'
  resourceReservationSetGroup: {
    __typename: 'ResourceReservationSetGroupPayload'
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    reservation: {
      __typename: 'ResourceReservation'
      color: string | null
      customerVisibility: ResourceReservationCustomerVisibility
      customerTimeVisibility: Visibility
      description: string | null
      descriptionOptions: Array<string>
      end: string
      id: string
      internalNote: string | null
      displayMessage: string | null
      resourceQuantity: number
      start: string
      dimensions: {
        __typename: 'DimensionReference'
        id: string
        selection: Array<{
          __typename: 'DimensionSelectionItem'
          id: string
          dimension: {
            __typename: 'Dimension'
            description: string | null
            dimensionHierarchyRoot: string | null
            hierarchyName: string | null
            id: string
            name: string
            sortOrder: number
          }
          selectedLabel: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          } | null
          validatedOptions: Array<{
            __typename: 'DimensionLabelOption'
            id: string
            validationCategory: DimensionLabelValidationCategory
            label: {
              __typename: 'DimensionLabel'
              active: boolean
              description: string | null
              id: string
              name: string
            }
          }>
        }>
      } | null
      group: {
        __typename: 'ResourceReservationGroup'
        end: string
        id: string
        name: string | null
        number: number
        start: string
      } | null
      issues: Array<{
        __typename: 'ValidationIssue'
        key: string
        code: number
        level: IssueLevel
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleCalendar'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | null
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          color: string
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          orderNumber: number
          customer: {
            __typename: 'OrderCustomer'
            detailsLocked: boolean
            details: {
              __typename: 'ParsedCustomerDetails'
              businessId: string | null
              contactPerson: string | null
              customerNumber: string | null
              email: string | null
              name: string | null
              phone: string | null
              type: CustomerType | null
              address: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }
            inheritedCustomer: {
              __typename: 'CustomerReference'
              customer:
                | { __typename: 'CustomerOrganization'; customerNumber: string }
                | { __typename: 'CustomerPerson'; customerNumber: string }
            } | null
          } | null
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
      tasks: Array<{
        __typename: 'Task'
        description: string | null
        dueDate: string | null
        dueTime: string | null
        id: string
        isOpen: boolean
        name: string | null
        number: number
        assignee:
          | { __typename: 'User'; id: string }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
        auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
        purchaseProducts: Array<{
          __typename: 'PurchaseProduct'
          id: string
          targetType: TargetType
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            duration: { __typename: 'Duration'; from: string; to: string }
          }
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          order: {
            __typename: 'Order'
            id: string
            orderNumber: number
            lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          status: {
            __typename: 'PurchaseProductStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
            validatedActions: Array<{
              __typename: 'PurchaseProductActionValidated'
              action: PurchaseProductAction
              valid: boolean
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }>
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        }>
        recommendedProducts: Array<{
          __typename: 'ProductTitleBasic'
          id: string
          type: string
          salesType: CatalogSalesType | null
          code: string | null
          name: string
          vatRate: number
          unitPrice: number
          quantityUnit: QuantityUnit
          catalog: { __typename: 'Catalog'; id: string; name: string }
        }>
        reservation: {
          __typename: 'ResourceReservation'
          description: string | null
          end: string
          id: string
          internalNote: string | null
          resourceQuantity: number
          start: string
          group: {
            __typename: 'ResourceReservationGroup'
            name: string | null
            number: number
          } | null
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                name: string
                type: ResourceType
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          commissionRate: number
          id: string
          locked: boolean
          name: string | null
          orderNumber: number | null
          type: SalesType
          customer: {
            __typename: 'CustomerReference'
            address: { __typename: 'CustomerAddress'; id: string } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              id: string
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  defaultContactId: string | null
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  contacts: Array<{
                    __typename: 'CustomerOrganizationContact'
                    email: string | null
                    firstName: string
                    id: string
                    lastName: string
                    notes: string | null
                    phone: string | null
                  }>
                  eInvoicingAddress: {
                    __typename: 'EInvoicingAddress'
                    address: string
                    operator: string
                  } | null
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
          } | null
          facet: {
            __typename: 'SalesFacet'
            id: string
            features: Array<{
              __typename: 'SalesFacetFeature'
              feature: Feature
            }>
          }
          lifecycle: {
            __typename: 'SalesLifecycle'
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
              names: Array<{
                __typename: 'LocalizedContent'
                locale: string
                content: string
              }>
            }
          }
          orders: Array<{
            __typename: 'Order'
            id: string
            name: string | null
            sortOrder: number
          }>
          seller: { __typename: 'Seller'; id: string; name: string } | null
        }
      }>
    }
  }
}

export type SetResourceReservationGroupNameMutationVariables = Exact<{
  input: ResourceReservationRenameGroupInput
}>

export type SetResourceReservationGroupNameMutation = {
  __typename: 'Mutation'
  resourceReservationRenameGroup: {
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  }
}

export type SetResourceReservationInternalNoteMutationVariables = Exact<{
  input: ResourceReservationSetInternalNoteInput
}>

export type SetResourceReservationInternalNoteMutation = {
  __typename: 'Mutation'
  resourceReservationSetInternalNote: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type SetResourceReservationResourceMutationVariables = Exact<{
  input: ResourceReservationSetResourceInput
}>

export type SetResourceReservationResourceMutation = {
  __typename: 'Mutation'
  resourceReservationSetResource:
    | { __typename: 'ReservationAvailabilityError'; message: string }
    | { __typename: 'ReservationAvailabilityWarning'; message: string }
    | {
        __typename: 'ReservationPayloadOk'
        reservation: {
          __typename: 'ResourceReservation'
          color: string | null
          customerVisibility: ResourceReservationCustomerVisibility
          customerTimeVisibility: Visibility
          description: string | null
          descriptionOptions: Array<string>
          end: string
          id: string
          internalNote: string | null
          displayMessage: string | null
          resourceQuantity: number
          start: string
          dimensions: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              id: string
              dimension: {
                __typename: 'Dimension'
                description: string | null
                dimensionHierarchyRoot: string | null
                hierarchyName: string | null
                id: string
                name: string
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                active: boolean
                description: string | null
                id: string
                name: string
              } | null
              validatedOptions: Array<{
                __typename: 'DimensionLabelOption'
                id: string
                validationCategory: DimensionLabelValidationCategory
                label: {
                  __typename: 'DimensionLabel'
                  active: boolean
                  description: string | null
                  id: string
                  name: string
                }
              }>
            }>
          } | null
          group: {
            __typename: 'ResourceReservationGroup'
            end: string
            id: string
            name: string | null
            number: number
            start: string
          } | null
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          recommendedProducts: Array<{
            __typename: 'ProductTitleCalendar'
            id: string
            type: string
            salesType: CatalogSalesType | null
            code: string | null
            name: string
            vatRate: number
            unitPrice: number
            quantityUnit: QuantityUnit
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }>
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | null
          purchaseProducts: Array<{
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          }>
          sales: {
            __typename: 'Sales'
            commissionRate: number
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              address: { __typename: 'CustomerAddress'; id: string } | null
              contact: {
                __typename: 'CustomerOrganizationContact'
                id: string
              } | null
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    defaultContactId: string | null
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    contacts: Array<{
                      __typename: 'CustomerOrganizationContact'
                      email: string | null
                      firstName: string
                      id: string
                      lastName: string
                      notes: string | null
                      phone: string | null
                    }>
                    eInvoicingAddress: {
                      __typename: 'EInvoicingAddress'
                      address: string
                      operator: string
                    } | null
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    person: {
                      __typename: 'CustomerPersonData'
                      email: string | null
                      firstName: string
                      lastName: string
                      phone: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
            } | null
            facet: {
              __typename: 'SalesFacet'
              color: string
              id: string
              features: Array<{
                __typename: 'SalesFacetFeature'
                feature: Feature
              }>
            }
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
                names: Array<{
                  __typename: 'LocalizedContent'
                  locale: string
                  content: string
                }>
              }
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              name: string | null
              orderNumber: number
              customer: {
                __typename: 'OrderCustomer'
                detailsLocked: boolean
                details: {
                  __typename: 'ParsedCustomerDetails'
                  businessId: string | null
                  contactPerson: string | null
                  customerNumber: string | null
                  email: string | null
                  name: string | null
                  phone: string | null
                  type: CustomerType | null
                  address: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }
                inheritedCustomer: {
                  __typename: 'CustomerReference'
                  customer:
                    | {
                        __typename: 'CustomerOrganization'
                        customerNumber: string
                      }
                    | { __typename: 'CustomerPerson'; customerNumber: string }
                } | null
              } | null
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            }>
            seller: { __typename: 'Seller'; id: string; name: string } | null
          }
          tasks: Array<{
            __typename: 'Task'
            description: string | null
            dueDate: string | null
            dueTime: string | null
            id: string
            isOpen: boolean
            name: string | null
            number: number
            assignee:
              | { __typename: 'User'; id: string }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
            auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
            purchaseProducts: Array<{
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }>
            recommendedProducts: Array<{
              __typename: 'ProductTitleBasic'
              id: string
              type: string
              salesType: CatalogSalesType | null
              code: string | null
              name: string
              vatRate: number
              unitPrice: number
              quantityUnit: QuantityUnit
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }>
            reservation: {
              __typename: 'ResourceReservation'
              description: string | null
              end: string
              id: string
              internalNote: string | null
              resourceQuantity: number
              start: string
              group: {
                __typename: 'ResourceReservationGroup'
                name: string | null
                number: number
              } | null
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              commissionRate: number
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                address: { __typename: 'CustomerAddress'; id: string } | null
                contact: {
                  __typename: 'CustomerOrganizationContact'
                  id: string
                } | null
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      defaultContactId: string | null
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      contacts: Array<{
                        __typename: 'CustomerOrganizationContact'
                        email: string | null
                        firstName: string
                        id: string
                        lastName: string
                        notes: string | null
                        phone: string | null
                      }>
                      eInvoicingAddress: {
                        __typename: 'EInvoicingAddress'
                        address: string
                        operator: string
                      } | null
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      person: {
                        __typename: 'CustomerPersonData'
                        email: string | null
                        firstName: string
                        lastName: string
                        phone: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
              } | null
              facet: {
                __typename: 'SalesFacet'
                id: string
                features: Array<{
                  __typename: 'SalesFacetFeature'
                  feature: Feature
                }>
              }
              lifecycle: {
                __typename: 'SalesLifecycle'
                state: {
                  __typename: 'SalesState'
                  key: string
                  systemState: SalesSystemState
                  names: Array<{
                    __typename: 'LocalizedContent'
                    locale: string
                    content: string
                  }>
                }
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                name: string | null
                sortOrder: number
              }>
              seller: { __typename: 'Seller'; id: string; name: string } | null
            }
          }>
        } | null
      }
}

export type SetResourceReservationSalesMutationVariables = Exact<{
  input: ResourceReservationSetSalesInput
}>

export type SetResourceReservationSalesMutation = {
  __typename: 'Mutation'
  resourceReservationSetSales: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type SetResourceReservationTimesMutationVariables = Exact<{
  input: ResourceReservationSetTimesInput
}>

export type SetResourceReservationTimesMutation = {
  __typename: 'Mutation'
  resourceReservationSetTimes:
    | { __typename: 'ReservationAvailabilityError'; message: string }
    | { __typename: 'ReservationAvailabilityWarning'; message: string }
    | {
        __typename: 'ReservationPayloadOk'
        reservation: {
          __typename: 'ResourceReservation'
          color: string | null
          customerVisibility: ResourceReservationCustomerVisibility
          customerTimeVisibility: Visibility
          description: string | null
          descriptionOptions: Array<string>
          end: string
          id: string
          internalNote: string | null
          displayMessage: string | null
          resourceQuantity: number
          start: string
          dimensions: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              id: string
              dimension: {
                __typename: 'Dimension'
                description: string | null
                dimensionHierarchyRoot: string | null
                hierarchyName: string | null
                id: string
                name: string
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                active: boolean
                description: string | null
                id: string
                name: string
              } | null
              validatedOptions: Array<{
                __typename: 'DimensionLabelOption'
                id: string
                validationCategory: DimensionLabelValidationCategory
                label: {
                  __typename: 'DimensionLabel'
                  active: boolean
                  description: string | null
                  id: string
                  name: string
                }
              }>
            }>
          } | null
          group: {
            __typename: 'ResourceReservationGroup'
            end: string
            id: string
            name: string | null
            number: number
            start: string
          } | null
          issues: Array<{
            __typename: 'ValidationIssue'
            key: string
            code: number
            level: IssueLevel
          }>
          recommendedProducts: Array<{
            __typename: 'ProductTitleCalendar'
            id: string
            type: string
            salesType: CatalogSalesType | null
            code: string | null
            name: string
            vatRate: number
            unitPrice: number
            quantityUnit: QuantityUnit
            catalog: { __typename: 'Catalog'; id: string; name: string }
          }>
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                internalInfo: string | null
                isPooled: boolean
                name: string
                type: ResourceType
              }
            | null
          purchaseProducts: Array<{
            __typename: 'PurchaseProduct'
            id: string
            targetType: TargetType
            attributes: {
              __typename: 'PurchaseProductAttributes'
              quantity: number
              duration: { __typename: 'Duration'; from: string; to: string }
            }
            auditLog: { __typename: 'AuditLog'; createdAt: string }
            link: {
              __typename: 'PurchaseProductLink'
              participant: {
                __typename: 'SalesParticipant'
                firstName: string
                id: string
                lastName: string
              } | null
              reservation: {
                __typename: 'ResourceReservation'
                id: string
                resourceQuantity: number
                resource:
                  | {
                      __typename: 'ResourceEquipment'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceHuman'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourceProperty'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | {
                      __typename: 'ResourcePropertyNested'
                      id: string
                      isPooled: boolean
                      name: string
                    }
                  | null
              } | null
              sales: {
                __typename: 'Sales'
                id: string
                orderNumber: number | null
                type: SalesType
              }
              task: {
                __typename: 'Task'
                id: string
                assignee:
                  | { __typename: 'User' }
                  | { __typename: 'UserGroup'; id: string; name: string }
                  | null
              } | null
            }
            order: {
              __typename: 'Order'
              id: string
              orderNumber: number
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
              paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
            }
            product: { __typename: 'SalesProduct'; id: string; name: string }
            status: {
              __typename: 'PurchaseProductStatus'
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
              validatedActions: Array<{
                __typename: 'PurchaseProductActionValidated'
                action: PurchaseProductAction
                valid: boolean
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
              }>
            }
            totalPrice: {
              __typename: 'PriceElements'
              amount: number
              amountVatIncluded: number
              vatAmount: number
            }
          }>
          sales: {
            __typename: 'Sales'
            commissionRate: number
            id: string
            locked: boolean
            name: string | null
            orderNumber: number | null
            type: SalesType
            customer: {
              __typename: 'CustomerReference'
              address: { __typename: 'CustomerAddress'; id: string } | null
              contact: {
                __typename: 'CustomerOrganizationContact'
                id: string
              } | null
              customer:
                | {
                    __typename: 'CustomerOrganization'
                    defaultContactId: string | null
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    contacts: Array<{
                      __typename: 'CustomerOrganizationContact'
                      email: string | null
                      firstName: string
                      id: string
                      lastName: string
                      notes: string | null
                      phone: string | null
                    }>
                    eInvoicingAddress: {
                      __typename: 'EInvoicingAddress'
                      address: string
                      operator: string
                    } | null
                    organization: {
                      __typename: 'CustomerOrganizationData'
                      businessId: string | null
                      name: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
                | {
                    __typename: 'CustomerPerson'
                    customerNumber: string
                    defaultAddressId: string | null
                    id: string
                    publicName: string | null
                    person: {
                      __typename: 'CustomerPersonData'
                      email: string | null
                      firstName: string
                      lastName: string
                      phone: string | null
                    }
                    addresses: Array<{
                      __typename: 'CustomerAddress'
                      id: string
                      label: string | null
                      postalAddress: {
                        __typename: 'PostalAddressType'
                        address1: string | null
                        address2: string | null
                        city: string | null
                        country: string | null
                        postcode: string | null
                      } | null
                    }>
                    labels: Array<{
                      __typename: 'CustomerLabel'
                      id: string
                      name: string
                    }>
                  }
            } | null
            facet: {
              __typename: 'SalesFacet'
              color: string
              id: string
              features: Array<{
                __typename: 'SalesFacetFeature'
                feature: Feature
              }>
            }
            lifecycle: {
              __typename: 'SalesLifecycle'
              state: {
                __typename: 'SalesState'
                key: string
                systemState: SalesSystemState
                names: Array<{
                  __typename: 'LocalizedContent'
                  locale: string
                  content: string
                }>
              }
            }
            orders: Array<{
              __typename: 'Order'
              id: string
              name: string | null
              orderNumber: number
              customer: {
                __typename: 'OrderCustomer'
                detailsLocked: boolean
                details: {
                  __typename: 'ParsedCustomerDetails'
                  businessId: string | null
                  contactPerson: string | null
                  customerNumber: string | null
                  email: string | null
                  name: string | null
                  phone: string | null
                  type: CustomerType | null
                  address: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }
                inheritedCustomer: {
                  __typename: 'CustomerReference'
                  customer:
                    | {
                        __typename: 'CustomerOrganization'
                        customerNumber: string
                      }
                    | { __typename: 'CustomerPerson'; customerNumber: string }
                } | null
              } | null
              lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            }>
            seller: { __typename: 'Seller'; id: string; name: string } | null
          }
          tasks: Array<{
            __typename: 'Task'
            description: string | null
            dueDate: string | null
            dueTime: string | null
            id: string
            isOpen: boolean
            name: string | null
            number: number
            assignee:
              | { __typename: 'User'; id: string }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
            auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
            purchaseProducts: Array<{
              __typename: 'PurchaseProduct'
              id: string
              targetType: TargetType
              attributes: {
                __typename: 'PurchaseProductAttributes'
                quantity: number
                duration: { __typename: 'Duration'; from: string; to: string }
              }
              auditLog: { __typename: 'AuditLog'; createdAt: string }
              link: {
                __typename: 'PurchaseProductLink'
                participant: {
                  __typename: 'SalesParticipant'
                  firstName: string
                  id: string
                  lastName: string
                } | null
                reservation: {
                  __typename: 'ResourceReservation'
                  id: string
                  resourceQuantity: number
                  resource:
                    | {
                        __typename: 'ResourceEquipment'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceHuman'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourceProperty'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | {
                        __typename: 'ResourcePropertyNested'
                        id: string
                        isPooled: boolean
                        name: string
                      }
                    | null
                } | null
                sales: {
                  __typename: 'Sales'
                  id: string
                  orderNumber: number | null
                  type: SalesType
                }
                task: {
                  __typename: 'Task'
                  id: string
                  assignee:
                    | { __typename: 'User' }
                    | { __typename: 'UserGroup'; id: string; name: string }
                    | null
                } | null
              }
              order: {
                __typename: 'Order'
                id: string
                orderNumber: number
                lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
                paymentInfo: {
                  __typename: 'PaymentInfo'
                  status: PaymentStatus
                }
              }
              product: { __typename: 'SalesProduct'; id: string; name: string }
              status: {
                __typename: 'PurchaseProductStatus'
                issues: Array<{
                  __typename: 'ValidationIssue'
                  code: number
                  key: string
                  level: IssueLevel
                }>
                validatedActions: Array<{
                  __typename: 'PurchaseProductActionValidated'
                  action: PurchaseProductAction
                  valid: boolean
                  issues: Array<{
                    __typename: 'ValidationIssue'
                    code: number
                    key: string
                    level: IssueLevel
                  }>
                }>
              }
              totalPrice: {
                __typename: 'PriceElements'
                amount: number
                amountVatIncluded: number
                vatAmount: number
              }
            }>
            recommendedProducts: Array<{
              __typename: 'ProductTitleBasic'
              id: string
              type: string
              salesType: CatalogSalesType | null
              code: string | null
              name: string
              vatRate: number
              unitPrice: number
              quantityUnit: QuantityUnit
              catalog: { __typename: 'Catalog'; id: string; name: string }
            }>
            reservation: {
              __typename: 'ResourceReservation'
              description: string | null
              end: string
              id: string
              internalNote: string | null
              resourceQuantity: number
              start: string
              group: {
                __typename: 'ResourceReservationGroup'
                name: string | null
                number: number
              } | null
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    name: string
                    type: ResourceType
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              commissionRate: number
              id: string
              locked: boolean
              name: string | null
              orderNumber: number | null
              type: SalesType
              customer: {
                __typename: 'CustomerReference'
                address: { __typename: 'CustomerAddress'; id: string } | null
                contact: {
                  __typename: 'CustomerOrganizationContact'
                  id: string
                } | null
                customer:
                  | {
                      __typename: 'CustomerOrganization'
                      defaultContactId: string | null
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      contacts: Array<{
                        __typename: 'CustomerOrganizationContact'
                        email: string | null
                        firstName: string
                        id: string
                        lastName: string
                        notes: string | null
                        phone: string | null
                      }>
                      eInvoicingAddress: {
                        __typename: 'EInvoicingAddress'
                        address: string
                        operator: string
                      } | null
                      organization: {
                        __typename: 'CustomerOrganizationData'
                        businessId: string | null
                        name: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
                  | {
                      __typename: 'CustomerPerson'
                      customerNumber: string
                      defaultAddressId: string | null
                      id: string
                      publicName: string | null
                      person: {
                        __typename: 'CustomerPersonData'
                        email: string | null
                        firstName: string
                        lastName: string
                        phone: string | null
                      }
                      addresses: Array<{
                        __typename: 'CustomerAddress'
                        id: string
                        label: string | null
                        postalAddress: {
                          __typename: 'PostalAddressType'
                          address1: string | null
                          address2: string | null
                          city: string | null
                          country: string | null
                          postcode: string | null
                        } | null
                      }>
                      labels: Array<{
                        __typename: 'CustomerLabel'
                        id: string
                        name: string
                      }>
                    }
              } | null
              facet: {
                __typename: 'SalesFacet'
                id: string
                features: Array<{
                  __typename: 'SalesFacetFeature'
                  feature: Feature
                }>
              }
              lifecycle: {
                __typename: 'SalesLifecycle'
                state: {
                  __typename: 'SalesState'
                  key: string
                  systemState: SalesSystemState
                  names: Array<{
                    __typename: 'LocalizedContent'
                    locale: string
                    content: string
                  }>
                }
              }
              orders: Array<{
                __typename: 'Order'
                id: string
                name: string | null
                sortOrder: number
              }>
              seller: { __typename: 'Seller'; id: string; name: string } | null
            }
          }>
        } | null
      }
}

export type SetResourceReservationVisibilityMutationVariables = Exact<{
  input: ResourceReservationSetVisibilityInput
}>

export type SetResourceReservationVisibilityMutation = {
  __typename: 'Mutation'
  resourceReservationSetVisibility: {
    __typename: 'ResourceReservation'
    color: string | null
    customerVisibility: ResourceReservationCustomerVisibility
    customerTimeVisibility: Visibility
    description: string | null
    descriptionOptions: Array<string>
    end: string
    id: string
    internalNote: string | null
    displayMessage: string | null
    resourceQuantity: number
    start: string
    dimensions: {
      __typename: 'DimensionReference'
      id: string
      selection: Array<{
        __typename: 'DimensionSelectionItem'
        id: string
        dimension: {
          __typename: 'Dimension'
          description: string | null
          dimensionHierarchyRoot: string | null
          hierarchyName: string | null
          id: string
          name: string
          sortOrder: number
        }
        selectedLabel: {
          __typename: 'DimensionLabel'
          active: boolean
          description: string | null
          id: string
          name: string
        } | null
        validatedOptions: Array<{
          __typename: 'DimensionLabelOption'
          id: string
          validationCategory: DimensionLabelValidationCategory
          label: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          }
        }>
      }>
    } | null
    group: {
      __typename: 'ResourceReservationGroup'
      end: string
      id: string
      name: string | null
      number: number
      start: string
    } | null
    issues: Array<{
      __typename: 'ValidationIssue'
      key: string
      code: number
      level: IssueLevel
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleCalendar'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          internalInfo: string | null
          isPooled: boolean
          name: string
          type: ResourceType
        }
      | null
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        color: string
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        orderNumber: number
        customer: {
          __typename: 'OrderCustomer'
          detailsLocked: boolean
          details: {
            __typename: 'ParsedCustomerDetails'
            businessId: string | null
            contactPerson: string | null
            customerNumber: string | null
            email: string | null
            name: string | null
            phone: string | null
            type: CustomerType | null
            address: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }
          inheritedCustomer: {
            __typename: 'CustomerReference'
            customer:
              | { __typename: 'CustomerOrganization'; customerNumber: string }
              | { __typename: 'CustomerPerson'; customerNumber: string }
          } | null
        } | null
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
    tasks: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleBasic'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      reservation: {
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        internalNote: string | null
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          name: string | null
          number: number
        } | null
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              name: string
              type: ResourceType
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              name: string
              type: ResourceType
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          sortOrder: number
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
    }>
  }
}

export type ReservationsDateOffsetMutationVariables = Exact<{
  input: ResourceReservationUpdateAllInput
}>

export type ReservationsDateOffsetMutation = {
  __typename: 'Mutation'
  resourceReservationUpdateAll: {
    __typename: 'ReservationUpdateAllPayload'
    reservations: Array<{
      __typename: 'ResourceReservation'
      color: string | null
      customerVisibility: ResourceReservationCustomerVisibility
      customerTimeVisibility: Visibility
      description: string | null
      descriptionOptions: Array<string>
      end: string
      id: string
      internalNote: string | null
      displayMessage: string | null
      resourceQuantity: number
      start: string
      dimensions: {
        __typename: 'DimensionReference'
        id: string
        selection: Array<{
          __typename: 'DimensionSelectionItem'
          id: string
          dimension: {
            __typename: 'Dimension'
            description: string | null
            dimensionHierarchyRoot: string | null
            hierarchyName: string | null
            id: string
            name: string
            sortOrder: number
          }
          selectedLabel: {
            __typename: 'DimensionLabel'
            active: boolean
            description: string | null
            id: string
            name: string
          } | null
          validatedOptions: Array<{
            __typename: 'DimensionLabelOption'
            id: string
            validationCategory: DimensionLabelValidationCategory
            label: {
              __typename: 'DimensionLabel'
              active: boolean
              description: string | null
              id: string
              name: string
            }
          }>
        }>
      } | null
      group: {
        __typename: 'ResourceReservationGroup'
        end: string
        id: string
        name: string | null
        number: number
        start: string
      } | null
      issues: Array<{
        __typename: 'ValidationIssue'
        key: string
        code: number
        level: IssueLevel
      }>
      recommendedProducts: Array<{
        __typename: 'ProductTitleCalendar'
        id: string
        type: string
        salesType: CatalogSalesType | null
        code: string | null
        name: string
        vatRate: number
        unitPrice: number
        quantityUnit: QuantityUnit
        catalog: { __typename: 'Catalog'; id: string; name: string }
      }>
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            internalInfo: string | null
            isPooled: boolean
            name: string
            type: ResourceType
          }
        | null
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        targetType: TargetType
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          duration: { __typename: 'Duration'; from: string; to: string }
        }
        auditLog: { __typename: 'AuditLog'; createdAt: string }
        link: {
          __typename: 'PurchaseProductLink'
          participant: {
            __typename: 'SalesParticipant'
            firstName: string
            id: string
            lastName: string
          } | null
          reservation: {
            __typename: 'ResourceReservation'
            id: string
            resourceQuantity: number
            resource:
              | {
                  __typename: 'ResourceEquipment'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceHuman'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourceProperty'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | {
                  __typename: 'ResourcePropertyNested'
                  id: string
                  isPooled: boolean
                  name: string
                }
              | null
          } | null
          sales: {
            __typename: 'Sales'
            id: string
            orderNumber: number | null
            type: SalesType
          }
          task: {
            __typename: 'Task'
            id: string
            assignee:
              | { __typename: 'User' }
              | { __typename: 'UserGroup'; id: string; name: string }
              | null
          } | null
        }
        order: {
          __typename: 'Order'
          id: string
          orderNumber: number
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
          paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        status: {
          __typename: 'PurchaseProductStatus'
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
          validatedActions: Array<{
            __typename: 'PurchaseProductActionValidated'
            action: PurchaseProductAction
            valid: boolean
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
          }>
        }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      sales: {
        __typename: 'Sales'
        commissionRate: number
        id: string
        locked: boolean
        name: string | null
        orderNumber: number | null
        type: SalesType
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        facet: {
          __typename: 'SalesFacet'
          color: string
          id: string
          features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
        }
        lifecycle: {
          __typename: 'SalesLifecycle'
          state: {
            __typename: 'SalesState'
            key: string
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
        }
        orders: Array<{
          __typename: 'Order'
          id: string
          name: string | null
          orderNumber: number
          customer: {
            __typename: 'OrderCustomer'
            detailsLocked: boolean
            details: {
              __typename: 'ParsedCustomerDetails'
              businessId: string | null
              contactPerson: string | null
              customerNumber: string | null
              email: string | null
              name: string | null
              phone: string | null
              type: CustomerType | null
              address: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }
            inheritedCustomer: {
              __typename: 'CustomerReference'
              customer:
                | { __typename: 'CustomerOrganization'; customerNumber: string }
                | { __typename: 'CustomerPerson'; customerNumber: string }
            } | null
          } | null
          lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        }>
        seller: { __typename: 'Seller'; id: string; name: string } | null
      }
      tasks: Array<{
        __typename: 'Task'
        description: string | null
        dueDate: string | null
        dueTime: string | null
        id: string
        isOpen: boolean
        name: string | null
        number: number
        assignee:
          | { __typename: 'User'; id: string }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
        auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
        purchaseProducts: Array<{
          __typename: 'PurchaseProduct'
          id: string
          targetType: TargetType
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            duration: { __typename: 'Duration'; from: string; to: string }
          }
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          link: {
            __typename: 'PurchaseProductLink'
            participant: {
              __typename: 'SalesParticipant'
              firstName: string
              id: string
              lastName: string
            } | null
            reservation: {
              __typename: 'ResourceReservation'
              id: string
              resourceQuantity: number
              resource:
                | {
                    __typename: 'ResourceEquipment'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceHuman'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourceProperty'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | {
                    __typename: 'ResourcePropertyNested'
                    id: string
                    isPooled: boolean
                    name: string
                  }
                | null
            } | null
            sales: {
              __typename: 'Sales'
              id: string
              orderNumber: number | null
              type: SalesType
            }
            task: {
              __typename: 'Task'
              id: string
              assignee:
                | { __typename: 'User' }
                | { __typename: 'UserGroup'; id: string; name: string }
                | null
            } | null
          }
          order: {
            __typename: 'Order'
            id: string
            orderNumber: number
            lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
            paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          status: {
            __typename: 'PurchaseProductStatus'
            issues: Array<{
              __typename: 'ValidationIssue'
              code: number
              key: string
              level: IssueLevel
            }>
            validatedActions: Array<{
              __typename: 'PurchaseProductActionValidated'
              action: PurchaseProductAction
              valid: boolean
              issues: Array<{
                __typename: 'ValidationIssue'
                code: number
                key: string
                level: IssueLevel
              }>
            }>
          }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        }>
        recommendedProducts: Array<{
          __typename: 'ProductTitleBasic'
          id: string
          type: string
          salesType: CatalogSalesType | null
          code: string | null
          name: string
          vatRate: number
          unitPrice: number
          quantityUnit: QuantityUnit
          catalog: { __typename: 'Catalog'; id: string; name: string }
        }>
        reservation: {
          __typename: 'ResourceReservation'
          description: string | null
          end: string
          id: string
          internalNote: string | null
          resourceQuantity: number
          start: string
          group: {
            __typename: 'ResourceReservationGroup'
            name: string | null
            number: number
          } | null
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                name: string
                type: ResourceType
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                name: string
                type: ResourceType
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          commissionRate: number
          id: string
          locked: boolean
          name: string | null
          orderNumber: number | null
          type: SalesType
          customer: {
            __typename: 'CustomerReference'
            address: { __typename: 'CustomerAddress'; id: string } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              id: string
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  defaultContactId: string | null
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  contacts: Array<{
                    __typename: 'CustomerOrganizationContact'
                    email: string | null
                    firstName: string
                    id: string
                    lastName: string
                    notes: string | null
                    phone: string | null
                  }>
                  eInvoicingAddress: {
                    __typename: 'EInvoicingAddress'
                    address: string
                    operator: string
                  } | null
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
          } | null
          facet: {
            __typename: 'SalesFacet'
            id: string
            features: Array<{
              __typename: 'SalesFacetFeature'
              feature: Feature
            }>
          }
          lifecycle: {
            __typename: 'SalesLifecycle'
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
              names: Array<{
                __typename: 'LocalizedContent'
                locale: string
                content: string
              }>
            }
          }
          orders: Array<{
            __typename: 'Order'
            id: string
            name: string | null
            sortOrder: number
          }>
          seller: { __typename: 'Seller'; id: string; name: string } | null
        }
      }>
    }>
  }
}

export type UpdateResourceMutationVariables = Exact<{
  input: ResourceUpdateInput
}>

export type UpdateResourceMutation = {
  __typename: 'Mutation'
  resourceUpdate:
    | {
        __typename: 'ResourceEquipment'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceHuman'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceProperty'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        internalInfo: string | null
        isPooled: boolean
        name: string
        type: ResourceType
      }
}

export type ResourceReservationGroupsBySalesQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type ResourceReservationGroupsBySalesQuery = {
  __typename: 'Query'
  reservationGroupsBySales: Array<{
    __typename: 'ResourceReservationGroup'
    end: string
    id: string
    name: string | null
    number: number
    start: string
  }>
}

export type ResourceReservationResourcesBySalesQueryVariables = Exact<{
  id: Scalars['ID']
  dates: DatesInput
}>

export type ResourceReservationResourcesBySalesQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    resourceReservations: Array<{
      __typename: 'ResourceReservation'
      id: string
      resource:
        | { __typename: 'ResourceEquipment'; id: string; isPooled: boolean }
        | { __typename: 'ResourceHuman'; id: string; isPooled: boolean }
        | { __typename: 'ResourceProperty'; id: string; isPooled: boolean }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            isPooled: boolean
          }
        | null
    }>
  }
}

export type ResourceReservationsQueryVariables = Exact<{
  input: ResourceReservationsInput
}>

export type ResourceReservationsQuery = {
  __typename: 'Query'
  resourceReservations: {
    __typename: 'ReservationsPayload'
    reservations: Array<{
      __typename: 'ReservationsByResource'
      reservations: Array<{
        __typename: 'ResourceReservation'
        description: string | null
        end: string
        id: string
        resourceQuantity: number
        start: string
        group: {
          __typename: 'ResourceReservationGroup'
          end: string
          id: string
          name: string | null
          number: number
          start: string
        } | null
        resource:
          | { __typename: 'ResourceEquipment'; id: string; isPooled: boolean }
          | { __typename: 'ResourceHuman'; id: string; isPooled: boolean }
          | { __typename: 'ResourceProperty'; id: string; isPooled: boolean }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
            }
          | null
        sales: {
          __typename: 'Sales'
          id: string
          locked: boolean
          name: string | null
          orderNumber: number | null
          customer: {
            __typename: 'CustomerReference'
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    name: string | null
                  }
                }
              | {
                  __typename: 'CustomerPerson'
                  person: {
                    __typename: 'CustomerPersonData'
                    firstName: string
                    lastName: string
                  }
                }
          } | null
          facet: {
            __typename: 'SalesFacet'
            color: string
            id: string
            features: Array<{
              __typename: 'SalesFacetFeature'
              feature: Feature
            }>
          }
          lifecycle: {
            __typename: 'SalesLifecycle'
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
              names: Array<{
                __typename: 'LocalizedContent'
                locale: string
                content: string
              }>
            }
          }
        }
      }>
    }>
  }
}

export type RoomLayoutRoomReservationFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    room: {
      __typename: 'Room'
      id: string
      number: string
      floor: number | null
      internalInfo: string | null
      beds: number
      extraBeds: number
      features: Array<{
        __typename: 'RoomLabel'
        id: string
        icon: string | null
        name: string
        shortName: string
      }>
      roomType: {
        __typename: 'RoomType'
        id: string
        name: string
        accommodationLevel: {
          __typename: 'AccommodationLevel'
          id: string
          name: string
          shortName: string
        }
      }
    }
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      id: string
      icon: string | null
      name: string
      shortName: string
    }>
  }
}

export type RoomLayoutRoomTypeReservationFragment = {
  __typename: 'RoomTypeReservation'
  id: string
  fulfilledByRooms: boolean
  request: {
    __typename: 'RoomTypeRequest'
    rooms: number
    beds: number
    extraBeds: number
    info: string | null
    roomType: {
      __typename: 'RoomType'
      id: string
      name: string
      beds: number | null
      extraBeds: number | null
      accommodationLevel: {
        __typename: 'AccommodationLevel'
        id: string
        name: string
        shortName: string
      }
    }
    checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
    checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
    features: Array<{
      __typename: 'RoomLabel'
      id: string
      icon: string | null
      name: string
      shortName: string
    }>
  }
  roomReservations: Array<{
    __typename: 'RoomReservation'
    id: string
    request: {
      __typename: 'RoomRequest'
      beds: number
      extraBeds: number
      info: string | null
      room: {
        __typename: 'Room'
        id: string
        number: string
        floor: number | null
        internalInfo: string | null
        beds: number
        extraBeds: number
        features: Array<{
          __typename: 'RoomLabel'
          id: string
          icon: string | null
          name: string
          shortName: string
        }>
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
        }
      }
      checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
      checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
      features: Array<{
        __typename: 'RoomLabel'
        id: string
        icon: string | null
        name: string
        shortName: string
      }>
    }
  }>
}

export type AccommodationRoomTypeReservationReserveRoomMutationMutationVariables =
  Exact<{
    input: AccommodationRoomTypeReservationReserveRoomInput
  }>

export type AccommodationRoomTypeReservationReserveRoomMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationReserveRoom:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        accommodation: {
          __typename: 'SalesAccommodation'
          id: string
          accommodationGroups: Array<{
            __typename: 'AccommodationGroup'
            id: string
            name: string | null
            sortOrder: number
            sales: { __typename: 'Sales'; id: string; type: SalesType }
            targets: Array<{
              __typename: 'AccommodationTarget'
              default: boolean
              floating: boolean
              id: string
              name: string
              nonBlockingCapacity: boolean
              private: boolean
              sortOrder: number
              accommodationGroup: {
                __typename: 'AccommodationGroup'
                id: string
                name: string | null
                sortOrder: number
              }
              sharedTo: Array<{
                __typename: 'Sales'
                id: string
                orderNumber: number | null
              }>
              status: {
                __typename: 'TargetCapacityStatus'
                status: {
                  __typename: 'ReservationStatus'
                  reservedCapacity: {
                    __typename: 'RoomsAndBeds'
                    rooms: number
                    beds: number
                    extraBeds: number
                  }
                  consumptionBreakdown: {
                    __typename: 'ReservationConsumption'
                    type: AccommodationConsumptionType
                    consumption: number
                    floatingConsumption: number
                  }
                }
                dailyStatus: Array<{
                  __typename: 'DailyReservationStatus'
                  date: string
                  status: {
                    __typename: 'ReservationStatus'
                    reservedCapacity: {
                      __typename: 'RoomsAndBeds'
                      rooms: number
                      beds: number
                      extraBeds: number
                    }
                    consumptionBreakdown: {
                      __typename: 'ReservationConsumption'
                      type: AccommodationConsumptionType
                      consumption: number
                      floatingConsumption: number
                    }
                  }
                }>
              }
            }>
            roomReservations: Array<{
              __typename: 'RoomReservation'
              id: string
              type: RoomReservationType
              issues: Array<{
                __typename: 'ValidationIssue'
                key: string
                code: number
                level: IssueLevel
              }>
              request: {
                __typename: 'RoomRequest'
                beds: number
                info: string | null
                extraBeds: number
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                room: {
                  __typename: 'Room'
                  floor: number | null
                  id: string
                  number: string
                  beds: number
                  extraBeds: number
                  internalInfo: string | null
                  roomType: {
                    __typename: 'RoomType'
                    id: string
                    name: string
                    accommodationLevel: {
                      __typename: 'AccommodationLevel'
                      id: string
                      name: string
                      shortName: string
                    }
                    category: {
                      __typename: 'RoomTypeCategory'
                      id: string
                      name: string
                    }
                  }
                  status: {
                    __typename: 'RoomStatus'
                    cleaningStatus: RoomCleaningStatus | null
                  }
                }
              }
              participantRooms: Array<{
                __typename: 'ParticipantRoom'
                id: string
                participant: { __typename: 'SalesParticipant'; id: string }
              }>
              roomTypeReservation: {
                __typename: 'RoomTypeReservation'
                id: string
              } | null
              target: { __typename: 'AccommodationTarget'; id: string } | null
            }>
            roomTypeReservations: Array<{
              __typename: 'RoomTypeReservation'
              id: string
              fulfilledByRooms: boolean
              request: {
                __typename: 'RoomTypeRequest'
                info: string | null
                beds: number
                extraBeds: number
                rooms: number
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
                features: Array<{
                  __typename: 'RoomLabel'
                  icon: string | null
                  id: string
                  name: string
                  shortName: string
                }>
                roomType: {
                  __typename: 'RoomType'
                  id: string
                  name: string
                  beds: number | null
                  extraBeds: number | null
                  accommodationLevel: {
                    __typename: 'AccommodationLevel'
                    id: string
                    name: string
                    shortName: string
                  }
                  category: {
                    __typename: 'RoomTypeCategory'
                    id: string
                    name: string
                  }
                }
              }
              target: { __typename: 'AccommodationTarget'; id: string } | null
            }>
            settings: {
              __typename: 'AccommodationGroupSettings'
              consumptionType: AccommodationConsumptionType | null
              consumptionDurationType: AccommodationConsumptionDurationType | null
            } | null
            status: {
              __typename: 'AccommodationReservationStatus'
              available: number
              reserved: number
              type: AccommodationConsumptionType
              dates: {
                __typename: 'AccommodationDates'
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
              } | null
              reservedDetailed: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              status: Array<{
                __typename: 'DailyAccommodationGroupStatus'
                available: number
                date: string
                reserved: number
                reservedDetailed: {
                  __typename: 'RoomsAndBeds'
                  rooms: number
                  beds: number
                  extraBeds: number
                }
              }>
            }
          }>
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted'; message: string }
    | { __typename: 'RoomReservationOverlappingError'; message: string }
}

export type AccommodationRoomReservationDeleteMutationMutationVariables =
  Exact<{
    input: AccommodationRoomReservationDeleteInput
  }>

export type AccommodationRoomReservationDeleteMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationDelete: {
    __typename: 'AccommodationRoomReservationDeletePayload'
    id: string
    deleted: boolean
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
      sales: { __typename: 'Sales'; id: string; type: SalesType }
      targets: Array<{
        __typename: 'AccommodationTarget'
        default: boolean
        floating: boolean
        id: string
        name: string
        nonBlockingCapacity: boolean
        private: boolean
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
        sharedTo: Array<{
          __typename: 'Sales'
          id: string
          orderNumber: number | null
        }>
        status: {
          __typename: 'TargetCapacityStatus'
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
          dailyStatus: Array<{
            __typename: 'DailyReservationStatus'
            date: string
            status: {
              __typename: 'ReservationStatus'
              reservedCapacity: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              consumptionBreakdown: {
                __typename: 'ReservationConsumption'
                type: AccommodationConsumptionType
                consumption: number
                floatingConsumption: number
              }
            }
          }>
        }
      }>
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        type: RoomReservationType
        issues: Array<{
          __typename: 'ValidationIssue'
          key: string
          code: number
          level: IssueLevel
        }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          info: string | null
          extraBeds: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            floor: number | null
            id: string
            number: string
            beds: number
            extraBeds: number
            internalInfo: string | null
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
              category: {
                __typename: 'RoomTypeCategory'
                id: string
                name: string
              }
            }
            status: {
              __typename: 'RoomStatus'
              cleaningStatus: RoomCleaningStatus | null
            }
          }
        }
        participantRooms: Array<{
          __typename: 'ParticipantRoom'
          id: string
          participant: { __typename: 'SalesParticipant'; id: string }
        }>
        roomTypeReservation: {
          __typename: 'RoomTypeReservation'
          id: string
        } | null
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      roomTypeReservations: Array<{
        __typename: 'RoomTypeReservation'
        id: string
        fulfilledByRooms: boolean
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          beds: number
          extraBeds: number
          rooms: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            beds: number | null
            extraBeds: number | null
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              name: string
              shortName: string
            }
            category: {
              __typename: 'RoomTypeCategory'
              id: string
              name: string
            }
          }
        }
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    } | null
  }
}

export type AccommodationRoomTypeReservationDeleteMutationMutationVariables =
  Exact<{
    input: AccommodationRoomTypeReservationDeleteInput
  }>

export type AccommodationRoomTypeReservationDeleteMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationDelete: {
    __typename: 'AccommodationRoomTypeReservationDeletePayload'
    id: string
    deleted: boolean
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
      sortOrder: number
      sales: { __typename: 'Sales'; id: string; type: SalesType }
      targets: Array<{
        __typename: 'AccommodationTarget'
        default: boolean
        floating: boolean
        id: string
        name: string
        nonBlockingCapacity: boolean
        private: boolean
        sortOrder: number
        accommodationGroup: {
          __typename: 'AccommodationGroup'
          id: string
          name: string | null
          sortOrder: number
        }
        sharedTo: Array<{
          __typename: 'Sales'
          id: string
          orderNumber: number | null
        }>
        status: {
          __typename: 'TargetCapacityStatus'
          status: {
            __typename: 'ReservationStatus'
            reservedCapacity: {
              __typename: 'RoomsAndBeds'
              rooms: number
              beds: number
              extraBeds: number
            }
            consumptionBreakdown: {
              __typename: 'ReservationConsumption'
              type: AccommodationConsumptionType
              consumption: number
              floatingConsumption: number
            }
          }
          dailyStatus: Array<{
            __typename: 'DailyReservationStatus'
            date: string
            status: {
              __typename: 'ReservationStatus'
              reservedCapacity: {
                __typename: 'RoomsAndBeds'
                rooms: number
                beds: number
                extraBeds: number
              }
              consumptionBreakdown: {
                __typename: 'ReservationConsumption'
                type: AccommodationConsumptionType
                consumption: number
                floatingConsumption: number
              }
            }
          }>
        }
      }>
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        type: RoomReservationType
        issues: Array<{
          __typename: 'ValidationIssue'
          key: string
          code: number
          level: IssueLevel
        }>
        request: {
          __typename: 'RoomRequest'
          beds: number
          info: string | null
          extraBeds: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            floor: number | null
            id: string
            number: string
            beds: number
            extraBeds: number
            internalInfo: string | null
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
              category: {
                __typename: 'RoomTypeCategory'
                id: string
                name: string
              }
            }
            status: {
              __typename: 'RoomStatus'
              cleaningStatus: RoomCleaningStatus | null
            }
          }
        }
        participantRooms: Array<{
          __typename: 'ParticipantRoom'
          id: string
          participant: { __typename: 'SalesParticipant'; id: string }
        }>
        roomTypeReservation: {
          __typename: 'RoomTypeReservation'
          id: string
        } | null
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      roomTypeReservations: Array<{
        __typename: 'RoomTypeReservation'
        id: string
        fulfilledByRooms: boolean
        request: {
          __typename: 'RoomTypeRequest'
          info: string | null
          beds: number
          extraBeds: number
          rooms: number
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: {
            __typename: 'RoomType'
            id: string
            name: string
            beds: number | null
            extraBeds: number | null
            accommodationLevel: {
              __typename: 'AccommodationLevel'
              id: string
              name: string
              shortName: string
            }
            category: {
              __typename: 'RoomTypeCategory'
              id: string
              name: string
            }
          }
        }
        target: { __typename: 'AccommodationTarget'; id: string } | null
      }>
      settings: {
        __typename: 'AccommodationGroupSettings'
        consumptionType: AccommodationConsumptionType | null
        consumptionDurationType: AccommodationConsumptionDurationType | null
      } | null
      status: {
        __typename: 'AccommodationReservationStatus'
        available: number
        reserved: number
        type: AccommodationConsumptionType
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        reservedDetailed: {
          __typename: 'RoomsAndBeds'
          rooms: number
          beds: number
          extraBeds: number
        }
        status: Array<{
          __typename: 'DailyAccommodationGroupStatus'
          available: number
          date: string
          reserved: number
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            rooms: number
            beds: number
            extraBeds: number
          }
        }>
      }
    } | null
  }
}

export type AccommodationRoomReservationSetBedQuantityMutationMutationVariables =
  Exact<{
    input: AccommodationRoomReservationSetBedQuantityInput
  }>

export type AccommodationRoomReservationSetBedQuantityMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetBedQuantity:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            beds: number
            extraBeds: number
            info: string | null
            room: {
              __typename: 'Room'
              id: string
              number: string
              floor: number | null
              internalInfo: string | null
              beds: number
              extraBeds: number
              features: Array<{
                __typename: 'RoomLabel'
                id: string
                icon: string | null
                name: string
                shortName: string
              }>
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
              }
            }
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError' }
}

export type AccommodationRoomReservationSetDatesMutationMutationVariables =
  Exact<{
    input: AccommodationRoomReservationSetDatesInput
  }>

export type AccommodationRoomReservationSetDatesMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetDates:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: { __typename: 'RoomReservation'; id: string }
      }
    | { __typename: 'RoomReservationCapacityRestricted'; message: string }
    | { __typename: 'RoomReservationOverlappingError'; message: string }
}

export type AccommodationRoomReservationSetNeedsMutationMutationVariables =
  Exact<{
    input: AccommodationRoomReservationSetNeedsInput
  }>

export type AccommodationRoomReservationSetNeedsMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetNeeds:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            beds: number
            extraBeds: number
            info: string | null
            room: {
              __typename: 'Room'
              id: string
              number: string
              floor: number | null
              internalInfo: string | null
              beds: number
              extraBeds: number
              features: Array<{
                __typename: 'RoomLabel'
                id: string
                icon: string | null
                name: string
                shortName: string
              }>
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
              }
            }
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError' }
}

export type AccommodationRoomReservationSetRoomMutationMutationVariables =
  Exact<{
    input: AccommodationRoomReservationSetRoomInput
  }>

export type AccommodationRoomReservationSetRoomMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomReservationSetRoom:
    | {
        __typename: 'AccommodationRoomReservationPayloadOk'
        roomReservation: {
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            beds: number
            info: string | null
            extraBeds: number
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              floor: number | null
              id: string
              internalInfo: string | null
              number: string
              beds: number
              extraBeds: number
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
                category: {
                  __typename: 'RoomTypeCategory'
                  id: string
                  name: string
                }
              }
              status: {
                __typename: 'RoomStatus'
                cleaningStatus: RoomCleaningStatus | null
              }
            }
          }
        }
      }
    | { __typename: 'RoomReservationCapacityRestricted' }
    | { __typename: 'RoomReservationOverlappingError'; message: string }
}

export type RoomSetStatusMutationVariables = Exact<{
  input: RoomSetStatusInput
}>

export type RoomSetStatusMutation = {
  __typename: 'Mutation'
  roomSetStatus: Array<{ __typename: 'RoomStatusChange'; id: string }>
}

export type AccommodationRoomTypeReservationSetFulfilledMutationMutationVariables =
  Exact<{
    input: AccommodationRoomTypeReservationSetFulfilledByRoomsInput
  }>

export type AccommodationRoomTypeReservationSetFulfilledMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetFulfilledByRooms: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        rooms: number
        beds: number
        extraBeds: number
        info: string | null
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          id: string
          icon: string | null
          name: string
          shortName: string
        }>
      }
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          room: {
            __typename: 'Room'
            id: string
            number: string
            floor: number | null
            internalInfo: string | null
            beds: number
            extraBeds: number
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
            }
          }
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            id: string
            icon: string | null
            name: string
            shortName: string
          }>
        }
      }>
    }
  }
}

export type AccommodationRoomTypeReservationSetNeedsMutationMutationVariables =
  Exact<{
    input: AccommodationRoomTypeReservationSetNeedsInput
  }>

export type AccommodationRoomTypeReservationSetNeedsMutationMutation = {
  __typename: 'Mutation'
  accommodationRoomTypeReservationSetNeeds: {
    __typename: 'AccommodationRoomTypeReservationPayload'
    roomTypeReservation: {
      __typename: 'RoomTypeReservation'
      id: string
      fulfilledByRooms: boolean
      request: {
        __typename: 'RoomTypeRequest'
        rooms: number
        beds: number
        extraBeds: number
        info: string | null
        roomType: {
          __typename: 'RoomType'
          id: string
          name: string
          beds: number | null
          extraBeds: number | null
          accommodationLevel: {
            __typename: 'AccommodationLevel'
            id: string
            name: string
            shortName: string
          }
        }
        checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
        checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
        features: Array<{
          __typename: 'RoomLabel'
          id: string
          icon: string | null
          name: string
          shortName: string
        }>
      }
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          room: {
            __typename: 'Room'
            id: string
            number: string
            floor: number | null
            internalInfo: string | null
            beds: number
            extraBeds: number
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
            }
          }
          checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
          checkOut: { __typename: 'CheckOut'; date: string; type: CheckOutType }
          features: Array<{
            __typename: 'RoomLabel'
            id: string
            icon: string | null
            name: string
            shortName: string
          }>
        }
      }>
    }
  }
}

export type RoomCleaningStatusesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
  from: Scalars['LocalDate']
  to: Scalars['LocalDate']
}>

export type RoomCleaningStatusesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    roomsByIds: Array<{
      __typename: 'Room'
      id: string
      status: {
        __typename: 'RoomStatus'
        cleaningStatus: RoomCleaningStatus | null
        cleaningStatusChanges: Array<{
          __typename: 'RoomStatusChange'
          id: string
          date: string
          newCleaningStatus: RoomCleaningStatus | null
        }>
      }
    }>
  }
}

export type RoomFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type RoomFeaturesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    roomFeatures: Array<{
      __typename: 'RoomLabel'
      id: string
      name: string
      shortName: string
      icon: string | null
    }>
  }
}

export type SaleRoomTypeAndRoomReservationsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SaleRoomTypeAndRoomReservationsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
        roomTypeReservations: Array<{
          __typename: 'RoomTypeReservation'
          id: string
          fulfilledByRooms: boolean
          request: {
            __typename: 'RoomTypeRequest'
            rooms: number
            beds: number
            extraBeds: number
            info: string | null
            roomType: {
              __typename: 'RoomType'
              id: string
              name: string
              beds: number | null
              extraBeds: number | null
              accommodationLevel: {
                __typename: 'AccommodationLevel'
                id: string
                name: string
                shortName: string
              }
            }
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
          }
          roomReservations: Array<{
            __typename: 'RoomReservation'
            id: string
            request: {
              __typename: 'RoomRequest'
              beds: number
              extraBeds: number
              info: string | null
              room: {
                __typename: 'Room'
                id: string
                number: string
                floor: number | null
                internalInfo: string | null
                beds: number
                extraBeds: number
                features: Array<{
                  __typename: 'RoomLabel'
                  id: string
                  icon: string | null
                  name: string
                  shortName: string
                }>
                roomType: {
                  __typename: 'RoomType'
                  id: string
                  name: string
                  accommodationLevel: {
                    __typename: 'AccommodationLevel'
                    id: string
                    name: string
                    shortName: string
                  }
                }
              }
              checkIn: {
                __typename: 'CheckIn'
                date: string
                type: CheckInType
              }
              checkOut: {
                __typename: 'CheckOut'
                date: string
                type: CheckOutType
              }
              features: Array<{
                __typename: 'RoomLabel'
                id: string
                icon: string | null
                name: string
                shortName: string
              }>
            }
          }>
        }>
        roomReservations: Array<{
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            beds: number
            extraBeds: number
            info: string | null
            room: {
              __typename: 'Room'
              id: string
              number: string
              floor: number | null
              internalInfo: string | null
              beds: number
              extraBeds: number
              features: Array<{
                __typename: 'RoomLabel'
                id: string
                icon: string | null
                name: string
                shortName: string
              }>
              roomType: {
                __typename: 'RoomType'
                id: string
                name: string
                accommodationLevel: {
                  __typename: 'AccommodationLevel'
                  id: string
                  name: string
                  shortName: string
                }
              }
            }
            checkIn: { __typename: 'CheckIn'; date: string; type: CheckInType }
            checkOut: {
              __typename: 'CheckOut'
              date: string
              type: CheckOutType
            }
            features: Array<{
              __typename: 'RoomLabel'
              id: string
              icon: string | null
              name: string
              shortName: string
            }>
          }
        }>
        targets: Array<{
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
          targetedReservations: {
            __typename: 'AccommodationReservations'
            roomTypeReservations: Array<{
              __typename: 'RoomTypeReservation'
              id: string
              fulfilledByRooms: boolean
              request: {
                __typename: 'RoomTypeRequest'
                rooms: number
                beds: number
                extraBeds: number
                info: string | null
                roomType: {
                  __typename: 'RoomType'
                  id: string
                  name: string
                  beds: number | null
                  extraBeds: number | null
                  accommodationLevel: {
                    __typename: 'AccommodationLevel'
                    id: string
                    name: string
                    shortName: string
                  }
                }
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
                features: Array<{
                  __typename: 'RoomLabel'
                  id: string
                  icon: string | null
                  name: string
                  shortName: string
                }>
              }
              roomReservations: Array<{
                __typename: 'RoomReservation'
                id: string
                request: {
                  __typename: 'RoomRequest'
                  beds: number
                  extraBeds: number
                  info: string | null
                  room: {
                    __typename: 'Room'
                    id: string
                    number: string
                    floor: number | null
                    internalInfo: string | null
                    beds: number
                    extraBeds: number
                    features: Array<{
                      __typename: 'RoomLabel'
                      id: string
                      icon: string | null
                      name: string
                      shortName: string
                    }>
                    roomType: {
                      __typename: 'RoomType'
                      id: string
                      name: string
                      accommodationLevel: {
                        __typename: 'AccommodationLevel'
                        id: string
                        name: string
                        shortName: string
                      }
                    }
                  }
                  checkIn: {
                    __typename: 'CheckIn'
                    date: string
                    type: CheckInType
                  }
                  checkOut: {
                    __typename: 'CheckOut'
                    date: string
                    type: CheckOutType
                  }
                  features: Array<{
                    __typename: 'RoomLabel'
                    id: string
                    icon: string | null
                    name: string
                    shortName: string
                  }>
                }
              }>
            }>
            roomReservations: Array<{
              __typename: 'RoomReservation'
              id: string
              request: {
                __typename: 'RoomRequest'
                beds: number
                extraBeds: number
                info: string | null
                room: {
                  __typename: 'Room'
                  id: string
                  number: string
                  floor: number | null
                  internalInfo: string | null
                  beds: number
                  extraBeds: number
                  features: Array<{
                    __typename: 'RoomLabel'
                    id: string
                    icon: string | null
                    name: string
                    shortName: string
                  }>
                  roomType: {
                    __typename: 'RoomType'
                    id: string
                    name: string
                    accommodationLevel: {
                      __typename: 'AccommodationLevel'
                      id: string
                      name: string
                      shortName: string
                    }
                  }
                }
                checkIn: {
                  __typename: 'CheckIn'
                  date: string
                  type: CheckInType
                }
                checkOut: {
                  __typename: 'CheckOut'
                  date: string
                  type: CheckOutType
                }
                features: Array<{
                  __typename: 'RoomLabel'
                  id: string
                  icon: string | null
                  name: string
                  shortName: string
                }>
              }
            }>
          } | null
        }>
      }>
    }
  }
}

export type SetSalesGroupMutationVariables = Exact<{
  input: SalesGroupsInput
}>

export type SetSalesGroupMutation = {
  __typename: 'Mutation'
  salesSetGroups: {
    __typename: 'Sales'
    id: string
    groups: {
      __typename: 'NamedValues'
      label: string | null
      values: Array<string>
    } | null
  }
}

export type PaymentAgreementsQueryVariables = Exact<{ [key: string]: never }>

export type PaymentAgreementsQuery = {
  __typename: 'Query'
  salesPaymentAgreements: Array<{
    __typename: 'PaymentAgreement'
    code: string
    id: string
    name: string
  }>
}

export type SignersForVstQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SignersForVstQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    participantConnection: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
        sortOrder: number
      }>
    }
  }
}

export type PublicRoomFragment = {
  __typename: 'PublicRoom'
  availableFrom: string | null
  availableTo: string | null
  minGuests: number
  maxGuests: number
  id: string
  publishedFrom: string | null
  publishedTo: string | null
  auditLog: { __typename: 'AuditLog'; createdAt: string }
  product: { __typename: 'SalesProduct'; id: string; name: string } | null
  roomMarketing: {
    __typename: 'RoomMarketing'
    id: string
    internalName: string
  } | null
}

export type SalesWebshopContactsFragment = {
  __typename: 'Sales'
  id: string
  webshopSettings: {
    __typename: 'WebshopSettings'
    id: string
    contacts: Array<{ __typename: 'WebshopContact'; id: string; name: string }>
  } | null
}

export type SalesPublisedProductsFragment = {
  __typename: 'SalesProduct'
  id: string
  name: string
  settings: {
    __typename: 'SalesProductSettings'
    published: boolean
    accommodationGroup: {
      __typename: 'AccommodationGroup'
      id: string
      name: string | null
    } | null
    purchaseDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
  } | null
}

export type SalesWebshopSettingsFragment = {
  __typename: 'WebshopSettings'
  id: string
  customerInformation: string | null
  contacts: Array<{ __typename: 'WebshopContact'; id: string; name: string }>
  fieldsConfig: {
    __typename: 'WebshopFieldsConfig'
    participant: Array<{
      __typename: 'ParticipantFieldConfig'
      field: ParticipantField
      status: FieldConfigStatus
    } | null>
  }
  theme: {
    __typename: 'WebshopTheme'
    id: string
    description: string | null
    name: string
  } | null
  tokens: Array<{
    __typename: 'WebshopSalesToken'
    id: string
    link: string
    scopes: Array<WebshopPermissionScope>
    token: string
    tokenDescription: string | null
    validFrom: string | null
    validTo: string | null
    completedAt: string | null
    issues: Array<{
      __typename: 'ValidationIssue'
      message: string
      level: IssueLevel
      key: string
      code: number
      targetPath: Array<{
        __typename: 'TargetIdentifier'
        id: string
        type: string
      }>
    }>
    invoice: {
      __typename: 'Invoice'
      id: string
      document: { __typename: 'Document'; id: string } | null
    } | null
  }>
}

export type WebshopSalesTokenFragment = {
  __typename: 'WebshopSalesToken'
  id: string
  link: string
  scopes: Array<WebshopPermissionScope>
  token: string
  tokenDescription: string | null
  validFrom: string | null
  validTo: string | null
  completedAt: string | null
  issues: Array<{
    __typename: 'ValidationIssue'
    message: string
    level: IssueLevel
    key: string
    code: number
    targetPath: Array<{
      __typename: 'TargetIdentifier'
      id: string
      type: string
    }>
  }>
  invoice: {
    __typename: 'Invoice'
    id: string
    document: { __typename: 'Document'; id: string } | null
  } | null
}

export type SalesWebshopSettingsQueryVariables = Exact<{
  input: Scalars['ID']
}>

export type SalesWebshopSettingsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    locked: boolean
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      customerInformation: string | null
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
      fieldsConfig: {
        __typename: 'WebshopFieldsConfig'
        participant: Array<{
          __typename: 'ParticipantFieldConfig'
          field: ParticipantField
          status: FieldConfigStatus
        } | null>
      }
      theme: {
        __typename: 'WebshopTheme'
        id: string
        description: string | null
        name: string
      } | null
      tokens: Array<{
        __typename: 'WebshopSalesToken'
        id: string
        link: string
        scopes: Array<WebshopPermissionScope>
        token: string
        tokenDescription: string | null
        validFrom: string | null
        validTo: string | null
        completedAt: string | null
        issues: Array<{
          __typename: 'ValidationIssue'
          message: string
          level: IssueLevel
          key: string
          code: number
          targetPath: Array<{
            __typename: 'TargetIdentifier'
            id: string
            type: string
          }>
        }>
        invoice: {
          __typename: 'Invoice'
          id: string
          document: { __typename: 'Document'; id: string } | null
        } | null
      }>
    } | null
  }
}

export type AddPublicRoomMutationVariables = Exact<{
  input: PublicRoomAddInput
}>

export type AddPublicRoomMutation = {
  __typename: 'Mutation'
  publicRoomAdd: {
    __typename: 'PublicRoomPayload'
    publicRoom: {
      __typename: 'PublicRoom'
      availableFrom: string | null
      availableTo: string | null
      minGuests: number
      maxGuests: number
      id: string
      publishedFrom: string | null
      publishedTo: string | null
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      product: { __typename: 'SalesProduct'; id: string; name: string } | null
      roomMarketing: {
        __typename: 'RoomMarketing'
        id: string
        internalName: string
      } | null
    }
  }
}

export type AddWebshopContactsMutationVariables = Exact<{
  input: SalesWebshopContactInput
}>

export type AddWebshopContactsMutation = {
  __typename: 'Mutation'
  salesAddWebshopContacts: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
    } | null
  }
}

export type AddWebshopTokenMutationVariables = Exact<{
  input: SalesAddWebshopSettingsInput
}>

export type AddWebshopTokenMutation = {
  __typename: 'Mutation'
  salesAddWebshopToken: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      customerInformation: string | null
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
      fieldsConfig: {
        __typename: 'WebshopFieldsConfig'
        participant: Array<{
          __typename: 'ParticipantFieldConfig'
          field: ParticipantField
          status: FieldConfigStatus
        } | null>
      }
      theme: {
        __typename: 'WebshopTheme'
        id: string
        description: string | null
        name: string
      } | null
      tokens: Array<{
        __typename: 'WebshopSalesToken'
        id: string
        link: string
        scopes: Array<WebshopPermissionScope>
        token: string
        tokenDescription: string | null
        validFrom: string | null
        validTo: string | null
        completedAt: string | null
        issues: Array<{
          __typename: 'ValidationIssue'
          message: string
          level: IssueLevel
          key: string
          code: number
          targetPath: Array<{
            __typename: 'TargetIdentifier'
            id: string
            type: string
          }>
        }>
        invoice: {
          __typename: 'Invoice'
          id: string
          document: { __typename: 'Document'; id: string } | null
        } | null
      }>
    } | null
  }
}

export type RemovePublicRoomMutationVariables = Exact<{
  input: PublicRoomRemoveInput
}>

export type RemovePublicRoomMutation = {
  __typename: 'Mutation'
  publicRoomRemove: {
    __typename: 'DeletePayload'
    deleted: boolean
    id: string
  }
}

export type RemoveWebshopContactsMutationVariables = Exact<{
  input: SalesWebshopContactInput
}>

export type RemoveWebshopContactsMutation = {
  __typename: 'Mutation'
  salesRemoveWebshopContacts: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
    } | null
  }
}

export type RemoveWebshopTokenMutationVariables = Exact<{
  input: SalesRemoveWebshopSettingsInput
}>

export type RemoveWebshopTokenMutation = {
  __typename: 'Mutation'
  salesRemoveWebshopToken: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      customerInformation: string | null
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
      fieldsConfig: {
        __typename: 'WebshopFieldsConfig'
        participant: Array<{
          __typename: 'ParticipantFieldConfig'
          field: ParticipantField
          status: FieldConfigStatus
        } | null>
      }
      theme: {
        __typename: 'WebshopTheme'
        id: string
        description: string | null
        name: string
      } | null
      tokens: Array<{
        __typename: 'WebshopSalesToken'
        id: string
        link: string
        scopes: Array<WebshopPermissionScope>
        token: string
        tokenDescription: string | null
        validFrom: string | null
        validTo: string | null
        completedAt: string | null
        issues: Array<{
          __typename: 'ValidationIssue'
          message: string
          level: IssueLevel
          key: string
          code: number
          targetPath: Array<{
            __typename: 'TargetIdentifier'
            id: string
            type: string
          }>
        }>
        invoice: {
          __typename: 'Invoice'
          id: string
          document: { __typename: 'Document'; id: string } | null
        } | null
      }>
    } | null
  }
}

export type UpdateAccommodationTargetMutationVariables = Exact<{
  input: AccommodationTargetUpdateInput
}>

export type UpdateAccommodationTargetMutation = {
  __typename: 'Mutation'
  accommodationTargetUpdate: {
    __typename: 'AccommodationTargetPayload'
    target: {
      __typename: 'AccommodationTarget'
      id: string
      nonBlockingCapacity: boolean
    }
  }
}

export type UpdatePublicRoomMutationVariables = Exact<{
  input: PublicRoomUpdateInput
}>

export type UpdatePublicRoomMutation = {
  __typename: 'Mutation'
  publicRoomUpdate: {
    __typename: 'PublicRoomPayload'
    publicRoom: {
      __typename: 'PublicRoom'
      availableFrom: string | null
      availableTo: string | null
      minGuests: number
      maxGuests: number
      id: string
      publishedFrom: string | null
      publishedTo: string | null
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      product: { __typename: 'SalesProduct'; id: string; name: string } | null
      roomMarketing: {
        __typename: 'RoomMarketing'
        id: string
        internalName: string
      } | null
    }
  }
}

export type UpdatePublishedProductMutationVariables = Exact<{
  input: SalesProductUpdateInput
}>

export type UpdatePublishedProductMutation = {
  __typename: 'Mutation'
  salesProductUpdate: {
    __typename: 'SalesProduct'
    id: string
    settings: {
      __typename: 'SalesProductSettings'
      published: boolean
      description: string | null
      purchaseDates: {
        __typename: 'LocalDateRange'
        end: string
        start: string
      } | null
      accommodationGroup: {
        __typename: 'AccommodationGroup'
        id: string
        name: string | null
        sortOrder: number
      } | null
    } | null
  }
}

export type UpdateWebshopSettingsMutationVariables = Exact<{
  input: SalesWebshopSettingsInput
}>

export type UpdateWebshopSettingsMutation = {
  __typename: 'Mutation'
  salesSetWebshopSettings: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      customerInformation: string | null
      contacts: Array<{
        __typename: 'WebshopContact'
        id: string
        name: string
      }>
      fieldsConfig: {
        __typename: 'WebshopFieldsConfig'
        participant: Array<{
          __typename: 'ParticipantFieldConfig'
          field: ParticipantField
          status: FieldConfigStatus
        } | null>
      }
      theme: {
        __typename: 'WebshopTheme'
        id: string
        description: string | null
        name: string
      } | null
      tokens: Array<{
        __typename: 'WebshopSalesToken'
        id: string
        link: string
        scopes: Array<WebshopPermissionScope>
        token: string
        tokenDescription: string | null
        validFrom: string | null
        validTo: string | null
        completedAt: string | null
        issues: Array<{
          __typename: 'ValidationIssue'
          message: string
          level: IssueLevel
          key: string
          code: number
          targetPath: Array<{
            __typename: 'TargetIdentifier'
            id: string
            type: string
          }>
        }>
        invoice: {
          __typename: 'Invoice'
          id: string
          document: { __typename: 'Document'; id: string } | null
        } | null
      }>
    } | null
  }
}

export type AccommodationTargetsForPublishQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type AccommodationTargetsForPublishQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationTargets: Array<{
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        nonBlockingCapacity: boolean
        sortOrder: number
        publicRooms: Array<{
          __typename: 'PublicRoom'
          availableFrom: string | null
          availableTo: string | null
          minGuests: number
          maxGuests: number
          id: string
          publishedFrom: string | null
          publishedTo: string | null
          auditLog: { __typename: 'AuditLog'; createdAt: string }
          product: {
            __typename: 'SalesProduct'
            id: string
            name: string
          } | null
          roomMarketing: {
            __typename: 'RoomMarketing'
            id: string
            internalName: string
          } | null
        }>
      }>
    }
  }
}

export type WebshopAvailableScopesQueryVariables = Exact<{
  input: WebshopAvailableScopesInput
}>

export type WebshopAvailableScopesQuery = {
  __typename: 'Query'
  webshop: {
    __typename: 'Webshop'
    availableScopes: {
      __typename: 'WebshopScopesPayload'
      scopes: Array<WebshopPermissionScope>
    }
  }
}

export type WebshopContactsQueryVariables = Exact<{ [key: string]: never }>

export type WebshopContactsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    webshopContacts: Array<{
      __typename: 'WebshopContact'
      id: string
      name: string
    }>
  }
}

export type WebshopRoomsQueryVariables = Exact<{ [key: string]: never }>

export type WebshopRoomsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    webshopRooms: Array<{
      __typename: 'RoomMarketing'
      id: string
      internalName: string
    }>
  }
}

export type WebshopThemesQueryVariables = Exact<{ [key: string]: never }>

export type WebshopThemesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    webshopThemes: Array<{
      __typename: 'WebshopTheme'
      id: string
      name: string
    }>
  }
}

export type SaleTasksQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SaleTasksQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    tasks: Array<{
      __typename: 'Task'
      id: string
      name: string | null
      description: string | null
      isOpen: boolean
      type: TaskType
      dueDate: string | null
      dueTime: string | null
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
    }>
  }
}

export type EventEnrollmentFragment = {
  __typename: 'Sales'
  id: string
  type: SalesType
  orderNumber: number | null
  name: string | null
  notes: string | null
  locked: boolean
  estimatedParticipants: number | null
  customer: {
    __typename: 'CustomerReference'
    address: { __typename: 'CustomerAddress'; id: string } | null
    contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
    customer:
      | {
          __typename: 'CustomerOrganization'
          defaultContactId: string | null
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          contacts: Array<{
            __typename: 'CustomerOrganizationContact'
            email: string | null
            firstName: string
            id: string
            lastName: string
            notes: string | null
            phone: string | null
          }>
          eInvoicingAddress: {
            __typename: 'EInvoicingAddress'
            address: string
            operator: string
          } | null
          organization: {
            __typename: 'CustomerOrganizationData'
            businessId: string | null
            name: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
      | {
          __typename: 'CustomerPerson'
          customerNumber: string
          defaultAddressId: string | null
          id: string
          publicName: string | null
          person: {
            __typename: 'CustomerPersonData'
            email: string | null
            firstName: string
            lastName: string
            phone: string | null
          }
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            label: string | null
            postalAddress: {
              __typename: 'PostalAddressType'
              address1: string | null
              address2: string | null
              city: string | null
              country: string | null
              postcode: string | null
            } | null
          }>
          labels: Array<{
            __typename: 'CustomerLabel'
            id: string
            name: string
          }>
        }
  } | null
  lifecycle: {
    __typename: 'SalesLifecycle'
    isConfirmed: boolean
    reason: SalesLifecycleReason | null
    specifier: string | null
    state: {
      __typename: 'SalesState'
      isConfirmed: boolean
      key: string
      processOrder: number
      disabled: boolean
      systemState: SalesSystemState
      names: Array<{
        __typename: 'LocalizedContent'
        locale: string
        content: string
      }>
    }
    stateOptions: Array<{
      __typename: 'ValidatedSalesStateOption'
      valid: boolean
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
      }
    }>
  }
  reservationDates: {
    __typename: 'LocalDateRange'
    start: string
    end: string
  } | null
  estimatedDates: {
    __typename: 'LocalDateRange'
    start: string
    end: string
  } | null
  participantConnection: {
    __typename: 'ParticipantConnection'
    totalElements: number
    nodes: Array<{
      __typename: 'SalesParticipant'
      id: string
      firstName: string
      lastName: string
    }>
  }
  orders: Array<{
    __typename: 'Order'
    paymentInfo: {
      __typename: 'PaymentInfo'
      totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
    }
  }>
  paymentAgreement: {
    __typename: 'PaymentAgreement'
    id: string
    name: string
    code: string
  } | null
}

export type EventSalesFragment = {
  __typename: 'SalesConnection'
  totalPages: number
  totalElements: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  nodes: Array<{
    __typename: 'Sales'
    id: string
    type: SalesType
    orderNumber: number | null
    name: string | null
    notes: string | null
    locked: boolean
    estimatedParticipants: number | null
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      isConfirmed: boolean
      reason: SalesLifecycleReason | null
      specifier: string | null
      state: {
        __typename: 'SalesState'
        isConfirmed: boolean
        key: string
        processOrder: number
        disabled: boolean
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }>
    }
    reservationDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    participantConnection: {
      __typename: 'ParticipantConnection'
      totalElements: number
      nodes: Array<{
        __typename: 'SalesParticipant'
        id: string
        firstName: string
        lastName: string
      }>
    }
    orders: Array<{
      __typename: 'Order'
      paymentInfo: {
        __typename: 'PaymentInfo'
        totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
      }
    }>
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }>
}

export type SalesAssigneeFragment = {
  __typename: 'User'
  abbreviation: string | null
  avatarUrl: string | null
  department: string | null
  email: string | null
  firstName: string | null
  id: string
  lastName: string | null
}

export type AccommodationRoomFeatureForSalesPrintFragment = {
  __typename: 'RoomLabel'
  icon: string | null
  id: string
  name: string
  shortName: string
}

export type AccommodationRoomReservationForSalesPrintFragment = {
  __typename: 'RoomReservation'
  id: string
  request: {
    __typename: 'RoomRequest'
    beds: number
    extraBeds: number
    info: string | null
    checkIn: { __typename: 'CheckIn'; date: string }
    checkOut: { __typename: 'CheckOut'; date: string }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    room: {
      __typename: 'Room'
      id: string
      internalInfo: string | null
      number: string
    }
  }
  target: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    sortOrder: number
  } | null
}

export type AccommodationRoomTypeReservationForSalesPrintFragment = {
  __typename: 'RoomTypeReservation'
  id: string
  request: {
    __typename: 'RoomTypeRequest'
    beds: number
    extraBeds: number
    info: string | null
    rooms: number
    checkIn: { __typename: 'CheckIn'; date: string }
    checkOut: { __typename: 'CheckOut'; date: string }
    features: Array<{
      __typename: 'RoomLabel'
      icon: string | null
      id: string
      name: string
      shortName: string
    }>
    roomType: { __typename: 'RoomType'; id: string; name: string }
  }
  roomReservations: Array<{
    __typename: 'RoomReservation'
    id: string
    request: {
      __typename: 'RoomRequest'
      beds: number
      extraBeds: number
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      room: {
        __typename: 'Room'
        id: string
        internalInfo: string | null
        number: string
      }
    }
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      id: string
      name: string
      sortOrder: number
    } | null
  }>
  target: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    sortOrder: number
  } | null
}

export type AccommodationForSalesPrintFragment = {
  __typename: 'SalesAccommodation'
  id: string
  roomReservations: Array<{
    __typename: 'RoomReservation'
    id: string
    request: {
      __typename: 'RoomRequest'
      beds: number
      extraBeds: number
      info: string | null
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      room: {
        __typename: 'Room'
        id: string
        internalInfo: string | null
        number: string
      }
    }
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      id: string
      name: string
      sortOrder: number
    } | null
  }>
  roomTypeReservations: Array<{
    __typename: 'RoomTypeReservation'
    id: string
    request: {
      __typename: 'RoomTypeRequest'
      beds: number
      extraBeds: number
      info: string | null
      rooms: number
      checkIn: { __typename: 'CheckIn'; date: string }
      checkOut: { __typename: 'CheckOut'; date: string }
      features: Array<{
        __typename: 'RoomLabel'
        icon: string | null
        id: string
        name: string
        shortName: string
      }>
      roomType: { __typename: 'RoomType'; id: string; name: string }
    }
    roomReservations: Array<{
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string }
        checkOut: { __typename: 'CheckOut'; date: string }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          id: string
          internalInfo: string | null
          number: string
        }
      }
      target: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
      } | null
    }>
    target: {
      __typename: 'AccommodationTarget'
      default: boolean
      id: string
      name: string
      sortOrder: number
    } | null
  }>
}

export type MealForSalesPrintFragment = {
  __typename: 'ScheduledMeal'
  id: string
  start: string
  status: ScheduledMealStatus
  duration: number | null
  notes: string | null
  location:
    | { __typename: 'ResourceEquipment'; name: string }
    | { __typename: 'ResourceHuman'; name: string }
    | { __typename: 'ResourceProperty'; name: string }
    | { __typename: 'ResourcePropertyNested'; name: string }
    | { __typename: 'Restaurant'; name: string }
    | { __typename: 'Room'; name: string }
  meal: { __typename: 'Meal'; name: string }
  quantities: Array<{ __typename: 'AgeCategoryQuantity'; quantity: number }>
}

export type ParticipantForSalesPrintServiceBedFragment = {
  __typename: 'ServiceParticipantBed'
  id: string
  accommodationTarget: {
    __typename: 'AccommodationTarget'
    default: boolean
    id: string
    name: string
    sortOrder: number
  } | null
  dates: {
    __typename: 'AccommodationDates'
    checkIn: { __typename: 'CheckIn'; date: string }
    checkOut: { __typename: 'CheckOut'; date: string }
  } | null
  participantRoom: {
    __typename: 'ParticipantRoom'
    id: string
    roomReservation: {
      __typename: 'RoomReservation'
      id: string
      request: {
        __typename: 'RoomRequest'
        beds: number
        extraBeds: number
        info: string | null
        checkIn: { __typename: 'CheckIn'; date: string }
        checkOut: { __typename: 'CheckOut'; date: string }
        features: Array<{
          __typename: 'RoomLabel'
          icon: string | null
          id: string
          name: string
          shortName: string
        }>
        room: {
          __typename: 'Room'
          id: string
          internalInfo: string | null
          number: string
        }
      }
      target: {
        __typename: 'AccommodationTarget'
        default: boolean
        id: string
        name: string
        sortOrder: number
      } | null
    }
  } | null
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      source: AttributeSource
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type ParticipantForSalesPrintServicePurchaseFragment = {
  __typename: 'ServicePurchase'
  id: string
  purchaseProduct: {
    __typename: 'PurchaseProduct'
    id: string
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      source: AttributeSource
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  } | null
}

export type ParticipantForSalesPrintFragment = {
  __typename: 'SalesParticipant'
  accommodationRequest: string | null
  additionalInfo: string | null
  age: number | null
  allergyDescription: string | null
  allergies: Array<string>
  diets: Array<string>
  drugAllergyDescription: string | null
  firstName: string
  id: string
  lastName: string
  group: string | null
  ageCategory: {
    __typename: 'AgeCategory'
    abbreviation: string | null
    key: string
    name: string
    shortName: string
    sortOrder: number
  } | null
  services: Array<
    | {
        __typename: 'ServiceParticipantBed'
        id: string
        accommodationTarget: {
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
        } | null
        dates: {
          __typename: 'AccommodationDates'
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
        } | null
        participantRoom: {
          __typename: 'ParticipantRoom'
          id: string
          roomReservation: {
            __typename: 'RoomReservation'
            id: string
            request: {
              __typename: 'RoomRequest'
              beds: number
              extraBeds: number
              info: string | null
              checkIn: { __typename: 'CheckIn'; date: string }
              checkOut: { __typename: 'CheckOut'; date: string }
              features: Array<{
                __typename: 'RoomLabel'
                icon: string | null
                id: string
                name: string
                shortName: string
              }>
              room: {
                __typename: 'Room'
                id: string
                internalInfo: string | null
                number: string
              }
            }
            target: {
              __typename: 'AccommodationTarget'
              default: boolean
              id: string
              name: string
              sortOrder: number
            } | null
          }
        } | null
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            source: AttributeSource
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
    | {
        __typename: 'ServicePurchase'
        id: string
        purchaseProduct: {
          __typename: 'PurchaseProduct'
          id: string
          attributes: {
            __typename: 'PurchaseProductAttributes'
            quantity: number
            source: AttributeSource
          }
          product: { __typename: 'SalesProduct'; id: string; name: string }
          totalPrice: {
            __typename: 'PriceElements'
            amount: number
            amountVatIncluded: number
            vatAmount: number
          }
        } | null
      }
  >
}

export type PurchaseForSalesPrintFragment = {
  __typename: 'PurchaseProduct'
  id: string
  attributes: {
    __typename: 'PurchaseProductAttributes'
    quantity: number
    source: AttributeSource
  }
  product: { __typename: 'SalesProduct'; id: string; name: string }
  totalPrice: {
    __typename: 'PriceElements'
    amount: number
    amountVatIncluded: number
    vatAmount: number
  }
}

export type ResourceReservationForSalesPrintFragment = {
  __typename: 'ResourceReservation'
  customerVisibility: ResourceReservationCustomerVisibility
  description: string | null
  end: string
  id: string
  internalNote: string | null
  resourceQuantity: number
  start: string
  dimensions: {
    __typename: 'DimensionReference'
    selection: Array<{
      __typename: 'DimensionSelectionItem'
      dimension: { __typename: 'Dimension'; name: string }
      selectedLabel: { __typename: 'DimensionLabel'; name: string } | null
    }>
  } | null
  group: {
    __typename: 'ResourceReservationGroup'
    customerEnd: string
    customerStart: string
    id: string
    name: string | null
    reservations: Array<{ __typename: 'ResourceReservation'; id: string }>
  } | null
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      source: AttributeSource
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  resource:
    | {
        __typename: 'ResourceEquipment'
        id: string
        internalInfo: string | null
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceHuman'
        id: string
        internalInfo: string | null
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourceProperty'
        id: string
        internalInfo: string | null
        name: string
        type: ResourceType
      }
    | {
        __typename: 'ResourcePropertyNested'
        id: string
        internalInfo: string | null
        name: string
        type: ResourceType
      }
    | null
}

export type TaskForSalesPrintFragment = {
  __typename: 'Task'
  description: string | null
  dueDate: string | null
  dueTime: string | null
  id: string
  isOpen: boolean
  name: string | null
  number: number
  assignee:
    | { __typename: 'User'; id: string }
    | { __typename: 'UserGroup'; id: string; name: string }
    | null
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      source: AttributeSource
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  reservation: { __typename: 'ResourceReservation'; id: string } | null
}

export type SalesLifecycleFragment = {
  __typename: 'SalesLifecycle'
  isConfirmed: boolean
  reason: SalesLifecycleReason | null
  specifier: string | null
  state: {
    __typename: 'SalesState'
    isConfirmed: boolean
    key: string
    processOrder: number
    disabled: boolean
    systemState: SalesSystemState
    names: Array<{
      __typename: 'LocalizedContent'
      locale: string
      content: string
    }>
  }
  stateOptions: Array<{
    __typename: 'ValidatedSalesStateOption'
    valid: boolean
    state: {
      __typename: 'SalesState'
      key: string
      systemState: SalesSystemState
    }
  }>
}

export type SalesVstFragment = {
  __typename: 'SalesVst'
  missingFields: Array<VstField>
  state: SalesVstState
  totalErrorParticipants: number
  totalIncompleteParticipants: number
  mainCode: {
    __typename: 'SalesVstCode'
    id: string
    code: string
    title: string
  } | null
  otherLesson: {
    __typename: 'Lesson'
    hours: number | null
    fee: number | null
  }
  participantLessonSummary: {
    __typename: 'Lesson'
    hours: number | null
    fee: number | null
  }
  participantVstSummary: Array<{
    __typename: 'SalesVstCalculation'
    dayVisitor: boolean
    gender: Gender | null
    vstDays: number
    vstParticipantCount: number
    nonVstDays: number
    nonVstParticipantCount: number
    ageCategory: { __typename: 'AgeCategory'; key: string; name: string } | null
  }>
  sideCode: {
    __typename: 'SalesVstCode'
    id: string
    code: string
    title: string
  } | null
  vstDocumentConfig: {
    __typename: 'VstDocumentConfig'
    showSignature: boolean
    documentSigner: {
      __typename: 'SalesParticipant'
      firstName: string
      id: string
      lastName: string
      sortOrder: number
    } | null
  }
}

export type CopySaleMutationVariables = Exact<{
  input: SalesCopyInput
}>

export type CopySaleMutation = {
  __typename: 'Mutation'
  salesCopy: {
    __typename: 'SalesCopyPayload'
    sales: { __typename: 'Sales'; id: string }
  }
}

export type CreateEnrollmentMutationVariables = Exact<{
  input: SalesCreateEnrollmentInput
  enrollmentsInput: InputMaybe<SalesEnrollmentsInput>
}>

export type CreateEnrollmentMutation = {
  __typename: 'Mutation'
  salesCreateEnrollment: {
    __typename: 'Sales'
    id: string
    type: SalesType
    orderNumber: number | null
    name: string | null
    notes: string | null
    locked: boolean
    estimatedParticipants: number | null
    event: {
      __typename: 'Sales'
      enrollments: {
        __typename: 'SalesConnection'
        totalPages: number
        totalElements: number
        hasNextPage: boolean
        hasPreviousPage: boolean
      } | null
    } | null
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      isConfirmed: boolean
      reason: SalesLifecycleReason | null
      specifier: string | null
      state: {
        __typename: 'SalesState'
        isConfirmed: boolean
        key: string
        processOrder: number
        disabled: boolean
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }>
    }
    reservationDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    participantConnection: {
      __typename: 'ParticipantConnection'
      totalElements: number
      nodes: Array<{
        __typename: 'SalesParticipant'
        id: string
        firstName: string
        lastName: string
      }>
    }
    orders: Array<{
      __typename: 'Order'
      paymentInfo: {
        __typename: 'PaymentInfo'
        totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
      }
    }>
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      id: string
      name: string
      code: string
    } | null
  }
}

export type CreateEventMutationVariables = Exact<{
  input: SalesCreateEventInput
}>

export type CreateEventMutation = {
  __typename: 'Mutation'
  salesCreateEvent: {
    __typename: 'Sales'
    id: string
    orderNumber: number | null
  }
}

export type CreateSaleMutationVariables = Exact<{
  input: SalesCreateInput
}>

export type CreateSaleMutation = {
  __typename: 'Mutation'
  salesCreate: { __typename: 'Sales'; id: string; orderNumber: number | null }
}

export type RefreshProductsVatMutationVariables = Exact<{
  input: SalesProductsRefreshVatInput
}>

export type RefreshProductsVatMutation = {
  __typename: 'Mutation'
  salesProductsRefreshVat: Array<{ __typename: 'SalesProduct'; id: string }>
}

export type CancelSaleMutationVariables = Exact<{
  input: SalesCancelInput
}>

export type CancelSaleMutation = {
  __typename: 'Mutation'
  salesCancel: {
    __typename: 'Sales'
    id: string
    locked: boolean
    lifecycle: {
      __typename: 'SalesLifecycle'
      isConfirmed: boolean
      reason: SalesLifecycleReason | null
      specifier: string | null
      state: {
        __typename: 'SalesState'
        isConfirmed: boolean
        key: string
        processOrder: number
        disabled: boolean
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }>
    }
  }
}

export type DeleteSaleMutationVariables = Exact<{
  input: SalesDeleteInput
}>

export type DeleteSaleMutation = {
  __typename: 'Mutation'
  salesDelete: { __typename: 'DeletePayload'; id: string; deleted: boolean }
}

export type SaleVstSetAttributesMutationVariables = Exact<{
  input: SalesVstSetAttributesInput
}>

export type SaleVstSetAttributesMutation = {
  __typename: 'Mutation'
  salesVstSetAttributes: {
    __typename: 'SalesVstSetAttributesOutput'
    salesId: string
    vst: {
      __typename: 'SalesVst'
      missingFields: Array<VstField>
      state: SalesVstState
      totalErrorParticipants: number
      totalIncompleteParticipants: number
      mainCode: {
        __typename: 'SalesVstCode'
        id: string
        code: string
        title: string
      } | null
      otherLesson: {
        __typename: 'Lesson'
        hours: number | null
        fee: number | null
      }
      participantLessonSummary: {
        __typename: 'Lesson'
        hours: number | null
        fee: number | null
      }
      participantVstSummary: Array<{
        __typename: 'SalesVstCalculation'
        dayVisitor: boolean
        gender: Gender | null
        vstDays: number
        vstParticipantCount: number
        nonVstDays: number
        nonVstParticipantCount: number
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          name: string
        } | null
      }>
      sideCode: {
        __typename: 'SalesVstCode'
        id: string
        code: string
        title: string
      } | null
      vstDocumentConfig: {
        __typename: 'VstDocumentConfig'
        showSignature: boolean
        documentSigner: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
          sortOrder: number
        } | null
      }
    }
  }
}

export type SetSalesAssigneeMutationVariables = Exact<{
  input: SalesUpdateAssigneeInput
}>

export type SetSalesAssigneeMutation = {
  __typename: 'Mutation'
  salesAssignee: { __typename: 'Sales'; id: string }
}

export type SetSalesCustomerMutationVariables = Exact<{
  input: SalesSetCustomerInput
}>

export type SetSalesCustomerMutation = {
  __typename: 'Mutation'
  salesSetCustomer: {
    __typename: 'SalesSetCustomerPayload'
    sales: { __typename: 'Sales'; id: string }
  }
}

export type SetSalesCustomerAddressMutationVariables = Exact<{
  input: SalesSetCustomerAddressInput
}>

export type SetSalesCustomerAddressMutation = {
  __typename: 'Mutation'
  salesSetCustomerAddress: {
    __typename: 'SalesSetCustomerAddressPayload'
    sales: { __typename: 'Sales'; id: string }
  }
}

export type SetSalesCustomerContactMutationVariables = Exact<{
  input: SalesSetCustomerContactInput
}>

export type SetSalesCustomerContactMutation = {
  __typename: 'Mutation'
  salesSetCustomerContact: {
    __typename: 'SalesSetCustomerContactPayload'
    sales: { __typename: 'Sales'; id: string }
  }
}

export type SetSalesFacetMutationVariables = Exact<{
  input: SalesUpdateFacetInput
}>

export type SetSalesFacetMutation = {
  __typename: 'Mutation'
  salesUpdateFacet: {
    __typename: 'Sales'
    id: string
    facet: {
      __typename: 'SalesFacet'
      abbreviation: string
      id: string
      name: string
      salesType: SalesType
    }
  }
}

export type SetSalesStateMutationVariables = Exact<{
  input: SalesSetStateInput
}>

export type SetSalesStateMutation = {
  __typename: 'Mutation'
  salesSetState: {
    __typename: 'Sales'
    id: string
    locked: boolean
    lifecycle: {
      __typename: 'SalesLifecycle'
      isConfirmed: boolean
      reason: SalesLifecycleReason | null
      specifier: string | null
      state: {
        __typename: 'SalesState'
        isConfirmed: boolean
        key: string
        processOrder: number
        disabled: boolean
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }>
    }
  }
}

export type SetVstSignerMutationVariables = Exact<{
  input: SalesVstSetSignerInput
}>

export type SetVstSignerMutation = {
  __typename: 'Mutation'
  salesVstSetSigner: {
    __typename: 'SalesParticipant'
    firstName: string
    id: string
    lastName: string
    sortOrder: number
  } | null
}

export type UpdateEventSettingsMutationVariables = Exact<{
  input: SalesWebshopSettingsInput
}>

export type UpdateEventSettingsMutation = {
  __typename: 'Mutation'
  salesSetWebshopSettings: {
    __typename: 'Sales'
    id: string
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      eventSettings: {
        __typename: 'EventSettings'
        enrollEnd: string | null
        enrollStart: string | null
        visibility: EventVisibility
        link: string
      } | null
    } | null
  }
}

export type UpdateSalesAttributesMutationVariables = Exact<{
  input: SalesUpdateAttributesInput
}>

export type UpdateSalesAttributesMutation = {
  __typename: 'Mutation'
  salesUpdateAttributes: {
    __typename: 'Sales'
    estimatedParticipants: number | null
    id: string
    language: string
    notes: string | null
    probabilityEstimate: number
    probabilitySource: ProbabilitySource | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      code: string
      id: string
      name: string
    } | null
  }
}

export type UpdateSalesCommissionMutationVariables = Exact<{
  input: SalesUpdateCommissionInput
}>

export type UpdateSalesCommissionMutation = {
  __typename: 'Mutation'
  salesUpdateCommission: {
    __typename: 'Sales'
    commissionRate: number
    id: string
  }
}

export type UpdateSalesNameMutationVariables = Exact<{
  input: SalesUpdateNameInput
}>

export type UpdateSalesNameMutation = {
  __typename: 'Mutation'
  salesUpdateName: { __typename: 'Sales'; id: string; name: string | null }
}

export type EventEnrollmentsQueryVariables = Exact<{
  id: Scalars['ID']
  input: InputMaybe<SalesEnrollmentsInput>
}>

export type EventEnrollmentsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    type: SalesType
    enrollments: {
      __typename: 'SalesConnection'
      totalPages: number
      totalElements: number
      hasNextPage: boolean
      hasPreviousPage: boolean
      nodes: Array<{
        __typename: 'Sales'
        id: string
        type: SalesType
        orderNumber: number | null
        name: string | null
        notes: string | null
        locked: boolean
        estimatedParticipants: number | null
        customer: {
          __typename: 'CustomerReference'
          address: { __typename: 'CustomerAddress'; id: string } | null
          contact: {
            __typename: 'CustomerOrganizationContact'
            id: string
          } | null
          customer:
            | {
                __typename: 'CustomerOrganization'
                defaultContactId: string | null
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                contacts: Array<{
                  __typename: 'CustomerOrganizationContact'
                  email: string | null
                  firstName: string
                  id: string
                  lastName: string
                  notes: string | null
                  phone: string | null
                }>
                eInvoicingAddress: {
                  __typename: 'EInvoicingAddress'
                  address: string
                  operator: string
                } | null
                organization: {
                  __typename: 'CustomerOrganizationData'
                  businessId: string | null
                  name: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
            | {
                __typename: 'CustomerPerson'
                customerNumber: string
                defaultAddressId: string | null
                id: string
                publicName: string | null
                person: {
                  __typename: 'CustomerPersonData'
                  email: string | null
                  firstName: string
                  lastName: string
                  phone: string | null
                }
                addresses: Array<{
                  __typename: 'CustomerAddress'
                  id: string
                  label: string | null
                  postalAddress: {
                    __typename: 'PostalAddressType'
                    address1: string | null
                    address2: string | null
                    city: string | null
                    country: string | null
                    postcode: string | null
                  } | null
                }>
                labels: Array<{
                  __typename: 'CustomerLabel'
                  id: string
                  name: string
                }>
              }
        } | null
        lifecycle: {
          __typename: 'SalesLifecycle'
          isConfirmed: boolean
          reason: SalesLifecycleReason | null
          specifier: string | null
          state: {
            __typename: 'SalesState'
            isConfirmed: boolean
            key: string
            processOrder: number
            disabled: boolean
            systemState: SalesSystemState
            names: Array<{
              __typename: 'LocalizedContent'
              locale: string
              content: string
            }>
          }
          stateOptions: Array<{
            __typename: 'ValidatedSalesStateOption'
            valid: boolean
            state: {
              __typename: 'SalesState'
              key: string
              systemState: SalesSystemState
            }
          }>
        }
        reservationDates: {
          __typename: 'LocalDateRange'
          start: string
          end: string
        } | null
        estimatedDates: {
          __typename: 'LocalDateRange'
          start: string
          end: string
        } | null
        participantConnection: {
          __typename: 'ParticipantConnection'
          totalElements: number
          nodes: Array<{
            __typename: 'SalesParticipant'
            id: string
            firstName: string
            lastName: string
          }>
        }
        orders: Array<{
          __typename: 'Order'
          paymentInfo: {
            __typename: 'PaymentInfo'
            totalPrice: {
              __typename: 'PriceElements'
              amountVatIncluded: number
            }
          }
        }>
        paymentAgreement: {
          __typename: 'PaymentAgreement'
          id: string
          name: string
          code: string
        } | null
      }>
    } | null
  }
}

export type LastUsedSalesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
}>

export type LastUsedSalesQuery = {
  __typename: 'Query'
  salesAll: Array<{
    __typename: 'Sales'
    id: string
    type: SalesType
    name: string | null
    orderNumber: number | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    reservationDates: {
      __typename: 'LocalDateRange'
      start: string
      end: string
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      abbreviation: string
      color: string
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
    customer: {
      __typename: 'CustomerReference'
      customer:
        | {
            __typename: 'CustomerOrganization'
            customerNumber: string
            organization: {
              __typename: 'CustomerOrganizationData'
              name: string | null
            }
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            person: {
              __typename: 'CustomerPersonData'
              firstName: string
              lastName: string
            }
          }
    } | null
  }>
}

export type RefreshVatEnabledQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type RefreshVatEnabledQuery = {
  __typename: 'Query'
  refreshVatEnabled: boolean | null
}

export type SalesAssignersQueryVariables = Exact<{ [key: string]: never }>

export type SalesAssignersQuery = {
  __typename: 'Query'
  users: Array<{
    __typename: 'User'
    abbreviation: string | null
    avatarUrl: string | null
    department: string | null
    email: string | null
    firstName: string | null
    id: string
    lastName: string | null
  }>
}

export type SalesDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesDetailsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    commissionRate: number
    estimatedParticipants: number | null
    id: string
    language: string
    locked: boolean
    name: string | null
    notes: string | null
    orderNumber: number | null
    probabilityEstimate: number
    probabilitySource: ProbabilitySource | null
    type: SalesType
    accommodation: {
      __typename: 'SalesAccommodation'
      id: string
      accommodationGroups: Array<{
        __typename: 'AccommodationGroup'
        id: string
        status: {
          __typename: 'AccommodationReservationStatus'
          reservedDetailed: {
            __typename: 'RoomsAndBeds'
            beds: number
            extraBeds: number
            rooms: number
          }
        }
      }>
    }
    assignee: {
      __typename: 'User'
      abbreviation: string | null
      avatarUrl: string | null
      department: string | null
      email: string | null
      firstName: string | null
      id: string
      lastName: string | null
    } | null
    attachments: Array<{
      __typename: 'FileItemInfo'
      created: string
      etag: string | null
      fileName: string
      lastModified: string
      mimeType: string
      id: string
      size: number
      info: {
        __typename: 'FileIdentifier'
        fileName: string
        folder: string
        ownerType: FileOwner
        ownerId: string
      }
    }>
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    event: {
      __typename: 'Sales'
      estimatedParticipants: number | null
      id: string
      name: string | null
      orderNumber: number | null
      probabilityEstimate: number
      estimatedDates: {
        __typename: 'LocalDateRange'
        start: string
        end: string
      } | null
    } | null
    webshopSettings: {
      __typename: 'WebshopSettings'
      id: string
      eventSettings: {
        __typename: 'EventSettings'
        enrollEnd: string | null
        enrollStart: string | null
        visibility: EventVisibility
        link: string
      } | null
    } | null
    facet: {
      __typename: 'SalesFacet'
      abbreviation: string
      color: string
      dimensionTarget: DimensionTarget
      id: string
      name: string
      probabilityEstimate: boolean
      features: Array<{
        __typename: 'SalesFacetFeature'
        defaultVisibility: Visibility
        feature: Feature
      }>
      participantsDefaults: {
        __typename: 'ParticipantsDefaults'
        groupedBy: ParticipantSortProperty | null
        pageSize: number
        sort: Array<{
          __typename: 'ParticipantSort'
          field: ParticipantSortProperty
          order: SortOrder
        }>
      } | null
    }
    groups: {
      __typename: 'NamedValues'
      label: string | null
      values: Array<string>
    } | null
    lifecycle: {
      __typename: 'SalesLifecycle'
      isConfirmed: boolean
      reason: SalesLifecycleReason | null
      specifier: string | null
      state: {
        __typename: 'SalesState'
        isConfirmed: boolean
        key: string
        processOrder: number
        disabled: boolean
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
        }
      }>
    }
    paymentAgreement: {
      __typename: 'PaymentAgreement'
      code: string
      id: string
      name: string
    } | null
    reservationDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    seller: { __typename: 'Seller'; id: string } | null
    tasks: Array<{
      __typename: 'Task'
      id: string
      isOpen: boolean
      type: TaskType
    }>
    vst: {
      __typename: 'SalesVst'
      missingFields: Array<VstField>
      state: SalesVstState
      totalErrorParticipants: number
      totalIncompleteParticipants: number
      mainCode: {
        __typename: 'SalesVstCode'
        id: string
        code: string
        title: string
      } | null
      otherLesson: {
        __typename: 'Lesson'
        hours: number | null
        fee: number | null
      }
      participantLessonSummary: {
        __typename: 'Lesson'
        hours: number | null
        fee: number | null
      }
      participantVstSummary: Array<{
        __typename: 'SalesVstCalculation'
        dayVisitor: boolean
        gender: Gender | null
        vstDays: number
        vstParticipantCount: number
        nonVstDays: number
        nonVstParticipantCount: number
        ageCategory: {
          __typename: 'AgeCategory'
          key: string
          name: string
        } | null
      }>
      sideCode: {
        __typename: 'SalesVstCode'
        id: string
        code: string
        title: string
      } | null
      vstDocumentConfig: {
        __typename: 'VstDocumentConfig'
        showSignature: boolean
        documentSigner: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
          sortOrder: number
        } | null
      }
    } | null
  }
}

export type SalesDetailsForPrintQueryVariables = Exact<{
  id: Scalars['ID']
  withAccommodation: Scalars['Boolean']
  withMeals: Scalars['Boolean']
  withParticipants: Scalars['Boolean']
  withReservations: Scalars['Boolean']
  withTasks: Scalars['Boolean']
}>

export type SalesDetailsForPrintQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    name: string | null
    notes: string | null
    orderNumber: number | null
    type: SalesType
    accommodation?: {
      __typename: 'SalesAccommodation'
      id: string
      roomReservations: Array<{
        __typename: 'RoomReservation'
        id: string
        request: {
          __typename: 'RoomRequest'
          beds: number
          extraBeds: number
          info: string | null
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          room: {
            __typename: 'Room'
            id: string
            internalInfo: string | null
            number: string
          }
        }
        target: {
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
        } | null
      }>
      roomTypeReservations: Array<{
        __typename: 'RoomTypeReservation'
        id: string
        request: {
          __typename: 'RoomTypeRequest'
          beds: number
          extraBeds: number
          info: string | null
          rooms: number
          checkIn: { __typename: 'CheckIn'; date: string }
          checkOut: { __typename: 'CheckOut'; date: string }
          features: Array<{
            __typename: 'RoomLabel'
            icon: string | null
            id: string
            name: string
            shortName: string
          }>
          roomType: { __typename: 'RoomType'; id: string; name: string }
        }
        roomReservations: Array<{
          __typename: 'RoomReservation'
          id: string
          request: {
            __typename: 'RoomRequest'
            beds: number
            extraBeds: number
            info: string | null
            checkIn: { __typename: 'CheckIn'; date: string }
            checkOut: { __typename: 'CheckOut'; date: string }
            features: Array<{
              __typename: 'RoomLabel'
              icon: string | null
              id: string
              name: string
              shortName: string
            }>
            room: {
              __typename: 'Room'
              id: string
              internalInfo: string | null
              number: string
            }
          }
          target: {
            __typename: 'AccommodationTarget'
            default: boolean
            id: string
            name: string
            sortOrder: number
          } | null
        }>
        target: {
          __typename: 'AccommodationTarget'
          default: boolean
          id: string
          name: string
          sortOrder: number
        } | null
      }>
    }
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    estimatedDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
    products: Array<{
      __typename: 'SalesProduct'
      id: string
      purchases: Array<{
        __typename: 'PurchaseProduct'
        id: string
        product: { __typename: 'SalesProduct'; name: string }
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
        }
      }>
    }>
    participantConnection?: {
      __typename: 'ParticipantConnection'
      nodes: Array<{
        __typename: 'SalesParticipant'
        accommodationRequest: string | null
        additionalInfo: string | null
        age: number | null
        allergyDescription: string | null
        allergies: Array<string>
        diets: Array<string>
        drugAllergyDescription: string | null
        firstName: string
        id: string
        lastName: string
        group: string | null
        ageCategory: {
          __typename: 'AgeCategory'
          abbreviation: string | null
          key: string
          name: string
          shortName: string
          sortOrder: number
        } | null
        services: Array<
          | {
              __typename: 'ServiceParticipantBed'
              id: string
              accommodationTarget: {
                __typename: 'AccommodationTarget'
                default: boolean
                id: string
                name: string
                sortOrder: number
              } | null
              dates: {
                __typename: 'AccommodationDates'
                checkIn: { __typename: 'CheckIn'; date: string }
                checkOut: { __typename: 'CheckOut'; date: string }
              } | null
              participantRoom: {
                __typename: 'ParticipantRoom'
                id: string
                roomReservation: {
                  __typename: 'RoomReservation'
                  id: string
                  request: {
                    __typename: 'RoomRequest'
                    beds: number
                    extraBeds: number
                    info: string | null
                    checkIn: { __typename: 'CheckIn'; date: string }
                    checkOut: { __typename: 'CheckOut'; date: string }
                    features: Array<{
                      __typename: 'RoomLabel'
                      icon: string | null
                      id: string
                      name: string
                      shortName: string
                    }>
                    room: {
                      __typename: 'Room'
                      id: string
                      internalInfo: string | null
                      number: string
                    }
                  }
                  target: {
                    __typename: 'AccommodationTarget'
                    default: boolean
                    id: string
                    name: string
                    sortOrder: number
                  } | null
                }
              } | null
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  source: AttributeSource
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
          | {
              __typename: 'ServicePurchase'
              id: string
              purchaseProduct: {
                __typename: 'PurchaseProduct'
                id: string
                attributes: {
                  __typename: 'PurchaseProductAttributes'
                  quantity: number
                  source: AttributeSource
                }
                product: {
                  __typename: 'SalesProduct'
                  id: string
                  name: string
                }
                totalPrice: {
                  __typename: 'PriceElements'
                  amount: number
                  amountVatIncluded: number
                  vatAmount: number
                }
              } | null
            }
        >
      }>
    }
    reservationDates: {
      __typename: 'LocalDateRange'
      end: string
      start: string
    } | null
    resourceReservations?: Array<{
      __typename: 'ResourceReservation'
      customerVisibility: ResourceReservationCustomerVisibility
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      dimensions: {
        __typename: 'DimensionReference'
        selection: Array<{
          __typename: 'DimensionSelectionItem'
          dimension: { __typename: 'Dimension'; name: string }
          selectedLabel: { __typename: 'DimensionLabel'; name: string } | null
        }>
      } | null
      group: {
        __typename: 'ResourceReservationGroup'
        customerEnd: string
        customerStart: string
        id: string
        name: string | null
        reservations: Array<{ __typename: 'ResourceReservation'; id: string }>
      } | null
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          source: AttributeSource
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            internalInfo: string | null
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            internalInfo: string | null
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            internalInfo: string | null
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            internalInfo: string | null
            name: string
            type: ResourceType
          }
        | null
    }>
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
    tasks?: Array<{
      __typename: 'Task'
      description: string | null
      dueDate: string | null
      dueTime: string | null
      id: string
      isOpen: boolean
      name: string | null
      number: number
      assignee:
        | { __typename: 'User'; id: string }
        | { __typename: 'UserGroup'; id: string; name: string }
        | null
      purchaseProducts: Array<{
        __typename: 'PurchaseProduct'
        id: string
        attributes: {
          __typename: 'PurchaseProductAttributes'
          quantity: number
          source: AttributeSource
        }
        product: { __typename: 'SalesProduct'; id: string; name: string }
        totalPrice: {
          __typename: 'PriceElements'
          amount: number
          amountVatIncluded: number
          vatAmount: number
        }
      }>
      reservation: { __typename: 'ResourceReservation'; id: string } | null
    }>
  }
  scheduledMealsBySales?: Array<{
    __typename: 'ScheduledMeal'
    id: string
    start: string
    status: ScheduledMealStatus
    duration: number | null
    notes: string | null
    location:
      | { __typename: 'ResourceEquipment'; name: string }
      | { __typename: 'ResourceHuman'; name: string }
      | { __typename: 'ResourceProperty'; name: string }
      | { __typename: 'ResourcePropertyNested'; name: string }
      | { __typename: 'Restaurant'; name: string }
      | { __typename: 'Room'; name: string }
    meal: { __typename: 'Meal'; name: string }
    quantities: Array<{ __typename: 'AgeCategoryQuantity'; quantity: number }>
  }>
}

export type SalesFacetsQueryVariables = Exact<{
  forCreate: InputMaybe<Scalars['Boolean']>
}>

export type SalesFacetsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    salesFacets: Array<{
      __typename: 'SalesFacet'
      abbreviation: string
      id: string
      name: string
      salesType: SalesType
    }>
  }
}

export type SalesIssuesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesIssuesQuery = {
  __typename: 'Query'
  salesIssues: {
    __typename: 'SalesIssuesPayload'
    accommodationIssues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
    reservationIssues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
    salesIssues: Array<{
      __typename: 'ValidationIssue'
      code: number
      key: string
      level: IssueLevel
    }>
  }
}

export type SalesPaymentInfoQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID']
}>

export type SalesPaymentInfoQuery = {
  __typename: 'Query'
  salesAll: Array<{
    __typename: 'Sales'
    id: string
    paymentInfo: {
      __typename: 'PaymentInfo'
      totalPrice: { __typename: 'PriceElements'; amountVatIncluded: number }
    } | null
  }>
}

export type SalesStateOptionsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SalesStateOptionsQuery = {
  __typename: 'Query'
  sales: {
    __typename: 'Sales'
    id: string
    lifecycle: {
      __typename: 'SalesLifecycle'
      stateOptions: Array<{
        __typename: 'ValidatedSalesStateOption'
        valid: boolean
        state: {
          __typename: 'SalesState'
          isConfirmed: boolean
          key: string
          processOrder: number
          disabled: boolean
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
          message: string
          targetPath: Array<{
            __typename: 'TargetIdentifier'
            id: string
            type: string
          }>
        }>
      }>
    }
  }
}

export type SalesStateReasonsQueryVariables = Exact<{ [key: string]: never }>

export type SalesStateReasonsQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    salesStateReasons: Array<{
      __typename: 'SalesStateReason'
      key: string
      names: Array<{
        __typename: 'LocalizedContent'
        locale: string
        content: string
      }>
    }>
  }
}

export type SalesStatesQueryVariables = Exact<{ [key: string]: never }>

export type SalesStatesQuery = {
  __typename: 'Query'
  registry: {
    __typename: 'Registry'
    salesStates: Array<{
      __typename: 'SalesState'
      isConfirmed: boolean
      key: string
      processOrder: number
      disabled: boolean
      systemState: SalesSystemState
      names: Array<{
        __typename: 'LocalizedContent'
        locale: string
        content: string
      }>
    }>
  }
}

export type SalesSubscriptionSubscriptionVariables = Exact<{
  filter: SalesFilterInput
}>

export type SalesSubscriptionSubscription = {
  __typename: 'Subscription'
  sales: {
    __typename: 'SalesContextEvent'
    salesId: string
    event:
      | {
          __typename: 'SalesCustomerEvent'
          id: string
          type: EventType
          data: {
            __typename: 'CustomerReference'
            address: { __typename: 'CustomerAddress'; id: string } | null
            contact: {
              __typename: 'CustomerOrganizationContact'
              id: string
            } | null
            customer:
              | {
                  __typename: 'CustomerOrganization'
                  defaultContactId: string | null
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  contacts: Array<{
                    __typename: 'CustomerOrganizationContact'
                    email: string | null
                    firstName: string
                    id: string
                    lastName: string
                    notes: string | null
                    phone: string | null
                  }>
                  eInvoicingAddress: {
                    __typename: 'EInvoicingAddress'
                    address: string
                    operator: string
                  } | null
                  organization: {
                    __typename: 'CustomerOrganizationData'
                    businessId: string | null
                    name: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
              | {
                  __typename: 'CustomerPerson'
                  customerNumber: string
                  defaultAddressId: string | null
                  id: string
                  publicName: string | null
                  person: {
                    __typename: 'CustomerPersonData'
                    email: string | null
                    firstName: string
                    lastName: string
                    phone: string | null
                  }
                  addresses: Array<{
                    __typename: 'CustomerAddress'
                    id: string
                    label: string | null
                    postalAddress: {
                      __typename: 'PostalAddressType'
                      address1: string | null
                      address2: string | null
                      city: string | null
                      country: string | null
                      postcode: string | null
                    } | null
                  }>
                  labels: Array<{
                    __typename: 'CustomerLabel'
                    id: string
                    name: string
                  }>
                }
          } | null
        }
      | {
          __typename: 'SalesDimensionEvent'
          id: string
          type: EventType
          data: {
            __typename: 'DimensionReference'
            id: string
            selection: Array<{
              __typename: 'DimensionSelectionItem'
              id: string
              dimension: {
                __typename: 'Dimension'
                dimensionHierarchyRoot: string | null
                hierarchyName: string | null
                id: string
                name: string
                required: boolean
                sortOrder: number
              }
              selectedLabel: {
                __typename: 'DimensionLabel'
                id: string
                name: string
              } | null
              validatedOptions: Array<{
                __typename: 'DimensionLabelOption'
                id: string
                validationCategory: DimensionLabelValidationCategory
                label: {
                  __typename: 'DimensionLabel'
                  id: string
                  name: string
                }
              }>
            }>
          } | null
        }
      | {
          __typename: 'SalesTokenEvent'
          id: string
          type: EventType
          data: {
            __typename: 'WebshopSalesToken'
            id: string
            link: string
            scopes: Array<WebshopPermissionScope>
            token: string
            tokenDescription: string | null
            validFrom: string | null
            validTo: string | null
            completedAt: string | null
            issues: Array<{
              __typename: 'ValidationIssue'
              message: string
              level: IssueLevel
              key: string
              code: number
              targetPath: Array<{
                __typename: 'TargetIdentifier'
                id: string
                type: string
              }>
            }>
            invoice: {
              __typename: 'Invoice'
              id: string
              document: { __typename: 'Document'; id: string } | null
            } | null
          } | null
        }
  }
}

export type TaskFragment = {
  __typename: 'Task'
  description: string | null
  dueDate: string | null
  dueTime: string | null
  id: string
  isOpen: boolean
  name: string | null
  number: number
  assignee:
    | { __typename: 'User'; id: string }
    | { __typename: 'UserGroup'; id: string; name: string }
    | null
  auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
  purchaseProducts: Array<{
    __typename: 'PurchaseProduct'
    id: string
    targetType: TargetType
    attributes: {
      __typename: 'PurchaseProductAttributes'
      quantity: number
      duration: { __typename: 'Duration'; from: string; to: string }
    }
    auditLog: { __typename: 'AuditLog'; createdAt: string }
    link: {
      __typename: 'PurchaseProductLink'
      participant: {
        __typename: 'SalesParticipant'
        firstName: string
        id: string
        lastName: string
      } | null
      reservation: {
        __typename: 'ResourceReservation'
        id: string
        resourceQuantity: number
        resource:
          | {
              __typename: 'ResourceEquipment'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceHuman'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourceProperty'
              id: string
              isPooled: boolean
              name: string
            }
          | {
              __typename: 'ResourcePropertyNested'
              id: string
              isPooled: boolean
              name: string
            }
          | null
      } | null
      sales: {
        __typename: 'Sales'
        id: string
        orderNumber: number | null
        type: SalesType
      }
      task: {
        __typename: 'Task'
        id: string
        assignee:
          | { __typename: 'User' }
          | { __typename: 'UserGroup'; id: string; name: string }
          | null
      } | null
    }
    order: {
      __typename: 'Order'
      id: string
      orderNumber: number
      lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
      paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
    }
    product: { __typename: 'SalesProduct'; id: string; name: string }
    status: {
      __typename: 'PurchaseProductStatus'
      issues: Array<{
        __typename: 'ValidationIssue'
        code: number
        key: string
        level: IssueLevel
      }>
      validatedActions: Array<{
        __typename: 'PurchaseProductActionValidated'
        action: PurchaseProductAction
        valid: boolean
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
      }>
    }
    totalPrice: {
      __typename: 'PriceElements'
      amount: number
      amountVatIncluded: number
      vatAmount: number
    }
  }>
  recommendedProducts: Array<{
    __typename: 'ProductTitleBasic'
    id: string
    type: string
    salesType: CatalogSalesType | null
    code: string | null
    name: string
    vatRate: number
    unitPrice: number
    quantityUnit: QuantityUnit
    catalog: { __typename: 'Catalog'; id: string; name: string }
  }>
  reservation: {
    __typename: 'ResourceReservation'
    description: string | null
    end: string
    id: string
    internalNote: string | null
    resourceQuantity: number
    start: string
    group: {
      __typename: 'ResourceReservationGroup'
      name: string | null
      number: number
    } | null
    resource:
      | {
          __typename: 'ResourceEquipment'
          id: string
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceHuman'
          id: string
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourceProperty'
          id: string
          name: string
          type: ResourceType
        }
      | {
          __typename: 'ResourcePropertyNested'
          id: string
          name: string
          type: ResourceType
        }
      | null
  } | null
  sales: {
    __typename: 'Sales'
    commissionRate: number
    id: string
    locked: boolean
    name: string | null
    orderNumber: number | null
    type: SalesType
    customer: {
      __typename: 'CustomerReference'
      address: { __typename: 'CustomerAddress'; id: string } | null
      contact: { __typename: 'CustomerOrganizationContact'; id: string } | null
      customer:
        | {
            __typename: 'CustomerOrganization'
            defaultContactId: string | null
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            contacts: Array<{
              __typename: 'CustomerOrganizationContact'
              email: string | null
              firstName: string
              id: string
              lastName: string
              notes: string | null
              phone: string | null
            }>
            eInvoicingAddress: {
              __typename: 'EInvoicingAddress'
              address: string
              operator: string
            } | null
            organization: {
              __typename: 'CustomerOrganizationData'
              businessId: string | null
              name: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
        | {
            __typename: 'CustomerPerson'
            customerNumber: string
            defaultAddressId: string | null
            id: string
            publicName: string | null
            person: {
              __typename: 'CustomerPersonData'
              email: string | null
              firstName: string
              lastName: string
              phone: string | null
            }
            addresses: Array<{
              __typename: 'CustomerAddress'
              id: string
              label: string | null
              postalAddress: {
                __typename: 'PostalAddressType'
                address1: string | null
                address2: string | null
                city: string | null
                country: string | null
                postcode: string | null
              } | null
            }>
            labels: Array<{
              __typename: 'CustomerLabel'
              id: string
              name: string
            }>
          }
    } | null
    facet: {
      __typename: 'SalesFacet'
      id: string
      features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
    }
    lifecycle: {
      __typename: 'SalesLifecycle'
      state: {
        __typename: 'SalesState'
        key: string
        systemState: SalesSystemState
        names: Array<{
          __typename: 'LocalizedContent'
          locale: string
          content: string
        }>
      }
    }
    orders: Array<{
      __typename: 'Order'
      id: string
      name: string | null
      sortOrder: number
    }>
    seller: { __typename: 'Seller'; id: string; name: string } | null
  }
}

type TaskAssignee_User_Fragment = { __typename: 'User'; id: string }

type TaskAssignee_UserGroup_Fragment = {
  __typename: 'UserGroup'
  id: string
  name: string
}

export type TaskAssigneeFragment =
  | TaskAssignee_User_Fragment
  | TaskAssignee_UserGroup_Fragment

export type UseSalesTasksQueryVariables = Exact<{
  salesId: Scalars['ID']
}>

export type UseSalesTasksQuery = {
  __typename: 'Query'
  tasksBySales: Array<{
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }>
}

export type UseTaskQueryVariables = Exact<{
  number: Scalars['Int']
}>

export type UseTaskQuery = {
  __typename: 'Query'
  taskByNumber: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}

export type CloseTaskMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CloseTaskMutation = {
  __typename: 'Mutation'
  closeTask: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}

export type CreateTaskMutationVariables = Exact<{
  input: InputMaybe<CreateTaskInput>
}>

export type CreateTaskMutation = {
  __typename: 'Mutation'
  createTask: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteTaskMutation = {
  __typename: 'Mutation'
  deleteTask: { __typename: 'TaskDeletePayload'; id: string; deleted: boolean }
}

export type OpenTaskMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type OpenTaskMutation = {
  __typename: 'Mutation'
  openTask: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}

export type SetTaskAssigneeMutationVariables = Exact<{
  input: TaskSetAssigneeInput
}>

export type SetTaskAssigneeMutation = {
  __typename: 'Mutation'
  taskSetAssignee: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID']
  input: InputMaybe<UpdateTaskInput>
}>

export type UpdateTaskMutation = {
  __typename: 'Mutation'
  updateTask: {
    __typename: 'Task'
    description: string | null
    dueDate: string | null
    dueTime: string | null
    id: string
    isOpen: boolean
    name: string | null
    number: number
    assignee:
      | { __typename: 'User'; id: string }
      | { __typename: 'UserGroup'; id: string; name: string }
      | null
    auditLog: { __typename: 'TaskAuditLog'; updatedAt: string }
    purchaseProducts: Array<{
      __typename: 'PurchaseProduct'
      id: string
      targetType: TargetType
      attributes: {
        __typename: 'PurchaseProductAttributes'
        quantity: number
        duration: { __typename: 'Duration'; from: string; to: string }
      }
      auditLog: { __typename: 'AuditLog'; createdAt: string }
      link: {
        __typename: 'PurchaseProductLink'
        participant: {
          __typename: 'SalesParticipant'
          firstName: string
          id: string
          lastName: string
        } | null
        reservation: {
          __typename: 'ResourceReservation'
          id: string
          resourceQuantity: number
          resource:
            | {
                __typename: 'ResourceEquipment'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceHuman'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourceProperty'
                id: string
                isPooled: boolean
                name: string
              }
            | {
                __typename: 'ResourcePropertyNested'
                id: string
                isPooled: boolean
                name: string
              }
            | null
        } | null
        sales: {
          __typename: 'Sales'
          id: string
          orderNumber: number | null
          type: SalesType
        }
        task: {
          __typename: 'Task'
          id: string
          assignee:
            | { __typename: 'User' }
            | { __typename: 'UserGroup'; id: string; name: string }
            | null
        } | null
      }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: number
        lifecycle: { __typename: 'OrderLifecycle'; state: OrderState }
        paymentInfo: { __typename: 'PaymentInfo'; status: PaymentStatus }
      }
      product: { __typename: 'SalesProduct'; id: string; name: string }
      status: {
        __typename: 'PurchaseProductStatus'
        issues: Array<{
          __typename: 'ValidationIssue'
          code: number
          key: string
          level: IssueLevel
        }>
        validatedActions: Array<{
          __typename: 'PurchaseProductActionValidated'
          action: PurchaseProductAction
          valid: boolean
          issues: Array<{
            __typename: 'ValidationIssue'
            code: number
            key: string
            level: IssueLevel
          }>
        }>
      }
      totalPrice: {
        __typename: 'PriceElements'
        amount: number
        amountVatIncluded: number
        vatAmount: number
      }
    }>
    recommendedProducts: Array<{
      __typename: 'ProductTitleBasic'
      id: string
      type: string
      salesType: CatalogSalesType | null
      code: string | null
      name: string
      vatRate: number
      unitPrice: number
      quantityUnit: QuantityUnit
      catalog: { __typename: 'Catalog'; id: string; name: string }
    }>
    reservation: {
      __typename: 'ResourceReservation'
      description: string | null
      end: string
      id: string
      internalNote: string | null
      resourceQuantity: number
      start: string
      group: {
        __typename: 'ResourceReservationGroup'
        name: string | null
        number: number
      } | null
      resource:
        | {
            __typename: 'ResourceEquipment'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceHuman'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourceProperty'
            id: string
            name: string
            type: ResourceType
          }
        | {
            __typename: 'ResourcePropertyNested'
            id: string
            name: string
            type: ResourceType
          }
        | null
    } | null
    sales: {
      __typename: 'Sales'
      commissionRate: number
      id: string
      locked: boolean
      name: string | null
      orderNumber: number | null
      type: SalesType
      customer: {
        __typename: 'CustomerReference'
        address: { __typename: 'CustomerAddress'; id: string } | null
        contact: {
          __typename: 'CustomerOrganizationContact'
          id: string
        } | null
        customer:
          | {
              __typename: 'CustomerOrganization'
              defaultContactId: string | null
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              contacts: Array<{
                __typename: 'CustomerOrganizationContact'
                email: string | null
                firstName: string
                id: string
                lastName: string
                notes: string | null
                phone: string | null
              }>
              eInvoicingAddress: {
                __typename: 'EInvoicingAddress'
                address: string
                operator: string
              } | null
              organization: {
                __typename: 'CustomerOrganizationData'
                businessId: string | null
                name: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
          | {
              __typename: 'CustomerPerson'
              customerNumber: string
              defaultAddressId: string | null
              id: string
              publicName: string | null
              person: {
                __typename: 'CustomerPersonData'
                email: string | null
                firstName: string
                lastName: string
                phone: string | null
              }
              addresses: Array<{
                __typename: 'CustomerAddress'
                id: string
                label: string | null
                postalAddress: {
                  __typename: 'PostalAddressType'
                  address1: string | null
                  address2: string | null
                  city: string | null
                  country: string | null
                  postcode: string | null
                } | null
              }>
              labels: Array<{
                __typename: 'CustomerLabel'
                id: string
                name: string
              }>
            }
      } | null
      facet: {
        __typename: 'SalesFacet'
        id: string
        features: Array<{ __typename: 'SalesFacetFeature'; feature: Feature }>
      }
      lifecycle: {
        __typename: 'SalesLifecycle'
        state: {
          __typename: 'SalesState'
          key: string
          systemState: SalesSystemState
          names: Array<{
            __typename: 'LocalizedContent'
            locale: string
            content: string
          }>
        }
      }
      orders: Array<{
        __typename: 'Order'
        id: string
        name: string | null
        sortOrder: number
      }>
      seller: { __typename: 'Seller'; id: string; name: string } | null
    }
  }
}
