import styled, { css } from 'styled-components/macro'

export const Section = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(2)}rem;
  `}

  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
`
