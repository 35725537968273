import { documentService } from '../services'

export const generateDownloadFn = (url: string, name: string) => async () => {
  const link = window.document.createElement('a')
  link.download = `${name}.pdf`
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const generateDownloadFnById =
  (id: string, name: string) => async () => {
    try {
      const url = await documentService.buildPdf(id)
      return generateDownloadFn(url, name)()
    } catch (err) {
      console.error('Failed to download', err)
      throw new Error(`Failed to download target PDF [id=${id}]`)
    }
  }
