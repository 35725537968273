import { useState } from 'react'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { SortOption } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticProductTitle } from '@/modules/Products/types'

import { ListFilters, ListHeader, ListItem } from './components'

const ComponentIds = Object.freeze({
  CATEGORY: 'category',
  LIST: 'page',
  SEARCH: 'product-titles-search',
})

type SelectedIds = {
  elasticItemId: string
  itemId: string
}

type Props = {
  isMulti?: boolean
  onItemSelect?: (item: ElasticProductTitle) => Promise<any>
  onItemUnselect?: (id: string) => Promise<any>
  sellerId: string
}

export const ProductTitleList = ({
  isMulti = false,
  onItemSelect,
  onItemUnselect,
  sellerId,
}: Props) => {
  const { language } = useLanguageContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<SelectedIds[]>([])

  const sortOptions: SortOption[] = [
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate('Products:CatalogProductList.sort.nameAsc', language),
    },
    {
      dataField: 'name.keyword',
      direction: 'desc',
      label: translate('Products:CatalogProductList.sort.nameDesc', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { _score: 'asc' },
        { [sortProperty.dataField]: sortProperty.direction },
        { 'name.keyword': 'asc' },
      ]
    : [{ _score: 'asc' }, { 'name.keyword': 'asc' }]

  const defaultQueryFn = () => ({
    query: {
      nested: {
        path: 'sellers',
        query: {
          bool: {
            should: [
              { term: { 'sellers.id': sellerId } },
              { term: { 'sellers.succeededById': sellerId } },
            ],
          },
        },
      },
    },
    sort,
  })

  const handleItemSelect = (item: ElasticProductTitle) => {
    if (isProcessing || !onItemSelect) {
      return new Promise<void>((reject) => reject())
    }

    setProcessing(true)

    if (isMulti && onItemUnselect) {
      const isItemSelected = selectedIds
        .map(({ elasticItemId }) => elasticItemId)
        .includes(item.id)

      const selectedItemId = selectedIds.find(
        ({ elasticItemId }) => elasticItemId === item.id
      )?.itemId

      return !isItemSelected
        ? onItemSelect(item)
            .then((data) => {
              if (typeof data === 'string') {
                setSelectedIds([
                  ...selectedIds,
                  { elasticItemId: item.id, itemId: data },
                ])
              }
            })
            .finally(() => setProcessing(false))
        : onItemUnselect(selectedItemId || '')
            .then((data) => {
              if (typeof data === 'string') {
                setSelectedIds(
                  selectedIds.filter(({ itemId }) => itemId !== selectedItemId)
                )
              }
            })
            .finally(() => setProcessing(false))
    } else {
      return onItemSelect(item)
    }
  }

  return (
    <ElasticFilterSearchList
      columnCount={4}
      indexName="registry.product-title"
      reactiveListProps={{
        componentId: ComponentIds.LIST,
        dataField: 'name',
        defaultQuery: defaultQueryFn,
        react: {
          and: [ComponentIds.CATEGORY, ComponentIds.SEARCH],
        },
        size: 50,
      }}
      renderListFilters={() => (
        <ListFilters
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
        />
      )}
      renderListHeader={() => (
        <ListHeader
          isMulti={isMulti}
          selectedItemsNumber={selectedIds.length}
        />
      )}
      renderListItem={(item: ElasticProductTitle) => (
        <ListItem
          data={item}
          disabled={isProcessing}
          isMulti={isMulti}
          isSelected={selectedIds
            .map(({ elasticItemId }) => elasticItemId)
            .includes(item.id)}
          key={`product-title-${item.id}`}
          onItemSelect={() => handleItemSelect(item)}
        />
      )}
      sortProps={{
        options: sortOptions,
        setValue: setSortProperty,
        value: sortProperty,
      }}
      title={<T>Products:ProductTitleList.title</T>}
    />
  )
}
