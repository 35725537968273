import { ApolloQueryResult, gql, useQuery } from '@apollo/client'

import {
  UseOwnerDocumentsQuery as QueryData,
  UseOwnerDocumentsQueryVariables as QueryVariables,
} from '~generated-types'

import { documentFragments } from '../fragments'
import { DownloadableDocument } from '../types'
import { generateDownloadFnById, generatePrintFnById } from '../utils'

type Params = {
  ownerId: string
  skip?: boolean
}

type Hook = {
  documentsByTemplateType: Record<string, DownloadableDocument[]>
  error: boolean
  loading: boolean
  refetch: () => Promise<ApolloQueryResult<QueryData>>
}

const QUERY = gql`
  ${documentFragments.document}

  query UseOwnerDocuments($ownerId: ID!) {
    documentsByOwnerId(ownerId: $ownerId) {
      ...Document
    }
  }
`

export const useOwnerDocuments = ({ ownerId, skip }: Params): Hook => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip,
      variables: { ownerId },
    }
  )

  const documentsByTemplateType: Record<string, DownloadableDocument[]> = data
    ? data?.documentsByOwnerId.reduce(
        (acc: Record<string, DownloadableDocument[]>, document) => {
          const templateType: string =
            document.template.documentType || 'undefined'

          acc[templateType] = acc[templateType] || []
          acc[templateType].push({
            document,
            downloadPDF: generateDownloadFnById(document.id, document.name),
            printPDF: generatePrintFnById(document.id),
          })

          return acc
        },
        {}
      )
    : {}

  return {
    documentsByTemplateType,
    error: !!error,
    loading,
    refetch,
  }
}
