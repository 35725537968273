import confirmation from './confirmation'
import confirmationInfo from './confirmationInfo'
import invoice from './invoice'
import invoiceInfo from './invoiceInfo'
import invoicePaymentInfo from './invoicePaymentInfo'
import invoiceWithPayments from './invoiceWithPayments'
import offer from './offer'
import offerInfo from './offerInfo'
import order from './order'
import orderCustomer from './orderCustomer'
import orderInfo from './orderInfo'
import orderShortInfo from './orderShortInfo'
import payment from './payment'

export const orderFragments = Object.freeze({
  confirmation,
  confirmationInfo,
  invoice,
  invoiceInfo,
  invoicePaymentInfo,
  invoiceWithPayments,
  offer,
  offerInfo,
  order,
  orderCustomer,
  orderInfo,
  orderShortInfo,
  payment,
})
