import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

export const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(4)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(4)}rem;
  `}
`

export const SubTitle = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`
