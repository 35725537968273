import { css } from 'styled-components/macro'

export const buttonCss = css`
  border-radius: 6px;
  font-weight: 500;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.main};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizeBase};
    height: ${theme.spacing.gu(4)}rem;
    margin-bottom: ${theme.spacing.gutterSmall};
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:focus,
  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

export const inputCss = css<{ disabled?: boolean }>`
  &:not([type='checkbox']):not([type='radio']) {
    flex-grow: 1;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.1s ease-out;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(1)}rem;
      margin-bottom: ${theme.spacing.gutterSmall};
      height: ${theme.spacing.gu(5)}rem;
      line-height: ${theme.spacing.gu(2)};
      max-width: ${theme.spacing.gu(60)}rem;
      color: ${theme.palette.text.main};
      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.fontSizeBase};
      border: 1px solid ${theme.palette.smoke.dark};
      background: ${theme.palette.white};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }

    &:focus,
    &:active {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}

      border-color: ${(props) =>
        props.disabled
          ? props.theme.palette.smoke.main
          : props.theme.palette.primary.main};
    }
  }
`

export const titleCss = css`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

export const listCss = css`
  & > h2 {
    ${titleCss}
  }

  & > ul {
    width: 100%;
    padding: 0;
  }

  & > ul > li {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.fontSizeBase};

      @media (max-width: 900px) {
        font-size: ${theme.typography.fontSizeBase2};
      }
    `}

    min-height: unset;
  }

  & > ul > li > label {
    ${({ theme }) => css`
      margin: 0 0 ${theme.spacing.gutterSmall};

      @media (max-width: 900px) {
        margin: 0 0 ${theme.spacing.gu(1)}rem;
      }
    `}
  }

  & > ul > li > label::after {
    ${({ theme }) => css`
      border-color: ${theme.palette.white};
      left: calc(16px / 5);
    `}
  }

  & > ul > li > label::before {
    border-width: 2px;
    box-sizing: border-box;
    width: 16px;
  }

  & > ul > li > label > span {
    margin-top: 2px;
    flex: 1;
  }
`
