import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'
import { Editor } from 'react-draft-wysiwyg'
import ReactMarkdown from 'react-markdown'
import styled, { createGlobalStyle, css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Gutter } from '@/components/Layout/Gutter'
import { T, useLanguageContext } from '@/modules/Language'

import { translations } from './translations'
import { convertDraftToPDFString } from './utils'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

type Props = {
  editorValue: string | null
  updateAttribute: (editorValue: string | null, templateValue: string) => void
}

export const AttributeRichEditor = ({
  editorValue,
  updateAttribute,
}: Props) => {
  const { language } = useLanguageContext()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [showForm, setShowForm] = useState<boolean>(false)

  const editorValueObject =
    typeof editorValue === 'string' ? JSON.parse(editorValue) : editorValue

  const editorContent = editorValue
    ? EditorState.createWithContent(convertFromRaw(editorValueObject))
    : EditorState.createEmpty()

  const cancelChanges = () => {
    setEditorState(editorContent)
  }

  const saveAttribute = () => {
    const editorContentAsJSON = convertToRaw(editorState.getCurrentContent())
    const PDFString = convertDraftToPDFString(editorContentAsJSON)

    updateAttribute(JSON.stringify(editorContentAsJSON), PDFString)
  }

  const handleChangeToShowForm = () => {
    setEditorState(editorContent)
    setShowForm(true)
  }

  const handleCancelForm = () => {
    cancelChanges()
    setShowForm(false)
  }

  const handleSaveForm = () => {
    saveAttribute()
    setShowForm(false)
  }

  const markdown: string = editorValue ? draftToMarkdown(editorValueObject) : ''

  return (
    <>
      <MarkdownCss />

      <Wrapper className="markdown-wrapper">
        {showForm ? (
          <>
            <Editor
              editorState={editorState}
              localization={{
                locale: language,
                translations:
                  translations[language as keyof typeof translations],
              }}
              onEditorStateChange={(value) => setEditorState(value)}
              toolbar={{
                inline: {
                  options: ['bold', 'italic'],
                },
                link: {
                  showOpenOptionOnHover: false,
                },
                options: ['inline', 'link'],
              }}
            />
            <GutterBottom type={[1]}>
              <InnocuousButton compact noNudge onClick={handleCancelForm}>
                <T>common:action.cancel</T>
              </InnocuousButton>

              <InnocuousButton compact noNudge onClick={handleSaveForm}>
                <PrimaryColor>
                  <T>common:action.ready</T>
                </PrimaryColor>
              </InnocuousButton>
            </GutterBottom>
          </>
        ) : (
          <ClickableRegion onClick={handleChangeToShowForm}>
            <MarkdownCss />

            <EditButton>
              <span className="edit-label">
                <T>common:action.edit</T>
              </span>

              <EditIcon icon="pen" size="xs" />
            </EditButton>

            <Gutter type={[2, 10, 1, 2]}>
              {!markdown && (
                <Placeholder>
                  <T>Document:Settings.attributes.placeholder</T>
                </Placeholder>
              )}
              <ReactMarkdown children={markdown} />
            </Gutter>
          </ClickableRegion>
        )}
      </Wrapper>
    </>
  )
}

const MarkdownCss = createGlobalStyle`
  & {
    .markdown-wrapper p {
      ${({ theme }) => css`
        margin: 0 0 ${theme.spacing.gu(1)}rem;
      `}
    }

    .public-DraftStyleDefault-block {
      ${({ theme }) => css`
        margin: 0 0 ${theme.spacing.gu(1)}rem;
      `}
    }

    .rdw-editor-main {
      border-radius: 0;

      ${({ theme }) => css`
        background: ${theme.palette.white};
        border-bottom: 1px solid ${theme.palette.smoke.main};
        border-top: 1px solid ${theme.palette.smoke.main};
        padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
          ${theme.spacing.gu(1)}rem;
      `}
    }

    .rdw-editor-toolbar.rdw-editor-toolbar {
      margin-bottom: 0;
      border: none;
      border-radius: 6px 6px 0 0;
      
      ${({ theme }) => css`
        padding: ${theme.spacing.gu(1)}rem;
        background: ${theme.palette.smoke.lighter};
      `}
    }

    .rdw-option-wrapper {
      border-radius: 4px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.main};
      `}
    }

    .rdw-editor-toolbar > div {
      margin-bottom: 0;
    }

    .rdw-link-modal {
      border-radius: 4px;
      box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
      height: auto;
      width: 204px;
      z-index: 501;
    }

    .rdw-link-modal-input {
      border-radius: 4px;
    }

    .rdw-link-modal-label {
      ${({ theme }) => css`
        font-size: ${theme.typography.fontSizeBase};
      `}
    }

    .rdw-link-modal-target-option {
      display: none;
    }

    .rdw-link-modal-buttonsection {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0;
    }

    .rdw-link-modal-btn {
      border-radius: 4px;
      margin: 0;

      ${({ theme }) => css`
        font-size: ${theme.typography.fontSizeBase};
      `}
    }
  }
`

const Wrapper = styled.div`
  white-space: normal;
  font-weight: 400;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(2)}rem 0;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active,
  &:focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const ClickableRegion = styled.div`
  position: relative;
  font-weight: 400;
  outline: none;
  cursor: pointer;

  & .edit-label {
    display: none;

    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }

  &:hover .edit-label {
    display: inline;
  }
`

const EditButton = styled.button`
  position: absolute;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    top: ${theme.spacing.gu(1.5)}rem;
    right: ${theme.spacing.gu(1.5)}rem;
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(1.25)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const EditIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Placeholder = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const GutterBottom = styled(Gutter)`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.lighter};
  `}
`
