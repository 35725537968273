import { Glossary } from './glossary.types'

export const en: Glossary = {
  address1: ['address 1'],
  address2: ['address 2'],
  age: ['age'],
  allergyDescription: ['allergy', 'allergy description'],
  birthday: ['birthday'],
  city: ['city'],
  country: ['country'],
  drugAllergyDescription: ['drug allergy', 'drug allergy description'],
  education: ['education'],
  email: ['email'],
  firstName: ['first name'],
  gender: ['gender'],
  group: ['group'],
  info: ['info'],
  language: ['language'],
  lastName: ['last name'],
  lifeStage: ['life stage'],
  nationality: ['nationality'],
  postcode: ['postcode', 'postal code'],
  roomNumber: ['room', 'room number'],
}
