import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { FetchState } from '@/common/types'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Gutter } from '@/components/Layout/Gutter'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { generateDownloadFn, generatePrintFn } from '@/modules/Document/utils'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { DocumentPreview } from './components'

type Props = {
  name: string
  onClose: () => void
  state: FetchState
  url: string
}

export const DocumentPreviewModal = ({ name, onClose, state, url }: Props) => {
  const { spacing } = useTheme()
  const { pathname, search } = useLocation()

  return createPortal(
    <>
      <SideDrawer width={`${spacing.gu(110)}rem`}>
        <Wrapper>
          <DocumentPreview
            handleDownload={generateDownloadFn(url, name)}
            handlePrint={generatePrintFn(url)}
            state={state}
            url={url}
          />
        </Wrapper>

        <SideDrawerBottomControls>
          <Gutter type={['gutter', 'gutterBig']}>
            <InnocuousButton noNudge onClick={onClose}>
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>

      <SideDrawerShadow to={`${pathname}${search}`} onClick={onClose} />
    </>,
    document.getElementById('modal-root') as HTMLDivElement
  )
}

///////

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`
