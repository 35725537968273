import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { useAuthContext } from '@/modules/Auth'
import { useTheme } from '@/theme'

import { AppBarLogo } from '../components'
import {
  LanguageToggle,
  LastUsedSales,
  NavbarButton,
  NewSaleButton,
  UserMenuButton,
} from './components'

export const MainNavbarDesktop = () => {
  const { branding } = useTheme()
  const { navItems } = useWillbaConfigContext()
  const { signOut, user } = useAuthContext()

  return (
    <Wrapper alignItems="center">
      <LogoWrapper>
        <AppBarLogo alt="Willba Logo" href="/" src={branding.logoWideSrc} />
      </LogoWrapper>

      <NavItemsWrapper>
        <NavItems>
          {navItems.map((item, idx) => (
            <NavbarButton key={`navLink-${item.key}-${idx}}`} item={item} />
          ))}
        </NavItems>
      </NavItemsWrapper>

      <LastUsedSales />

      <NewSaleButton />

      <LanguageToggle />

      {user && <UserMenuButton signOut={signOut} user={user} />}
    </Wrapper>
  )
}

////////////

const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter} 0 ${theme.spacing.gu(5)}rem;
  `}
`

const NavItemsWrapper = styled.nav`
  flex: 1;
  height: 100%;
`

const NavItems = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0;
  padding: 0;
  height: 100%;

  list-style: none;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(8)}rem;
    background: ${theme.palette.white};
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}

  @media print {
    display: none;
  }
`
