import { gql } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

import confirmationInfo from './confirmationInfo'

export default gql`
  ${confirmationInfo}
  ${documentFragments.document}

  fragment Confirmation on OrderConfirmation {
    ...ConfirmationInfo

    document {
      ...Document
    }
  }
`
