import { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import styled, { css } from 'styled-components'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

type Props = GridOptions<Record<string, string | number | null | undefined>>

export const AgGrid = ({ columnDefs, rowData, rowClassRules }: Props) => (
  <GridWrapper className="ag-theme-quartz">
    <AgGridReact
      rowData={rowData}
      columnDefs={columnDefs}
      rowClassRules={rowClassRules}
    />
  </GridWrapper>
)

/////////

const GridWrapper = styled.div`
  height: 1100px;
  width: 100%;

  ${({ theme }) => css`
    .ag-cell-error {
      color: ${theme.palette.danger.darker};
      background: #ffefef;
    }

    .ag-row-error {
      background: #ffefef;
    }
  `}
`
