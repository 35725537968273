import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { EventEnrollment } from '@/modules/Sales/types'
import { useTheme } from '@/theme'

import { Section } from './Section'

type Props = {
  customer: EventEnrollment['customer']
}

export const CustomerSection = ({ customer }: Props) => {
  const { spacing } = useTheme()

  return (
    <Section width={`${spacing.gu(25)}rem`}>
      <Wrapper alignItems="center">
        {customer?.customer ? (
          <>
            <Icon
              fixedWidth
              icon={
                customer?.customer.__typename === 'CustomerOrganization'
                  ? ['far', 'building']
                  : 'user'
              }
              size="sm"
            />
            <Name>
              {customer?.customer.__typename === 'CustomerOrganization'
                ? `${customer.customer.organization.name}`
                : `${customer.customer.person.firstName}, ${customer.customer.person.lastName}`}
            </Name>
          </>
        ) : (
          <Placeholder>
            <T>SalesDetails:Customer.unset</T>
          </Placeholder>
        )}
      </Wrapper>
    </Section>
  )
}

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexRow)`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Name = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(22)}rem;
  `}
`
