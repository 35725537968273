import { ReactNode, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { DropdownButton, InnocuousButton } from '@/components/ExtraButtons'
import { notify } from '@/components/NotificationService'
import { DocumentEditorModal } from '@/modules/Document'
import { documentHooks } from '@/modules/Document/hooks'
import { Document } from '@/modules/Document/types'
import { T, translate } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ExcelImport } from './ExcelImport'
import generateParticipantExcel from './generateParticipantExcel'
import { PrintableParticipantsList } from './PrintableParticipantsList'

type Props = {
  ownerId: string
}

export const DocumentsButton = ({ ownerId }: Props) => {
  const client = useApolloClient()
  const { palette } = useTheme()
  const { documentsByTemplateType, loading: loadingDocuments } =
    documentHooks.useOwnerDocuments({
      ownerId,
    })
  const {
    data: { language },
  } = salesHooks.useSalesDetailsContext()

  const [isImportModalOpen, setImportModalOpen] = useState<boolean>(false)
  const [isPrintModalOpen, setPrintModalOpen] = useState<boolean>(false)
  const [openedDocument, setOpenedDocument] = useState<Document | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)

  const doc = [...(documentsByTemplateType['PARTICIPANT_LIST'] || [])].sort(
    generateCompareFn('document.name')
  )[0]

  const handleDownloadParticipantExcel = () => {
    setProcessing(true)

    generateParticipantExcel({
      client,
      salesId: ownerId,
      translateFn: (keys) => translate(keys, language),
    })
      .catch(() =>
        notify({ content: <T>common:error.common</T>, type: 'ERROR' })
      )
      .finally(() => setProcessing(false))
  }

  const renderLabel = (label: string | ReactNode, icon: IconProp) => (
    <>
      <Icon icon={icon} />
      {label}
    </>
  )

  const documentOptions = doc
    ? [
        {
          disabled: processing,
          label: renderLabel(
            <>
              <T>ParticipantsList:openDocument</T> <small>(PDF)</small>
            </>,
            'print'
          ),
          onClick: () => setOpenedDocument(doc.document),
        },
      ]
    : []

  const options = [
    {
      disabled: processing,
      label: renderLabel(<T>ParticipantsList:PrintableList.action</T>, 'print'),
      onClick: () => setPrintModalOpen(true),
    },
    ...documentOptions,
    {
      disabled: processing,
      label: renderLabel(<T>ParticipantsList:downloadExcel</T>, [
        'far',
        'file-excel',
      ]),
      onClick: handleDownloadParticipantExcel,
    },
    {
      disabled: processing,
      label: renderLabel(
        <T>ParticipantsList:Import.importParticipants</T>,
        'file-import'
      ),
      onClick: () => setImportModalOpen(true),
    },
  ]

  return (
    <>
      <DropdownButton
        options={options}
        dropdownPlacement="bottom-start"
        renderCustomButton={({ onClick }) => (
          <IconButtonWrapper>
            {processing || loadingDocuments ? (
              <ReactLoading
                color={palette.smoke.dark}
                height={18}
                type="spin"
                width={18}
              />
            ) : (
              <IconButton noNudge onClick={onClick}>
                <FontAwesomeIcon icon="ellipsis-vertical" size="lg" />
              </IconButton>
            )}
          </IconButtonWrapper>
        )}
      />

      {isImportModalOpen && (
        <ExcelImport closeModal={() => setImportModalOpen(false)} />
      )}

      {isPrintModalOpen && (
        <PrintableParticipantsList
          onClose={() => setPrintModalOpen(false)}
          salesId={ownerId}
        />
      )}

      {openedDocument && (
        <DocumentEditorModal
          document={openedDocument}
          onClose={() => setOpenedDocument(null)}
          onUpdateDocument={(doc) => setOpenedDocument(doc)}
          showLanguageSettings
          showTemplateSettings
          showSettingsSection
        />
      )}
    </>
  )
}

//////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 20px;
    margin-right: 8px;
  }
`

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
  `}
`

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
