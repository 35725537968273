import { Reservation } from '@/modules/Reservations/ResourceReservation'
import { resourceReservationHooks as hooks } from '@/modules/Reservations/ResourceReservation'

import { ResourceReservationCustomerVisibility as CustomerVisibility } from '~generated-types'

import { CustomerVisibilityChip } from './CustomerVisibilityChip'

type Props = {
  customerVisibility: CustomerVisibility
  disabled?: boolean
  reservationId: string
  setReservations: (reservations: Reservation[]) => void
}

export const CustomerVisibilityToggle = ({
  customerVisibility,
  disabled,
  reservationId,
  setReservations,
}: Props) => {
  const { setVisibility } = hooks.useResourceReservationMutations({
    updateReservations: setReservations,
  })

  const handleSetVisibility = () =>
    setVisibility({
      customerVisibility: getNextVisibility(customerVisibility),
      reservationId,
    })

  return (
    <CustomerVisibilityChip
      onClick={handleSetVisibility}
      visibility={customerVisibility}
      disabled={disabled}
    />
  )
}

export default CustomerVisibilityToggle

////////////

const getNextVisibility = (customerVisibility: CustomerVisibility) => {
  switch (customerVisibility) {
    case CustomerVisibility.Hidden:
      return CustomerVisibility.Visible
    case CustomerVisibility.Visible:
      return CustomerVisibility.ResourceNameHidden
    case CustomerVisibility.ResourceNameHidden:
    default:
      return CustomerVisibility.Hidden
  }
}
