import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { Gutter } from '@/components/Layout'
import { DatePicker } from '@/components/TimeControls'

import { SalesForDateType } from '~generated-types'

import { DimensionFilter } from './DimensionFilter'
import { FreeTextSearch } from './FreeTextSearch'
import { SaleTypeFilter } from './SaleTypeFilter'
import { TypeFilter } from './TypeFilter'

type Props = {
  date: Moment
  onChangeDimensionHits: (salesIds: string[]) => void
  onChangeSearchHits: (salesIds: null | string[]) => void
  onSetDate: (date: Moment) => void
  onSetSaleTypes: (saleTypes: string[]) => void
  onSetTypes: (type: SalesForDateType[]) => void
  saleTypes: string[]
  types: SalesForDateType[]
}

export const Sidebar = ({
  date,
  onChangeDimensionHits,
  onChangeSearchHits,
  onSetDate,
  onSetSaleTypes,
  onSetTypes,
  saleTypes,
  types,
}: Props) => (
  <Wrapper>
    <DatePicker
      setValue={(date) => onSetDate(date || moment())}
      showTodayButton
      value={date}
    />

    <Separator />

    <Gutter type={[2, 2]}>
      <FreeTextSearch onChangeMatchingSales={onChangeSearchHits} />

      <TypeFilter setValue={onSetTypes} value={types} />

      <SaleTypeFilter setValue={onSetSaleTypes} value={saleTypes} />

      <DimensionFilter onChangeMatchingSales={onChangeDimensionHits} />
    </Gutter>
  </Wrapper>
)

////////////

const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 280px;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
  `}
`
