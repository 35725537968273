import { gql } from '@apollo/client'

export default gql`
  query DimensionShortcuts($salesId: ID) {
    registry {
      dimensionShortcuts(salesId: $salesId) {
        id
        name
      }
    }
  }
`
