import { gql, useMutation } from '@apollo/client'

import { documentFragments } from '@/modules/Document/fragments'

import type {
  SetDocumentTemplateMutation,
  SetDocumentTemplateMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  ${documentFragments.document}

  mutation SetDocumentTemplate($input: DocumentSetTemplateInput!) {
    documentSetTemplate(input: $input) {
      ...Document
    }
  }
`

export const useSetDocumentTemplateMutation = () =>
  useMutation<
    SetDocumentTemplateMutation,
    SetDocumentTemplateMutationVariables
  >(MUTATION)
