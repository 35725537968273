import { gql, useMutation } from '@apollo/client'

import {
  SetSalesFacetMutation,
  SetSalesFacetMutationVariables,
} from '~generated-types'

const MUTATION = gql`
  mutation SetSalesFacet($input: SalesUpdateFacetInput!) {
    salesUpdateFacet(input: $input) {
      id
      facet {
        abbreviation
        id
        name
        salesType
      }
    }
  }
`

export const useSetSalesFacetMutation = () =>
  useMutation<SetSalesFacetMutation, SetSalesFacetMutationVariables>(MUTATION)
