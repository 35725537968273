import { ChangeEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { attachmentHooks } from '@/modules/Attachments/hooks'
import { Attachment } from '@/modules/Attachments/types'
import { DocumentPreviewModal } from '@/modules/Document'
import { EditButton } from '@/modules/Order/components'
import { useTheme } from '@/theme'
import { getFileName, getFileType } from '@/utils/files'

import { FileOwner } from '~generated-types'

type Props = {
  attachment: Attachment
  attachments: Attachment[]
  documentId: string
  onUpdateAttachments: (attachments: Attachment[]) => void
  readOnly: boolean
}

export const AttachmentEditor = ({
  attachment,
  attachments,
  documentId,
  onUpdateAttachments,
  readOnly,
}: Props) => {
  const theme = useTheme()

  const {
    fileName,
    id,
    info: { folder, ownerId, ownerType },
  } = attachment
  const { getAttachmentUrl, removeAttachment, renameAttachment } =
    attachmentHooks.useAttachment()

  const [editorValue, setEditorValue] = useState<string>(getFileName(fileName))
  const [fileState, setFileState] = useState<FetchState>(FetchStates.IDLE)
  const [fileUrl, setFileUrl] = useState<string>('')
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isFilePreviewOpen, setFilePreviewOpen] = useState<boolean>(false)
  const [isRemoving, setRemoving] = useState<boolean>(false)

  const handleOpenPreview = () => {
    setFilePreviewOpen(true)
    setFileState(FetchStates.LOADING)

    getAttachmentUrl(fileName, folder, ownerId, ownerType)
      .then((res) => {
        setFileUrl(res)
        setFileState(FetchStates.IDLE)
      })
      .catch(() => setFileState(FetchStates.ERROR))
  }

  const handleRemoveAttachment = () => {
    setRemoving(true)

    removeAttachment(fileName, documentId, FileOwner.Document)
      .then(
        ({ data }) =>
          data?.fileDelete.deleted &&
          onUpdateAttachments(attachments.filter((a) => a.id !== id))
      )
      .catch(() => setRemoving(false))
  }

  const handleRenameAttachment = () => {
    if (!isError) {
      renameAttachment(
        fileName,
        `${editorValue}.${getFileType(fileName).toLowerCase()}`,
        documentId,
        FileOwner.Document
      )
        .then(({ data }) => {
          data &&
            onUpdateAttachments(
              attachments.map((a) => (a.id === id ? data.fileUpdate : a))
            )
          setEditMode(false)
        })
        .catch((err) => console.error(err))
    }
  }

  const isError =
    editorValue.length === 0 ||
    attachments
      .filter((a: Attachment) => a.id !== id)
      .map((a: Attachment) => getFileName(a.fileName))
      .includes(editorValue)

  return (
    <FlexRow alignItems="center" justifyContent="space-between">
      <Dash>–</Dash>

      {isEditMode ? (
        <FileNameInput
          autoFocus
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEditorValue(e.target.value)
          }
          onBlur={handleRenameAttachment}
          onSubmit={handleRenameAttachment}
          type="text"
          value={editorValue}
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 1, overflow: 'hidden' }}
        >
          <FileName title={fileName}>{fileName}</FileName>
        </EditButton>
      )}

      <IconButton compact noNudge onClick={handleOpenPreview}>
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon={['far', 'file-lines']}
          size="sm"
        />
      </IconButton>

      <IconButton
        compact
        disabled={isRemoving}
        noNudge
        onClick={handleRemoveAttachment}
      >
        <FontAwesomeIcon
          color={theme.palette.danger.main}
          icon="trash"
          size="sm"
        />
      </IconButton>

      {isFilePreviewOpen && (
        <DocumentPreviewModal
          name={getFileName(fileName)}
          state={fileState}
          url={fileUrl}
          onClose={() => setFilePreviewOpen(false)}
        />
      )}
    </FlexRow>
  )
}

///////

const Dash = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
    color: ${theme.palette.text.lighter};
  `}
`

const FileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  text-overflow: ellipsis;
`

const FileNameInput = styled(Input)`
  &&& {
    height: 30px;
    width: fit-content;
  }
`

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
    margin: 0 0 0 ${theme.spacing.gutterSmall};
  `}
`
