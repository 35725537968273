import { gql } from '@apollo/client'

import { CapacityStatusFragment } from '@/modules/Accommodation/Accommodation.fragments'
import { productFragments } from '@/modules/Products/fragments'

export const ParticipantVst = gql`
  fragment ParticipantVst on ParticipantVst {
    end
    included
    lesson {
      fee
      hours
    }
    state
    start
    vstDays
  }
`

export const ParticipantRoomReservationFragment = gql`
  fragment ParticipantRoomReservation on RoomReservation {
    id
    request {
      checkIn {
        date
      }
      checkOut {
        date
      }
      features {
        icon
        id
        name
        shortName
      }
      info
      room {
        id
        number
        building {
          id
          name
        }
        roomType {
          id
          name
        }
      }
    }
    roomTypeReservation {
      request {
        features {
          icon
          id
          name
          shortName
        }
        info
      }
    }
  }
`

export const ParticipantRoomFragment = gql`
  ${ParticipantRoomReservationFragment}

  fragment ParticipantRoom on ParticipantRoom {
    checkIn
    checkOut
    id
    roomReservation {
      ...ParticipantRoomReservation
    }
  }
`

export const TargetFragment = gql`
  ${CapacityStatusFragment}

  fragment Target on AccommodationTarget {
    accommodationGroup {
      id
      name
      sortOrder
    }
    default
    id
    name
    private
    sharedTo {
      id
      orderNumber
    }
    sortOrder
    status {
      ...CapacityStatus
    }
  }
`

export const ParticipantTargetFragment = gql`
  fragment ParticipantTarget on AccommodationTarget {
    accommodationGroup {
      id
      name
      sortOrder
    }
    default
    id
    name
    sortOrder
  }
`

export const ServiceParticipantBedFragment = gql`
  ${ParticipantTargetFragment}
  ${ParticipantRoomFragment}
  ${productFragments.productPurchase}

  fragment ServiceParticipantBed on ServiceParticipantBed {
    accommodationTarget {
      ...ParticipantTarget
    }
    dates {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
    id
    participantRoom {
      ...ParticipantRoom
    }
    purchaseProduct {
      ...ProductPurchase
    }
  }
`

export const ServicePurchaseFragment = gql`
  ${productFragments.productPurchase}

  fragment ServicePurchase on ServicePurchase {
    id
    purchaseProduct {
      ...ProductPurchase
    }
  }
`

export const ParticipantServiceFragment = gql`
  ${ServiceParticipantBedFragment}
  ${ServicePurchaseFragment}

  fragment ParticipantService on ServiceParticipantConnection {
    id
    type
    ...ServiceParticipantBed
    ...ServicePurchase
  }
`

export const ParticipantPersonalDataFragment = gql`
  ${ParticipantVst}

  fragment ParticipantPersonalData on SalesParticipant {
    accommodationRequest
    additionalInfo
    age
    ageCategory {
      abbreviation
      key
      name
    }
    ageSource
    allergyDescription
    allergies
    auditLog {
      createdAt
    }
    birthday {
      date
      month
      year
    }
    diets
    drugAllergyDescription
    education
    email
    firstName
    gender
    group
    id
    language
    lastName
    lifeStage
    nationality
    postalAddress {
      address1
      address2
      city
      country
      postcode
    }
    roomFriends
    sales {
      id
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      name
      orderNumber
      type
    }
    sortOrder
    visitStatus {
      dayVisitorDates {
        end
        start
      }
      isDayVisitor
    }
    vst {
      ...ParticipantVst
    }
  }
`

export const ParticipantFragmentFull = gql`
  ${ParticipantPersonalDataFragment}
  ${ParticipantServiceFragment}

  fragment SalesParticipantFull on SalesParticipant {
    ...ParticipantPersonalData
    services {
      ...ParticipantService
    }
  }
`
