import { emailRegex } from '@/utils/forms'

import { Participant } from '../ExcelImport.types'

export const validateXlsxParticipantsData = (participants: Participant[]) => {
  const errorIndexes: { [key: number]: string[] } = {}

  participants.forEach((item, idx) => {
    if (item.email && !emailRegex.test(item.email)) {
      if (!errorIndexes[idx]) {
        errorIndexes[idx] = ['email']
      } else {
        errorIndexes[idx].push('email')
      }
    }
  })

  return errorIndexes
}
