import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  isProcessing: boolean
  onClick: () => Promise<void>
  disabled: boolean
}

export const ImportButton = ({ isProcessing, onClick, disabled }: Props) => {
  const { palette } = useTheme()

  return (
    <InnocuousButton
      disabled={isProcessing || disabled}
      noMargin
      onClick={onClick}
    >
      <PrimaryColor>
        <FlexRow alignItems="center">
          <IconWrapper>
            {isProcessing ? (
              <ReactLoading
                color={palette.primary.main}
                height={14}
                type="spin"
                width={14}
              />
            ) : (
              <FontAwesomeIcon icon="arrow-up-from-bracket" />
            )}
          </IconWrapper>

          <T>ParticipantsList:Import.import</T>
        </FlexRow>
      </PrimaryColor>
    </InnocuousButton>
  )
}

////////

const IconWrapper = styled.span`
  width: 14px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
