import { Reservation } from '@/modules/Reservations/ResourceReservation'

import {
  ResourceReservationCustomerVisibility as ReservationVisibility,
  ResourceType,
  Visibility as TimeVisibility,
} from '~generated-types'

import { ReservationVisibilityRow } from './ReservationVisibilityRow'
import { TimeVisibilityRow } from './TimeVisibilityRow'

type Props = {
  isProgramReservation: boolean
  readOnly: boolean
  reservationId: string
  reservationVisibility: ReservationVisibility
  resourceType: ResourceType | null
  timeVisibility: TimeVisibility
  updateReservations: (reservations: Reservation[]) => void
}

export const CustomerVisibility = ({
  isProgramReservation,
  reservationVisibility,
  resourceType,
  timeVisibility,
  ...props
}: Props) => (
  <>
    <ReservationVisibilityRow visibility={reservationVisibility} {...props} />

    {isProgramReservation && (
      <TimeVisibilityRow visibility={timeVisibility} {...props} />
    )}
  </>
)
